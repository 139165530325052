<template>
  <div class="page-wrap">
    <ProductionSettingsToolbar> </ProductionSettingsToolbar>
    <div class="main-page-column pt-0">
      <workflow-edit-dialog
        :edited-item="editedItem"
        :edit-mode="edit"
        :open="dialog"
        @save-workflow="saveWorkflow"
        @close-edit-workflow-dialog="close"
      ></workflow-edit-dialog>
      <div class="scroll-container pt-0">
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="3">
            <workflow-list
              :workflows="workflows"
              :current-user="currentUser"
              :loading="$apollo.queries.workflows.loading"
              @selected="setSelected"
              @editWorkflow="editWorkflow"
              @addWorkflow="addWorkflow"
            />
          </v-col>
          <v-col v-if="selectedItem">
            <workflow-box
              :selected-workflow="selectedItem"
              @reorderList="orderStepList"
              @edit-steps="saveWorkflowSteps"
            />
          </v-col>
          <v-col
            v-else-if="$apollo.queries.workflows.loading"
            cols="12"
            sm="12"
            md="8"
            lg="8"
            xl="9"
          >
            <v-skeleton-loader
              v-for="num in 3"
              :key="num"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ProductionSettingsToolbar from '../components/base/ProductionSettingsToolbar.vue'
import WorkflowList from '../../../components/core/WorkflowList'
import WorkflowBox from '../../../components/core/WorkflowBox'
import WorkflowEditDialog from '../../../components/core/WorkflowEditDialog'
import WORKFLOWS from '../../../graphql/Workflows.gql'
import CREATE_WORKFLOW from '../../../graphql/WorkflowCreate.gql'
import UPDATE_WORKFLOW from '../../../graphql/WorkflowUpdate.gql'

export default {
  name: 'ProductionSettingsWorkflow',
  components: {
    ProductionSettingsToolbar,
    WorkflowList,
    WorkflowBox,
    WorkflowEditDialog
  },
  data: () => ({
    dialog: false,
    stepDialog: false,
    edit: false,
    search: '',
    drag: false,
    item: 0,
    e6: null,
    defaultItem: {
      id: '',
      name: '',
      description: '',
      steps: [],
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      name: '',
      description: '',
      steps: [],
      updatedAt: new Date(),
      createdAt: new Date()
    },
    workflows: []
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    selectedItem() {
      return this.workflows[this.item]
    },
    authLevel() {
      return this.$store.getters.authLevel('production_settings')
    }
  },
  apollo: {
    workflows: WORKFLOWS
  },
  methods: {
    setSelected(idx) {
      this.item = idx
    },
    orderStepList(evt) {
      console.log(evt)
    },
    addWorkflow() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    editWorkflow(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },
    addStep(workflow, step) {
      // Open edit workflow step dialog
      console.log(workflow)
      console.log(step)
    },
    editStep(workflow) {
      this.edit = true
      this.editedItem = this._.cloneDeep(workflow)
      this.stepDialog = true
    },
    saveWorkflowSteps(item) {
      this.edit = true
      this.saveWorkflow(item)
    },
    saveWorkflow(workflow) {
      if (this.edit) {
        workflow.steps.forEach(element => {
          delete element.__typename
        })
        // Update Item
        let data = {
          id: workflow.id,
          name: workflow.name,
          description: workflow.description,
          templateStatus: workflow.templateStatus,
          steps: workflow.steps
        }

        this.$apollo
          .mutate({
            mutation: UPDATE_WORKFLOW,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.close(true)
          })
      } else {
        // New Item
        let data = {
          name: workflow.name,
          description: workflow.description
        }

        this.$apollo
          .mutate({
            mutation: CREATE_WORKFLOW,
            variables: {
              input: data
            },
            update: (store, { data: { createWorkflow } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: WORKFLOWS
              })
              // Add our requirement from the mutation to the end
              data.workflows.push(createWorkflow)
              // Write our data back to the cache.
              store.writeQuery({ query: WORKFLOWS, data })
            }
          })
          .then(() => {
            this.close(true)
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'A general server error occured. Please try again later'
            })
            return this.close(false)
          })
      }
    },
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: this.edit ? 'Updated Workflow' : 'Saved Workflow'
        })
      }
    },
    closeStepDialog(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.stepDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Steps Saved'
        })
      }
    }
  }
}
</script>

<style>
/* .page-wrap {
  --page-header-height: 162px;
} */

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  /* background: #c8ebfb; */
  background: blue;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.section-handle {
  cursor: grab;
}
.item-placeholder {
  display: block;
  /* height: 20px; */
}
</style>
