<template>
  <div class="page-wrap">
    <div
      v-if="
        !$apollo.loading &&
          (!shippers || !shippers.items || shippers.items.length > 0)
      "
      class="page-header"
    >
      <v-toolbar class="mb-0 b-radius" flat>
        <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
        <v-row>
          <v-col>
            <v-btn
              class="ml-2"
              color="primary lighten-2"
              rounded
              small
              @click="addShipper"
            >
              add shipper
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <shipper-edit-dialog
        :dialog="dialog"
        :shipper="editedItem"
        :payment-terms="paymentTerms"
        :currencies="currencies"
        :edit-mode="editMode"
        :processing="processing"
        :error-msg="errorMsg"
        @close-shipper-edit-dialog="close"
        @save-shipper-edit-dialog="save"
      ></shipper-edit-dialog>
      <div class="scroll-container pt-0">
        <v-card flat>
          <v-card-title class="pt-0">
            <v-text-field
              v-if="
                !$apollo.loading &&
                  shippers &&
                  shippers.items &&
                  shippers.items.length > 0
              "
              v-model="search"
              hide-details
              flat
              clearable
              background-color="#fff"
              placeholder="Search..."
              prepend-inner-icon="mdi-magnify"
              autocomplete="off"
            ></v-text-field>
          </v-card-title>

          <v-data-table
            v-model="selected"
            :loading="$apollo.loading"
            :headers="headers"
            :items="shippers.items"
            :options.sync="options"
            :server-items-length="itemCount"
            :mobile-breakpoint="null"
            fixed-header
            :hide-default-header="
              !$apollo.loading &&
                !search &&
                (!shippers || !shippers.items || shippers.items.length === 0)
            "
            hide-default-footer
            class="plain-table"
            @click:row="navToDetail"
          >
            <template v-slot:item.updatedAt="{ item }">
              {{ item.updatedAt | moment('from', 'now') }}
            </template>
            <template v-slot:no-data>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Shippers"
                :caption="
                  search
                    ? 'Try adjusting your filters to get some results'
                    : 'Add your first shipper to get started'
                "
              >
                <v-btn
                  v-if="!search"
                  class="ml-2"
                  color="primary lighten-2"
                  rounded
                  small
                  @click="addShipper"
                >
                  add shipper
                </v-btn>
              </message-box>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                v-for="item in 5"
                :key="item"
                type="list-item"
                class="mx-auto"
              ></v-skeleton-loader>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import ShipperEditDialog from '@/modules/production/components/core/ShipperEditDialog.vue'
import SHIPPER_LIST from '@/graphql/Shippers.gql'
import SHIPPER_CREATE from '@/graphql/ShipperCreate.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'

export default {
  name: 'ProductionShipperList',
  components: { MessageBox, ShipperEditDialog },
  data: () => ({
    search: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    selected: [],
    shippers: [],
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    defaultItem: {
      id: null,
      name: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null,
        country: null
      },
      companyRegistration: null,
      vatRegistration: null,
      paymentTerm: null,
      shippingTerm: null,
      currency: null
    },
    editedItem: {
      id: null,
      name: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null,
        country: null
      },
      companyRegistration: null,
      vatRegistration: null,
      paymentTerm: null,
      shippingTerm: null,
      currency: null
    }
  }),
  apollo: {
    shippers: {
      query: SHIPPER_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.shippers.page
        this.itemsPerPage = result.data.shippers.pageSize
        this.itemCount = result.data.shippers.total
      }
    },
    currencies: CURRENCY_LIST,
    paymentTerms: PAYMENT_TERM_LIST
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Payment Term',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'paymentTerm.name'
        },
        {
          text: 'Phone',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'addresses[0].phone'
        },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'addresses[0].email'
        },
        {
          text: 'Last Updated',
          value: 'updatedAt',
          sortable: true,
          filterable: true
        },
        {
          text: '',
          value: 'controls',
          sortable: false,
          filterable: true
        }
      ]
    }
  },
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'ShipperDetail',
        params: { shipperID: item.id }
      })
    },
    addShipper() {
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      // if (this.editMode) {
      //   // Edit item
      //   delete item.id
      //   delete item.address
      // } else {
      // Save New Item

      delete item.id
      delete item.address.id

      this.$apollo
        .mutate({
          mutation: SHIPPER_CREATE,
          variables: {
            input: item
          }
          // update: (store, { data: { createShipper } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: SHIPPER_LIST
          //   })
          //   // Add our requirement from the mutation to the end
          //   data.shippers.items.push(createShipper)
          //   // Write our data back to the cache.
          //   store.writeQuery({
          //     query: SHIPPER_LIST,
          //     data
          //   })
          // }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Shipper Added'
          })
          this.$router.push({
            name: 'ShipperDetail',
            params: { shipperID: result.data.createShipper.id }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
