var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-radius",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"tboard-column-wrap"},[_c('h4',{staticClass:"tboard-column-header primary--text text-truncate",class:[_vm.col.inbox ? '' : 'tboard-column-handle']},[_vm._v(" "+_vm._s(_vm.col.name)+" ")]),_c('div',{staticClass:"tboard-scroll-column"},[_c('draggable',_vm._b({model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{staticClass:"item-placeholder",attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"tboard-item pa-3 mb-2 elevation-2"},[_c('div',{staticClass:"tboard-item-header text-truncate"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"tboard-item-body"},[_vm._v(" Description ")]),_c('v-row',{staticClass:"tboard-item-footer",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('avatar',{staticClass:"mr-1",staticStyle:{"user-select":"none"},attrs:{"username":item.designer.firstName +
                            ' ' +
                            item.designer.lastName,"size":20,"color":"white"}})],1)]}}],null,true)},[(item.designer)?_c('span',[_vm._v(_vm._s(item.designer.firstName + ' ' + item.designer.lastName)+" - Designer")]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('span',_vm._g({},on),[_c('avatar',{staticClass:"mr-1",staticStyle:{"user-select":"none"},attrs:{"username":item.producer.firstName +
                            ' ' +
                            item.producer.lastName,"size":20,"color":"white"}})],1)]}}],null,true)},[(item.producer)?_c('span',[_vm._v(_vm._s(item.producer.firstName + ' ' + item.producer.lastName)+" - Producer")]):_vm._e()])],1),_c('v-spacer'),_c('v-chip',{attrs:{"label":"","outlined":"","color":"info","x-small":""}},[_vm._v(_vm._s(item.season.shortName))])],1)],1)}),0)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }