<template>
  <v-select
    v-model="selectedSeason"
    hide-details
    :outlined="outlined"
    flat
    :dense="dense"
    clearable
    :background-color="backgroundColor"
    label="season"
    :placeholder="placeholder"
    :prepend-inner-icon="prependInnerIcon"
    item-value="id"
    return-object
    :items="items"
    :disabled="disabled"
  >
    <template v-slot:item="{ item }">
      {{ item.longName }} ({{ item.shortName }})
    </template>
    <template v-slot:selection="{ item }">
      <div class="text-truncate">{{ item.shortName }}</div>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'SeasonSwitcher',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'season'
    },
    backgroundColor: {
      type: String,
      default: '#fff'
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'all'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selectedSeason: {
      get() {
        return this.$store.state.selectedSeason
      },
      set(season) {
        this.$store.commit('setSelectedSeason', season)
      }
    }
  }
}
</script>

<style></style>
