<template>
  <v-row>
    <v-col v-if="manufacturer" cols="12" sm="12" md="4" lg="3" xl="2">
      <v-card flat>
        <v-card-title class="subtitle-1"
          ><span>Locations</span><v-spacer></v-spacer>
          <v-btn rounded small color="primary" @click="addLocation"
            >Add Location</v-btn
          >
        </v-card-title>
        <v-list dense>
          <div
            v-if="
              activeItem &&
                activeItem.addresses &&
                activeItem.addresses.length > 0
            "
          >
            <v-list-item
              v-for="(item, idx) in activeItem.addresses"
              :key="item.id"
              color="info"
              :input-value="selectedIdx === idx"
              @click="setSelected(idx)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters>
                    <div>{{ item.name }}</div>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        !item.defaultAddress &&
                          currentUser &&
                          currentUser.isSuperAdmin &&
                          item &&
                          activeItem &&
                          item.id === activeItem.id
                      "
                      icon
                      x-small
                      aria-label="edit item"
                      @click.stop="$emit('editManufacturer', item)"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="loading">
            <v-skeleton-loader
              v-for="line in 3"
              :key="line"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </div>

          <div v-else>
            <message-box
              icon="mdi-emoticon-neutral-outline"
              title="No Manufacturers"
              caption="Add your first manufacturer to organise your work"
            ></message-box>
          </div>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="8" lg="9" xl="10">
      <v-card v-if="editedItem && selectedIdx > -1" flat>
        <v-card-title class="mb-3">
          <span v-if="!editedItem.id">New Location</span>
          <span v-if="editMode && editedItem.id">Update Location</span>
          <v-spacer></v-spacer
          ><v-btn
            v-if="
              editMode &&
                ((editedItem && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            text
            small
            :disabled="processing"
            class="mr-2"
            @click="cancelEdit(false)"
          >
            cancel
          </v-btn>
          <v-btn
            v-if="
              editMode &&
                ((editedItem && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="primary"
            rounded
            small
            :loading="processing"
            @click="saveLocation"
          >
            save
          </v-btn>
          <v-btn
            v-if="
              !editMode &&
                ((editedItem && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="info"
            rounded
            small
            :disabled="processing"
            @click="editLocation"
          >
            edit
          </v-btn></v-card-title
        >
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="locationForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  ref="nameField"
                  v-model="editedItem.name"
                  autocomplete="off"
                  label="Name"
                  hide-details="auto"
                  outlined
                  :rules="requiredRule"
                  dense
                  :readonly="!editMode"
                  :disabled="!editMode && !editedItem.name"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="mt-4 mb-2 caption text--secondary text-uppercase">
              Contact Details
            </div>
            <v-divider class="mb-2"></v-divider>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.email"
                      autocomplete="off"
                      label="Email"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.phone"
                      autocomplete="off"
                      label="Phone"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.phone"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <div class="mt-4 mb-2 caption text--secondary text-uppercase">
              Address
            </div>
            <v-divider class="mb-2"></v-divider>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.line1"
                      autocomplete="off"
                      label="Line 1"
                      hide-details="auto"
                      outlined
                      dense
                      :rules="requiredRule"
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.line1"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.line2"
                      autocomplete="off"
                      label="Line 2"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.line2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.line3"
                      autocomplete="off"
                      label="Line 3"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.line3"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col> -->
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.postCode"
                      autocomplete="off"
                      label="Postcode"
                      hide-details="auto"
                      outlined
                      dense
                      :rules="requiredRule"
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.postCode"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.state"
                      autocomplete="off"
                      label="State/Province"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.state"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.city"
                      autocomplete="off"
                      label="City"
                      hide-details="auto"
                      outlined
                      dense
                      :rules="requiredRule"
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.city"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="editedItem.country"
                      autocomplete="off"
                      :items="countryList"
                      label="Country"
                      hide-details="auto"
                      outlined
                      dense
                      item-text="name"
                      item-value="code"
                      auto-select-first
                      :rules="requiredSelectRule"
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.country"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <div v-else>
        <message-box
          icon="mdi-emoticon-neutral-outline"
          title="No Locations Selected"
          caption="Select a location to view the details"
        ></message-box>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox'
// import CountryList from '@/helpers/CountryList'
import country from 'country-list'
import MANUFACTURER from '@/graphql/Manufacturer.gql'
import MANUFACTURER_ADDRESS_CREATE from '@/graphql/ManufacturerAddressCreate.gql'
import MANUFACTURER_ADDRESS_UPDATE from '@/graphql/ManufacturerAddressUpdate.gql'

export default {
  name: 'ManufacturerLocations',
  components: {
    MessageBox
  },
  props: {
    manufacturer: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    paymentTerms: {
      type: Array,
      default: () => []
    },
    currencies: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedIdx: 0,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [(v) => !!v || 'Field is required'],
    editDialog: false,
    processing: false,
    editMode: false,
    errorMsg: '',
    defaultItem: {
      id: null,
      name: 'New Location',
      email: null,
      phone: null,
      line1: null,
      line2: null,
      line3: null,
      city: null,
      postCode: null,
      country: null
    },
    editedItem: {
      id: null,
      name: null,
      email: null,
      phone: null,
      line1: null,
      line2: null,
      line3: null,
      city: null,
      postCode: null,
      country: null
    }
  }),
  computed: {
    activeItem() {
      this.setEditedItem(this.selectedIdx)
      return this.manufacturer
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    },
    newItem() {
      var lastItem = this._.last(this.manufacturer.addresses)
      return !lastItem.id
    }
  },
  destroyed() {
    var lastItem = this._.last(this.manufacturer.addresses)
    if (!lastItem.id)
      this.manufacturer.addresses.splice(
        this.manufacturer.addresses.length - 1,
        1
      )
  },
  methods: {
    setSelected(idx) {
      if (!idx || idx === -1) {
        this.selectedIdx = 0
      } else {
        this.selectedIdx = idx
      }

      var lastItem = this._.last(this.manufacturer.addresses)
      if (!lastItem.id && idx !== this.manufacturer.addresses.length - 1) {
        this.manufacturer.addresses.splice(
          this.manufacturer.addresses.length - 1,
          1
        )
        this.editMode = false
      }

      this.setEditedItem(idx)
    },
    setEditedItem(idx) {
      if (!idx || idx === -1) {
        idx = 0
      }
      if (
        this.manufacturer &&
        this.manufacturer.id &&
        this.manufacturer.addresses &&
        this.manufacturer.addresses.length > 0
      ) {
        this.editedItem = this._.cloneDeep(this.manufacturer.addresses[idx])
        if (this.newItem) {
          this.editedItem.name = null
        }
      }
    },
    addLocation() {
      var lastItem = this._.last(this.manufacturer.addresses)
      if (lastItem.id) {
        this.manufacturer.addresses.push(this._.cloneDeep(this.defaultItem))

        this.selectedIdx = this.manufacturer.addresses.length - 1
        this.editMode = true
        this.$refs.nameField.focus()
        this.$refs.locationForm.resetValidation()
      }
    },
    // Manufacturer methods
    cancelEdit(success) {
      if (!success) {
        this.$refs.locationForm.resetValidation()
        this.setEditedItem()
      }

      var lastItem = this._.last(this.manufacturer.addresses)
      if (!lastItem.id) {
        this.manufacturer.addresses.splice(
          this.manufacturer.addresses.length - 1,
          1
        )
      }

      // this.editDialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.newItem === true) {
          msg = 'Location Added'
        } else {
          msg = 'Location Updated'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false

      if (this.selectedIdx > this.manufacturer.addresses.length - 1) {
        this.setSelected(0)
      }
    },

    editLocation() {
      this.editMode = true
    },

    saveLocation() {
      var that = this

      this.errorMsg = ''

      var data = {
        id: this.editedItem.id,
        manufacturerID: this.$route.params.manufacturerID,
        name: this.editedItem.name,
        email: this.editedItem.email,
        phone: this.editedItem.phone,
        line1: this.editedItem.line1,
        line2: this.editedItem.line2,
        line3: this.editedItem.line3,
        state: this.editedItem.state,
        city: this.editedItem.city,
        postCode: this.editedItem.postCode,
        country: this.editedItem.country
      }

      if (this.$refs.locationForm.validate()) {
        this.processing = true

        if (this.newItem) {
          delete data.id
          // Save Item
          this.$apollo
            .mutate({
              mutation: MANUFACTURER_ADDRESS_CREATE,
              variables: {
                input: data
              },
              update: (store, { data: { createManufacturerAddress } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: MANUFACTURER,
                  variables: {
                    id: that.$route.params.manufacturerID
                  }
                })

                // Remove the placeholder item
                that.manufacturer.addresses.pop()

                // Add our address from the mutation to the end
                data.manufacturer.addresses.push(createManufacturerAddress)
                // Write our data back to the cache.
                store.writeQuery({
                  query: MANUFACTURER,
                  variables: {
                    id: that.$route.params.manufacturerID
                  },
                  data
                })
              }
            })
            .then(() => {
              this.cancelEdit(true)
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        } else {
          // Update Item
          this.$apollo
            .mutate({
              mutation: MANUFACTURER_ADDRESS_UPDATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.cancelEdit(true)
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.team-selection >>> .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.size-delimiter {
  &:last-child {
    span {
      visibility: hidden;
    }
  }
}
</style>
