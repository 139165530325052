<template>
  <v-row justify="center">
    <season-edit-dialog
      :open="dialog"
      :edited-item="editedItem"
      :edit-mode="edit"
      @close-edit-season-dialog="close"
    ></season-edit-dialog>
    <v-col v-if="season" cols="12" md="10" lg="8" xl="6">
      <v-card flat class="mx-auto">
        <v-card-title class="mb-3"
          ><span>Details</span><v-spacer></v-spacer
          ><v-btn
            v-if="
              season &&
                authLevel &&
                authLevel === 2 &&
                currentUser &&
                (currentUser.isSuperAdmin ||
                  currentUser.userRole === 'director' ||
                  currentUser.userRole === 'seniormanager' ||
                  currentUser.userRole === 'projectmanager')
            "
            color="primary"
            rounded
            small
            @click="editSeason"
            >edit
          </v-btn></v-card-title
        >
        <v-card-text>
          <div class="mb-2">Name</div>
          <div class="font-weight-bold">
            {{ season.longName }} ({{ season.shortName }})
          </div>

          <v-divider class="my-4"></v-divider>

          <div class="mb-2">Style Number Prefix</div>
          <div class="font-weight-bold">{{ season.styleNumberPrefix }}</div>
          <!-- <v-divider class="my-4"></v-divider>
          <div class="mb-2">Owner</div>
          <v-row no-gutters justify="start">
            <avatar
              :username="season.owner.firstName + ' ' + season.owner.lastName"
              :size="25"
              color="white"
              style="user-select: none;"
              class="mr-3"
            ></avatar>
            <span class="font-weight-bold">{{
              season.owner.firstName + ' ' + season.owner.lastName
            }}</span>
          </v-row> -->
          <v-divider class="my-4"></v-divider>
          <div class="mb-2">Season Period</div>
          <v-row no-gutters justify="start">
            <v-col>
              From:
              <b>{{ season.from | moment('DD MMMM YYYY') }}</b>
            </v-col>
            <v-col>
              Until:
              <b>{{ season.till | moment('DD MMMM YYYY') }}</b>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <div class="mb-2">Prebook Period</div>
          <v-row no-gutters justify="start">
            <v-col>
              From:
              <b>{{ season.prebookStartDate | moment('DD MMMM YYYY') }}</b>
            </v-col>
            <v-col>
              Until:
              <b>{{ season.prebookEndDate | moment('DD MMMM YYYY') }}</b>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <div class="mb-2">First Drop Period</div>
          <v-row no-gutters justify="start">
            <v-col>
              From:
              <b>{{ season.firstDropStartDate | moment('DD MMMM YYYY') }}</b>
            </v-col>
            <v-col>
              Until:
              <b>{{ season.firstDropEndDate | moment('DD MMMM YYYY') }}</b>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions> </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SeasonEditDialog from '@/modules/production/components/core/SeasonEditDialog.vue'

export default {
  name: 'ProductionSeasonDetail',
  components: {
    SeasonEditDialog
  },
  props: {
    season: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  data: () => ({
    dialog: false,
    edit: false,
    defaultItem: {
      id: '',
      shortName: '',
      longName: '',
      status: 'active',
      from: new Date().toISOString(),
      till: new Date().toISOString(),
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      shortName: '',
      longName: '',
      status: 'active',
      from: new Date().toISOString(),
      till: new Date().toISOString(),
      updatedAt: new Date(),
      createdAt: new Date()
    }
  }),
  computed: {},
  methods: {
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Season Updated'
        })
      }
    },

    editSeason() {
      this.edit = true
      let item = this._.cloneDeep(this.season)
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    }
  }
}
</script>

<style></style>
