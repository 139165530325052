<template>
  <v-row justify="center">
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Warehouse</v-card-title
        >
        <v-card-text>
          <p>
            This will delete this warehouse. This will also remove all products
            associated with this warehouse.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closeRemoveDialog(false)"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="openSecondRemoveDialog"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="secondRemoveDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Are you really sure?</v-card-title
        >
        <v-card-text>
          <p>
            This operation can't be undone!
          </p>

          <v-checkbox
            v-model="removalConfirmation"
            color="error"
            label="I accept the concequences!"
          >
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closeRemoveDialog(false)"
            >Cancel</v-btn
          >
          <v-btn
            color="error"
            text
            :disabled="!removalConfirmation"
            @click="removeWarehouse"
            >Remove Warehouse</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <promese-edit-dialog
      :promese="promeseEditedItem"
      :dialog="promeseDialog"
      :processing="processing"
      :edit-mode="editMode"
      :error-msg="errorMsg"
      @close-promese-edit-dialog="close"
      @save-promese="savePromese"
    ></promese-edit-dialog>

    <bleckmann-edit-dialog
      :bleckmann="bleckmannEditedItem"
      :dialog="bleckmannDialog"
      :processing="processing"
      :edit-mode="editMode"
      :error-msg="errorMsg"
      @close-bleckmann-edit-dialog="close"
      @save-bleckmann="saveBleckmann"
    ></bleckmann-edit-dialog>

    <shopify-edit-dialog
      :shopify="shopifyEditedItem"
      :dialog="shopifyDialog"
      :processing="processing"
      :edit-mode="editMode"
      :error-msg="errorMsg"
      @close-shopify-edit-dialog="close"
      @save-shopify="saveShopify"
    ></shopify-edit-dialog>

    <v-col v-if="warehouse" cols="12" md="10" lg="8" xl="6">
      <v-card v-if="!warehouse.bleckmann" flat class="mx-auto mb-3">
        <v-card-title class="mb-3">Promese</v-card-title>
        <v-card-subtitle
          >Integrate this warehouse with the Promese Warehouse
          platform.</v-card-subtitle
        >

        <v-card-text>
          <v-row v-if="warehouse.promese">
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Name</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.promese.name
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Description</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.promese.description
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Type</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.promese.type
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Prefix</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.promese.prefix
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1"
                    >DP Service API Key</label
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="font-weight-medium grey--text text--darken-2">
                    {{ warehouse.promese.apiKey }}
                  </div>
                  <div class="caption grey--text pt-2">
                    This should be supplied to Promese
                  </div>
                </v-col>
              </v-row>
            </v-col> -->
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Promese Key</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.promese.promeseKey
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="
                  warehouse &&
                    (!warehouse.promese ||
                      !warehouse.promese ||
                      !warehouse.promese.id ||
                      warehouse.promese.id === '') &&
                    authLevel &&
                    authLevel === 2 &&
                    currentUser &&
                    (currentUser.isSuperAdmin || currentUser.isAdmin)
                "
                color="primary lighten-2"
                depressed
                rounded
                small
                class="mt-3 upperCaseSpacing"
                @click="editPromese(false)"
              >
                setup
              </v-btn>
              <v-btn
                v-if="
                  warehouse &&
                    warehouse.promese &&
                    warehouse.promese &&
                    warehouse.promese.id &&
                    warehouse.promese.id !== '' &&
                    authLevel &&
                    authLevel === 2 &&
                    currentUser &&
                    (currentUser.isSuperAdmin || currentUser.isAdmin)
                "
                color="grey lighten-1"
                rounded
                small
                outlined
                class="mt-3 upperCaseSpacing"
                @click="editPromese(true)"
              >
                edit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="!warehouse.promese" flat class="mx-auto mb-3">
        <v-card-title class="mb-3">Bleckmann</v-card-title>
        <v-card-subtitle
          >Integrate this warehouse with the Bleckmann Warehouse
          platform.</v-card-subtitle
        >

        <v-card-text>
          <v-row v-if="warehouse.bleckmann">
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Name</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.bleckmann.name
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Description</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.bleckmann.description
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Type</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.bleckmann.type
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Prefix</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.bleckmann.prefix
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Profile</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.bleckmann.profile
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="
                  warehouse &&
                    (!warehouse.bleckmann ||
                      !warehouse.bleckmann ||
                      !warehouse.bleckmann.id ||
                      warehouse.bleckmann.id === '') &&
                    authLevel &&
                    authLevel === 2 &&
                    currentUser &&
                    (currentUser.isSuperAdmin || currentUser.isAdmin)
                "
                color="primary lighten-2"
                depressed
                rounded
                small
                class="mt-3 upperCaseSpacing"
                @click="editBleckmann(false)"
              >
                setup
              </v-btn>
              <v-btn
                v-if="
                  warehouse &&
                    warehouse.bleckmann &&
                    warehouse.bleckmann &&
                    warehouse.bleckmann.id &&
                    warehouse.bleckmann.id !== '' &&
                    authLevel &&
                    authLevel === 2 &&
                    currentUser &&
                    (currentUser.isSuperAdmin || currentUser.isAdmin)
                "
                color="grey lighten-1"
                rounded
                small
                outlined
                class="mt-3 upperCaseSpacing"
                @click="editBleckmann(true)"
              >
                edit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="warehouse.type === 'ECOMMERCE'" flat class="mx-auto mb-3">
        <v-card-title class="mb-3">Shopify</v-card-title>
        <v-card-subtitle
          >Integrate this warehouse with the Shopify Platform.</v-card-subtitle
        >

        <v-card-text>
          <v-row v-if="warehouse.shopify">
            <v-col cols="12">
              <v-alert
                text
                dense
                color="info"
                icon="mdi-information-outline"
                border="left"
                class="mb-2"
                ><h3 v-if="warehouse.shopify.inboundOrders" class="text-h5">
                  Full Communication
                </h3>
                <h3 v-else>Outbound Communication</h3>
                <div v-if="warehouse.shopify.inboundOrders">
                  This integration processes all paid messages and outgoing messages for this warehouse.
                </div>
                <div v-else>
                  This integration only processes outgoing messages for this warehouse.
                </div>
                </v-alert
              >
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Name</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.shopify.name
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1">Description</label>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.shopify.description
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1"
                    >Shopify API Key</label
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.shopify.shopifyApiKey
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1"
                    >Shopify Password</label
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.shopify.shopifyPassword
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1"
                    >Shopify Webhook Secret</label
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.shopify.webhookSecret
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1"
                    >Shopify Location ID</label
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.shopify.locationID
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="grey--text text--lighten-1"
                    >Shopify Shop Name</label
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium grey--text text--darken-2">{{
                    warehouse.shopify.shopName
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="
                  warehouse &&
                    (!warehouse.shopify ||
                      !warehouse.shopify ||
                      !warehouse.shopify.id ||
                      warehouse.shopify.id === '') &&
                    authLevel &&
                    authLevel === 2 &&
                    currentUser &&
                    (currentUser.isSuperAdmin || currentUser.isAdmin)
                "
                color="primary lighten-2"
                depressed
                rounded
                small
                class="mt-3 upperCaseSpacing"
                @click="editShopify(false)"
              >
                setup
              </v-btn>
              <v-btn
                v-if="
                  warehouse &&
                    warehouse.shopify &&
                    warehouse.shopify &&
                    warehouse.shopify.id &&
                    warehouse.shopify.id !== '' &&
                    authLevel &&
                    authLevel === 2 &&
                    currentUser &&
                    (currentUser.isSuperAdmin || currentUser.isAdmin)
                "
                color="grey lighten-1"
                rounded
                small
                outlined
                class="mt-3 upperCaseSpacing"
                @click="editShopify(true)"
              >
                edit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="currentUser && (currentUser.isSuperAdmin || currentUser.isAdmin)"
        flat
        class="mx-auto"
      >
        <v-card-title class="mb-3">Delete Warehouse</v-card-title>
        <v-card-subtitle
          >Once you delete this warehouse, you will lose all data associated
          with it.</v-card-subtitle
        >

        <v-card-text>
          <v-btn
            v-if="
              warehouse &&
                authLevel &&
                authLevel === 2 &&
                currentUser &&
                (currentUser.isSuperAdmin ||
                  currentUser.userRole === 'director' ||
                  currentUser.userRole === 'seniormanager' ||
                  currentUser.userRole === 'projectmanager')
            "
            color="error lighten-2"
            depressed
            rounded
            small
            class="mt-3 upperCaseSpacing"
            @click="openSecondRemoveDialog"
          >
            delete warehouse
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import REMOVE_WAREHOUSE from '@/graphql/WarehouseRemove.gql'
import PromeseEditDialog from '../../logistics/components/core/PromeseEditDialog.vue'
import BleckmannEditDialog from '../../logistics/components/core/BleckmannEditDialog.vue'
import ShopifyEditDialog from '../../logistics/components/core/ShopifyEditDialog.vue'
import PROMESE_CREATE from '../../../graphql/IntegrationPromeseCreate.gql'
import PROMESE_UPDATE from '../../../graphql/IntegrationPromeseUpdate.gql'
import BLECKMANN_CREATE from '../../../graphql/IntegrationBleckmannCreate.gql'
import BLECKMANN_UPDATE from '../../../graphql/IntegrationBleckmannUpdate.gql'
import SHOPIFY_CREATE from '../../../graphql/IntegrationShopifyCreate.gql'
import SHOPIFY_UPDATE from '../../../graphql/IntegrationShopifyUpdate.gql'

export default {
  name: 'WarehouseSettings',
  components: { PromeseEditDialog, BleckmannEditDialog, ShopifyEditDialog },
  props: {
    warehouse: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  data: () => ({
    processing: false,
    errorMsg: '',
    editMode: false,
    removeDialog: false,
    secondRemoveDialog: false,
    removalConfirmation: false,
    promeseDialog: false,
    promeseDefaultItem: {
      id: null,
      name: null,
      description: null,
      promeseKey: null,
      apiKey: null,
      warehouseID: null,
      active: true,
      prefix: null,
      type: 'ECOMMERCE'
    },
    promeseEditedItem: {},

    bleckmannDialog: false,
    bleckmannDefaultItem: {
      id: null,
      name: null,
      description: null,
      profile: null,
      apiKey: null,
      warehouseID: null,
      active: true,
      prefix: null,
      type: 'ECOMMERCE'
    },
    bleckmannEditedItem: {},

    shopifyDialog: false,
    shopifyDefaultItem: {
      id: null,
      name: null,
      description: null,
      shopifyApiKey: null,
      shopifyPassword: null,
      webhookSecret: null,
      locationID: null,
      shopName: null,
      warehouseID: null,
      active: true,
      inboundOrders: false
    },
    shopifyEditedItem: {}
  }),
  computed: {},
  methods: {
    closeRemoveDialog(success) {
      this.removeDialog = false
      this.secondRemoveDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Warehouse Removed'
        })
      }
    },

    openSecondRemoveDialog() {
      this.removalConfirmation = false
      this.removeDialog = false
      this.secondRemoveDialog = true
    },

    removeWarehouse() {
      var that = this
      if (this.removalConfirmation) {
        this.$apollo
          .mutate({
            mutation: REMOVE_WAREHOUSE,
            variables: {
              id: that.warehouse.id
            }
          })
          .then(() => {
            this.closeRemoveDialog(true)

            if (this.$route.name !== 'LogisticsWarehouseSettings') {
              this.$router.replace({ name: 'LogisticsWarehouseList' })
            } else {
              this.$router.replace({ name: 'ProductionWarehouseList' })
            }
          })
      }
    },

    editPromese(edit) {
      if (edit) {
        this.editMode = true
        this.promeseEditedItem = this._.cloneDeep(this.warehouse?.promese)
      } else {
        this.editMode = false
        this.promeseEditedItem = this._.cloneDeep(this.promeseDefaultItem)
        this.promeseEditedItem.warehouseID = this.$route.params.warehouseID
      }

      this.promeseDialog = true
    },

    editBleckmann(edit) {
      if (edit) {
        this.editMode = true
        this.bleckmannEditedItem = this._.cloneDeep(this.warehouse?.bleckmann)
      } else {
        this.editMode = false
        this.bleckmannEditedItem = this._.cloneDeep(this.bleckmannDefaultItem)
        this.bleckmannEditedItem.warehouseID = this.$route.params.warehouseID
      }

      this.bleckmannDialog = true
    },

    editShopify(edit) {
      if (edit) {
        this.editMode = true
        this.shopifyEditedItem = this._.cloneDeep(this.warehouse?.shopify)
      } else {
        this.editMode = false
        this.shopifyEditedItem = this._.cloneDeep(this.shopifyDefaultItem)
        this.shopifyEditedItem.warehouseID = this.$route.params.warehouseID
      }

      this.shopifyDialog = true
    },
    close() {
      this.promeseDialog = false
      this.bleckmannDialog = false
      this.shopifyDialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
      this.promeseEditedItem = {}
      this.bleckmannEditedItem = {}
      this.shopifyEditedItem = {}
    },
    savePromese(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        // Update item
        this.$apollo
          .mutate({
            mutation: PROMESE_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Promese Integration Updated'
            })
            this.$emit('refresh-warehouse')
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        // Create new item
        this.$apollo
          .mutate({
            mutation: PROMESE_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Promese Integration Added'
            })
            this.$emit('refresh-warehouse')
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    saveBleckmann(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        // Update item
        this.$apollo
          .mutate({
            mutation: BLECKMANN_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Bleckmann Integration Updated'
            })
            this.$emit('refresh-warehouse')
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        // Create new item
        this.$apollo
          .mutate({
            mutation: BLECKMANN_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Bleckmann Integration Added'
            })
            this.$emit('refresh-warehouse')
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    saveShopify(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        // Update item
        this.$apollo
          .mutate({
            mutation: SHOPIFY_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Shopify Integration Updated'
            })
            this.$emit('refresh-warehouse')
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        // Create new item
        this.$apollo
          .mutate({
            mutation: SHOPIFY_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Shopify Integration Added'
            })
            this.$emit('refresh-warehouse')
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style></style>
