<template>
  <div class="page-wrap">
    <product-detail-header
      :product="seasonProduct"
      :product-assets="productAssets"
      :product-materials="productMaterials"
      :product-manufacturers="productManufacturers"
      :selected-season="selectedSeason"
      :auth-level="authLevel"
      @set-season="setSelectedSeason"
      @change-state="changeState"
    ></product-detail-header>

    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <router-view
          v-if="selectedSeason && !this.$apollo.loading"
          :product="seasonProduct"
          :users="users"
          :colors="colors"
          :manufacturer-list="manufacturers"
          :departments="departments"
          :categories="productCategories"
          :article-groups="articleGroups"
          :sub-article-groups="subArticleGroups"
          :style-types="styleTypes"
          :size-groups="sizeGroups"
          :current-user="currentUser"
          :auth-level="authLevel"
          :selected-season="selectedSeason"
          :currency-list="currencyList"
          :seasons="seasons.seasons"
          :price-markups="priceMarkups"
          :warehouses="warehouses"
          @change-state="changeState"
        />
        <div v-if="!selectedSeason && !this.$apollo.loading">
          <message-box
            v-if="product"
            icon="mdi-emoticon-neutral-outline"
            title="No Season Selected"
            caption="Please select a season to continue"
          >
            <v-autocomplete
              v-if="product && product.seasons && product.seasons.length > 0"
              v-model="selectedSeason"
              hide-details
              outlined
              flat
              dense
              background-color="white"
              label="Season"
              placeholder="Select Season"
              item-value="id"
              :filter="seasonFilter"
              color="info"
              item-color="info"
              class="highlight-control info--text mt-3"
              :items="product.seasons"
            >
              <template v-slot:item="{ item }">
                {{ item.longName }} ({{ item.shortName }})
              </template>
              <template v-slot:selection="{ item }">
                <div class="text-truncate">{{ item.shortName }}</div>
              </template>
            </v-autocomplete>
          </message-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import ProductDetailHeader from '@/modules/production/components/core/ProductDetailHeader.vue'
import PRODUCT from '@/graphql/Product.gql'
import SEASON_PRODUCT from '@/graphql/SeasonProduct.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'
import USERS from '@/graphql/Users.gql'
import COLORS from '@/graphql/Colors.gql'
import SIZE_GROUP_LIST from '@/graphql/SizeGroups.gql'
import CATEGORIES from '@/graphql/ProductCategories.gql'
import ARTICLE_GROUPS from '@/graphql/ArticleGroups.gql'
import SUB_ARTICLE_GROUPS from '@/graphql/SubArticleGroups.gql'
import STYLE_TYPES from '@/graphql/StyleTypes.gql'
import MANUFACTURER_LIST from '@/graphql/Manufacturers.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import SEASON_LIST from '@/graphql/Seasons.gql'
import PRICE_MARKUP_LIST from '@/graphql/PriceMarkups.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import PRODUCT_ASSETS from '@/graphql/ProductAssets.gql'
import PRODUCT_MANUFACTURER_LIST from '@/graphql/ProductManufacturers.gql'
import PRODUCT_MATERIALS_LIST from '@/graphql/ProductMaterials.gql'

export default {
  name: 'ProductDetailLayout',
  components: {
    MessageBox,
    ProductDetailHeader
  },
  data: () => ({
    seasons: {
      seasons: []
    }
  }),
  computed: {
    currencyList() {
      return this.currencies
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('products')
    },
    currentRoute() {
      return this.$route
    },
    selectedSeason: {
      get() {
        return this.$route.query.season
      },
      set(id) {
        this.setSelectedSeason(id)
      }
    }
  },
  apollo: {
    seasonProduct: {
      query: SEASON_PRODUCT,
      variables() {
        return {
          id: this.$route.params.productID,
          seasonID: this.selectedSeason
        }
      },
      fetchPolicy: 'no-cache',
      skip() {
        return !this.selectedSeason
      }
      // result({ data }) {
      //   // this.$nextTick(() => {
      //   this.setRouterProductName(data.product.name)
      //   // })
      // }
    },
    product: {
      query: PRODUCT,
      variables() {
        return {
          id: this.$route.params.productID
        }
      }
    },
    productAssets: {
      query: PRODUCT_ASSETS,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    },
    productManufacturers: {
      query: PRODUCT_MANUFACTURER_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    },
    productMaterials: {
      query: PRODUCT_MATERIALS_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    },
    departments: DEPARTMENTS,
    users: USERS,
    colors: COLORS,
    productCategories: CATEGORIES,
    articleGroups: ARTICLE_GROUPS,
    subArticleGroups: SUB_ARTICLE_GROUPS,
    styleTypes: STYLE_TYPES,
    sizeGroups: SIZE_GROUP_LIST,
    manufacturers: MANUFACTURER_LIST,
    currencies: { query: CURRENCY_LIST, fetchPolicy: 'no-cache' },
    seasons: SEASON_LIST,
    priceMarkups: PRICE_MARKUP_LIST,
    warehouses: WAREHOUSE_LIST
  },
  watch: {},
  created() {},
  methods: {
    setSelectedSeason(seasonID) {
      this.$router.push({
        name: this.currentRoute.name,
        params: { productID: this.currentRoute.params.productID },
        query: { season: seasonID }
      })
      // this.$apollo.queries.seasonProduct.refetch()
    },
    setRouterProductName(name) {
      if (name) {
        let resolved = this.$route.matched
        let detailIdx = resolved.length - 2

        // this.$nextTick(() => {
        this.$route.matched[detailIdx].meta.breadCrumb = name

        // })
      }
    },
    seasonFilter(item, queryText) {
      const longName = item.longName.toLowerCase()
      const shortName = item.shortName.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        longName.indexOf(searchText) > -1 || shortName.indexOf(searchText) > -1
      )
    },
    changeState() {
      this.$apollo.queries.seasonProduct.refetch()
      this.$apollo.queries.productManufacturers.refetch()
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 92px;
  /* --page-wrap-offset: -20px; */
}
</style>
