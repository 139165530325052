<template>
  <div class="page-wrap">
    <div class="page-header">
      <v-toolbar class="mb-0 b-radius" color="transparent" flat>
        <material-edit-dialog
          :dialog="dialog"
          :material="editedItem"
          :edit-mode="editMode"
          :processing="processing"
          :error-msg="errorMsg"
          @search-materials="searchMaterials"
          @close-material-edit-dialog="close"
          @save-material-edit-dialog="save"
        ></material-edit-dialog>
        <v-dialog v-model="removeDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline error--text mb-3"
              >Remove Material</v-card-title
            >
            <v-card-text>
              <p>
                This will delete this material. This can't be undone!
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="grey darken-1"
                text
                :disabled="processing"
                @click="removeDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                :loading="processing"
                color="error"
                text
                @click="removeMaterial"
                >Remove</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row>
          <v-col cols="6" lg="2" xl="2" class="pl-0">
            <v-btn color="primary" rounded @click="addMaterial">
              add material
            </v-btn>
          </v-col>

          <!-- <v-col cols="6" lg="3" xl="3">
            <v-select
              hide-details
              outlined
              flat
              dense
              clearable
              label="season"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              :disabled="!materials || materials.items.length === 0"
            >
            </v-select>
          </v-col>

          <v-col cols="4" lg="3" xl="3">
            <v-text-field
              v-model="search"
              hide-details
              outlined
              flat
              dense
              clearable
              background-color="#fff"
              placeholder="Search..."
              prepend-inner-icon="mdi-magnify"
              autocomplete="off"
              :disabled="!materials || materials.items.length === 0"
            ></v-text-field>
          </v-col>

          <v-col
            cols="4"
            lg="3"
            xl="3"
            class="d-none d-sm-none d-md-none d-lg-flex"
          >
            <v-select
              hide-details
              outlined
              flat
              dense
              clearable
              label="producer"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              :disabled="!materials || materials.items.length === 0"
            >
            </v-select>
          </v-col> -->
        </v-row>

        <v-spacer></v-spacer>

        <v-row justify="end">
          <span class="pt-3 text-truncate">{{ itemCount }} materials</span>

          <v-btn
            icon
            class="d-sm-flex d-md-flex d-lg-none d-xl-none ml-2"
            @click="filters = !filters"
          >
            <v-icon :color="filters ? 'info' : ''">mdi-filter-variant</v-icon>
          </v-btn>
        </v-row>

        <!-- <v-btn class="ml-2" color="primary" fab small>
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
      </v-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="6">
            <v-card v-if="filters" class="mb-3">
              <div>
                <v-col cols="12">
                  <v-row no-gutters>
                    <span>Advanced Filters</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="filters = !filters"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-select
                    outlined
                    flat
                    dense
                    clearable
                    label="producer"
                    placeholder="all"
                    prepend-inner-icon="mdi-filter-variant"
                    autocomplete="off"
                    :disabled="!materials || materials.items.length === 0"
                  >
                  </v-select>
                </v-col>
              </div>
            </v-card>

            <v-card flat>
              <v-card-title class="pt-0">
                <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Search..."
                  single-line
                  hide-details
                  clearable
                  autocomplete="off"
                ></v-text-field>
              </v-card-title>

              <v-data-table
                v-model="selected"
                :headers="headers"
                :loading="$apollo.loading"
                :items="materials.items"
                :options.sync="options"
                :server-items-length="itemCount"
                :mobile-breakpoint="null"
                fixed-header
                :hide-default-footer="
                  !materials ||
                    materials.items.length === 0 ||
                    materials.items.length < 15
                "
              >
                <template v-slot:item.name="data">
                  <div class="text-truncate">
                    {{ data.item.name }}
                  </div>
                </template>
                <template v-slot:item.description="data">
                  <div class="text-truncate">
                    {{ data.item.description }}
                  </div>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  {{ item.updatedAt | moment('from', 'now') }}
                </template>
                <template v-slot:item.controls="{ item }">
                  <div class="text-truncate">
                    <v-btn
                      text
                      small
                      rounded
                      color="info"
                      @click.stop="editMaterial(item)"
                      >edit</v-btn
                    >
                    <v-btn
                      text
                      small
                      rounded
                      class="m--2"
                      color="grey"
                      @click.stop="openRemoveDialog(item)"
                      >delete</v-btn
                    >
                  </div>
                </template>
                <template v-slot:no-data>
                  <message-box
                    icon="mdi-emoticon-neutral-outline"
                    title="No Results"
                    caption="Try adjusting your filters to get some results"
                  ></message-box>
                </template>
                <template v-slot:loading>
                  <v-skeleton-loader
                    v-for="item in 5"
                    :key="item"
                    type="list-item"
                    class="mx-auto"
                  ></v-skeleton-loader>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import MATERIAL_LIST from '@/graphql/Materials.gql'
import CREATE_MATERIAL from '@/graphql/MaterialCreate.gql'
import UPDATE_MATERIAL from '@/graphql/MaterialUpdate.gql'
import REMOVE_MATERIAL from '@/graphql/MaterialRemove.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import MaterialEditDialog from '@/modules/production/components/core/MaterialEditDialog.vue'

export default {
  name: 'ProductionMaterialList',
  components: {
    MessageBox,
    MaterialEditDialog
  },
  data: () => ({
    filters: false,
    search: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    dialog: false,
    removeDialog: false,
    removeableMaterial: null,
    editMode: false,
    processing: false,
    errorMsg: '',
    selected: [],
    materials: { items: [] },
    defaultItem: {
      id: null,
      name: null,
      description: null,
      compound: null,
      unit: null
    },
    editedItem: {
      id: null,
      name: null,
      description: null,
      compound: null,
      unit: null
    }
  }),
  apollo: {
    materials: {
      query: MATERIAL_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        this.page = result.data.materials.page
        this.itemsPerPage = result.data.materials.pageSize
        this.itemCount = result.data.materials.total
      }
    },
    paymentTerms: PAYMENT_TERM_LIST,
    currencies: CURRENCY_LIST
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: false,
          value: 'name'
        },
        {
          text: 'Code',
          align: 'left',
          sortable: false,
          filterable: false,
          value: 'materialCode'
        },
        {
          text: 'Description',
          align: 'left',
          sortable: false,
          filterable: false,
          value: 'description'
        },
        {
          text: '',
          align: 'right',
          value: 'controls'
        }
      ]
    }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    // navToDetail(item) {
    //   this.$router.push({
    //     name: 'MaterialDetail',
    //     params: { materialID: item.id }
    //   })
    // },
    searchMaterials(val) {
      this.search = val
    },
    editMaterial(item) {
      this.editMode = true

      this.editedItem = this._.cloneDeep(item)

      this.dialog = true
    },
    addMaterial() {
      this.dialog = true
    },
    close(success) {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = 'Material Added'

        if (this.editMode) msg = 'Material Updated'

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },
    openRemoveDialog(item) {
      this.removeableMaterial = item
      this.removeDialog = true
    },
    removeMaterial() {
      this.processing = true

      if (this.removeableMaterial) {
        this.$apollo
          .mutate({
            mutation: REMOVE_MATERIAL,
            variables: {
              id: this.removeableMaterial.id
            }
          })
          .then(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Item removed'
            })
            this.processing = false
            this.removeableMaterial = null
            this.removeDialog = false
            this.$apollo.queries.materials.refresh()
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong.'
            })
            this.processing = false
            this.removeableMaterial = null
            this.removeDialog = false
          })
      }
    },
    save(item) {
      this.processing = true

      // Save Item
      if (this.editMode) {
        // Update Material
        this.$apollo
          .mutate({
            mutation: UPDATE_MATERIAL,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$apollo.queries.materials.refresh()
            this.close(true)
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        // Add Material
        delete item.id

        this.$apollo
          .mutate({
            mutation: CREATE_MATERIAL,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$apollo.queries.materials.refresh()
            this.close(true)
            // this.$router.push({
            //   name: 'MaterialDetail',
            //   params: { materialID: result.data.createMaterial.id }
            // })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style></style>
