var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('product-edit-dialog',{attrs:{"dialog":_vm.editDialog,"edit":_vm.editMode,"seasons":_vm.seasons.seasons,"selected-season":_vm.selectedSeason,"product":_vm.editedItem,"colors":_vm.colors,"categories":_vm.productCategories,"article-groups":_vm.articleGroups,"sub-article-groups":_vm.subArticleGroups,"style-types":_vm.styleTypes,"processing":_vm.processing,"error-msg":_vm.errorMsg,"season-add":true},on:{"save-product-edit-dialog":_vm.saveProduct,"close-product-edit-dialog":_vm.closeEditProductDialog}}),_c('v-col',{staticClass:"py-0 mb-0",attrs:{"cols":"12"}},[_c('v-sheet',[_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"color":"white","flat":""}},[_c('v-row',[_c('v-col',[(_vm.productDesignAuthLevel && _vm.productDesignAuthLevel === 2)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.editDialog = true}}},[_vm._v(" add product ")]):_vm._e()],1),_c('v-col',{staticClass:"d-none d-sm-none d-md-none d-lg-flex",attrs:{"cols":"3","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"items":_vm.users,"hide-details":"","outlined":"","flat":"","dense":"","clearable":"","background-color":"#fff","label":"designer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","item-value":"id","disabled":!_vm.products ||
                  (_vm.products.items.length === 0 && !_vm.users) ||
                  _vm.users.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(subData){return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(subData.item.firstName + ' ' + subData.item.lastName)+" ")])]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}}]),model:{value:(_vm.selectedDesigner),callback:function ($$v) {_vm.selectedDesigner=$$v},expression:"selectedDesigner"}})],1),_c('v-col',{staticClass:"d-none d-sm-none d-md-none d-lg-flex",attrs:{"cols":"3","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"hide-details":"","outlined":"","flat":"","dense":"","clearable":"","background-color":"#fff","label":"producer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","item-value":"id","items":_vm.users,"disabled":!_vm.products ||
                  (_vm.products.items.length === 0 && !_vm.users) ||
                  _vm.users.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(subData){return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(subData.item.firstName + ' ' + subData.item.lastName)+" ")])]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}}]),model:{value:(_vm.selectedProducer),callback:function ($$v) {_vm.selectedProducer=$$v},expression:"selectedProducer"}})],1)],1),_c('v-spacer'),_c('span',{staticClass:"pt-3 text-truncate"},[_vm._v(_vm._s(_vm.itemCount)+" Products")]),_c('v-btn',{staticClass:"d-sm-flex d-md-flex d-lg-none d-xl-none ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.filters = !_vm.filters}}},[_c('v-icon',{attrs:{"color":_vm.filters ? 'info' : ''}},[_vm._v("mdi-filter-variant")])],1)],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[(_vm.filters)?_c('v-card',{staticClass:"mb-3"},[_c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',[_vm._v("Advanced Filters")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.filters = !_vm.filters}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.users,"hide-details":"","outlined":"","flat":"","dense":"","clearable":"","background-color":"#fff","label":"designer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","item-value":"id","disabled":!_vm.products ||
                (_vm.products.items.length === 0 && !_vm.users) ||
                _vm.users.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(subData){return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(subData.item.firstName + ' ' + subData.item.lastName)+" ")])]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}}],null,false,2196333882),model:{value:(_vm.selectedDesigner),callback:function ($$v) {_vm.selectedDesigner=$$v},expression:"selectedDesigner"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"hide-details":"","outlined":"","flat":"","dense":"","clearable":"","background-color":"#fff","label":"producer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","item-value":"id","items":_vm.users,"disabled":!_vm.products ||
                (_vm.products.items.length === 0 && !_vm.users) ||
                _vm.users.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(subData){return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(subData.item.firstName + ' ' + subData.item.lastName)+" ")])]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}}],null,false,2196333882),model:{value:(_vm.selectedProducer),callback:function ($$v) {_vm.selectedProducer=$$v},expression:"selectedProducer"}})],1)],1)]):_vm._e(),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"hide-details":"","flat":"","clearable":"","background-color":"#fff","placeholder":"Search...","prepend-inner-icon":"mdi-magnify","autocomplete":"off","disabled":!_vm.products || _vm.products.length === 0},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"loading":_vm.$apollo.loading,"items":_vm.products.items,"options":_vm.options,"server-items-length":_vm.itemCount,"mobile-breakpoint":null,"hide-default-footer":!_vm.products ||
            _vm.products.items.length === 0 ||
            _vm.products.items.length < 15,"show-select":""},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text text--darken-2 font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.styleState",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[_c('v-chip',{attrs:{"pill":"","x-small":"","color":_vm.styleState(item.styleState) + ' upperCaseSpacing',"text-color":_vm.styleText(item.styleState)}},[_vm._v(" "+_vm._s(item.styleState)+" ")])],1)]}},{key:"item.category",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[(item.category && item.category.id)?_c('v-chip',{attrs:{"color":"info","x-small":"","pill":"","outlined":""}},[_vm._v(_vm._s(item.category.name))]):_vm._e()],1)]}},{key:"item.designers",fn:function(ref){
            var item = ref.item;
return [(item.designers && item.designers.length > 0)?_c('div',{staticClass:"text-truncate"},[_vm._l((item.designers),function(designer,index){return [(index < 1)?_c('v-chip',{key:designer.id,staticClass:"mr-2",attrs:{"pill":"","x-small":"","outlined":"","color":"secondary"}},[_c('v-avatar',{staticStyle:{"margin-left":"-19px"},attrs:{"x-small":"","left":""}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_vm._v(" "+_vm._s(designer.firstName + ' ' + designer.lastName))],1):_vm._e(),(index > 0)?_c('span',{key:designer.id,staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(item.designers.length - 1)+" others)")]):_vm._e()]})],2):_vm._e()]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Results","caption":"Try adjusting your filters to get some results"}})]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }