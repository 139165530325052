<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="750"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title class="headline primary--text mb-3">{{
        paymentTermTitle
      }}</v-card-title>
      <v-card-text>
        <v-alert type="info" border="left" text dense>
          Make sure all terms add up to 100%.
        </v-alert>
        <div v-if="errorMsg !== ''" class="mt-3">
          <v-alert type="error" class="mb-0">
            {{ errorMsg }}
          </v-alert>
        </div>
        <v-form
          ref="paymentTermForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-row>
            <v-col cols="12" md="3">
              <v-currency-field
                v-model="editTerm.percentage"
                :decimal-length="2"
                :default-value="0"
                autocomplete="off"
                suffix="%"
                hide-details="auto"
                outlined
                dense
                :rules="requiredRule"
                autofocus
              ></v-currency-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="editTerm.days"
                type="number"
                autocomplete="off"
                suffix="Days"
                hide-details="auto"
                :rules="requiredRule"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="editTerm.direction"
                :items="['after', 'before']"
                autocomplete="off"
                hide-details="auto"
                :rules="requiredRule"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="editTerm.eventType"
                :items="['order', 'shipment']"
                autocomplete="off"
                hide-details="auto"
                :rules="requiredRule"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <!-- <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="editTerm.description"
                  autocomplete="off"
                  label="Description"
                  hide-details="auto"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="grey darken-1"
          text
          :disabled="processing"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          small
          :loading="processing"
          @click="savePaymentTerm"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CustomPaymentTermDialog',
  props: {
    paymentTerm: {
      type: Object,
      required: true,
      default: () => ({
        percentage: 100,
        days: 30,
        description: null,
        direction: 'after',
        eventType: 'shipment'
      })
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valid: true,
      requiredRule: [
        v => !!v || 'Field is required',
        v => !/^\s+$/.test(v) || 'Field is required'
      ],
      addPaymentTermDialog: false,
      editTerm: {
        percentage: 100,
        days: 30,
        description: null,
        direction: 'after',
        eventType: 'shipment'
      }
    }
  },
  computed: {
    // editTerm() {
    //   return this._.cloneDeep(this.paymentTerm)
    // },
    paymentTermTitle() {
      return this.editMode ? 'Edit Payment term' : 'Add Payment Term'
    }
  },
  watch: {
    dialog() {
      this.editTerm = this._.cloneDeep(this.paymentTerm)
    }
  },
  methods: {
    close() {
      this.$emit('close-custom-payment-dialog', false)

      this.$nextTick(() => {
        this.$refs.paymentTermForm.reset()
      })
    },
    savePaymentTerm() {
      if (this.$refs.paymentTermForm.validate()) {
        var data = {
          manufacturingOrderID: this.editTerm.manufacturingOrderID,
          percentage: this.editTerm.percentage,
          days: this.editTerm.days,
          description: this.editTerm.description,
          direction: this.editTerm.direction,
          eventType: this.editTerm.eventType
        }

        if (this.editMode) {
          data.id = this.editTerm.id
        }

        this.$emit('save-payment-term', data)
      }
    }
  }
}
</script>

<style></style>
