<template>
  <v-row
    no-gutters
    class="page-wrap d-flex flex-wrap flex-sm-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap"
  >
    <!-- Order edit dialog -->
    <manufacturing-order-edit-dialog
      :dialog="dialog"
      :manufacturing-order="editedItem"
      :payment-terms="paymentTerms"
      :currencies="currencies"
      :manufacturers="manufacturers.items"
      :warehouses="warehouses"
      :seasons="seasons.seasons"
      :edit-mode="editMode"
      :edit-manufacturer-address="editManufacturingAddress"
      :edit-delivery-address="editDeliveryAddress"
      :edit-delivery-date="editDeliveryDate"
      :edit-note="editNote"
      :edit-docket-ref="editDocketRef"
      :edit-order-date="editOrderDate"
      :processing="processing"
      :error-msg="errorMsg"
      @search-manufacturer="searchManufacturer"
      @close-manufacturing-order-edit-dialog="close"
      @save-manufacturing-order-edit-dialog="save"
    ></manufacturing-order-edit-dialog>

    <!-- Payment Term dialog -->
    <custom-payment-term-dialog
      :dialog="addPaymentTermDialog"
      :edit-mode="editMode"
      :payment-term="paymentTermEdit"
      :processing="processing"
      :error-msg="ptErrorMsg"
      @close-custom-payment-dialog="closePaymentTermDialog"
      @save-payment-term="savePaymentTerm"
    ></custom-payment-term-dialog>

    <!-- Remove Payment Term Dialog -->
    <v-dialog
      v-model="removePaymentTermDialog"
      persistent
      max-width="350"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Payment Term</v-card-title
        >
        <v-card-text>
          <p>
            This will delete the payment term. Make sure all terms add up to
            100%.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closePaymentTermDialog"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removePaymentTerm">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Remove order dialog -->
    <v-dialog
      v-model="removeOrderLineDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Order Line</v-card-title
        >
        <v-card-text>
          <p>
            This will delete the order line and can't be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processingOrderLine"
            @click="closeRemoveOrderLineDialog"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processingOrderLine"
            color="error"
            text
            @click="removeOrderLine"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Remove order line dialog -->
    <v-dialog
      v-model="removeOrderDialog"
      :fullscreen="$vuetify.breakpoint.xs"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Order</v-card-title
        >
        <v-card-text>
          <p>
            This will delete the order and all its associated order lines and
            can't be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processingOrderLine"
            @click="removeOrderDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processingOrderLine"
            color="error"
            text
            @click="removeOrder"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Request Review dialog -->
    <v-dialog
      v-model="reviewOrderDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Request Review</v-card-title
        >
        <v-card-text>
          <p>
            This will submit the order for review and will block you from making
            any further changes!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processingOrderLine"
            @click="reviewOrderDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processingOrderLine"
            color="primary lighten-2"
            small
            rounded
            @click="requestReview"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Mark Send Dialog -->
    <v-dialog
      v-model="markSendDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Update Order State</v-card-title
        >
        <v-card-text>
          <p>
            This set the order state to proposal send to indicate the order has
            been send to the Manufacturer.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processingOrderState"
            @click="markSendDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processingOrderState"
            color="primary lighten-2"
            small
            rounded
            @click="updateState('PROPOSALSEND')"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Order Acceptance Dialog -->
    <v-dialog
      v-model="orderAcceptanceDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      max-width="550"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Manufacturer <span v-if="acceptanceState" class="px-2">Accepted</span
          ><span v-else class="px-2">Rejected</span> Order</v-card-title
        >
        <v-card-text>
          <p>
            This set the order state to
            <span v-if="acceptanceState">Accepted</span
            ><span v-else>Rejected</span> to record the Manufacturers decission.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processingOrderState"
            @click="orderAcceptanceDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processingOrderState"
            color="primary lighten-2"
            small
            rounded
            @click="
              updateState(
                acceptanceState ? 'PROPOSALACCEPTED' : 'PROPOSALREJECTED'
              )
            "
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Cancel Order Dialog -->
    <v-dialog
      v-model="cancelOrderDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Cancel Order</v-card-title
        >
        <v-card-text>
          <p>
            This will cancel the order and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processingOrderState"
            @click="cancelOrderDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processingOrderState"
            color="primary lighten-2"
            small
            rounded
            @click="updateState('CANCELED')"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Reopen Order Dialog -->
    <v-dialog
      v-model="reopenOrderDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Reopen Order</v-card-title
        >
        <v-card-text>
          <p>
            This will set the order to draft for editing and will need to be reauthorized.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processingOrderState"
            @click="reopenOrderDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processingOrderState"
            color="primary lighten-2"
            small
            rounded
            @click="updateState('DRAFT')"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Auth Flow Dialog -->
    <v-dialog
      v-model="authorisationProgressDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Authorisation Progess</v-card-title
        >
        <v-card-text>
          <v-row v-if="manufacturingOrder">
            <v-col v-if="manufacturingOrder.auditFlows" cols="12">
              <v-card
                v-for="flow in orderBy(
                  manufacturingOrder.auditFlows,
                  'updatedAt',
                  -1
                )"
                :key="flow.id"
                class="mb-5"
              >
                <v-card-title class="py-0">
                  <v-row>
                    <v-col cols="7">
                      <v-row no-gutters align="center" class="mt-2">
                        <v-col
                          cols="12"
                          class="title primary--text text-truncate "
                        >
                          Authorisation Request
                        </v-col>
                        <v-col cols="12">
                          <v-chip :color="stateColor(flow.status)" x-small>{{
                            flow.status
                          }}</v-chip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-row justify="end">
                        <!-- <div class="title primary--text text-truncate ">
                      Authorisation Request
                    </div> -->
                        <div
                          class="grey--text text-caption font-weight-bold pa-3"
                        >
                          {{ flow.createdAt | moment('DD MMM YYYY') }}
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <div
                      v-for="(item, index) in flow.auditors"
                      :key="index - 1"
                    >
                      <v-divider></v-divider>
                      <v-list-item :key="item.id">
                        <v-list-item-content>
                          <v-row class="pt-2" no-gutters>
                            <v-col cols="8" class="pb-0">
                              <v-row no-gutters justify="start" align="center">
                                <avatar
                                  :username="
                                    item.firstName + ' ' + item.lastName
                                  "
                                  :size="25"
                                  color="white"
                                  style="user-select: none;"
                                  class="mr-3"
                                ></avatar>
                                <div class="pt-1">
                                  {{ item.firstName + ' ' + item.lastName }}
                                </div>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-row no-gutters justify="end" align="center">
                                <div>
                                  <v-chip
                                    :color="stateColor(item.status)"
                                    x-small
                                    >{{ item.status }}</v-chip
                                  >
                                  <div
                                    v-if="item.approvalDate"
                                    class="grey--text font-weight-bold text-caption text-right mt-2"
                                  >
                                    {{
                                      item.approvalDate | moment('DD MMM YYYY')
                                    }}
                                  </div>
                                </div>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row v-if="item.comments" no-gutters>
                            <v-col cols="12">
                              <div class="pb-4">
                                <v-row>
                                  <v-col cols="12" class="text--secondary pb-0">
                                    <!-- eslint-disable-next-line -->
                                    <div v-html="item.comments"></div>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-else cols="12">
              <v-alert color="info" border="left" text class="mb-0"
                >Please come back once the manufacturing order has been
                submitted for approval to track progress.</v-alert
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            @click="authorisationProgressDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PDF Template -->
    <!-- <div style="position: absolute;top:0;right:0;width: 100%;clear: both;"> -->
    <div v-if="previewPDF" class="page-wrap">
      <div v-if="manufacturingOrder" class="scroll-container py-3 ">
        <!-- Print preview header -->

        <v-card flat class="py-2 px-3 mb-6">
          <v-row>
            <v-col class="pb-0 pt-1"><h3>Print Preview</h3></v-col>
            <v-col class="py-0">
              <v-row justify="center">
                <v-select
                  v-model="pdfLayout"
                  outlined
                  label="Page Layout"
                  dense
                  hide-details
                  :items="layoutItems"
                ></v-select>
              </v-row>
            </v-col>
            <v-col class="py-0">
              <v-row no-gutters justify="end">
                <v-btn text rounded color="grey" @click="previewPDF = false"
                  >cancel</v-btn
                >
                <v-btn
                  small
                  rounded
                  color="primary"
                  class="mr-3 mt-1"
                  @click="createPDF"
                  >Save</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <manufacturing-order-cover-page-pdf
          v-if="pdfPageCount >= 1"
          id="pdfDoc0"
          :page="1"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-cover-page-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 2"
          id="pdfDoc1"
          :page="2"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 3"
          id="pdfDoc2"
          :page="3"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 4"
          id="pdfDoc3"
          :page="4"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 5"
          id="pdfDoc4"
          :page="5"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 6"
          id="pdfDoc5"
          :page="6"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 7"
          id="pdfDoc6"
          :page="7"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 8"
          id="pdfDoc7"
          :page="8"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-9"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 9"
          id="pdfDoc8"
          :page="9"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 10"
          id="pdfDoc9"
          :page="10"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 11"
          id="pdfDoc10"
          :page="11"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 12"
          id="pdfDoc11"
          :page="12"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 13"
          id="pdfDoc12"
          :page="13"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 14"
          id="pdfDoc13"
          :page="14"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 15"
          id="pdfDoc14"
          :page="15"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 16"
          id="pdfDoc15"
          :page="16"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 17"
          id="pdfDoc16"
          :page="17"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 18"
          id="pdfDoc17"
          :page="18"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 19"
          id="pdfDoc18"
          :page="19"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 20"
          id="pdfDoc19"
          :page="20"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 21"
          id="pdfDoc20"
          :page="21"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 22"
          id="pdfDoc21"
          :page="22"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 23"
          id="pdfDoc22"
          :page="23"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 24"
          id="pdfDoc23"
          :page="24"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 25"
          id="pdfDoc24"
          :page="25"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 26"
          id="pdfDoc25"
          :page="26"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 27"
          id="pdfDoc26"
          :page="27"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 28"
          id="pdfDoc27"
          :page="28"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 29"
          id="pdfDoc28"
          :page="29"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 30"
          id="pdfDoc29"
          :page="30"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 31"
          id="pdfDoc30"
          :page="31"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 32"
          id="pdfDoc31"
          :page="32"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 33"
          id="pdfDoc32"
          :page="33"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 34"
          id="pdfDoc33"
          :page="34"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 35"
          id="pdfDoc34"
          :page="35"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 36"
          id="pdfDoc35"
          :page="36"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 37"
          id="pdfDoc36"
          :page="37"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 38"
          id="pdfDoc37"
          :page="38"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 39"
          id="pdfDoc38"
          :page="39"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
        <manufacturing-order-pdf
          v-if="pdfPageCount >= 40"
          id="pdfDoc39"
          :page="40"
          :manufacturing-order="manufacturingOrder"
          :page-count="pdfPageCount"
          :layout="pdfLayout"
          class="mb-3"
        ></manufacturing-order-pdf>
      </div>

      <v-overlay :value="generatingPDF">
        <h2 class="mb-3">Generating PDF</h2>
        <v-row justify="center">
          <v-progress-linear indeterminate color="info"></v-progress-linear>
        </v-row>
      </v-overlay>
    </div>

    <v-col
      v-if="!previewPDF"
      cols="12"
      md="8"
      lg="9"
      xl="9"
      class="pa-0 order-2 order-md-0 flex-grow-1 bkground"
    >
      <div class="scroll-container py-0">
        <v-row class="mb-6">
          <v-col cols="12">
            <!-- New Product Header -->
            <v-card
              v-if="
                manufacturingOrder &&
                  manufacturingOrder.status &&
                  (manufacturingOrder.status === 'DRAFT' ||
                    manufacturingOrder.status === 'PENDINGCHANGES' ||
                    manufacturingOrder.status === 'PENDINGCHANGES')
              "
              flat
              class="px-3 mb-3"
            >
              <v-row>
                <v-col cols="6" sm="4">
                  <v-autocomplete
                    v-model="selectedProducts"
                    label="Products"
                    placeholder="select product(s)"
                    item-text="name"
                    item-value="id"
                    multiple
                    autocomplete="off"
                    :items="unusedProducts"
                    dense
                    outlined
                    hide-details
                    clearable
                    :disabled="
                      (manufacturingOrder && authLevel && authLevel !== 2) ||
                        (manufacturingOrder &&
                          manufacturingOrder.status &&
                          manufacturingOrder.status === 'REVIEW') ||
                        (manufacturingOrder &&
                          manufacturingOrder.status &&
                          manufacturingOrder.status === 'APPROVED') ||
                        (manufacturingOrder &&
                          manufacturingOrder.status &&
                          manufacturingOrder.status === 'PROPOSALSEND') ||
                        (manufacturingOrder &&
                          manufacturingOrder.status &&
                          manufacturingOrder.status === 'PROPOSALREJECTED') ||
                        (manufacturingOrder &&
                          manufacturingOrder.status &&
                          manufacturingOrder.status === 'CLOSED')
                    "
                  >
                    <template v-slot:item="{ item }">
                      <div>
                        {{ item.name }}
                        <span
                          v-if="item.seasonColor"
                          class="text-lowercase grey--text darken-1"
                        >
                          - {{ item.seasonColor }}</span
                        >
                      </div>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" small>
                        <span class="text-truncate">{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption"
                        >(+{{ selectedProducts.length - 1 }} others)</span
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="3" sm="4">
                  <v-btn
                    :disabled="
                      !selectedProducts || selectedProducts.length === 0
                    "
                    :loading="processing"
                    small
                    rounded
                    color="primary lighten-2"
                    class="mt-1"
                    @click="addProducts"
                    >Add Product(s)</v-btn
                  >
                </v-col>
                <v-col>
                  <v-row justify="end">
                    <div class="mt-2 mr-3">
                      <span class="grey--text caption">Total Qty: </span
                      ><span>{{ totalProductCount() }}</span>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

            <!-- Order Lines -->
            <v-row v-if="$apollo.loading" flat>
              <v-col cols="12">
                <v-card v-for="i in 4" :key="i" flat class="px-3 mb-3">
                  <v-skeleton-loader
                    v-for="item in 2"
                    :key="item"
                    type="list-item"
                    class="mx-auto"
                  ></v-skeleton-loader>
                </v-card>
              </v-col>
            </v-row>

            <v-expansion-panels
              v-else-if="
                manufacturingOrder.orderLines &&
                  manufacturingOrder.orderLines.length > 0
              "
              v-model="activeOrderLineIndex"
              hover
              flat
            >
              <manufacturing-order-line
                v-for="line in manufacturingOrder.orderLines"
                :key="line.id"
                :manufacturing-order="manufacturingOrder"
                :order-line="line"
                :auth-level="authLevel"
                :processing="processingOrderLine"
                :error-msg="errorMsg"
                :active-item="activeOrderLineID"
                @save-order-line="saveOrderLine"
                @remove-order-line="openRemoveOrderLineDialog"
              ></manufacturing-order-line>
            </v-expansion-panels>

            <v-card v-else flat>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Order Lines"
                caption="You have not added any products to this order yet."
              >
              </message-box>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-col
      v-if="!previewPDF"
      cols="12"
      class="order-1 order-md-2 flex-grow-1 d-flex d-sm-flex d-md-none px-5 bkground"
    >
      <v-row>
        <v-col cols="12" class="pb-0"><h3>Products</h3></v-col>
        <v-col cols="12"><v-divider></v-divider></v-col>
      </v-row>
    </v-col>

    <v-col
      v-if="!previewPDF"
      cols="12"
      md="4"
      lg="3"
      xl="3"
      class="pa-0 order-0 order-md-1 flex-grow-1 bkground"
    >
      <div class="scroll-container py-0">
        <v-row class="mb-6">
          <v-col v-if="$apollo.loading">
            <v-card flat class="mb-3">
              <v-skeleton-loader
                type="card"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-card>
            <v-card v-for="i in 2" :key="i" flat class="mb-3">
              <v-skeleton-loader
                v-for="item in 2"
                :key="item"
                type="list-item"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
          <v-col v-else cols="12">
            <v-card flat class="mb-1 pa-5">
              <v-row class="px-3">
                <span class="subtitle-1 grey--text">Order #</span
                ><span
                  v-if="
                    manufacturingOrder.status !== 'DRAFT' &&
                      manufacturingOrder.status !== 'REVIEW'
                  "
                  class="subtitle-1 ml-1"
                  >{{ manufacturingOrder.id }}</span
                >
                <v-spacer></v-spacer>
                <v-chip
                  pill
                  x-small
                  :color="styleState(manufacturingOrder.status)"
                  :text-color="styleText(manufacturingOrder.status)"
                  class="upperCaseSpacing"
                >
                  {{ manufacturingOrder.status }}
                </v-chip>
              </v-row>
              <v-row v-if="manufacturingOrder.orderDate">
                <v-col>
                  <div class="caption grey--text">Docket Ref</div>
                  <div>
                    <span>{{ manufacturingOrder.docketRef }}</span>
                    <v-btn
                      v-if="
                        manufacturingOrder &&
                          manufacturingOrder.status &&
                          (manufacturingOrder.status === 'DRAFT' ||
                            manufacturingOrder.status === 'PENDINGCHANGES') &&
                          manufacturingOrder.docketRef
                      "
                      icon
                      small
                      class="ml-3"
                      @click="editManufacturingOrderDocketRef"
                      ><v-icon small>mdi-pencil-outline</v-icon></v-btn
                    >
                    <v-btn
                      v-if="
                        manufacturingOrder &&
                          manufacturingOrder.status &&
                          (manufacturingOrder.status === 'DRAFT' ||
                            manufacturingOrder.status === 'PENDINGCHANGES' ||
                            manufacturingOrder.status === 'PENDINGCHANGES') &&
                          !manufacturingOrder.docketRef
                      "
                      x-small
                      outlined
                      rounded
                      color="grey"
                      class="text-lowercase"
                      @click="editManufacturingOrderDocketRef"
                      >add reference</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="manufacturingOrder.orderDate">
                <v-col>
                  <div class="caption grey--text">Order Date</div>
                  <div>
                    <span>{{
                      manufacturingOrder.orderDate | moment('DD MMM YYYY')
                    }}</span
                    ><v-btn
                      v-if="
                        manufacturingOrder &&
                          manufacturingOrder.status &&
                          (manufacturingOrder.status === 'DRAFT' ||
                            manufacturingOrder.status === 'PENDINGCHANGES')
                      "
                      icon
                      small
                      class="ml-3"
                      @click="editManufacturingOrderDate"
                      ><v-icon small>mdi-pencil-outline</v-icon></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="caption grey--text">Season</div>
                  <div>
                    {{ manufacturingOrder.season.longName }} ({{
                      manufacturingOrder.season.shortName
                    }})
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="caption grey--text">Created By</div>
                  <div v-if="manufacturingOrder.createdBy">
                    {{
                      manufacturingOrder.createdBy.firstName +
                        ' ' +
                        manufacturingOrder.createdBy.lastName
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="manufacturingOrder">
                <v-col>
                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                    class="mt-4"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        rounded
                        color="info"
                        v-bind="attrs"
                        v-on.prevent="on"
                        >actions</v-btn
                      >
                    </template>

                    <v-list>
                      <v-list-item v-if="!validOrder || draftProduct">
                        <v-alert
                          v-if="draftProduct"
                          type="info"
                          dense
                          text
                          class="caption mt-2 mb-0"
                        >
                          Finalize all draft products in this order.
                        </v-alert>
                        <v-alert
                          v-else-if="!validOrder && !draftProduct"
                          type="info"
                          dense
                          text
                          class="caption mt-2 mb-0"
                        >
                          Correct the pink dots to send for review.
                        </v-alert>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          validOrder &&
                            !draftProduct &&
                            manufacturingOrder &&
                            authLevel &&
                            authLevel > 0 &&
                            (manufacturingOrder.status === 'DRAFT' ||
                              manufacturingOrder.status === 'PENDINGCHANGES')
                        "
                      >
                        <v-btn
                          :disabled="!validOrder"
                          small
                          text
                          color="info"
                          @click="reviewOrderDialog = true"
                          >Request Review</v-btn
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="manufacturingOrder && authLevel && authLevel > 0"
                      >
                        <v-btn small text color="info" @click="openPDF"
                          >Download As PDF</v-btn
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="
                          (manufacturingOrder &&
                            authLevel &&
                            authLevel === 2 &&
                            manufacturingOrder.status === 'APPROVED') ||
                            (manufacturingOrder &&
                              manufacturingOrder.status === 'APPROVED' &&
                              currentUser &&
                              (currentUser.isSuperAdmin ||
                                currentUser.userRole === 'director' ||
                                currentUser.userRole === 'seniormanager' ||
                                currentUser.userRole === 'projectmanager'))
                        "
                      >
                        <v-btn
                          small
                          text
                          color="info"
                          @click="markSendDialog = true"
                          >Sent To Manufacturer</v-btn
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="
                          (manufacturingOrder &&
                            authLevel &&
                            authLevel === 2 &&
                            manufacturingOrder.status === 'PROPOSALSEND') ||
                            (manufacturingOrder &&
                              manufacturingOrder.status === 'PROPOSALSEND' &&
                              currentUser &&
                              (currentUser.isSuperAdmin ||
                                currentUser.userRole === 'director' ||
                                currentUser.userRole === 'seniormanager' ||
                                currentUser.userRole === 'projectmanager'))
                        "
                      >
                        <v-btn
                          small
                          text
                          color="info"
                          @click="openAcceptanceDialog(true)"
                          >Order Accepted</v-btn
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="
                          (manufacturingOrder &&
                            authLevel &&
                            authLevel === 2 &&
                            manufacturingOrder.status === 'PROPOSALSEND') ||
                            (manufacturingOrder &&
                              manufacturingOrder.status === 'PROPOSALSEND' &&
                              currentUser &&
                              (currentUser.isSuperAdmin ||
                                currentUser.userRole === 'director' ||
                                currentUser.userRole === 'seniormanager' ||
                                currentUser.userRole === 'projectmanager'))
                        "
                      >
                        <v-btn
                          small
                          text
                          color="info"
                          @click="openAcceptanceDialog(false)"
                          >Order Rejected</v-btn
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="
                          (manufacturingOrder &&
                            authLevel &&
                            authLevel === 2 &&
                            (manufacturingOrder.status === 'APPROVED' ||
                              manufacturingOrder.status === 'PROPOSALSEND')) ||
                            (manufacturingOrder &&
                              (manufacturingOrder.status === 'APPROVED' ||
                                manufacturingOrder.status === 'PROPOSALSEND') &&
                              currentUser &&
                              (currentUser.isSuperAdmin ||
                                currentUser.userRole === 'director' ||
                                currentUser.userRole === 'seniormanager' ||
                                currentUser.userRole === 'projectmanager'))
                        "
                      >
                        <v-btn
                          small
                          text
                          color="info"
                          @click="cancelOrderDialog = true"
                          >Cancel Order</v-btn
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="
                          (manufacturingOrder &&
                            authLevel &&
                            authLevel === 2 &&
                            (manufacturingOrder.status === 'APPROVED' ||
                              manufacturingOrder.status === 'PROPOSALSEND')) ||
                            (manufacturingOrder &&
                              (manufacturingOrder.status === 'APPROVED' ||
                                manufacturingOrder.status === 'PROPOSALSEND') &&
                              currentUser &&
                              (currentUser.isSuperAdmin ||
                                currentUser.userRole === 'director' ||
                                currentUser.userRole === 'seniormanager' ||
                                currentUser.userRole === 'projectmanager'))
                        "
                      >
                        <v-btn
                          small
                          text
                          color="info"
                          @click="reopenOrderDialog = true"
                          >Reopen Order</v-btn
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="
                          (manufacturingOrder &&
                            authLevel &&
                            authLevel === 2 &&
                            manufacturingOrder.status === 'FINAL') ||
                            (manufacturingOrder &&
                              manufacturingOrder.status === 'FINAL' &&
                              currentUser &&
                              (currentUser.isSuperAdmin ||
                                currentUser.userRole === 'director' ||
                                currentUser.userRole === 'seniormanager' ||
                                currentUser.userRole === 'projectmanager'))
                        "
                      >
                        <v-btn small text color="info" @click="unlock"
                          >Unlock</v-btn
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="
                          (manufacturingOrder &&
                            authLevel &&
                            authLevel === 2 &&
                            (manufacturingOrder.status === 'DRAFT' ||
                              manufacturingOrder.status ===
                                'PENDINGCHANGES')) ||
                            (manufacturingOrder &&
                              (manufacturingOrder.status === 'DRAFT' ||
                                manufacturingOrder.status ===
                                  'PENDINGCHANGES') &&
                              currentUser &&
                              (currentUser.isSuperAdmin ||
                                currentUser.userRole === 'director' ||
                                currentUser.userRole === 'seniormanager' ||
                                currentUser.userRole === 'projectmanager'))
                        "
                      >
                        <v-btn
                          small
                          text
                          color="info"
                          @click="removeOrderDialog = true"
                          >Delete Order</v-btn
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          small
                          text
                          color="info"
                          @click="authorisationProgressDialog = true"
                          >View Authorisation Progress</v-btn
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card>

            <v-card flat class="px-5 py-3">
              <v-row>
                <v-col class="py-0">
                  <div class="caption grey--text">Quantity</div>
                  <h3 class="pt-2">
                    <span>{{ totalProductCount() }}</span>
                  </h3>
                </v-col>
                <v-col class="py-0">
                  <div class="caption grey--text">Cost</div>
                  <h3 class="pt-2">
                    <span>{{
                      (totalProductCost / 100)
                        | currency(manufacturingOrder.currency.symbol, 2, {
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}</span>
                  </h3>
                </v-col>
              </v-row>
            </v-card>

            <v-expansion-panels hover multiple flat>
              <!-- Manufacturer -->
              <v-expansion-panel class="mt-1">
                <v-expansion-panel-header>
                  <template v-slot:default="{}">
                    <v-row no-gutters>
                      <v-col cols="4"
                        ><span class="caption grey--text text-truncate"
                          >Manufacturer</span
                        ></v-col
                      >

                      <v-col
                        v-if="manufacturingOrder.manufacturer"
                        cols="12"
                        class="pt-2"
                        >{{ manufacturingOrder.manufacturer.name }}</v-col
                      >
                    </v-row>
                  </template>

                  <template v-slot:actions>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="body-2 grey--text text--darken-1">
                    <v-col class="py-0" cols="12">
                      <div>
                        {{ manufacturingOrder.manufacturerAddress.line1 }}
                      </div>
                      <div>
                        {{ manufacturingOrder.manufacturerAddress.line2 }}
                      </div>
                      <div>
                        {{ manufacturingOrder.manufacturerAddress.line3 }}
                      </div>
                      <div>
                        {{ manufacturingOrder.manufacturerAddress.city }}
                      </div>
                      <div>
                        {{ manufacturingOrder.manufacturerAddress.postCode }}
                      </div>
                      <div
                        v-if="
                          manufacturingOrder.manufacturerAddress &&
                            manufacturingOrder.manufacturerAddress.country
                        "
                      >
                        {{
                          getFullCountryName(
                            manufacturingOrder.manufacturerAddress.country
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      manufacturingOrder &&
                        authLevel &&
                        authLevel === 2 &&
                        (manufacturingOrder.status === 'DRAFT' ||
                          manufacturingOrder.status === 'PENDINGCHANGES')
                    "
                    class="mt-6"
                  >
                    <v-col class="py-0">
                      <v-btn
                        v-if="
                          !editMode &&
                            ((manufacturingOrder &&
                              authLevel &&
                              authLevel === 2) ||
                              (currentUser &&
                                (currentUser.isSuperAdmin ||
                                  currentUser.userRole === 'director' ||
                                  currentUser.userRole === 'seniormanager' ||
                                  currentUser.userRole === 'projectmanager')))
                        "
                        rounded
                        outlined
                        small
                        color="grey"
                        class="mr-5 mt-1"
                        :disabled="processing"
                        @click="editManufacturingOrderManufacturingAddress"
                      >
                        edit
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Delivery Address -->
              <v-expansion-panel class="mt-1">
                <v-expansion-panel-header>
                  <template v-slot:default="{}">
                    <v-row no-gutters>
                      <v-col cols="4"
                        ><span class="caption grey--text text-truncate"
                          >Delivery Address</span
                        ></v-col
                      >

                      <v-col
                        v-if="manufacturingOrder.deliverTo"
                        cols="12"
                        class="pt-2"
                        >{{ manufacturingOrder.deliverTo.name }}</v-col
                      >
                    </v-row>
                  </template>

                  <template v-slot:actions>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="manufacturingOrder.deliverTo">
                  <v-row class="body-2">
                    <v-col
                      v-if="
                        manufacturingOrder.deliverTo &&
                          manufacturingOrder.deliverTo.provider
                      "
                      cols="12"
                      class="pt-2"
                      >{{ manufacturingOrder.deliverTo.provider.name }}</v-col
                    >
                    <v-col class="py-0  grey--text text--darken-1" cols="12">
                      <div>
                        {{ manufacturingOrder.deliverTo.address.line1 }}
                      </div>
                      <div>
                        {{ manufacturingOrder.deliverTo.address.line2 }}
                      </div>
                      <div>
                        {{ manufacturingOrder.deliverTo.address.line3 }}
                      </div>
                      <div>{{ manufacturingOrder.deliverTo.address.city }}</div>
                      <div>
                        {{ manufacturingOrder.deliverTo.address.postCode }}
                      </div>
                      <div
                        v-if="
                          manufacturingOrder.deliverTo.address &&
                            manufacturingOrder.deliverTo.address.country
                        "
                      >
                        {{
                          getFullCountryName(
                            manufacturingOrder.deliverTo.address.country
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      manufacturingOrder &&
                        authLevel &&
                        authLevel === 2 &&
                        (manufacturingOrder.status === 'DRAFT' ||
                          manufacturingOrder.status === 'PENDINGCHANGES')
                    "
                    class="mt-6"
                  >
                    <v-col class="py-0">
                      <v-btn
                        v-if="
                          !editMode &&
                            ((manufacturingOrder &&
                              authLevel &&
                              authLevel === 2) ||
                              (currentUser &&
                                (currentUser.isSuperAdmin ||
                                  currentUser.userRole === 'director' ||
                                  currentUser.userRole === 'seniormanager' ||
                                  currentUser.userRole === 'projectmanager')))
                        "
                        rounded
                        outlined
                        small
                        color="grey"
                        class="mr-5 mt-1"
                        :disabled="processing"
                        @click="editManufacturingOrderDeliveryAddress"
                      >
                        edit
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Delivery Date -->
              <v-expansion-panel class="mt-1">
                <v-expansion-panel-header>
                  <template v-slot:default="{}">
                    <v-row no-gutters>
                      <v-col cols="8">
                        <v-badge
                          v-if="!manufacturingOrder.exFactoryDate"
                          color="pink lighten-2"
                          dot
                          inline
                        >
                          <span class="caption grey--text text-truncate"
                            >Delivery Dates</span
                          >
                        </v-badge>
                        <span v-else class="caption grey--text text-truncate"
                          >Delivery Dates</span
                        >
                      </v-col>
                      <v-col cols="12" class="pt-3">
                        <v-row>
                          <v-col>
                            <h4 class="mb-2 caption grey--text text-truncate">
                              Ex-Factory
                            </h4>
                            <span
                              v-if="
                                manufacturingOrder &&
                                  manufacturingOrder.exFactoryDate
                              "
                              >{{
                                manufacturingOrder.exFactoryDate
                                  | moment('DD MMM YYYY')
                              }}</span
                            >
                          </v-col>
                          <v-col>
                            <h4 class="mb-2 caption grey--text text-truncate">
                              Arrival
                            </h4>
                            <span
                              v-if="
                                manufacturingOrder &&
                                  manufacturingOrder.arrivalDate
                              "
                            >
                              {{
                                manufacturingOrder.arrivalDate
                                  | moment('DD MMM YYYY')
                              }}</span
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:actions>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    v-if="manufacturingOrder && authLevel && authLevel === 2"
                    class="mt-3"
                  >
                    <v-col class="py-0">
                      <v-btn
                        v-if="
                          !editMode &&
                            ((manufacturingOrder &&
                              authLevel &&
                              authLevel === 2) ||
                              (currentUser &&
                                (currentUser.isSuperAdmin ||
                                  currentUser.userRole === 'director' ||
                                  currentUser.userRole === 'seniormanager' ||
                                  currentUser.userRole === 'projectmanager')))
                        "
                        rounded
                        outlined
                        small
                        color="grey"
                        class="mr-3"
                        :disabled="processing"
                        @click="editManufacturingOrderDeliveryDates"
                      >
                        edit
                      </v-btn>
                      <v-btn
                        v-if="
                          (manufacturingOrder.exFactoryDate ||
                            manufacturingOrder.arrivalDate) &&
                            manufacturingOrder.orderLines &&
                            manufacturingOrder.orderLines.length > 0
                        "
                        small
                        rounded
                        outlined
                        color="info"
                        :loading="processingDates"
                        @click="applyDatesToAllLines"
                        >Apply to all lines</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Payment Terms -->
              <v-expansion-panel class="mt-1">
                <v-expansion-panel-header>
                  <template v-slot:default="{}">
                    <v-row no-gutters>
                      <v-col cols="4"
                        ><span class="caption grey--text text-truncate"
                          >Payment Terms</span
                        ></v-col
                      >
                    </v-row>
                  </template>

                  <template v-slot:actions>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    v-for="(term, index) in manufacturingOrder.paymentTerms"
                    :key="term.id"
                    no-gutters
                    class="grey--text text--darken-1"
                  >
                    <v-col cols="12"
                      ><v-divider v-if="index > 0"></v-divider
                    ></v-col>
                    <v-col cols="9" class="py-2">
                      <span class="caption">
                        {{
                          term.percentage +
                            '% ' +
                            term.days +
                            ' days ' +
                            term.direction +
                            ' ' +
                            term.eventType
                        }}
                      </span>
                    </v-col>
                    <v-col cols="3" class="py-2">
                      <v-row
                        v-if="
                          manufacturingOrder.status === 'DRAFT' ||
                            manufacturingOrder.status === 'PENDINGCHANGES' || currentUser.isSuperAdmin || currentUser.isAdmin
                        "
                        justify="end"
                      >
                        <v-btn
                          right
                          icon
                          x-small
                          color="info"
                          class="ml-3"
                          @click="openPaymentTermDialog(true, term)"
                          ><v-icon x-small>mdi-pencil-outline</v-icon></v-btn
                        >
                        <v-btn
                          right
                          icon
                          x-small
                          color="grey"
                          class="ml-2"
                          @click="openRemovePaymentTermDialog(term)"
                          ><v-icon x-small>mdi-delete-outline</v-icon></v-btn
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      manufacturingOrder.status === 'DRAFT' ||
                        manufacturingOrder.status === 'PENDINGCHANGES' || currentUser.isSuperAdmin || currentUser.isAdmin
                    "
                  >
                    <v-col cols="12"
                      ><v-divider
                        v-if="
                          manufacturingOrder.paymentTerms &&
                            manufacturingOrder.paymentTerms.length > 0
                        "
                      ></v-divider
                    ></v-col>
                    <v-col cols="12" class="py-0">
                      <v-btn
                        text
                        block
                        color="info"
                        small
                        @click="openPaymentTermDialog(false)"
                        >Add</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Shipper -->
              <v-expansion-panel v-if="false" class="mt-1">
                <v-expansion-panel-header>
                  <template v-slot:default="{}">
                    <v-row no-gutters>
                      <v-col cols="4"
                        ><span class="caption grey--text text-truncate"
                          >Shipper</span
                        ></v-col
                      >

                      <v-col
                        v-if="manufacturingOrder.shipper"
                        cols="12"
                        class="pt-2"
                        >{{ manufacturingOrder.shipper.name }}</v-col
                      >
                    </v-row>
                  </template>

                  <template v-slot:actions>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    v-if="
                      manufacturingOrder &&
                        manufacturingOrder.shipper &&
                        manufacturingOrder.shipper.addresses &&
                        manufacturingOrder.shipper.addresses.length > 0
                    "
                    class="body-2 grey--text text--darken-1"
                  >
                    <v-col class="py-0" cols="12">
                      <div>
                        {{ manufacturingOrder.shipper.addresses[0].line1 }}
                      </div>
                      <div>
                        {{ manufacturingOrder.shipper.addresses[0].line2 }}
                      </div>
                      <div>
                        {{ manufacturingOrder.shipper.addresses[0].line3 }}
                      </div>
                      <div>
                        {{ manufacturingOrder.shipper.addresses[0].state }}
                      </div>
                      <div>
                        {{ manufacturingOrder.shipper.addresses[0].city }}
                      </div>
                      <div>
                        {{ manufacturingOrder.shipper.addresses[0].postCode }}
                      </div>
                      <div
                        v-if="
                          manufacturingOrder.shipper.addresses[0] &&
                            manufacturingOrder.shipper.addresses[0].country
                        "
                      >
                        {{
                          getFullCountryName(
                            manufacturingOrder.shipper.addresses[0].country
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <v-row class="mt-6">
                    <v-col class="py-0">
                      <v-btn
                        v-if="
                          !editMode &&
                            ((manufacturingOrder &&
                              authLevel &&
                              authLevel === 2) ||
                              (currentUser &&
                                (currentUser.isSuperAdmin ||
                                  currentUser.userRole === 'director' ||
                                  currentUser.userRole === 'seniormanager' ||
                                  currentUser.userRole === 'projectmanager')))
                        "
                        rounded
                        outlined
                        small
                        color="grey"
                        class="mr-5 mt-1"
                        :disabled="processing"
                      >
                        edit
                      </v-btn>
                    </v-col>
                  </v-row> -->
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Notes -->
              <v-expansion-panel class="mt-1">
                <v-expansion-panel-header>
                  <template v-slot:default="{}">
                    <v-row no-gutters>
                      <v-col cols="4"
                        ><span class="caption grey--text text-truncate"
                          >Notes</span
                        ></v-col
                      >
                    </v-row>
                  </template>

                  <template v-slot:actions>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="body-2 grey--text text--darken-1">
                    <v-col class="py-0" cols="12">
                      <div>
                        {{ manufacturingOrder.note }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      manufacturingOrder &&
                        authLevel &&
                        authLevel === 2 &&
                        (manufacturingOrder.status === 'DRAFT' ||
                          manufacturingOrder.status === 'PENDINGCHANGES')
                    "
                    class="mt-6"
                  >
                    <v-col class="py-0">
                      <v-btn
                        v-if="
                          !editMode &&
                            ((manufacturingOrder &&
                              authLevel &&
                              authLevel === 2) ||
                              (currentUser &&
                                (currentUser.isSuperAdmin ||
                                  currentUser.userRole === 'director' ||
                                  currentUser.userRole === 'seniormanager' ||
                                  currentUser.userRole === 'projectmanager')))
                        "
                        rounded
                        outlined
                        small
                        color="grey"
                        class="mr-5 mt-1"
                        :disabled="processing"
                        @click="editManufacturingOrderNote"
                      >
                        edit
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import html2canvas from 'html2canvas'
import * as JsPDF from 'jspdf'
import Vue2Filters from 'vue2-filters'
import Avatar from 'vue-avatar'
import { getName } from 'country-list'
import CustomPaymentTermDialog from '@/modules/production/components/core/CustomPaymentTermDialog.vue'
import ManufacturingOrderPdf from '@/modules/production/components/core/ManufacturingOrderPdf.vue'
import ManufacturingOrderCoverPagePdf from '@/modules/production/components/core/ManufacturingOrderCoverPagePdf.vue'
import MessageBox from '@/components/core/MessageBox.vue'
import ManufacturingOrderLine from '@/modules/production/components/core/ManufacturingOrderLine.vue'
import ManufacturingOrderEditDialog from '@/modules/production/components/core/ManufacturingOrderEditDialog.vue'
import PRODUCTS_BY_MANUFACTURER from '@/graphql/ProductsByManufacturer.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import MANUFACTURING_ORDER from '@/graphql/ManufacturingOrder.gql'
import MANUFACTURER_LIST from '@/graphql/Manufacturers.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import SEASONS from '@/graphql/Seasons.gql'
import MANUFACTURING_ORDER_UPDATE from '@/graphql/ManufacturingOrderUpdate.gql'
import MANUFACTURING_ORDER_STATE_UPDATE from '@/graphql/ManufacturingOrderStateUpdate.gql'
import MANUFACTURING_ORDER_REMOVE from '@/graphql/ManufacturingOrderRemove.gql'
import MANUFACTURING_ORDER_REQUEST_REVIEW from '@/graphql/ManufacturingOrderRequestReview.gql'
import MANUFACTURING_ORDER_LINE_CREATE from '@/graphql/ManufacturingOrderLineCreate.gql'
import MANUFACTURING_ORDER_LINE_UPDATE from '@/graphql/ManufacturingOrderLineUpdate.gql'
import MANUFACTURING_ORDER_LINE_REMOVE from '@/graphql/ManufacturingOrderLineRemove.gql'
import CREATE_CUSTOM_PAYMENT_TERM from '@/graphql/CustomPaymentTermCreate.gql'
import UPDATE_CUSTOM_PAYMENT_TERM from '@/graphql/CustomPaymentTermUpdate.gql'
import REMOVE_CUSTOM_PAYMENT_TERM from '@/graphql/CustomPaymentTermRemove.gql'

export default {
  name: 'ManufacturingOrderDetail',
  components: {
    Avatar,
    MessageBox,
    ManufacturingOrderEditDialog,
    ManufacturingOrderLine,
    ManufacturingOrderPdf,
    ManufacturingOrderCoverPagePdf,
    CustomPaymentTermDialog
  },
  mixins: [Vue2Filters.mixin],
  props: {},
  data: () => ({
    ptErrorMsg: '',
    addPaymentTermDialog: false,
    removePaymentTermDialog: false,
    paymentTermEdit: {
      percentage: 100,
      days: 30,
      description: null,
      direction: 'after',
      eventType: 'shipment'
    },
    generatingPDF: false,
    previewPDF: false,
    pdfLayout: 'portrait',
    layoutItems: ['portrait', 'landscape'],
    activeOrderLineIndex: null,
    dialog: false,
    addressDialog: false,
    removeOrderDialog: false,
    removeOrderLineDialog: false,
    reviewOrderDialog: false,
    removeOrderLineID: null,
    editMode: false,
    editDocketRef: false,
    editManufacturingAddress: false,
    editDeliveryAddress: false,
    editDeliveryDate: false,
    editOrderDate: false,
    editNote: false,
    processing: false,
    processingOrderLine: false,
    processingDates: false,
    markSendDialog: false,
    cancelOrderDialog: false,
    reopenOrderDialog: false,
    acceptanceState: true,
    orderAcceptanceDialog: false,
    authorisationProgressDialog: false,
    processingOrderState: false,
    errorMsg: '',
    manufacturers: { items: [] },
    warehouses: { items: [] },
    productsByManufacturer: { items: [] },
    seasons: { seasons: [] },
    selectedProducts: null,
    searchMan: null,
    defaultItem: {
      id: null,
      name: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        state: null,
        city: null,
        postCode: null,
        country: null
      },
      addresses: [],
      companyRegistration: null,
      vatRegistration: null,
      paymentTerm: null,
      shippingTerm: null,
      currency: null
    },
    editedItem: {
      id: null,
      name: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        state: null,
        city: null,
        postCode: null,
        country: null
      },
      addresses: [],
      companyRegistration: null,
      vatRegistration: null,
      paymentTerm: null,
      shippingTerm: null,
      currency: null
    }
  }),
  computed: {
    pdfPageCount() {
      var pageCount = 1
      if (
        this.manufacturingOrder &&
        this.manufacturingOrder.orderLines &&
        this.manufacturingOrder.orderLines.length > 0
      ) {
        this.pdfLayout === 'portrait'
          ? (pageCount =
              Math.ceil(this.manufacturingOrder.orderLines.length / 8) + 1)
          : (pageCount =
              Math.ceil(this.manufacturingOrder.orderLines.length / 5) + 1)
      }

      return pageCount
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('manufacturing_orders')
    },
    // totalProductCount() {
    //   return this.manufacturingOrder && this.manufacturingOrder.orderLines
    //     ? this._.sumBy(
    //         this.manufacturingOrder.orderLines,
    //         item => parseInt(item.quantity) || 0
    //       )
    //     : 0
    // },
    totalProductCost() {
      return this.manufacturingOrder && this.manufacturingOrder.orderLines
        ? this._.sumBy(
            this.manufacturingOrder.orderLines,
            item => parseInt(item.amount) || 0
          )
        : 0
    },
    activeOrderLineID() {
      return this.activeOrderLineIndex &&
        this.activeOrderLineIndex.id &&
        this.manufacturingOrder &&
        this.manufacturingOrder.orderLines &&
        this.manufacturingOrder.orderLines.length > 0
        ? this.manufacturingOrder.orderLines[this.activeOrderLineIndex].id
        : null
    },
    unusedProducts() {
      var vm = this

      var keys =
        vm.manufacturingOrder &&
        vm.manufacturingOrder.orderLines &&
        vm.manufacturingOrder.orderLines.length > 0
          ? (vm.productKeys = vm._.map(
              vm.manufacturingOrder.orderLines,
              function(line) {
                return line.product.id
              }
            ))
          : []

      return vm.productsByManufacturer &&
        vm.productsByManufacturer.items &&
        vm.productsByManufacturer.items.length > 0
        ? vm._.filter(vm.productsByManufacturer.items, function(item) {
            return keys.indexOf(item.id) === -1
          })
        : []
    },
    validOrder() {
      var valid = false

      if (
        !this.manufacturingOrder.orderLines ||
        this.manufacturingOrder.orderLines.length === 0
      ) {
        valid = false
        return valid
      }

      let linesDone = this._.findIndex(
        this.manufacturingOrder.orderLines,
        item => item.quantity === 0
      )

      if (linesDone > -1) {
        valid = false
        return valid
      }

      if (
        !this.manufacturingOrder.exFactoryDate &&
        !this.manufacturingOrder.arrivalDate
      ) {
        valid = false
        return valid
      }

      valid = true
      return valid
    },
    draftProduct() {
      var state = false

      var drafts = []

      this._.forEach(this.manufacturingOrder.orderLines, function(item) {
        if (
          item.seasonProduct &&
          item.seasonProduct.productState &&
          (item.seasonProduct.productState === 'OPEN' ||
            item.seasonProduct.productState === 'REVIEW')
        ) {
          drafts.push(item)
        }
      })

      if (drafts.length > 0) {
        state = true
      }

      return state
    }
  },
  apollo: {
    currencies: CURRENCY_LIST,
    paymentTerms: PAYMENT_TERM_LIST,
    warehouses: WAREHOUSE_LIST,
    manufacturers: {
      query: MANUFACTURER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchMan,
            page: 1,
            pageSize: 15
          }
        }
      },
      fetchPolicy: 'no-cache'
    },
    manufacturingOrder: {
      query: MANUFACTURING_ORDER,
      variables() {
        return {
          id: this.$route.params.manufacturingOrderID
        }
      }
    },
    productsByManufacturer: {
      query: PRODUCTS_BY_MANUFACTURER,
      variables() {
        return {
          filters: {
            nameSearch: this.search,
            manufacturer:
              this.manufacturingOrder && this.manufacturingOrder.manufacturer
                ? this.manufacturingOrder.manufacturer.id
                : null,
            season:
              this.manufacturingOrder && this.manufacturingOrder.season
                ? this.manufacturingOrder.season.id
                : null,
            styleState: 'FINAL'
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      skip() {
        return !this.manufacturingOrder || !this.manufacturingOrder.id
      }
    },
    seasons: SEASONS
  },
  methods: {
    totalProductCount() {
      return this.manufacturingOrder && this.manufacturingOrder.orderLines
        ? this._.sumBy(
            this.manufacturingOrder.orderLines,
            item => parseInt(item.quantity) || 0
          )
        : 0
    },
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    },
    styleState: state => {
      var color
      switch (state) {
        case 'PROPOSALREJECTED':
          color = 'error lighten-3 font-weight-medium'
          break
        case 'REVIEWREJECTED':
          color = 'error lighten-3 font-weight-medium'
          break
        case 'CANCELED':
          color = 'error lighten-3 font-weight-medium'
          break
        case 'REVIEW':
          color = 'warning lighten-1 font-weight-medium'
          break
        case 'PENDINGCHANGES':
          color = 'warning lighten-1 font-weight-medium'
          break
        case 'PROPOSALSEND':
          color = 'success lighten-3 font-weight-medium'
          break
        case 'SHIPPED':
          color = 'success lighten-3 font-weight-medium'
          break
        case 'APPROVED':
          color = 'info lighten-2 font-weight-medium'
          break
        case 'PROPOSALACCEPTED':
          color = 'info lighten-2 font-weight-medium'
          break
        default:
          color = 'grey lighten-2 font-weight-medium'
          break
      }
      return color
    },
    styleText(state) {
      var color
      switch (state) {
        case 'PROPOSALREJECTED':
          color = 'error darken-3'
          break
        case 'REVIEWREJECTED':
          color = 'error darken-3'
          break
        case 'CANCELED':
          color = 'error darken-3'
          break
        case 'REVIEW':
          color = 'warning darken-3'
          break
        case 'PROPOSALSEND':
          color = 'success'
          break
        case 'SHIPPED':
          color = 'success'
          break
        case 'APPROVED':
          color = 'info darken-1'
          break
        case 'PROPOSALACCEPTED':
          color = ' info darken-1'
          break
        default:
          color = 'grey darken-1'
          break
      }
      return color
    },
    getFullCountryName(code) {
      return getName(code)
    },
    searchManufacturer(input) {
      this.searchMan = input
    },
    editManufacturingOrderManufacturingAddress() {
      this.editedItem = this._.cloneDeep(this.manufacturingOrder)
      this.editedItem.seasonID = this.editedItem.season.id
      this.editedItem.manufacturerAddressID = this.editedItem.manufacturerAddress.id
      this.editedItem.deliverToID = this.editedItem.deliverTo.id
      this.editManufacturingAddress = true
      this.editMode = true
      this.dialog = true
    },
    editManufacturingOrderDeliveryAddress() {
      this.editedItem = this._.cloneDeep(this.manufacturingOrder)
      this.editedItem.seasonID = this.editedItem.season.id
      this.editedItem.manufacturerAddressID = this.editedItem.manufacturerAddress.id
      this.editedItem.deliverToID = this.editedItem.deliverTo.id
      this.editDeliveryAddress = true
      this.editMode = true
      this.dialog = true
    },
    editManufacturingOrderDeliveryDates() {
      this.editedItem = this._.cloneDeep(this.manufacturingOrder)
      this.editedItem.seasonID = this.editedItem.season.id
      this.editedItem.manufacturerAddressID = this.editedItem.manufacturerAddress.id
      this.editedItem.deliverToID = this.editedItem.deliverTo.id
      this.editDeliveryDate = true
      this.editMode = true
      this.dialog = true
    },
    editManufacturingOrderDate() {
      this.editedItem = this._.cloneDeep(this.manufacturingOrder)
      this.editedItem.seasonID = this.editedItem.season.id
      this.editedItem.manufacturerAddressID = this.editedItem.manufacturerAddress.id
      this.editedItem.deliverToID = this.editedItem.deliverTo.id
      this.editOrderDate = true
      this.editMode = true
      this.dialog = true
    },
    editManufacturingOrderDocketRef() {
      this.editedItem = this._.cloneDeep(this.manufacturingOrder)
      this.editedItem.seasonID = this.editedItem.season.id
      this.editedItem.manufacturerAddressID = this.editedItem.manufacturerAddress.id
      this.editedItem.deliverToID = this.editedItem.deliverTo.id
      this.editDocketRef = true
      this.editMode = true
      this.dialog = true
    },
    editManufacturingOrderNote() {
      this.editedItem = this._.cloneDeep(this.manufacturingOrder)
      this.editedItem.seasonID = this.editedItem.season.id
      this.editedItem.manufacturerAddressID = this.editedItem.manufacturerAddress.id
      this.editedItem.deliverToID = this.editedItem.deliverTo.id
      this.editNote = true
      this.editMode = true
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
      this.editManufacturingAddress = false
      this.editDeliveryAddress = false
      this.editDeliveryDate = false
      this.editNote = false
      this.editOrderDate = false
      this.processingOrderState = false
      this.markSendDialog = false
      this.orderAcceptanceDialog = false
      this.cancelOrderDialog = false
      this.reopenOrderDialog = false
    },
    closePaymentTermDialog() {
      this.ptErrorMsg = ''
      this.addPaymentTermDialog = false
      this.removePaymentTermDialog = false
      this.processing = false
      this.paymentTermEdit = {
        percentage: 100,
        days: 30,
        description: null,
        direction: 'after',
        eventType: 'shipment'
      }
    },
    openPaymentTermDialog(edit, item) {
      if (edit) {
        this.editMode = true

        this.paymentTermEdit = {
          id: item.id,
          manufacturingOrderID: item.manufacturingOrderID,
          percentage: item.percentage,
          days: item.days,
          description: null,
          direction: item.direction,
          eventType: item.eventType
        }
      } else {
        this.paymentTermEdit.manufacturingOrderID = this.manufacturingOrder.id
      }

      this.addPaymentTermDialog = true
    },
    savePaymentTerm(item) {
      var vm = this
      this.ptErrorMsg = ''

      this.processing = true

      var data = {
        manufacturingOrderID: item.manufacturingOrderID,
        percentage: item.percentage,
        days: item.days,
        description: item.description,
        direction: item.direction,
        eventType: item.eventType
      }

      if (this.editMode) {
        // Edit
        data.id = item.id

        this.$apollo
          .mutate({
            mutation: UPDATE_CUSTOM_PAYMENT_TERM,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.closePaymentTermDialog()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Payment Term Updated'
            })
          })
          .catch(() => {
            this.ptErrorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        // Save
        this.$apollo
          .mutate({
            mutation: CREATE_CUSTOM_PAYMENT_TERM,
            variables: {
              input: data
            },
            update: (store, { data: { createManufacturerPaymentTerm } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: MANUFACTURING_ORDER,
                variables: {
                  id: vm.$route.params.manufacturingOrderID
                }
              })
              // Add our requirement from the mutation to the end
              if (!data.manufacturingOrder.paymentTerms)
                data.manufacturingOrder.paymentTerms = []
              data.manufacturingOrder.paymentTerms.push(
                createManufacturerPaymentTerm
              )
              // Write our data back to the cache.
              store.writeQuery({
                query: MANUFACTURING_ORDER,
                data,
                variables: {
                  id: vm.$route.params.manufacturingOrderID
                }
              })
            }
          })
          .then(() => {
            this.closePaymentTermDialog()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Payment Term Created'
            })
          })
          .catch(() => {
            this.ptErrorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    openRemovePaymentTermDialog(data) {
      this.editTerm = this._.cloneDeep(data)
      this.removePaymentTermDialog = true
    },
    removePaymentTerm() {
      var vm = this
      this.processing = true

      this.$apollo
        .mutate({
          mutation: REMOVE_CUSTOM_PAYMENT_TERM,
          variables: {
            id: vm.editTerm.id
          },
          update: (store, { data: { removeManufacturerPaymentTerm } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: MANUFACTURING_ORDER,
              variables: {
                id: vm.$route.params.manufacturingOrderID
              }
            })
            // Find the index
            let idx = vm._.findIndex(
              data.manufacturingOrder.paymentTerms,
              item => item.id === removeManufacturerPaymentTerm
            )

            data.manufacturingOrder.paymentTerms.splice(idx, 1)

            // Write our data back to the cache.
            store.writeQuery({
              query: MANUFACTURING_ORDER,
              data,
              variables: {
                id: vm.$route.params.manufacturerID
              }
            })
          }
        })
        .then(() => {
          this.closePaymentTermDialog()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Payment Term Removed'
          })
        })
        .catch(() => {
          this.ptErrorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: MANUFACTURING_ORDER_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.close()

          // if (this.editDeliveryDate) {
          //   this.$store.commit('showSnackbar', {
          //     active: true,
          //     message: 'Delivery Date Updated'
          //   })
          // } else if (this.editNote) {
          //   this.$store.commit('showSnackbar', {
          //     active: true,
          //     message: 'Notes Saved'
          //   })
          // } else {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Manufacturing Order Updated'
          })
          // }
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    addProducts() {
      var vm = this
      this.processing = true

      if (this.selectedProducts && vm.selectedProducts.length > 0) {
        for (let index = 0; index < vm.selectedProducts.length; index++) {
          var data = {
            manufacturingOrderID: vm.manufacturingOrder.id,
            productID: this.selectedProducts[index]
          }

          this.$apollo
            .mutate({
              mutation: MANUFACTURING_ORDER_LINE_CREATE,
              variables: {
                input: data
              },
              update: (store, { data: { createManufacturingOrderLine } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: MANUFACTURING_ORDER,
                  variables: {
                    id: createManufacturingOrderLine.manufacturingOrderID
                  }
                })
                // Add our requirement from the mutation to the end
                if (!data.manufacturingOrder.orderLines)
                  data.manufacturingOrder.orderLines = []

                data.manufacturingOrder.orderLines.unshift(
                  createManufacturingOrderLine
                )
                // Write our data back to the cache.
                store.writeQuery({
                  query: MANUFACTURING_ORDER,
                  variables: {
                    id: createManufacturingOrderLine.manufacturingOrderID
                  },
                  data
                })
              }
            })
            .then(() => {
              this.processing = false

              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Product Added'
              })

              this.selectedProducts = null
            })
            .catch(err => {
              this.$store.commit('showSnackbar', {
                active: true,
                message:
                  err?.graphQLErrors[0]?.message ||
                  'Something went wrong. Please try again later.'
              })

              this.processing = false
              this.selectedProducts = null
            })
        }
      }
    },
    saveOrderLine(orderLine) {
      var vm = this
      this.errorMsg = ''
      vm.processingOrderLine = true

      vm.$apollo
        .mutate({
          mutation: MANUFACTURING_ORDER_LINE_UPDATE,
          variables: {
            input: orderLine
          }
        })
        .then(() => {
          this.$apollo.queries.manufacturingOrder.refetch()

          vm.processingOrderLine = false

          vm.$store.commit('showSnackbar', {
            active: true,
            message: 'Order Line Updated'
          })
        })
        .catch(() => {
          vm.errorMsg = 'Something went wrong. Please try again later'

          vm.processingOrderLine = false
        })
    },
    applyDatesToAllLines() {
      var vm = this
      vm.processingDates = true

      vm.manufacturingOrder.orderLines.forEach((item, index) => {
        var orderLineData = {
          id: item.id,
          exFactoryDate: vm.$moment(vm.manufacturingOrder.exFactoryDate).utc(),
          arrivalDate: vm.$moment(vm.manufacturingOrder.arrivalDate).utc(),
          unitCost: item.unitCost,
          done: item.done
        }

        vm.$apollo
          .mutate({
            mutation: MANUFACTURING_ORDER_LINE_UPDATE,
            variables: {
              input: orderLineData
            }
          })
          .then(() => {
            if (index === vm.manufacturingOrder.orderLines.length - 1) {
              vm.processingDates = false

              vm.$store.commit('showSnackbar', {
                active: true,
                message: 'Order Lines Updated'
              })
            }
          })
          .catch(() => {
            vm.errorMsg = 'Something went wrong. Please try again later'

            vm.processingDates = false
          })
      })
    },
    openAcceptanceDialog(decission) {
      this.acceptanceState = decission

      this.orderAcceptanceDialog = true
    },
    openRemoveOrderLineDialog(id) {
      this.removeOrderLineID = id
      this.removeOrderLineDialog = true
    },
    closeRemoveOrderLineDialog() {
      this.removeOrderLineID = null
      this.removeOrderLineDialog = false
    },
    removeOrderLine() {
      var vm = this
      this.errorMsg = ''
      this.processingOrderLine = true

      this.$apollo
        .mutate({
          mutation: MANUFACTURING_ORDER_LINE_REMOVE,
          variables: {
            id: this.removeOrderLineID
          },
          update: (store, { data: { removeManufacturingOrderLine } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: MANUFACTURING_ORDER,
              variables: {
                id: vm.$route.params.manufacturingOrderID
              }
            })
            // Add our requirement from the mutation to the end
            let idx = vm._.findIndex(
              data.manufacturingOrder.orderLines,
              item => item.id === removeManufacturingOrderLine
            )

            data.manufacturingOrder.orderLines.splice(idx, 1)
            // Write our data back to the cache.
            store.writeQuery({
              query: MANUFACTURING_ORDER,
              variables: {
                id: vm.$route.params.manufacturingOrderID
              },
              data
            })
          }
        })
        .then(() => {
          this.removeOrderLineDialog = false
          this.processingOrderLine = false

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Line Removed'
          })
        })
        .catch(() => {
          this.removeOrderLineDialog = false
          this.processingOrderLine = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again later.'
          })
        })
    },
    removeOrder() {
      // var vm = this
      this.processingOrderLine = true

      this.$apollo
        .mutate({
          mutation: MANUFACTURING_ORDER_REMOVE,
          variables: {
            id: this.manufacturingOrder.id
          }
          // update: (store, { data: { removeManufacturingOrder } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: MANUFACTURING_ORDER_LIST,
          //     variables: {
          //       filters: {
          //         search: null,
          //         page: 1,
          //         pageSize: 15
          //       }
          //     },
          //     fetchPolicy: 'cache-and-network'
          //   })
          //   // Add our requirement from the mutation to the end
          //   if (
          //     data.manufacturingOrders &&
          //     data.manufacturingOrders.items &&
          //     data.manufacturingOrders.items.length > 0
          //   ) {
          //     let idx = vm._.findIndex(
          //       data.manufacturingOrders.items,
          //       item => item.id === removeManufacturingOrder
          //     )

          //     if (idx > -1) {
          //       data.manufacturingOrders.items.splice(idx, 1)
          //       // Write our data back to the cache.
          //       store.writeQuery({
          //         query: MANUFACTURING_ORDER_LIST,
          //         variables: {
          //           filters: {
          //             search: null,
          //             page: 1,
          //             pageSize: 15
          //           }
          //         },
          //         data
          //       })
          //     }
          //   }
          // }
        })
        .then(() => {
          this.removeOrderDialog = false
          this.processingOrderLine = false

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Manufacturing Order Removed'
          })

          this.$router.push({
            name: 'ProductionManufacturingOrderList'
          })
        })
        .catch(() => {
          this.removeOrderDialog = false
          this.processingOrderLine = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again later'
          })
        })
    },
    requestReview() {
      this.processingOrderLine = true

      this.$apollo
        .mutate({
          mutation: MANUFACTURING_ORDER_REQUEST_REVIEW,
          variables: {
            id: this.manufacturingOrder.id
          }
        })
        .then(() => {
          this.reviewOrderDialog = false
          this.processingOrderLine = false

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Order Submitted'
          })
        })
        .catch(() => {
          this.reviewOrderDialog = false
          this.processingOrderLine = false

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again later.'
          })
        })
    },
    updateState(state) {
      this.processingOrderState = true

      this.$apollo
        .mutate({
          mutation: MANUFACTURING_ORDER_STATE_UPDATE,
          variables: {
            id: this.manufacturingOrder.id,
            status: state
          }
        })
        .then(() => {
          this.close()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Manufacturing Order State Updated'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong while processing your request'
          })

          this.processingOrderState = false
          this.markSendDialog = false
          this.orderAcceptanceDialog = false
          this.cancelOrderDialog = false
        })
    },
    openPDF() {
      this.previewPDF = true
    },
    async createPDF() {
      var vm = this
      this.generatingPDF = true

      var orientation = vm.pdfLayout === 'portrait' ? 'p' : 'l'
      var doc = new JsPDF(orientation, 'mm', 'a4')

      var dateStamp = vm.$moment().format('MMDDYYYY')

      var documentName =
        'MO_' + vm.manufacturingOrder.id + '_' + dateStamp + '.pdf'

      if (
        vm.manufacturingOrder.status === 'draft' ||
        vm.manufacturingOrder.status === 'review'
      ) {
        documentName = 'MO_DRAFT_' + dateStamp + '.pdf'
      }

      for (let index = 0; index < vm.pdfPageCount; index++) {
        await new Promise(function(resolve) {
          html2canvas(document.querySelector('#pdfDoc' + index), {
            imageTimeout: 5000,
            useCORS: true,
            logging: false,
            allowTaint: true
          })
            .then(canvas => {
              let img = canvas.toDataURL('image/png')

              var imgWidth = vm.pdfLayout === 'portrait' ? 210 : 297

              var imgHeight = Math.floor(
                (canvas.height * imgWidth) / canvas.width
              )

              var position = 0 // give some top padding to page

              doc.addImage(
                img,
                'JPEG',
                0,
                position,
                imgWidth,
                imgHeight,
                '',
                'MEDIUM'
              )

              if (index + 1 === vm.pdfPageCount) {
                doc.save(documentName)

                vm.generatingPDF = false
                vm.previewPDF = false
              } else {
                doc.addPage()
              }
              resolve()
            })
            .catch(() => {
              vm.$store.commit('showSnackbar', {
                active: true,
                message:
                  'Something went wrong while generating the PDF. Please try again later.'
              })

              vm.generatingPDF = false
            })
        })
      }
    },
    unlock() {}
  }
}
</script>

<style lang="scss" scoped></style>
