<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="materialForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      autocomplete="off"
                      label="Name"
                      outlined
                      :rules="requiredRule"
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.materialCode"
                      autocomplete="off"
                      label="Code"
                      outlined
                      :rules="requiredRule"
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      label="Description"
                      outlined
                      dense
                      hide-details="auto"
                      rows="3"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>

                <!-- <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.compound"
                      autocomplete="off"
                      label="Compound"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="item.unit"
                      :items="units"
                      autocomplete="off"
                      label="Unit"
                      outlined
                      :rules="requiredRule"
                      hide-details="auto"
                      dense
                    ></v-select>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn color="grey" text rounded :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MaterialEditDialog',
  props: {
    material: {
      type: Object,
      required: true,
      default: () => ({})
    },
    edit: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    units: ['Item', 'Meter', 'Feet', 'Kilograms', 'Pounds']
  }),
  computed: {
    title() {
      return this.edit ? 'Edit Material' : 'New Material'
    },
    item() {
      return this._.cloneDeep(this.material)
    }
  },
  watch: {},
  methods: {
    close() {
      this.$emit('close-material-edit-dialog', false)

      this.$nextTick(() => {
        this.$refs.materialForm.reset()
      })
    },
    save() {
      var data = {
        id: this.item.id,
        materialCode: this.item.materialCode,
        name: this.item.name,
        description: this.item.description,
        compound: this.item.compound,
        unit: this.item.unit
      }

      var that = this

      this.$emit('save-material-edit-dialog', data)
      this.$nextTick(() => {
        if (that.errorMsg === '') {
          that.$refs.materialForm.reset()
        } else {
          that.$refs.materialForm.resetValidation()
        }
      })
    }
  }
}
</script>

<style></style>
