<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="manufacturerForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.name"
                          autocomplete="off"
                          label="Name"
                          hide-details
                          outlined
                          :rules="requiredRule"
                          dense
                          autofocus
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.manufacturerCode"
                          autocomplete="off"
                          label="Manufaturer Code"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Email</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.email"
                          autocomplete="off"
                          label="Email"
                          hide-details
                          outlined
                          dense
                          type="email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Phone</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.phone"
                          autocomplete="off"
                          label="Phone"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Website</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.website"
                          autocomplete="off"
                          label="Website"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Company Registration</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.companyRegistration"
                          autocomplete="off"
                          label="Company Registration"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.vatRegistration"
                          autocomplete="off"
                          label="VAT ID"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Currency</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-select
                          v-model="item.currency"
                          :items="currencies"
                          outlined
                          autocomplete
                          return-object
                          item-text="name"
                          hide-details
                          dense
                          label="Currency"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.mid"
                          autocomplete="off"
                          label="MID Code"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12">
                    <label>Address</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.line1"
                          autocomplete="off"
                          label="Line 1"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.line2"
                          autocomplete="off"
                          label="Line 2"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.line3"
                          autocomplete="off"
                          label="Line 3"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.city"
                          autocomplete="off"
                          label="City"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.postCode"
                          autocomplete="off"
                          label="Postcode"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.state"
                          autocomplete="off"
                          label="State/Province"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="item.address.country"
                          :items="countryList"
                          autocomplete="off"
                          label="Country"
                          hide-details="auto"
                          outlined
                          dense
                          item-text="name"
                          item-value="code"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn color="grey" text :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          dark
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'ManufacturerEditDialog',
  props: {
    manufacturer: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currencies: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  computed: {
    title() {
      return this.edit ? 'Edit Manufacturer' : 'New Manufacturer'
    },
    item() {
      return this._.cloneDeep(this.manufacturer)
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    }
  },
  watch: {},
  methods: {
    close() {
      this.$emit('close-manufacturer-edit-dialog', false)

      this.$nextTick(() => {
        this.$refs.manufacturerForm.reset()
      })
    },
    save() {
      var data = {
        id: this.item.id,
        manufacturerCode: this.item.manufacturerCode,
        name: this.item.name,
        mid: this.item.mid,
        website: this.item.website,
        address: {
          id: this.item.address ? this.item.address.id : null,
          email: this.item.address ? this.item.address.email : null,
          phone: this.item.address ? this.item.address.phone : null,
          line1: this.item.address ? this.item.address.line1 : null,
          line2: this.item.address ? this.item.address.line2 : null,
          line3: this.item.address ? this.item.address.line3 : null,
          state: this.item.address ? this.item.address.state : null,
          city: this.item.address ? this.item.address.city : null,
          postCode: this.item.address ? this.item.address.postCode : null,
          country: this.item.address ? this.item.address.country : null
        },
        companyRegistration: this.item.companyRegistration,
        vatRegistration: this.item.vatRegistration,
        currencyID: this.item.currency ? this.item.currency.id : null
      }

      if (this.editMode) {
        delete data.address
      }

      var that = this

      this.$emit('save-manufacturer-edit-dialog', data)
      this.$nextTick(() => {
        if (that.errorMsg === '') {
          that.$refs.manufacturerForm.reset()
        } else {
          that.$refs.manufacturerForm.resetValidation()
        }
      })
    }
  }
}
</script>

<style></style>
