<template>
  <v-row>
    <v-col cols="12">
      <v-card flat>
        <v-card-title class="pt-0">
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
                clearable
                autocomplete="off"
                :disabled="!productsByManufacturer"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" class="">
              <season-switcher
                :background-color="'#fff'"
                :items="seasons.seasons"
                :outlined="false"
                :dense="false"
                :disabled="
                  $apollo.queries.seasons.loading ||
                    !seasons ||
                    seasons.seasons.length === 0
                "
              ></season-switcher>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :loading="$apollo.loading"
          :options.sync="options"
          :server-items-length="itemCount"
          :items="productsByManufacturer.items"
          :mobile-breakpoint="null"
          :hide-default-footer="!productsByManufacturer || itemCount < 16"
          class="plain-table"
          @page-count="pageCount = $event"
          @click:row="navToDetail"
        >
          <template v-slot:item.name="{ item }">
            <div class="text-truncate primary--text">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.styleState="{ item }">
            <div class="text-truncate primary--text">
              <v-chip
                pill
                x-small
                :color="styleState(item.styleState)"
                :text-color="styleText(item.styleState)"
              >
                {{ item.styleState }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.category="{ item }">
            <div class="text-truncate primary--text">
              <!-- <v-chip
                  v-if="item.category && item.category.id"
                  color="info lighten-2"
                  text-color="info darken-2"
                  outlined
                  x-small
                  pill
                  >{{ item.category.name }}</v-chip
                > -->
              <span v-if="item.category">{{ item.category.name }}</span>
            </div>
          </template>
          <template v-slot:item.color="{ item }">
            <div class="text-truncate primary--text">
              <span v-if="item.color.id"
                >{{ item.color.name }} ({{ item.color.colorCode }})</span
              >
            </div>
          </template>
          <template v-slot:item.designers="{ item }">
            <div
              v-if="item.designers && item.designers.length > 0"
              class="text-truncate"
            >
              <template v-for="(designer, index) in item.designers">
                <!-- <v-chip
                    v-if="index < 1"
                    :key="designer.id"
                    pill
                    x-small
                    color="secondary lighten-2 font-weight-bold"
                    text-color="secondary darken-2"
                    class="mr-2"
                    >
                    <v-avatar left style="margin-left: -20px">
                      <v-icon small>mdi-account-circle</v-icon>
                    </v-avatar> -->
                <span v-if="index < 1" :key="designer.id" class="mr-2">{{
                  designer.firstName + ' ' + designer.lastName
                }}</span>
                <!-- </v-chip
                  > -->
                <span
                  v-if="index > 0"
                  :key="designer.id"
                  class="grey--text caption"
                  >(+{{ item.designers.length - 1 }} others)</span
                >
              </template>
            </div>
          </template>
          <template v-slot:no-data>
            <message-box
              v-if="!$apollo.loading"
              icon="mdi-emoticon-neutral-outline"
              title="No Results"
              caption="Try adjusting your filters to get some results or add the manufacturer to a product"
            ></message-box>
          </template>
          <template v-slot:loading>
            <v-skeleton-loader
              v-for="item in 5"
              :key="item"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import SeasonSwitcher from '@/components/core/SeasonSwitcher.vue'
import SEASONS from '@/graphql/Seasons.gql'
import PRODUCTS_BY_MANUFACTURER from '@/graphql/ProductsByManufacturer.gql'

export default {
  name: 'ManufacturerProducts',
  components: {
    MessageBox,
    SeasonSwitcher
  },
  data: () => ({
    filters: false,
    selectedDesigner: null,
    selectedProducer: null,
    search: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    editDialog: false,
    editMode: false,
    errorMsg: '',
    selected: [],
    productsByManufacturer: { items: [] },
    users: [],
    seasons: [],
    processing: false,
    defaultItem: {
      name: '',
      description: '',
      designer: {}
    },
    editedItem: {
      name: '',
      description: '',
      designer: {}
    }
  }),
  apollo: {
    seasons: SEASONS,
    productsByManufacturer: {
      query: PRODUCTS_BY_MANUFACTURER,
      variables() {
        return {
          filters: {
            nameSearch: this.search,
            manufacturer: this.$route.params.manufacturerID,
            season: this.selectedSeason,
            designer: this.selectedDesigner,
            producer: this.selectedProducer,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        this.page = result.data.productsByManufacturer.page
        this.itemsPerPage = result.data.productsByManufacturer.pageSize
        this.itemCount = result.data.productsByManufacturer.total
      }
    }
  },
  computed: {
    headers() {
      return [
        // {
        //   text: '',
        //   align: 'left',
        //   sortable: false,
        //   filterable: true,
        //   value: 'images.thumbnail.url'
        // },
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Style Status',
          align: 'left',
          value: 'styleState',
          sortable: false,
          filter: this.stateFilterValue
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
          filterable: true
        },
        {
          text: 'Color',
          value: 'color',
          sortable: false,
          filterable: true
        },
        {
          text: 'Designer',
          value: 'designers',
          sortable: false,
          filterable: true
        }
      ]
    },
    selectedSeason() {
      return this.$store.state.selectedSeason
        ? this.$store.state.selectedSeason.id
        : null
    },
    authLevel() {
      return this.$store.getters.authLevel('productsByManufacturer')
    }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    styleState(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'grey lighten-2 font-weight-bold'
          break
        case 'REVIEW':
          color = 'warning lighten-1 font-weight-bold'
          break
        case 'FINAL':
          color = 'info lighten-2 font-weight-bold'
          break
        default:
          color = 'success lighten-3 font-weight-bold'
          break
      }
      return color
    },
    styleText(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'grey darken-1'
          break
        case 'REVIEW':
          color = 'warning darken-3'
          break
        case 'FINAL':
          color = 'info darken-1'
          break
        default:
          color = 'success'
          break
      }
      return color
    },
    // switchSeason(season) {
    //   this.$store.commit('setSelectedSeason', season)
    // },
    navToDetail(item) {
      var seasonID = this.selectedSeason || item.seasons[0].id

      this.$router.push({
        name: 'ProductionProductDetail',
        params: { productID: item.id },
        query: { season: seasonID }
      })
    },
    showSnackbar() {
      this.$store.commit('showSnackbar', { active: true })
    }
  }
}
</script>

<style scoped>
.v-data-footer {
  min-height: 59px;
}
/* div.v-data-footer__select {
  display: none !important;
} */
</style>
