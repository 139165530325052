var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editedItem)?_c('v-expansion-panel',{staticClass:"mb-1"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-truncate body-2"},[(_vm.orderLine.quantity === 0)?_c('v-badge',{attrs:{"color":"pink lighten-2","dot":"","inline":""}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.editedItem.product.name))]),(_vm.editedItem.product.seasonColor)?_c('span',{staticClass:"text-lowercase grey--text darken-1"},[_vm._v(" - "+_vm._s(_vm.editedItem.product.seasonColor))]):_vm._e()])]):_c('span',[_c('span',[_vm._v(_vm._s(_vm.editedItem.product.name))]),(_vm.editedItem.product.seasonColor)?_c('span',{staticClass:"text-lowercase grey--text darken-1"},[_vm._v(" - "+_vm._s(_vm.editedItem.product.seasonColor))]):_vm._e()]),_c('span',{staticClass:"ml-2"},[(
                  _vm.orderLine &&
                    _vm.orderLine.seasonProduct &&
                    _vm.orderLine.seasonProduct.productState === 'OPEN'
                )?_c('v-chip',{staticClass:"upperCaseSpacing",attrs:{"pill":"","x-small":"","color":"grey lighten-2 font-weight-medium","text-color":"grey darken-1"}},[_vm._v(" Draft ")]):_vm._e()],1)],1)]),_c('v-col',[_c('span',{staticClass:"grey--text body-2"},[_c('span',{staticClass:"hidden-md-and-up"},[_vm._v("Min:")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Minimum Qty:")])]),_c('span',{staticClass:"pl-5"},[_c('span',[_vm._v(_vm._s(_vm.minQty))])])]),_c('v-col',[_c('span',{staticClass:"grey--text body-2"},[_c('span',{staticClass:"hidden-md-and-up"},[_vm._v("Qty:")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Production Qty:")])]),_c('span',{staticClass:"pl-5",class:_vm.itemCount < _vm.minQty && _vm.itemCount > 0
                ? 'error--text text--lighten-2'
                : ''},[_vm._v(_vm._s(_vm.itemCount))])]),_c('v-col',[_c('div',{staticClass:"text-right pr-3 body-2"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.orderLine.amount / 100),_vm.manufacturingOrder.currency.symbol, 2, { spaceBetweenAmountAndSymbol: true }))+" ")])])],1)]}},{key:"actions",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,4258528007)}),_c('v-expansion-panel-content',[_c('v-row',[(_vm.errorMsg)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":"error","color":"error lighten-1"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])],1):_vm._e(),_vm._l((_vm.sizeFilter(_vm.editedItem.items)),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"3","md":"2","lg":"2","xl":"2","xs":"12"}},[_c('v-text-field',{attrs:{"dense":"","placeholder":"Qty","type":"number","min":"0","outlined":"","hide-details":"","disabled":!_vm.edit ||
              (_vm.manufacturingOrder.status !== 'DRAFT' &&
                _vm.manufacturingOrder.status !== 'PENDINGCHANGES')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.productSize.name))])]},proxy:true}],null,true),model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}),(false)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2 text-truncate",staticStyle:{"border":"1px solid var(--v-light-theme-darken1)","border-radius":"4px"},attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(item.productSize.name)+" |")]),_c('strong',[_vm._v(" "+_vm._s(item.quantity))])])],1):_vm._e()],1)})],2),_c('v-row',{staticClass:"mt-3"},[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"4"}},[_c('v-currency-field',{attrs:{"prefix":_vm.manufacturingOrder.currency.symbol,"decimal-length":2,"default-value":0,"autocomplete":"off","label":"Unit Cost","hide-details":"auto","outlined":"","dense":"","hint":_vm.currentUnitCost,"persistent-hint":"","disabled":(_vm.manufacturingOrder.status !== 'DRAFT' &&
                  _vm.manufacturingOrder.status !== 'PENDINGCHANGES') ||
                  !_vm.edit},model:{value:(_vm.unitCost),callback:function ($$v) {_vm.unitCost=$$v},expression:"unitCost"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"grey--text caption"},[_vm._v("Delivery Dates")])]),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"auto"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"autocomplete":"off","close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedStartDate,"outlined":"","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","clearable":"","label":"ex-factory","placeholder":"Ex-Factory Date","hide-details":"auto","readonly":"","disabled":!_vm.edit},on:{"click:clear":function($event){_vm.editedItem.exFactoryDate = null}}},'v-text-field',attrs,false),on))]}}],null,false,2809193156),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-date-picker',{attrs:{"no-title":"","hide-details":"","first-day-of-week":"1"},on:{"input":function($event){_vm.startDate = false}},model:{value:(_vm.editedItem.exFactoryDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "exFactoryDate", $$v)},expression:"editedItem.exFactoryDate"}})],1)],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"auto"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedEndDate,"autocomplete":"off","outlined":"","label":"arrival","placeholder":"Arrival Date","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","clearable":"","hide-details":"auto","readonly":"","disabled":!_vm.edit},on:{"click:clear":function($event){_vm.editedItem.arrivalDate = null}}},'v-text-field',attrs,false),on))]}}],null,false,2828601166),model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-date-picker',{attrs:{"min":_vm.$moment(_vm.editedItem.exFactoryDate)
                        .add(1, 'days')
                        .format('YYYY-MM-DD'),"no-title":"","hide-details":"","first-day-of-week":"1"},on:{"input":function($event){_vm.endDate = false}},model:{value:(_vm.editedItem.arrivalDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "arrivalDate", $$v)},expression:"editedItem.arrivalDate"}})],1)],1)],1)],1)],1)],1),(_vm.manufacturingOrder && _vm.authLevel && _vm.authLevel === 2)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pl-3",attrs:{"justify":"end"}},[(!_vm.edit)?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","outlined":"","rounded":"","color":"info"},on:{"click":function($event){$event.stopPropagation();_vm.edit = true}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-pencil-outline")]),_vm._v("edit")],1):_vm._e(),(_vm.edit)?_c('v-btn',{attrs:{"small":"","rounded":"","color":"primary lighten-2","loading":_vm.processing},on:{"click":_vm.save}},[_vm._v("save")]):_vm._e(),(_vm.edit)?_c('v-btn',{attrs:{"small":"","text":"","color":"grey"},on:{"click":_vm.cancel}},[_vm._v("cancel")]):_vm._e(),(
              !_vm.edit &&
                (_vm.manufacturingOrder.status === 'DRAFT' ||
                  _vm.manufacturingOrder.status === 'PENDINGCHANGES')
            )?_c('v-btn',{attrs:{"small":"","text":"","color":"grey"},on:{"click":function($event){return _vm.remove(_vm.editedItem.id)}}},[_vm._v("delete")]):_vm._e()],1)],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }