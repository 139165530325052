<template>
  <v-row justify="center">
    <product-material-edit-dialog
      :dialog="dialog"
      :product="product"
      :material="editedItem"
      :material-list="materials.items"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @search-materials="searchMaterial ? searchMaterial : $event"
      @close-product-material-edit-dialog="cancelEdit"
      @save-product-material-edit-dialog="saveMaterial"
    ></product-material-edit-dialog>
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Material</v-card-title
        >
        <v-card-text>
          <p>
            This will delete the material from the product. This can't be
            undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="removeDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="error"
            text
            @click="removeMaterial"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col v-if="product" cols="12" class="pt-0">
      <v-card
        v-if="
          product &&
            product.styleState &&
            product.styleState === 'DEVELOPMENT' &&
            !editMode &&
            ((product && materialAuthLevel && materialAuthLevel === 2) ||
              (currentUser &&
                (currentUser.isSuperAdmin ||
                  currentUser.userRole === 'director' ||
                  currentUser.userRole === 'seniormanager' ||
                  currentUser.userRole === 'projectmanager')))
        "
        flat
        class="mb-3 pa-3"
      >
        <v-btn
          color="primary"
          rounded
          small
          :disabled="processing"
          @click="newMaterial"
        >
          add material
        </v-btn>
      </v-card>

      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card flat>
            <v-card-title class="pt-0">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
                clearable
                autocomplete="off"
                :disabled="!materials"
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :search="search"
              :headers="headers"
              :loading="$apollo.loading"
              :items="productMaterials"
              :mobile-breakpoint="null"
              fixed-header
              hide-default-footer
            >
              <template v-slot:item.updatedAt="{ item }">
                {{ item.updatedAt | moment('from', 'now') }}
              </template>
              <template v-slot:item.controls="{ item }">
                <v-btn
                  v-if="
                    product &&
                      product.styleState &&
                      product.styleState === 'DEVELOPMENT' &&
                      materialAuthLevel &&
                      materialAuthLevel === 2
                  "
                  fab
                  x-small
                  elevation="0"
                  class="mr-2"
                  @click="editMaterial(item)"
                  ><v-icon>mdi-pencil-outline</v-icon></v-btn
                >

                <v-btn
                  v-if="
                    product &&
                      product.styleState &&
                      product.styleState === 'DEVELOPMENT' &&
                      materialAuthLevel &&
                      materialAuthLevel === 2
                  "
                  fab
                  x-small
                  elevation="0"
                  @click="openRemoveDialog(item)"
                  ><v-icon color="error">mdi-delete-outline</v-icon></v-btn
                >
              </template>
              <template v-slot:no-data>
                <message-box
                  icon="mdi-emoticon-neutral-outline"
                  title="No Results"
                  caption="Go ahead and add some materials to your product"
                ></message-box>
              </template>
              <template v-slot:loading>
                <v-skeleton-loader
                  v-for="item in 5"
                  :key="item"
                  type="list-item"
                  class="mx-auto"
                ></v-skeleton-loader>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import PRODUCT_MATERIALS_LIST from '@/graphql/ProductMaterials.gql'
import MATERIAL_LIST from '@/graphql/Materials.gql'
import CREATE_PRODUCT_MATERIAL from '@/graphql/ProductMaterialCreate.gql'
import UPDATE_PRODUCT_MATERIAL from '@/graphql/ProductMaterialUpdate.gql'
import REMOVE_PRODUCT_MATERIAL from '@/graphql/ProductMaterialRemove.gql'
import ProductMaterialEditDialog from '@/modules/production/components/core/ProductMaterialEditDialog.vue'

export default {
  name: 'ProductionProductMaterials',
  components: { ProductMaterialEditDialog, MessageBox },
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    colors: { type: Array, default: () => [] },
    categories: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    sizeGroups: { type: Array, default: () => [] }
  },
  apollo: {
    productMaterials: {
      query: PRODUCT_MATERIALS_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    },
    materials: {
      query: MATERIAL_LIST,
      variables() {
        return {
          filters: {
            search: this.searchMaterial,
            page: 1,
            pageSize: 25
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        this.page = result.data.materials.page
        this.itemsPerPage = result.data.materials.pageSize
        this.itemCount = result.data.materials.total
      }
      // skip() {
      //   return !this.searchMaterial
      // }
    }
  },
  data: () => ({
    search: null,
    searchMaterial: null,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    dialog: false,
    removeDialog: false,
    processing: false,
    editMode: false,
    errorMsg: '',
    deletableItem: null,
    defaultItem: {
      id: null,
      productID: null,
      material: {
        id: null,
        name: null,
        description: null,
        compound: null,
        unit: null,
        updatedAt: null,
        createdAt: null
      },
      name: null,
      compound: null,
      unit: null,
      quantity: null
    },
    editedItem: {
      id: null,
      productID: null,
      material: {
        id: null,
        name: null,
        description: null,
        compound: null,
        unit: null,
        updatedAt: null,
        createdAt: null
      },
      name: null,
      compound: null,
      unit: null,
      quantity: null
    },
    materials: { items: [] }
  }),
  computed: {
    materialAuthLevel() {
      return this.$store.getters.authLevel('product_material')
    },
    headers() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'material.name'
        },
        {
          text: 'Code',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'material.materialCode'
        },
        {
          text: 'Composition Amount',
          value: 'compound',
          sortable: true,
          filterable: true
        },
        // {
        //   text: 'Quantity',
        //   align: 'left',
        //   value: 'quantity'
        //   // sortable: true,
        //   // filter: this.stateFilterValue
        // },
        // {
        //   text: 'Unit',
        //   align: 'left',
        //   value: 'material.unit'
        // },
        {
          text: 'Last Update',
          align: 'left',
          value: 'updatedAt'
        },
        {
          text: '',
          align: 'right',
          value: 'controls'
        }
      ]
    }
  },
  methods: {
    newMaterial() {
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editedItem.productID = this.$route.params.productID
      this.dialog = true
    },
    // Product methods
    cancelEdit(success) {
      // if (!success) {
      //   // this.$refs.productForm.resetValidation()
      //   this.setEditedItem()
      // }

      this.dialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.editMode === true) {
          msg = 'Material Updated'
        } else {
          msg = 'Material Added'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },

    editMaterial(item) {
      this.searchMaterial = item.name
      this.editMode = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },

    saveMaterial(data) {
      this.errorMsg = ''

      this.processing = true

      if (this.editMode) {
        // Edit Item
        this.$apollo
          .mutate({
            mutation: UPDATE_PRODUCT_MATERIAL,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.cancelEdit(true)
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        delete data.id

        this.$apollo
          .mutate({
            mutation: CREATE_PRODUCT_MATERIAL,
            variables: {
              input: data
            },
            update: (store, { data: { createProductMaterial } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PRODUCT_MATERIALS_LIST,
                variables: {
                  productID: createProductMaterial.productID
                }
              })
              // Add our requirement from the mutation to the end
              data.productMaterials.push(createProductMaterial)
              // Write our data back to the cache.
              store.writeQuery({
                query: PRODUCT_MATERIALS_LIST,
                variables: {
                  productID: createProductMaterial.productID
                },
                data
              })
            }
          })
          .then(() => {
            this.cancelEdit(true)
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    openRemoveDialog(item) {
      this.deletableItem = item
      this.removeDialog = true
    },
    removeMaterial() {
      var item = this.deletableItem
      var that = this
      this.processing = true

      this.$apollo
        .mutate({
          mutation: REMOVE_PRODUCT_MATERIAL,
          variables: {
            id: item.id
          },
          update: (store, { data: { removeProductMaterial } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: PRODUCT_MATERIALS_LIST,
              variables: {
                productID: that.$route.params.productID
              }
            })

            // Add our requirement from the mutation to the end
            let idx = that._.findIndex(data.productMaterials, function(e) {
              return e.id === removeProductMaterial
            })

            data.productMaterials.splice(idx, 1)

            // Write our data back to the cache.
            store.writeQuery({
              query: PRODUCT_MATERIALS_LIST,
              variables: {
                productID: that.$route.params.productID
              },
              data
            })
          }
        })
        .then(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Item removed'
          })
          this.processing = false
          this.deletableItem = null
          this.removeDialog = false
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong.'
          })
          this.processing = false
          this.deletableItem = null
          this.removeDialog = false
        })
    }
  }
}
</script>

<style scoped lang="scss"></style>
