<template>
  <div style="height: 100%;">
    <main-navigation nav-drawar-color="light-theme" light>
      <template v-slot:nav-items>
        <!-- <v-list-item
          :to="{ name: 'ProductionDashboard' }"
          exact
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item
          v-if="activeScope('seasons')"
          :to="{ name: 'ProductionSeasonList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Seasons</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('materials')"
          :to="{ name: 'ProductionMaterialList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-cube-unfolded</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Materials</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'ProductionProductList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('manufacturers')"
          :to="{ name: 'ProductionManufacturerList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-factory</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manufacturers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('manufacturing_orders')"
          :to="{ name: 'ProductionManufacturingOrderList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-tray-full</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Manufacturing Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader
          v-if="activeScope('warehouses') || activeScope('stock_items')"
          class="text-uppercase"
          >Logistics</v-subheader
        >

        <v-list-item
          v-if="activeScope('warehouses')"
          :to="{ name: 'ProductionWarehouseList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-warehouse</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Warehouses</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item
          :to="{ name: 'ProductionShipperList' }"
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-truck-fast</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Shippers</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item
          v-if="activeScope('stock_items')"
          :to="{ name: 'ProductionStockList' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Stock</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item class="menu-item" link>
          <v-list-item-icon>
            <v-icon>mdi-arrow-decision-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Allocation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="menu-item" link>
          <v-list-item-icon>
            <v-icon>mdi-ticket-confirmation-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pick Tickets</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="menu-item" link>
          <v-list-item-icon>
            <v-icon>mdi-receipt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Invoices</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- <v-subheader class="text-uppercase">Workflow</v-subheader>
        <v-list-item
          :to="{ name: 'ProcessBoard', params: { name: 'design' } }"
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-pencil-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Design</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="menu-item" link>
          <v-list-item-icon>
            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Production</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="menu-item" link>
          <v-list-item-icon>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Costing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="menu-item" link>
          <v-list-item-icon>
            <v-icon>mdi-tray-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Changes</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- <v-subheader class="text-uppercase">Sales</v-subheader>

        <v-list-item
          :to="{ name: 'ProductionCustomerList' }"
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Customers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'ProductionSalesOrderList' }"
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-cart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sales Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'ProductionSampleOrderList' }"
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-tag-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sample Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-subheader
          v-if="activeScope('warehouses') || activeScope('stock_items')"
          >&nbsp;</v-subheader
        >
        <v-list-item
          v-if="activeScope('product_reports')"
          :to="{ name: 'ProductionReportList' }"
          class="menu-item"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-file-chart-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="activeScope('production_settings')"
          :to="{ name: 'ProductionSettingsSizes' }"
          class="menu-item"
          link
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </main-navigation>

    <div class="app-layout pr-4">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainNavigation from '@/components/core/MainNavigation.vue'
import SEASONS from '@/graphql/Seasons.gql'

export default {
  name: 'ProductionLayout',
  components: {
    MainNavigation
  },
  data: () => ({
    drawer: true
  }),
  apollo: {
    seasons: SEASONS
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    userScopes() {
      return this.$store.getters.userScopes
    }
  },
  created() {
    this.$vuetify.theme.themes.light.primary = '#002447'
    this.$vuetify.theme.themes.light.secondary = '#53d3d1'
    this.$vuetify.theme.themes.light.accent = '#fbeceb'
    this.$vuetify.theme.themes.light.error = '#FF5252'
    this.$vuetify.theme.themes.light.info = '#2196F3'
    this.$vuetify.theme.themes.light.success = '#4CAF50'
    this.$vuetify.theme.themes.light.warning = '#FFC107'
  },
  methods: {
    activeScope(scope) {
      var idx = this._.findIndex(this.userScopes, function(item) {
        return item.name === scope && item.accessLevel > 0
      })
      if (idx > -1) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.page-wrap {
  --page-header-height: 64px;
  /* --page-wrap-offset: -20px; */
}
</style>
