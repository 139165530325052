var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('product-material-edit-dialog',{attrs:{"dialog":_vm.dialog,"product":_vm.product,"material":_vm.editedItem,"material-list":_vm.materials.items,"edit-mode":_vm.editMode,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"search-materials":function($event){_vm.searchMaterial ? _vm.searchMaterial : $event},"close-product-material-edit-dialog":_vm.cancelEdit,"save-product-material-edit-dialog":_vm.saveMaterial}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline error--text mb-3"},[_vm._v("Remove Material")]),_c('v-card-text',[_c('p',[_vm._v(" This will delete the material from the product. This can't be undone! ")])]),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"grey darken-1","text":"","disabled":_vm.processing},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"loading":_vm.processing,"color":"error","text":""},on:{"click":_vm.removeMaterial}},[_vm._v("Remove")])],1)],1)],1),(_vm.product)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[(
        _vm.product &&
          _vm.product.styleState &&
          _vm.product.styleState === 'DEVELOPMENT' &&
          !_vm.editMode &&
          ((_vm.product && _vm.materialAuthLevel && _vm.materialAuthLevel === 2) ||
            (_vm.currentUser &&
              (_vm.currentUser.isSuperAdmin ||
                _vm.currentUser.userRole === 'director' ||
                _vm.currentUser.userRole === 'seniormanager' ||
                _vm.currentUser.userRole === 'projectmanager')))
      )?_c('v-card',{staticClass:"mb-3 pa-3",attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"primary","rounded":"","small":"","disabled":_vm.processing},on:{"click":_vm.newMaterial}},[_vm._v(" add material ")])],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":"","clearable":"","autocomplete":"off","disabled":!_vm.materials},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"loading":_vm.$apollo.loading,"items":_vm.productMaterials,"mobile-breakpoint":null,"fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,'from', 'now'))+" ")]}},{key:"item.controls",fn:function(ref){
      var item = ref.item;
return [(
                  _vm.product &&
                    _vm.product.styleState &&
                    _vm.product.styleState === 'DEVELOPMENT' &&
                    _vm.materialAuthLevel &&
                    _vm.materialAuthLevel === 2
                )?_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","elevation":"0"},on:{"click":function($event){return _vm.editMaterial(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(
                  _vm.product &&
                    _vm.product.styleState &&
                    _vm.product.styleState === 'DEVELOPMENT' &&
                    _vm.materialAuthLevel &&
                    _vm.materialAuthLevel === 2
                )?_c('v-btn',{attrs:{"fab":"","x-small":"","elevation":"0"},on:{"click":function($event){return _vm.openRemoveDialog(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete-outline")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Results","caption":"Go ahead and add some materials to your product"}})]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}],null,false,1750739558)})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }