var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header pt-0 px-1"},[_c('v-toolbar',{staticClass:"mb-3 b-radius",attrs:{"color":"transparent","flat":""}},[_c('season-edit-dialog',{attrs:{"open":_vm.dialog,"edited-item":_vm.editedItem,"edit-mode":_vm.edit},on:{"close-edit-season-dialog":_vm.close}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.authLevel === 2)?_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.newItem}},[_vm._v(" add season ")]):_vm._e()],1)],1)],1)],1)],1),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"sm":"12","md":"10","lg":"10","xl":"8"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":"","clearable":"","autocomplete":"off"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"items":_vm.seasons.seasons,"search":_vm.search,"loading":_vm.$apollo.loading,"mobile-breakpoint":null,"hide-default-footer":_vm.seasons && _vm.seasons.seasons && _vm.seasons.seasons.length === 0},on:{"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.shortName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shortName))]),(
                      item.from && item.till
                        ? _vm.isCurrentSeason(item.from, item.till)
                        : false
                    )?_c('v-chip',{staticClass:"ml-3",attrs:{"x-small":"","color":"success lighten-3 font-weight-medium upperCaseSpacing","text-color":"success"}},[_vm._v("active")]):_vm._e()]}},{key:"item.seasonPeriod",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.from,'DD/MM/YY')))]),_c('span',[_vm._v("- "+_vm._s(_vm._f("moment")(item.till,'DD/MM/YY')))])])]}},{key:"item.prebookPeriod",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.prebookStartDate,'DD/MM/YY')))]),_c('span',[_vm._v("- "+_vm._s(_vm._f("moment")(item.prebookEndDate,'DD/MM/YY')))])])]}},{key:"item.firstDropPeriod",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.firstDropStartDate,'DD/MM/YY')))]),_c('span',[_vm._v("- "+_vm._s(_vm._f("moment")(item.firstDropEndDate,'DD/MM/YY')))])])]}},{key:"no-data",fn:function(){return [_vm._v("Nothing here yet...")]},proxy:true}])})],1)],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }