<template>
  <v-row>
    <v-dialog
      v-model="addPaymentTermDialog"
      persistent
      max-width="750"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3">{{
          paymentTermTitle
        }}</v-card-title>
        <v-card-text>
          <v-alert type="info" border="left" text dense>
            Make sure all terms add up to 100%.
          </v-alert>
          <div v-if="ptErrorMsg !== ''" class="mt-3">
            <v-alert type="error" class="mb-0">
              {{ ptErrorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="paymentTermForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12" md="3">
                <v-currency-field
                  v-model="editTerm.percentage"
                  :decimal-length="2"
                  :default-value="0"
                  autocomplete="off"
                  suffix="%"
                  hide-details="auto"
                  outlined
                  dense
                  :rules="requiredRule"
                  autofocus
                ></v-currency-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editTerm.days"
                  type="number"
                  autocomplete="off"
                  suffix="Days"
                  hide-details="auto"
                  :rules="requiredRule"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="editTerm.direction"
                  :items="['after', 'before']"
                  autocomplete="off"
                  hide-details="auto"
                  :rules="requiredRule"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="editTerm.eventType"
                  :items="['order', 'shipment']"
                  autocomplete="off"
                  hide-details="auto"
                  :rules="requiredRule"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="editTerm.description"
                  autocomplete="off"
                  label="Description"
                  hide-details="auto"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row> -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closePaymentTermDialog(false)"
            >Cancel</v-btn
          >
          <v-btn
            color="primary lighten-2"
            rounded
            small
            :loading="processing"
            @click="savePaymentTerm"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Payment Term dialog -->
    <v-dialog
      v-model="addPaymentDetailDialog"
      persistent
      max-width="600"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3">{{
          paymentDetailTitle
        }}</v-card-title>
        <v-card-text>
          <div v-if="ptErrorMsg !== ''" class="mt-3">
            <v-alert type="error" class="mb-0">
              {{ ptErrorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="paymentDetailForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="paymentDetail.paymentMethod"
                  autocomplete="off"
                  label="Payment Method"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="paymentDetail.currency"
                  :items="currencies"
                  autocomplete="off"
                  label="Currency"
                  item-text="name"
                  return-object
                  hide-details="auto"
                  :rules="requiredRule"
                  outlined
                  dense
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="paymentDetail.beneficiary"
                  autocomplete="off"
                  label="Beneficiary"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="paymentDetail.accountNumber"
                  autocomplete="off"
                  label="Account Number"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12" md="6">
                <v-text-field
                  v-model="paymentDetail.sortCode"
                  autocomplete="off"
                  label="Sort Code"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col> -->

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="paymentDetail.swift"
                  autocomplete="off"
                  label="SWIFT/BIC"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="paymentDetail.bankName"
                  autocomplete="off"
                  label="Bank Name"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12" md="6">
                <v-text-field
                  v-model="paymentDetail.routingNumber"
                  autocomplete="off"
                  label="Routing Number"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col> -->

              <v-col cols="12">
                <v-textarea
                  v-model="paymentDetail.bankAddress"
                  autocomplete="off"
                  label="Bank Address"
                  hide-details="auto"
                  rows="3"
                  outlined
                  dense
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="paymentDetail.verified"
                  autocomplete="off"
                  label="Verified"
                  hide-details="auto"
                  outlined
                  dense
                ></v-checkbox>
              </v-col>

              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="paymentDetail.active"
                  autocomplete="off"
                  label="Active"
                  hide-details="auto"
                  outlined
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processing"
            @click.prevent="closePaymentDetailDialog(false)"
            >Cancel</v-btn
          >
          <v-btn
            color="primary lighten-2"
            rounded
            small
            :loading="processing"
            @click.prevent="savePaymentDetail"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Remove Dialog -->
    <v-dialog
      v-model="removeDialog"
      persistent
      max-width="350"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Payment Term</v-card-title
        >
        <v-card-text>
          <p>
            Make sure all terms add up to 100%.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closePaymentTermDialog"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removePaymentTerm">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Remove Payment Detail Dialog -->
    <v-dialog
      v-model="removePaymentDetailDialog"
      persistent
      max-width="350"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Payment Detail</v-card-title
        >
        <v-card-text>
          <p>
            You are about to remove this payment detail. This can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closePaymentDetailDialog"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removePaymentDetail">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Details -->
    <v-col v-if="manufacturer" cols="12" md="6" lg="6" xl="6">
      <v-card flat class="mb-3">
        <v-card-title class="mb-3"
          ><span>Details</span><v-spacer></v-spacer
          ><v-btn
            v-if="
              editMode &&
                ((manufacturer && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            text
            small
            :disabled="processing"
            class="mr-2"
            @click="cancelEdit(false)"
          >
            cancel
          </v-btn>
          <v-btn
            v-if="
              editMode &&
                ((manufacturer && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="primary"
            rounded
            small
            :loading="processing"
            @click="saveManufacturer"
          >
            save
          </v-btn>
          <v-btn
            v-if="
              !editMode &&
                ((manufacturer && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="grey"
            outlined
            rounded
            small
            :disabled="processing"
            @click="editManufacturer"
          >
            edit
          </v-btn></v-card-title
        >
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="manufacturerForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12" md="6">
                <div v-if="!editMode" class="grey--text">
                  <label>Name</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.name }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.name"
                  autocomplete="off"
                  label="Name"
                  hide-details="auto"
                  outlined
                  :rules="requiredRule"
                  dense
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div v-if="!editMode" class="grey--text">
                  <label>Manufacturer Code</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.manufacturerCode }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.manufacturerCode"
                  autocomplete="off"
                  label="Manufacturer Code"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col>
                    <div v-if="!editMode" class="grey--text">
                      <label>Mid</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      {{ item.mid }}
                    </div>
                    <v-text-field
                      v-else
                      v-model="editedItem.mid"
                      autocomplete="off"
                      label="Mid"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <div v-if="!editMode" class="grey--text">
                      <label>Website</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      {{ item.website }}
                    </div>
                    <v-text-field
                      v-else
                      v-model="editedItem.website"
                      autocomplete="off"
                      label="Website"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col>
                    <div v-if="!editMode" class="grey--text">
                      <label>VAT ID</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      {{ item.vatRegistration }}
                    </div>
                    <v-text-field
                      v-else
                      v-model="editedItem.vatRegistration"
                      autocomplete="off"
                      label="VAT ID"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <div v-if="!editMode" class="grey--text">
                      <label>Currency</label>
                    </div>
                    <div v-if="!editMode" class="font-weight-medium">
                      <span v-show="item && item.currency">{{
                        item.currency.name
                      }}</span>
                    </div>
                    <v-select
                      v-else
                      v-model="editedItem.currency"
                      :items="currencies"
                      outlined
                      autocomplete
                      return-object
                      item-text="name"
                      hide-details
                      dense
                      label="Currency"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <div v-if="!editMode" class="grey--text">
                  <label>Company Registration</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.companyRegistration }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.companyRegistration"
                  autocomplete="off"
                  label="Company Registration"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div v-if="!editMode" class="grey--text">
                  <label>Promese ID</label>
                </div>
                <div v-if="!editMode" class="font-weight-medium">
                  {{ item.promeseID }}
                </div>
                <v-text-field
                  v-else
                  v-model="editedItem.promeseID"
                  autocomplete="off"
                  label="Promese ID"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Payment Details -->
    <v-col v-if="manufacturer" cols="12" md="6" lg="6" xl="6">
      <v-card flat class="mb-3">
        <v-card-title class="mb-3">
          <span>Payment Details</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              !editMode &&
                ((manufacturer && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="grey"
            outlined
            rounded
            small
            :disabled="processing"
            @click="addPaymentDetailDialog = true"
          >
            add
          </v-btn></v-card-title
        >
        <v-card-text>
          <div v-if="$apollo.loading">
            <v-skeleton-loader
              v-for="i in 5"
              :key="i"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </div>

          <message-box
            v-else-if="
              !$apollo.loading &&
                manufacturer &&
                (!manufacturer.paymentDetails ||
                  manufacturer.paymentDetails.length === 0)
            "
            icon="mdi-emoticon-neutral-outline"
            title="No Payment Details"
          >
            <v-btn
              class="ml-2"
              color="primary lighten-2"
              rounded
              small
              @click="addPaymentDetailDialog = true"
            >
              add payment details
            </v-btn>
          </message-box>
          <v-data-table
            v-else
            :loading="$apollo.loading"
            :headers="paymentDetailHeaders"
            :items="manufacturer.paymentDetails"
            :items-per-page="5"
            :mobile-breakpoint="null"
            fixed-header
            :hide-default-footer="
              !manufacturer ||
                !manufacturer.paymentDetails ||
                manufacturer.paymentDetails.length < 6
            "
            class="plain-table"
          >
            <template v-slot:item.verified="{ item }">
              <v-icon v-if="item.verified">mdi-check</v-icon>
            </template>
            <template v-slot:item.active="{ item }">
              <v-icon v-if="item.active">mdi-check</v-icon>
            </template>
            <template v-slot:item.updatedAt="{ item }">
              {{ item.updatedAt | moment('from', 'now') }}
            </template>
            <template v-slot:item.controls="{ item }">
              <v-btn fab elevation="0" x-small
                ><v-icon small @click.stop="editPaymentDetail(item)"
                  >mdi-pencil-outline</v-icon
                ></v-btn
              >
              <v-btn class="ml-2" icon small
                ><v-icon
                  color="error"
                  small
                  @click.stop="openRemovePaymentDetailDialog(item)"
                  >mdi-delete-outline</v-icon
                ></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Payment terms -->
    <v-col v-if="manufacturer" cols="12" md="6" lg="6" xl="6">
      <v-card flat class="mb-3">
        <v-card-title class="mb-3">
          <span>Payment Terms</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              !editMode &&
                ((manufacturer && authLevel && authLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="grey"
            outlined
            rounded
            small
            :disabled="processing"
            @click="addPaymentTermDialog = true"
          >
            add
          </v-btn></v-card-title
        >
        <v-card-text>
          <v-row v-for="term in item.paymentTerms" :key="term.id">
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col class="py-0">
              <div class="body-2">
                {{
                  term.percentage +
                    '% ' +
                    term.days +
                    ' days ' +
                    term.direction +
                    ' ' +
                    term.eventType
                }}
              </div>
            </v-col>
            <v-col class="py-0">
              <v-row justify="end">
                <v-btn
                  right
                  text
                  small
                  rounded
                  color="info"
                  @click="editPaymentTerm(term)"
                  >edit</v-btn
                ><v-btn
                  right
                  text
                  small
                  rounded
                  color="grey"
                  @click="openRemovePaymentTermDialog(term)"
                  >remove</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import MANUFACTURER from '@/graphql/Manufacturer.gql'
import UPDATE_MANUFACTURER from '@/graphql/ManufacturerUpdate.gql'
import CREATE_CUSTOM_PAYMENT_TERM from '@/graphql/CustomPaymentTermCreate.gql'
import UPDATE_CUSTOM_PAYMENT_TERM from '@/graphql/CustomPaymentTermUpdate.gql'
import REMOVE_CUSTOM_PAYMENT_TERM from '@/graphql/CustomPaymentTermRemove.gql'
import CREATE_MANUFACTURER_PAYMENT_DETAIL from '@/graphql/ManufacturerPaymentDetailCreate.gql'
import UPDATE_MANUFACTURER_PAYMENT_DETAIL from '@/graphql/ManufacturerPaymentDetailUpdate.gql'
import REMOVE_MANUFACTURER_PAYMENT_DETAIL from '@/graphql/ManufacturerPaymentDetailRemove.gql'

export default {
  name: 'ProductionManufacturerDetail',
  components: { MessageBox },
  props: {
    manufacturer: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    editDialog: false,
    processing: false,
    editMode: false,
    addPaymentTermDialog: false,
    addPaymentDetailDialog: false,
    removePaymentDetailDialog: false,
    removeDialog: false,
    errorMsg: '',
    ptErrorMsg: '',
    editTerm: {
      percentage: 100,
      days: 30,
      description: null,
      direction: 'after',
      eventType: 'shipment'
    },
    defaultItem: {
      id: null,
      manufacturerCode: null,
      name: null,
      mid: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        state: null,
        city: null,
        postCode: null
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null
    },
    editedItem: {
      id: null,
      manufacturerCode: null,
      name: null,
      mid: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        state: null,
        city: null,
        postCode: null
      },
      companyRegistration: null,
      vatRegistration: null,
      currency: null
    },
    defaultPaymentDetail: {
      id: null,
      manufacturerID: null,
      currency: {},
      paymentMethod: '',
      beneficiary: '',
      accountNumber: '',
      sortCode: '',
      swift: '',
      bankName: '',
      bankAddress: '',
      routingNumber: '',
      verified: false,
      active: false
    },
    paymentDetail: {
      id: null,
      manufacturerID: null,
      currency: {},
      paymentMethod: '',
      beneficiary: '',
      accountNumber: '',
      sortCode: '',
      swift: '',
      bankName: '',
      bankAddress: '',
      routingNumber: '',
      verified: false,
      active: false
    }
  }),
  computed: {
    item() {
      this.setEditedItem()
      return this._.cloneDeep(this.manufacturer)
    },
    paymentTermTitle() {
      return this.editMode ? 'Edit Payment term' : 'Add Payment Term'
    },
    paymentDetailTitle() {
      return this.editMode ? 'Edit Payment Detail' : 'Add Payment Detail'
    },
    paymentDetailHeaders() {
      return [
        // {
        //   text: 'Payment Method',
        //   align: 'left',
        //   sortable: false,
        //   value: 'paymentMethod'
        // },
        {
          text: 'Bank Name',
          align: 'left',
          sortable: false,
          value: 'bankName'
        },
        {
          text: 'Account Number',
          align: 'left',
          sortable: false,
          value: 'accountNumber'
        },
        {
          text: 'Verified',
          align: 'left',
          value: 'verified'
        },
        {
          text: 'Active',
          align: 'left',
          value: 'active'
        },
        {
          text: 'Last Updated',
          value: 'updatedAt',
          sortable: true,
          filterable: true
        },
        {
          text: '',
          value: 'controls',
          sortable: false,
          filterable: true
        }
      ]
    }
  },
  methods: {
    setEditedItem() {
      this.editedItem = this._.cloneDeep(this.manufacturer)
    },
    // Manufacturer methods
    cancelEdit(success) {
      if (!success) {
        this.$refs.manufacturerForm.resetValidation()
        this.setEditedItem()
      }

      // this.editDialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.editMode === true) {
          msg = 'Manufacturer Updated'
        } else {
          msg = 'Manufacturer Added'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },

    editManufacturer() {
      this.editMode = true
    },

    saveManufacturer() {
      this.errorMsg = ''

      var data = {
        id: this.editedItem.id,
        promeseID: this.editedItem.promeseID,
        manufacturerCode: this.editedItem.manufacturerCode,
        name: this.editedItem.name,
        mid: this.editedItem.mid,
        website: this.editedItem.website,
        companyRegistration: this.editedItem.companyRegistration,
        vatRegistration: this.editedItem.vatRegistration,
        currencyID: this.editedItem.currency
          ? this.editedItem.currency.id
          : null
      }

      if (this.$refs.manufacturerForm.validate()) {
        this.processing = true

        // Save Item
        this.$apollo
          .mutate({
            mutation: UPDATE_MANUFACTURER,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.cancelEdit(true)
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },

    editPaymentTerm(data) {
      this.editTerm = this._.cloneDeep(data)
      this.editMode = true
      this.addPaymentTermDialog = true
    },

    closePaymentTermDialog() {
      this.addPaymentTermDialog = false
      this.editMode = false
      this.editTerm = {
        percentage: 100,
        days: 30,
        description: null,
        direction: 'after',
        eventType: 'shipment'
      }
      this.processing = false
      this.ptErrorMsg = ''
      this.removeDialog = false
    },

    editPaymentDetail(data) {
      this.paymentDetail = this._.cloneDeep(data)
      this.editMode = true
      this.addPaymentDetailDialog = true
    },

    closePaymentDetailDialog() {
      this.addPaymentDetailDialog = false
      this.removePaymentDetailDialog = false
      this.editMode = false
      this.paymentDetail = this._.cloneDeep(this.defaultPaymentDetail)
      this.processing = false
      this.ptErrorMsg = ''
      this.$refs.paymentDetailForm.reset()
    },

    savePaymentDetail() {
      var vm = this
      this.ptErrorMsg = ''

      if (this.$refs.paymentDetailForm.validate()) {
        this.processing = true

        var data = {
          manufacturerID: vm.$route.params.manufacturerID,
          currencyID: this.paymentDetail.currency
            ? this.paymentDetail.currency.id
            : null,
          paymentMethod: this.paymentDetail.paymentMethod,
          beneficiary: this.paymentDetail.beneficiary,
          accountNumber: this.paymentDetail.accountNumber,
          sortCode: this.paymentDetail.sortCode,
          swift: this.paymentDetail.swift,
          bankName: this.paymentDetail.bankName,
          bankAddress: this.paymentDetail.bankAddress,
          routingNumber: this.paymentDetail.routingNumber,
          verified: this.paymentDetail.verified,
          active: this.paymentDetail.active
        }

        if (this.editMode) {
          // Edit
          data.id = this.paymentDetail.id

          this.$apollo
            .mutate({
              mutation: UPDATE_MANUFACTURER_PAYMENT_DETAIL,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.closePaymentDetailDialog()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Payment Detail Updated'
              })
            })
            .catch(() => {
              this.ptErrorMsg = 'Something went wrong.'
              this.processing = false
            })
        } else {
          // Save
          this.$apollo
            .mutate({
              mutation: CREATE_MANUFACTURER_PAYMENT_DETAIL,
              variables: {
                input: data
              },
              update: (
                store,
                { data: { createManufacturerPaymentDetail } }
              ) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: MANUFACTURER,
                  variables: {
                    id: vm.$route.params.manufacturerID
                  }
                })
                // Add our requirement from the mutation to the end
                if (!data.manufacturer.paymentDetails)
                  data.manufacturer.paymentDetails = []
                data.manufacturer.paymentDetails.push(
                  createManufacturerPaymentDetail
                )
                // Write our data back to the cache.
                store.writeQuery({
                  query: MANUFACTURER,
                  data,
                  variables: {
                    id: vm.$route.params.manufacturerID
                  }
                })
              }
            })
            .then(() => {
              this.closePaymentDetailDialog()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Payment Detail Created'
              })
            })
            .catch(() => {
              this.ptErrorMsg = 'Something went wrong.'
              this.processing = false
            })
        }
      }
    },

    savePaymentTerm() {
      var vm = this
      this.ptErrorMsg = ''

      if (this.$refs.paymentTermForm.validate()) {
        this.processing = true

        var data = {
          manufacturerID: vm.$route.params.manufacturerID,
          percentage: this.editTerm.percentage,
          days: this.editTerm.days,
          description: this.editTerm.description,
          direction: this.editTerm.direction,
          eventType: this.editTerm.eventType
        }

        if (this.editMode) {
          // Edit
          data.id = this.editTerm.id

          this.$apollo
            .mutate({
              mutation: UPDATE_CUSTOM_PAYMENT_TERM,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.closePaymentTermDialog()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Payment Term Updated'
              })
            })
            .catch(() => {
              this.ptErrorMsg = 'Something went wrong.'
              this.processing = false
            })
        } else {
          // Save
          this.$apollo
            .mutate({
              mutation: CREATE_CUSTOM_PAYMENT_TERM,
              variables: {
                input: data
              },
              update: (store, { data: { createManufacturerPaymentTerm } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: MANUFACTURER,
                  variables: {
                    id: vm.$route.params.manufacturerID
                  }
                })
                // Add our requirement from the mutation to the end
                if (!data.manufacturer.paymentTerms)
                  data.manufacturer.paymentTerms = []
                data.manufacturer.paymentTerms.push(
                  createManufacturerPaymentTerm
                )
                // Write our data back to the cache.
                store.writeQuery({
                  query: MANUFACTURER,
                  data,
                  variables: {
                    id: vm.$route.params.manufacturerID
                  }
                })
              }
            })
            .then(() => {
              this.closePaymentTermDialog()
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Payment Term Created'
              })
            })
            .catch(() => {
              this.ptErrorMsg = 'Something went wrong.'
              this.processing = false
            })
        }
      }
    },

    openRemovePaymentTermDialog(data) {
      this.editTerm = this._.cloneDeep(data)
      this.removeDialog = true
    },

    openRemovePaymentDetailDialog(data) {
      this.paymentDetail = this._.cloneDeep(data)
      this.removePaymentDetailDialog = true
    },

    removePaymentDetail() {
      var vm = this
      this.processing = true

      this.$apollo
        .mutate({
          mutation: REMOVE_MANUFACTURER_PAYMENT_DETAIL,
          variables: {
            id: vm.paymentDetail.id
          },
          update: (store, { data: { removeManufacturerPaymentDetail } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: MANUFACTURER,
              variables: {
                id: vm.$route.params.manufacturerID
              }
            })
            // Find the index
            let idx = vm._.findIndex(
              data.manufacturer.paymentDetails,
              item => item.id === removeManufacturerPaymentDetail
            )

            data.manufacturer.paymentDetails.splice(idx, 1)

            // Write our data back to the cache.
            store.writeQuery({
              query: MANUFACTURER,
              data,
              variables: {
                id: vm.$route.params.manufacturerID
              }
            })
          }
        })
        .then(() => {
          this.closePaymentDetailDialog()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Payment Detail Removed'
          })
        })
        .catch(() => {
          this.ptErrorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    removePaymentTerm() {
      var vm = this
      this.processing = true

      this.$apollo
        .mutate({
          mutation: REMOVE_CUSTOM_PAYMENT_TERM,
          variables: {
            id: vm.editTerm.id
          },
          update: (store, { data: { removeManufacturerPaymentTerm } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: MANUFACTURER,
              variables: {
                id: vm.$route.params.manufacturerID
              }
            })
            // Find the index
            let idx = vm._.findIndex(
              data.manufacturer.paymentTerms,
              item => item.id === removeManufacturerPaymentTerm
            )

            data.manufacturer.paymentTerms.splice(idx, 1)

            // Write our data back to the cache.
            store.writeQuery({
              query: MANUFACTURER,
              data,
              variables: {
                id: vm.$route.params.manufacturerID
              }
            })
          }
        })
        .then(() => {
          this.closePaymentTermDialog()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Payment Term Removed'
          })
        })
        .catch(() => {
          this.ptErrorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.team-selection >>> .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.size-delimiter {
  &:last-child {
    span {
      visibility: hidden;
    }
  }
}
</style>
