<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="px-3">
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="priceMarkupForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      autocomplete="off"
                      label="Name"
                      outlined
                      :rules="requiredRule"
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      label="Description"
                      outlined
                      dense
                      hide-details="auto"
                      rows="3"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>

                <v-row v-if="item.markupType === 'RETAIL'">
                  <v-col>
                    <v-currency-field
                      v-model="item.multiplier"
                      :decimal-length="2"
                      :default-value="0"
                      autocomplete="off"
                      label="Multiplier"
                      placeholder="Multiplier"
                      hide-details="auto"
                      outlined
                      dense
                      @input="updateCalculation('multiplier')"
                    ></v-currency-field>
                  </v-col>

                  <v-col>
                    <v-checkbox
                      v-model="item.defaultMarkup"
                      label="Default"
                      hide-details="auto"
                      dense
                      class="mt-1"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row v-if="item.markupType === 'WHOLESALE'">
                  <v-col cols="12" md="4">
                    <v-currency-field
                      v-model="item.multiplier"
                      :decimal-length="2"
                      :default-value="0"
                      autocomplete="off"
                      label="Multiplier"
                      placeholder="Multiplier"
                      hide-details="auto"
                      outlined
                      dense
                      @input="updateCalculation('multiplier')"
                    ></v-currency-field>
                  </v-col>

                  <v-col>
                    <v-currency-field
                      v-model="item.markupPercentage"
                      suffix="%"
                      :decimal-length="2"
                      :default-value="0"
                      autocomplete="off"
                      label="Markup"
                      placeholder="Markup"
                      hide-details="auto"
                      outlined
                      dense
                      @input="updateCalculation('markup')"
                    ></v-currency-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-currency-field
                      v-model="item.margin"
                      suffix="%"
                      :decimal-length="2"
                      :default-value="0"
                      autocomplete="off"
                      label="Margin"
                      placeholder="Margin"
                      hide-details="auto"
                      outlined
                      dense
                      @input="updateCalculation('margin')"
                    ></v-currency-field>
                  </v-col>
                </v-row>

                <v-row v-if="item.markupType === 'WHOLESALE'">
                  <v-col cols="12">
                    <v-checkbox
                      v-model="item.defaultMarkup"
                      label="Default"
                      hide-details="auto"
                      dense
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text :disabled="$apollo.loading" @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn color="primary" rounded :loading="$apollo.loading" @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PriceMarkupEditDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: { type: Boolean, default: false },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    disabled: false,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    item: null,
    updateCalc: null
  }),
  computed: {
    formTitle() {
      return this.editMode ? 'Edit Markup' : 'New Markup'
    }
  },
  watch: {
    editedItem() {
      this.item = this._.cloneDeep(this.editedItem)
    }
  },
  methods: {
    close(success) {
      this.$refs.priceMarkupForm.resetValidation()
      this.$emit('close-edit-price-markup-dialog', success)
    },
    save() {
      if (this.$refs.priceMarkupForm.validate()) {
        this.$emit('save-price-markup', this.item)
      }
    },
    updateCalculation(evt) {
      switch (evt) {
        case 'multiplier':
          this.updateMultiplier()
          break
        case 'markup':
          this.updateMarkup()
          break
        case 'margin':
          this.updateMargin()
          break
        default:
          break
      }
    },
    updateMultiplier() {
      if (this.item.multiplier === 0) {
        this.item.markupPercentage = 0
        this.item.margin = 0
      } else {
        this.item.markupPercentage = (this.item.multiplier * 100 - 100).toFixed(
          2
        )
        this.item.margin = (
          this.item.markupPercentage / this.item.multiplier
        ).toFixed(2)
      }
    },
    updateMarkup() {
      if (this.item.markupPercentage === 0) {
        this.item.margin = 0
        this.item.multiplier = 1
      } else {
        this.item.multiplier = (this.item.markupPercentage / 100 + 1).toFixed(2)
      }
    },
    updateMargin() {
      if (this.item.margin !== 0) {
        this.item.multiplier = (100 / (100 - this.item.margin)).toFixed(2)
        this.item.markupPercentage = (this.item.multiplier * 100 - 100).toFixed(
          2
        )
      } else {
        this.item.multiplier = 1
        this.item.markupPercentage = 0
      }
    }
  }
}
</script>

<style lang="scss">
#edit-priceMarkup-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
