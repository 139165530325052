<template>
  <div>
    <v-card flat>
      <v-card-title class="subtitle-1"
        ><span>Workflows</span><v-spacer></v-spacer>
        <v-btn
          v-if="currentUser && currentUser.isSuperAdmin"
          rounded
          small
          color="primary"
          @click="$emit('addWorkflow')"
          >Add Workflow</v-btn
        >
      </v-card-title>
      <v-list dense>
        <v-list-item-group
          v-if="workflows.length > 0"
          v-model="selectedWorkflow"
          color="info"
        >
          <v-list-item v-for="item in workflows" :key="item.id">
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters>
                  <div>{{ item.name }}</div>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      currentUser &&
                        currentUser.isSuperAdmin &&
                        item &&
                        activeItem &&
                        item.id === activeItem.id
                    "
                    icon
                    x-small
                    aria-label="edit item"
                    @click.stop="$emit('editWorkflow', item)"
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <div v-else-if="loading">
          <v-skeleton-loader
            type="list-item"
            class="mx-auto"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item"
            class="mx-auto"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item"
            class="mx-auto"
          ></v-skeleton-loader>
        </div>

        <div v-else>
          <message-box
            icon="mdi-emoticon-neutral-outline"
            title="No Workflows"
            caption="Add your first workflow to organise your work"
          ></message-box>
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import MessageBox from './MessageBox'

export default {
  name: 'WorkflowList',
  components: { MessageBox },
  props: {
    workflows: {
      type: Array,
      default: () => []
    },
    currentUser: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selectedWorkflow: 0
  }),
  computed: {
    activeItem() {
      return this.workflows[this.selectedWorkflow]
    }
    // selectedWorkflow: {
    //   get(item) {
    //     return item
    //   },
    //   set(item) {
    //     if (!item && this.workflows.length > 0) {
    //       return 0
    //     }
    //     return item
    //   }
    // }
  },
  watch: {
    selectedWorkflow(item) {
      this.$emit('selected', item)
    },
    workflows(items) {
      if (!this.selectedWorkflow && items.length > 0) {
        this.selectedWorkflow = 0
      }
    }
  }
}
</script>

<style></style>
