var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header"},[(_vm.manufacturer)?_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"extended":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left","background-color":"transparent","align-with-title":"","center-active":"","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ManufacturerDetail',
            params: { manufacturerID: _vm.$route.params.manufacturerID }
          },"exact":""}},[_vm._v("Details")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ManufacturerLocations',
            params: { manufacturerID: _vm.$route.params.manufacturerID }
          }}},[_vm._v("Locations")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ManufacturerManufacturingOrderList',
            params: { manufacturerID: _vm.$route.params.manufacturerID }
          }}},[_vm._v("Orders")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ManufacturerProducts',
            params: { manufacturerID: _vm.$route.params.manufacturerID }
          }}},[_vm._v("Products")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
            name: 'ManufacturerSettings',
            params: { manufacturerID: _vm.$route.params.manufacturerID }
          }}},[_vm._v("Settings")])],1),_vm._t("controls")]},proxy:true}],null,true)},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8","lg":"9","xl":"10"}},[_c('h2',{staticClass:"mb-0 text-truncate primary--text"},[_vm._v(" "+_vm._s(_vm.manufacturer.name)+" ")])]),_c('v-spacer')],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }