<template>
  <div class="app-layout">
    <sub-article-group-edit-dialog
      :open="dialog"
      :edited-item="editedItem"
      :edit-mode="edit"
      @close-edit-article-group-dialog="close"
    ></sub-article-group-edit-dialog>

    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Article Group</v-card-title
        >
        <v-card-text>
          <p>
            This will delete this group. This can't be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="close"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="error"
            text
            @click.prevent="removeSubArticleGroup"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="page-wrap">
      <ProductionSettingsToolbar>
        <template v-slot:controls>
          <v-btn
            v-if="authLevel === 2"
            fab
            small
            color="primary"
            @click="newItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </ProductionSettingsToolbar>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col sm="12" md="10" lg="8" xl="8">
              <v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                    clearable
                    autocomplete="off"
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="subArticleGroups"
                  :search="search"
                  :loading="$apollo.loading"
                  :mobile-breakpoint="null"
                  :items-per-page="-1"
                  class="plain-table"
                  hide-default-footer
                >
                  <template v-slot:no-data>Nothing here yet...</template>
                  <template v-slot:item.controls="{ item }">
                    <v-btn icon small
                      ><v-icon small @click.stop="editItem(item)"
                        >mdi-pencil-outline</v-icon
                      ></v-btn
                    >
                    <v-btn class="ml-2" icon small
                      ><v-icon
                        color="error"
                        small
                        @click.stop="openRemoveEditDialog(item)"
                        >mdi-delete-outline</v-icon
                      ></v-btn
                    >
                  </template>
                  <template v-slot:item.updatedAt="{ item }">
                    {{ item.updatedAt | moment('DD MMM YYYY') }}
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductionSettingsToolbar from '@/modules/production/components/base/ProductionSettingsToolbar.vue'
import SubArticleGroupEditDialog from '@/modules/production/components/core/SubArticleGroupEditDialog.vue'
import ARTICLE_GROUPS from '@/graphql/SubArticleGroups.gql'
import ARTICLE_GROUP_REMOVE from '@/graphql/SubArticleGroupRemove.gql'

export default {
  name: 'ProductionSettingsSubArticleGroups',
  components: {
    ProductionSettingsToolbar,
    SubArticleGroupEditDialog
  },
  data: () => ({
    processing: false,
    removeDialog: false,
    removeableSubArticleGroup: null,
    dialog: false,
    edit: false,
    search: '',
    colors: [],
    defaultItem: {
      id: '',
      name: '',
      description: '',
      articleCode: '',
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      name: '',
      description: '',
      articleCode: '',
      updatedAt: new Date(),
      createdAt: new Date()
    }
  }),
  computed: {
    headers() {
      return [
        // {
        //   text: 'Code',
        //   value: 'articleCode',
        //   align: 'left',
        //   sortable: true
        // },
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: true
        },
        {
          text: 'Description',
          value: 'description',
          align: 'left',
          sortable: true
        },
        {
          text: 'Last Update',
          value: 'updatedAt',
          align: 'left',
          sortable: true
        },
        {
          text: '',
          value: 'controls',
          align: 'right',
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('production_settings')
    }
  },
  apollo: {
    subArticleGroups: ARTICLE_GROUPS
  },
  methods: {
    close(success) {
      this.edit = false

      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false

      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Sub Article Group Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },
    openRemoveEditDialog(data) {
      this.removeableSubArticleGroup = data
      this.removeDialog = true
    },
    removeSubArticleGroup() {
      var that = this

      if (this.removeableSubArticleGroup) {
        this.processing = true

        this.$apollo
          .mutate({
            mutation: ARTICLE_GROUP_REMOVE,
            variables: {
              id: this.removeableSubArticleGroup.id
            },
            update: (store, { data: { removeSubArticleGroup } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: ARTICLE_GROUPS
              })

              let idx = that._.findIndex(data.subArticleGroups, function(item) {
                return item.id === removeSubArticleGroup
              })

              // Add our requirement from the mutation to the end
              if (idx > -1) data.subArticleGroups.splice(idx, 1)
              // Write our data back to the cache.
              store.writeQuery({
                query: ARTICLE_GROUPS,
                data
              })
            }
          })
          .then(() => {
            this.processing = false
            this.removeDialog = false

            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Sub Article Group Removed'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style></style>
