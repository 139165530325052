<template>
  <div class="page-wrap">
    <customer-detail-header
      :customer="customer"
      :auth-level="authLevel"
    ></customer-detail-header>

    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <router-view
          :customer="customer"
          :current-user="currentUser"
          :auth-level="authLevel"
          :currencies="currencies"
          :payment-terms="paymentTerms"
          :loading="$apollo.loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomerDetailHeader from '@/modules/production/components/core/CustomerDetailHeader.vue'
import CUSTOMER from '@/graphql/Customer.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'

export default {
  name: 'CustomerDetailLayout',
  components: {
    CustomerDetailHeader
  },
  data: () => ({}),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('customers')
    }
  },
  apollo: {
    customer: {
      query: CUSTOMER,
      variables() {
        return {
          id: this.$route.params.customerID
        }
      }
    },
    currencies: CURRENCY_LIST,
    paymentTerms: PAYMENT_TERM_LIST
  },
  watch: {},
  created() {},
  methods: {}
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 92px;
  /* --page-wrap-offset: -20px; */
}
</style>
