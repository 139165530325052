<template>
  <v-row>
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Manufacturer</v-card-title
        >
        <v-card-text>
          <p>
            This will delete this manufacturer and can't be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="removeDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="error"
            text
            @click="removeManufacturer"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col v-if="product" cols="12" sm="12" md="4" lg="4" xl="3">
      <v-card flat>
        <v-card-title class="subtitle-1"
          ><span>Manufacturers</span><v-spacer></v-spacer>
          <v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                manufacturerAuthLevel &&
                manufacturerAuthLevel === 2 &&
                !editMode
            "
            rounded
            small
            color="primary"
            @click="addManufacturer"
            >Add</v-btn
          >
        </v-card-title>
        <v-list dense>
          <div v-if="productManufacturers && productManufacturers.length > 0">
            <v-list-item
              v-for="(item, idx) in productManufacturers"
              :key="item.id"
              color="info"
              :input-value="selectedIdx === idx"
              @click="setSelected(idx)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters>
                    <div>
                      <span>{{ item.manufacturer.name }}</span
                      ><span v-if="item.address && item.address" class="ml-2"
                        >(<span v-if="item.address.name">{{
                          item.address.name
                        }}</span>
                        -
                        <span v-if="item.address.country">{{
                          country(item.address.country)
                        }}</span
                        >)</span
                      >

                      <v-chip
                        v-if="item.defaultManufacturer"
                        class="ml-3"
                        color="info lighten-2 font-weight-bold"
                        text-color="info darken-2"
                        pill
                        x-small
                        >default</v-chip
                      >
                    </div>
                    <!-- <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        !newItem &&
                          !item.defaultManufacturer &&
                          currentUser &&
                          item &&
                          editedItem &&
                          item.id === editedItem.id
                      "
                      icon
                      x-small
                      color="error"
                      aria-label="edit item"
                      @click.stop="openRemoveDialog(item)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn> -->
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="$apollo.loading">
            <v-skeleton-loader
              v-for="line in 3"
              :key="line"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </div>
          <v-card-text v-else>
            <div>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Manufacturers"
                caption="Add your first manufacturer to organise your work"
              ></message-box>
            </div>
          </v-card-text>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="8" lg="8" xl="9">
      <v-card v-if="activeItem && selectedIdx > -1" flat>
        <v-card-title class="mb-3">
          <span v-if="!editedItem.id">New Manufacturer</span>
          <span v-else-if="editMode && editedItem.id">Update Manufacturer</span>
          <span v-else>Details</span>
          <v-spacer></v-spacer
          ><v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                manufacturerAuthLevel &&
                manufacturerAuthLevel === 2 &&
                editMode &&
                ((editedItem &&
                  manufacturerAuthLevel &&
                  manufacturerAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            rounded
            small
            text
            :disabled="processing"
            class="mr-2"
            @click="cancelEdit(false)"
          >
            cancel
          </v-btn>
          <v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                manufacturerAuthLevel &&
                manufacturerAuthLevel === 2 &&
                editMode &&
                ((editedItem &&
                  manufacturerAuthLevel &&
                  manufacturerAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="primary"
            rounded
            small
            :loading="processing"
            @click="saveManufacturer"
          >
            save
          </v-btn>
          <v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                manufacturerAuthLevel &&
                manufacturerAuthLevel === 2 &&
                !editMode &&
                ((editedItem &&
                  manufacturerAuthLevel &&
                  manufacturerAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="grey"
            outlined
            rounded
            depressed
            small
            :disabled="processing"
            @click="editManufacturer"
          >
            edit
          </v-btn>
          <v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                manufacturerAuthLevel &&
                manufacturerAuthLevel === 2 &&
                !newItem &&
                editedItem &&
                defaultManufacturer &&
                ((manufacturerAuthLevel && manufacturerAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager'))) &&
                editedItem.id !== defaultManufacturer.id
            "
            fab
            outlined
            color="error"
            x-small
            elevation="0"
            aria-label="edit item"
            class="ml-2"
            @click.stop="openRemoveDialog(editedItem)"
          >
            <v-icon color="error">mdi-delete-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="manufacturerForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col
                v-if="manufacturerList && manufacturerList.items"
                cols="12"
              >
                <v-autocomplete
                  v-model="editedItem.manufacturer"
                  autocomplete="off"
                  :items="manufacturerList.items"
                  label="Manufacturer"
                  hide-details="auto"
                  hide-no-data
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                  auto-select-first
                  :rules="requiredSelectRule"
                  :disabled="!editMode"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="
                  editedItem &&
                    editedItem.manufacturer &&
                    editedItem.manufacturer.id
                "
                cols="12"
              >
                <v-autocomplete
                  v-model="editedItem.address"
                  autocomplete="off"
                  :items="editedItem.manufacturer.addresses"
                  label="Location"
                  hide-details="auto"
                  hide-no-data
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                  auto-select-first
                  :rules="requiredSelectRule"
                  :disabled="!editMode"
                >
                  <template v-slot:selection="data">
                    <span>{{ data.item.name }}</span>
                    <span v-if="data.item.country" class="ml-2"
                      >({{ country(data.item.country) }})</span
                    >
                  </template>
                  <template v-slot:item="data">
                    <span>{{ data.item.name }}</span>
                    <span v-if="data.item.country" class="ml-2"
                      >({{ country(data.item.country) }})</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="editedItem && editedItem.address && editedItem.address.id"
            >
              <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :value="country(editedItem.address.country)"
                      autocomplete="off"
                      label="Country"
                      hide-details="auto"
                      outlined
                      dense
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.address.email"
                      autocomplete="off"
                      label="Email"
                      hide-details="auto"
                      outlined
                      dense
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.address.phone"
                      autocomplete="off"
                      label="Phone"
                      hide-details="auto"
                      outlined
                      dense
                      readonly
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-checkbox
                  v-model="editedItem.defaultManufacturer"
                  color="info"
                  label="Default"
                  hide-details="auto"
                  outlined
                  dense
                  :readonly="!editMode"
                  :disabled="
                    (!editMode && !editedItem.defaultManufacturer) ||
                      (defaultManufacturer &&
                        editedItem &&
                        editedItem.id === defaultManufacturer.id)
                  "
                ></v-checkbox>
              </v-col>
            </v-row>

            <div class="mt-4 mb-2 caption text--secondary text-uppercase">
              Production Info
            </div>
            <v-divider class="mb-2"></v-divider>
            <!-- <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.currency"
                      autocomplete="off"
                      label="Currency"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.currency"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.makeCost"
                      autocomplete="off"
                      label="Make Cost"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.makeCost"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.leadTime"
                      type="number"
                      autocomplete="off"
                      label="Lead Time (days)"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.leadTime"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.minimumQuantity"
                      type="number"
                      autocomplete="off"
                      label="Minimum Quantity"
                      hide-details="auto"
                      outlined
                      dense
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.minimumQuantity"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.notes"
                      autocomplete="off"
                      label="Notes"
                      hide-details="auto"
                      outlined
                      dense
                      rows="5"
                      :readonly="!editMode"
                      :disabled="!editMode && !editedItem.notes"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <div v-else>
        <message-box
          icon="mdi-emoticon-neutral-outline"
          title="No Manufacturers Selected"
          caption="Select a manufacturer to view the details"
        ></message-box>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox'
import PRODUCT_MANUFACTURER_LIST from '@/graphql/ProductManufacturers.gql'
import PRODUCT_MANUFACTURER_CREATE from '@/graphql/ProductManufacturerCreate.gql'
import PRODUCT_MANUFACTURER_UPDATE from '@/graphql/ProductManufacturerUpdate.gql'
import PRODUCT_MANUFACTURER_REMOVE from '@/graphql/ProductManufacturerRemove.gql'
import { getName } from 'country-list'

export default {
  name: 'ProductionProductManufacturers',
  components: { MessageBox },
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    colors: { type: Array, default: () => [] },
    categories: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    sizeGroups: { type: Array, default: () => [] },
    manufacturerList: { type: Object, default: () => {} }
  },
  apollo: {
    productManufacturers: {
      query: PRODUCT_MANUFACTURER_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
      // fetchPolicy: 'no-cache'
    }
  },
  data: () => ({
    selectedIdx: 0,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    editDialog: false,
    removeDialog: false,
    removeableManufacturer: null,
    processing: false,
    editMode: false,
    errorMsg: '',
    defaultItem: {
      id: null,
      productID: null,
      manufacturerID: null,
      manufacturer: { name: 'New Manufacturer', addresses: null },
      address: null,
      leadTime: null,
      defaultManufacturer: null
    },
    editedItem: null,
    productManufacturers: []
  }),
  computed: {
    manufacturerAuthLevel() {
      return this.$store.getters.authLevel('product_manufacturer')
    },
    activeItem() {
      this.setEditedItem(this.selectedIdx)
      return this.productManufacturers[this.selectedIdx]
    },
    newItem() {
      var lastItem = this._.last(this.productManufacturers)
      return lastItem && !lastItem.id
    },
    defaultManufacturer() {
      return this._.find(this.productManufacturers, function(item) {
        return item.defaultManufacturer
      })
    }
    // item() {
    //   this.setEditedItem()
    //   return this._.cloneDeep(this.product)
    // }
    // country(item) {
    //   return getName(item)
    //   // return null
    // }
  },
  destroyed() {
    var lastItem = this._.last(this.productManufacturers)
    if (!lastItem || !lastItem.id)
      if (this.productManufacturers && this.productManufacturers.length > 0) {
        this.productManufacturers.splice(
          this.productManufacturers.length - 1,
          1
        )
      }
  },
  methods: {
    country(item) {
      return getName(item)
    },
    setSelected(idx) {
      if (!idx || idx === -1) {
        this.selectedIdx = 0
      } else {
        this.selectedIdx = idx
      }

      var lastItem = this._.last(this.productManufacturers)
      if (
        !lastItem ||
        (!lastItem.id && idx !== this.productManufacturers.length - 1)
      ) {
        this.productManufacturers.splice(
          this.productManufacturers.length - 1,
          1
        )
        this.editMode = false
      }

      this.setEditedItem(idx)
    },
    setEditedItem(idx) {
      if (!idx || idx === -1) {
        idx = 0
      }

      if (this.productManufacturers && this.productManufacturers.length > 0) {
        this.editedItem = this._.cloneDeep(this.productManufacturers[idx])
        // this.$refs.manufacturerForm.resetValidation()
      } else {
        this.editedItem = null
        // this.$refs.manufacturerForm.resetValidation()
      }
    },
    // Manufacturer methods
    cancelEdit(success) {
      if (!success) {
        this.$refs.manufacturerForm.resetValidation()
        this.setEditedItem()
      }

      var lastItem = this._.last(this.productManufacturers)
      if (lastItem && !lastItem.id) {
        this.productManufacturers.splice(
          this.productManufacturers.length - 1,
          1
        )
      }

      // this.editDialog = false
      this.processing = false
      this.errorMsg = ''

      this.editMode = false

      if (this.selectedIdx > this.productManufacturers.length - 1) {
        this.setSelected(0)
      }
    },

    addManufacturer() {
      var lastItem = this._.last(this.productManufacturers)
      if (lastItem && lastItem.id) {
        this.productManufacturers.push(this._.cloneDeep(this.defaultItem))

        this.selectedIdx = this.productManufacturers.length - 1
        this.editMode = true

        this.$refs.manufacturerForm.resetValidation()
      } else {
        this.editedItem = this._.cloneDeep(this.defaultItem)
        this.productManufacturers.push(this.editedItem)

        this.editMode = true
      }
    },

    editManufacturer() {
      this.editMode = true
    },

    saveManufacturer() {
      // var that = this

      this.errorMsg = ''

      if (this.$refs.manufacturerForm.validate()) {
        this.processing = true

        var data = {
          id: this.editedItem.id,
          productID: this.$route.params.productID,
          manufacturerID: this.editedItem.manufacturer.id,
          manufacturerAddressID: this.editedItem.address.id,
          leadTime: this.editedItem.leadTime,
          minimumQuantity: this.editedItem.minimumQuantity,
          notes: this.editedItem.notes,
          defaultManufacturer:
            this.productManufacturers && this.productManufacturers.length === 0
              ? true
              : this.editedItem.defaultManufacturer
        }

        if (this.newItem) {
          delete data.id
          // Save Item
          this.$apollo
            .mutate({
              mutation: PRODUCT_MANUFACTURER_CREATE,
              variables: {
                input: data
              }
            })
            .then(result => {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Manufacturer Added'
              })

              if (result.data.createProductManufacturer.defaultManufacturer) {
                this.selectedIdx = 0
              }
              this.$apollo.queries.productManufacturers.refetch()
              this.cancelEdit(true)
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        } else {
          // Update Item
          this.$apollo
            .mutate({
              mutation: PRODUCT_MANUFACTURER_UPDATE,
              variables: {
                input: data
              }
            })
            .then(result => {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Manufacturer Updated'
              })

              if (result.data.updateProductManufacturer.defaultManufacturer) {
                this.selectedIdx = 0
              }
              this.$apollo.queries.productManufacturers.refetch()
              this.cancelEdit(true)
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        }
      }
    },

    openRemoveDialog(item) {
      if (item.id !== this.defaultManufacturer.id) {
        this.removeableManufacturer = item
        this.removeDialog = true
      } else {
        this.removeableManufacturer = null
        this.$store.commit('showSnackbar', {
          active: true,
          message: "Can't remove default manufacturer"
        })
      }
    },

    removeManufacturer() {
      if (this.removeableManufacturer.id !== this.defaultManufacturer.id) {
        this.$apollo
          .mutate({
            mutation: PRODUCT_MANUFACTURER_REMOVE,
            variables: {
              id: this.removeableManufacturer.id
            }
          })
          .then(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Manufacturer Removed'
            })

            this.selectedIdx = 0

            this.$apollo.queries.productManufacturers.refresh()

            this.removeDialog = false
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong'
            })
            this.removeableManufacturer = null
            this.processing = false

            this.removeDialog = false
          })
      } else {
        this.removeableManufacturer = null
        this.$store.commit('showSnackbar', {
          active: true,
          message: "Can't remove default manufacturer"
        })
        this.removeDialog = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
