<template>
  <div class="page-wrap">
    <div class="page-header">
      <product-edit-dialog
        :dialog="editDialog"
        :edit="editMode"
        :seasons="seasons.seasons"
        :product="editedItem"
        :colors="colors"
        :categories="productCategories"
        :article-groups="articleGroups"
        :sub-article-groups="subArticleGroups"
        :style-types="styleTypes"
        :processing="processing"
        :error-msg="errorMsg"
        @save-product-edit-dialog="saveProduct"
        @close-product-edit-dialog="closeEditProductDialog"
      >
      </product-edit-dialog>
      <v-toolbar class="mb-0 b-radius" color="transparent" flat>
        <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
        <v-row>
          <v-col cols="6" lg="2" xl="2" class="pl-0">
            <v-btn
              v-if="authLevel && authLevel === 2"
              color="primary"
              rounded
              @click="editDialog = true"
            >
              add product
            </v-btn>
          </v-col>

          <v-col
            cols="7"
            lg="3"
            xl="2"
            class="d-none d-sm-none d-md-none d-lg-flex"
          >
            <season-switcher
              :background-color="'#fff'"
              :items="seasons.seasons"
              :prepend-inner-icon="'mdi-filter-variant'"
              :disabled="
                $apollo.queries.seasons.loading ||
                  !seasons ||
                  seasons.seasons.length === 0
              "
            ></season-switcher>
          </v-col>

          <v-col
            cols="3"
            lg="3"
            xl="2"
            class="d-none d-sm-none d-md-none d-lg-flex"
          >
            <v-select
              v-model="selectedDesigner"
              :items="designers"
              hide-details
              outlined
              flat
              dense
              clearable
              background-color="#fff"
              label="designer"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              item-value="id"
              :disabled="
                !products ||
                  (products.items.length === 0 && !users) ||
                  users.length === 0
              "
            >
              <template v-slot:selection="subData"
                ><div class="text-truncate" style="width: 80%">
                  {{ subData.item.firstName + ' ' + subData.item.lastName }}
                </div></template
              >
              <template v-slot:item="{ item }">
                <span>{{ item.firstName + ' ' + item.lastName }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="3"
            lg="3"
            xl="2"
            class="d-none d-sm-none d-md-none d-lg-flex"
          >
            <v-select
              v-model="selectedProducer"
              hide-details
              outlined
              flat
              dense
              clearable
              background-color="#fff"
              label="developer"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              item-value="id"
              :items="productDevelopers"
              :disabled="
                !products ||
                  (products.items.length === 0 && !users) ||
                  users.length === 0
              "
            >
              <template v-slot:selection="subData"
                ><div class="text-truncate" style="width: 80%">
                  {{ subData.item.firstName + ' ' + subData.item.lastName }}
                </div></template
              >
              <template v-slot:item="{ item }">
                <span>{{ item.firstName + ' ' + item.lastName }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>
        <v-row justify="end">
          <span class="pt-3 text-truncate">{{ itemCount }} Products</span>

          <v-btn
            icon
            class="d-sm-flex d-md-flex d-lg-none d-xl-none ml-2"
            @click="filters = !filters"
          >
            <v-icon :color="filters ? 'info' : ''">mdi-filter-variant</v-icon>
          </v-btn>
        </v-row>
      </v-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-card v-if="filters" class="mb-3">
          <div>
            <v-col cols="12">
              <v-row no-gutters>
                <span>Advanced Filters</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="filters = !filters"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </v-col>

            <v-col cols="12">
              <season-switcher
                :background-color="'#fff'"
                :items="seasons.seasons"
                :prepend-inner-icon="'mdi-filter-variant'"
                :disabled="
                  $apollo.queries.seasons.loading ||
                    !seasons ||
                    seasons.seasons.length === 0
                "
              ></season-switcher>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="selectedDesigner"
                :items="users"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                label="designer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                item-value="id"
                :disabled="
                  !products ||
                    (products.items.length === 0 && !users) ||
                    users.length === 0
                "
              >
                <template v-slot:selection="subData"
                  ><div class="text-truncate" style="width: 80%">
                    {{ subData.item.firstName + ' ' + subData.item.lastName }}
                  </div></template
                >
                <template v-slot:item="{ item }">
                  <span>{{ item.firstName + ' ' + item.lastName }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selectedProducer"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                label="producer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                item-value="id"
                :items="users"
                :disabled="
                  !products ||
                    (products.items.length === 0 && !users) ||
                    users.length === 0
                "
              >
                <template v-slot:selection="subData"
                  ><div class="text-truncate" style="width: 80%">
                    {{ subData.item.firstName + ' ' + subData.item.lastName }}
                  </div></template
                >
                <template v-slot:item="{ item }">
                  <span>{{ item.firstName + ' ' + item.lastName }}</span>
                </template>
              </v-select>
            </v-col>
          </div>
        </v-card>

        <v-card flat>
          <v-card-title class="pt-0">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search By Name..."
              single-line
              hide-details
              clearable
              autocomplete="off"
            ></v-text-field>
          </v-card-title>

          <v-data-table
            v-model="selected"
            :headers="headers"
            :loading="$apollo.loading"
            :items="products.items"
            :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
            :options.sync="options"
            :server-items-length="itemCount"
            :mobile-breakpoint="null"
            :hide-default-footer="!products || itemCount < 16"
            class="plain-table"
            @page-count="pageCount = $event"
            @click:row="navToDetail"
          >
            <template v-slot:item.frontImage="{ item }">
              <div class="text-truncate font-weight-normal">
                <v-img
                  style="border-radius: 4px"
                  width="40"
                  max-height="40"
                  :src="
                    item.frontImage && item.frontImage !== ''
                      ? item.frontImage
                      : '/DAILY_PAPER_LOGO.png'
                  "
                  lazy-src="/DAILY_PAPER_LOGO.png"
                ></v-img>
              </div>
            </template>
            <template v-slot:item.name="{ item }">
              <div class="text-truncate primary--text">
                {{ item.name }}
              </div>
            </template>
            <template v-slot:item.styleState="{ item }">
              <div class="text-truncate primary--text">
                <v-chip
                  pill
                  x-small
                  :color="styleState(item.styleState) + ' upperCaseSpacing'"
                  :text-color="styleText(item.styleState)"
                >
                  {{ item.styleState }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.productState="{ item }">
              <div class="text-truncate">
                <v-chip
                  pill
                  x-small
                  :color="styleState(item.productState) + ' upperCaseSpacing'"
                  :text-color="styleText(item.productState)"
                >
                  {{ item.productState }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.category="{ item }">
              <div class="text-truncate primary--text">
                <!-- <v-chip
                  v-if="item.category && item.category.id"
                  color="info lighten-2"
                  text-color="info darken-2"
                  outlined
                  x-small
                  pill
                  >{{ item.category.name }}</v-chip
                > -->
                <span>{{ item.category.name }}</span>
              </div>
            </template>
            <!-- <template v-slot:item.color="{ item }">
              <div class="text-truncate primary--text">
                <span v-if="item.color.id"
                  >{{ item.color.name }} ({{ item.color.colorCode }})</span
                >
              </div>
            </template> -->
            <template v-slot:item.designers="{ item }">
              <div
                v-if="item.designers && item.designers.length > 0"
                class="text-truncate"
              >
                <template v-for="(designer, index) in item.designers">
                  <!-- <v-chip
                    v-if="index < 1"
                    :key="designer.id"
                    pill
                    x-small
                    color="secondary lighten-2 font-weight-bold"
                    text-color="secondary darken-2"
                    class="mr-2"
                    >
                    <v-avatar left style="margin-left: -20px">
                      <v-icon small>mdi-account-circle</v-icon>
                    </v-avatar> -->
                  <span v-if="index < 1" :key="designer.id" class="mr-2">{{
                    designer.firstName + ' ' + designer.lastName
                  }}</span>
                  <!-- </v-chip
                  > -->
                  <span
                    v-if="index > 0"
                    :key="designer.id"
                    class="grey--text caption"
                    >(+{{ item.designers.length - 1 }} others)</span
                  >
                </template>
              </div>
            </template>
            <template v-slot:no-data>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Results"
                caption="Try adjusting your filters to get some results"
              ></message-box>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                v-for="item in 5"
                :key="item"
                type="list-item"
                class="mx-auto"
              ></v-skeleton-loader>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import SeasonSwitcher from '@/components/core/SeasonSwitcher.vue'
import ProductEditDialog from '@/modules/production/components/core/ProductEditDialog.vue'
import SEASONS from '@/graphql/Seasons.gql'
import PRODUCTS from '@/graphql/Products.gql'
import CREATE_PRODUCT from '@/graphql/ProductCreate.gql'
import USERS from '@/graphql/Users.gql'
import COLORS from '@/graphql/Colors.gql'
import CATEGORIES from '@/graphql/ProductCategories.gql'
import ARTICLE_GROUPS from '@/graphql/ArticleGroups.gql'
import SUB_ARTICLE_GROUPS from '@/graphql/SubArticleGroups.gql'
import STYLE_TYPES from '@/graphql/StyleTypes.gql'
import DEPARTMENTS from '@/graphql/Departments.gql'

export default {
  name: 'ProductionProductList',
  components: {
    MessageBox,
    SeasonSwitcher,
    ProductEditDialog
  },
  data: () => ({
    filters: false,
    selectedDesigner: null,
    selectedProducer: null,

    search: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    editDialog: false,
    editMode: false,
    errorMsg: '',
    selected: [],
    products: { items: [] },
    users: [],
    seasons: [],
    processing: false,
    defaultItem: {
      name: '',
      styleNumber: '',
      description: '',
      gender: 'MALE',
      designer: {}
    },
    editedItem: {
      name: '',
      styleNumber: '',
      description: '',
      gender: 'MALE',
      designer: {}
    }
  }),
  apollo: {
    users: USERS,
    seasons: SEASONS,
    colors: COLORS,
    productCategories: CATEGORIES,
    articleGroups: ARTICLE_GROUPS,
    subArticleGroups: SUB_ARTICLE_GROUPS,
    styleTypes: STYLE_TYPES,
    departments: DEPARTMENTS,
    products: {
      query: PRODUCTS,
      variables() {
        return {
          filters: {
            nameSearch: this.search,
            season: this.selectedSeason,
            designer: this.selectedDesigner,
            producer: this.selectedProducer,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.products.page
        this.itemsPerPage = result.data.products.pageSize
        this.itemCount = result.data.products.total

        // Set page state
        this.$store.commit('production/SET_PAGE', this.options.page)
        this.$store.commit(
          'production/SET_PAGE_SIZE',
          this.options.itemsPerPage
        )
        this.$store.commit('production/SET_SEASON', this.selectedSeason)
        this.$store.commit('production/SET_DESIGNER', this.selectedDesigner)
        this.$store.commit('production/SET_PRODUCER', this.selectedProducer)
        this.$store.commit('production/SET_SEARCH', this.search)
      }
    }
  },
  computed: {
    salesAuthLevel() {
      return this.$store.getters.authLevel('product_sales_details')
    },
    designAuthLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    developmentAuthLevel() {
      return this.$store.getters.authLevel('product_development_details')
    },
    logisticsAuthLevel() {
      return this.$store.getters.authLevel('product_logistics')
    },
    productDeailAccess() {
      if (
        this.salesAuthLevel > 0 ||
        this.designAuthLevel > 0 ||
        this.developmentAuthLevel > 0 ||
        this.logisticsAuthLevel > 0
      ) {
        return true
      } else {
        return false
      }
    },
    headers() {
      return [
        {
          text: '',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'frontImage'
        },

        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Style Number',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'styleNumber'
        },
        {
          text: 'Color',
          value: 'seasonColor',
          sortable: false,
          filterable: true
        },
        {
          text: 'Composition',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'textileContent'
        },
        {
          text: 'Style Status',
          align: 'left',
          value: 'styleState',
          sortable: false,
          filter: this.stateFilterValue
        },
        {
          text: 'Gender',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
          filterable: true
        }
      ]
    },
    selectedSeason() {
      return this.$store.state.selectedSeason
        ? this.$store.state.selectedSeason.id
        : null
    },
    authLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    designDepartment() {
      return this._.find(this.departments, item => item.name === 'Design')
    },
    productionDepartment() {
      return this._.find(this.departments, item => item.name === 'Production')
    },
    designers() {
      var vm = this

      if (this.designDepartment) {
        return this._.filter(
          this.users,
          item => item.department.id === vm.designDepartment.id
        )
      } else {
        return []
      }
    },
    productDevelopers() {
      var vm = this
      if (this.productionDepartment) {
        return this._.filter(
          this.users,
          item => item.department.id === vm.productionDepartment.id
        )
      } else {
        return []
      }
    }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  created() {
    var that = this
    var filters = this.$store.state.production.productsPage
    window.onpopstate = function() {
      // that.backNav = true
      // Apply state filters
      that.options.page = filters.page
      that.options.itemsPerPage = filters.pageSize
      // that.selectedSeason = filters.season
      that.selectedDesigner = filters.designer
      that.selectedProducer = filters.producer
      if (filters.search !== '') that.search = filters.search
      // that.backNav = false
    }
  },
  methods: {
    styleState(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'grey lighten-2 font-weight-bold'
          break
        case 'REVIEW':
          color = 'warning lighten-1 font-weight-bold'
          break
        case 'FINAL':
          color = 'info lighten-2 font-weight-bold'
          break
        default:
          color = 'success lighten-3 font-weight-bold'
          break
      }
      return color
    },
    styleText(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'grey darken-1'
          break
        case 'REVIEW':
          color = 'warning darken-3'
          break
        case 'FINAL':
          color = 'info darken-1'
          break
        default:
          color = 'success'
          break
      }
      return color
    },
    // switchSeason(season) {
    //   this.$store.commit('setSelectedSeason', season)
    // },
    navToDetail(item) {
      // if (this.productDeailAccess) {
      var seasonID = this.selectedSeason || item.seasons[0].id

      this.$router.push({
        name: 'ProductionProductDetail',
        params: { productID: item.id },
        query: { season: seasonID }
      })
      // } else {
      //   this.$store.commit('showSnackbar', {
      //     active: true,
      //     message: 'You have no access to product details'
      //   })
      // }
    },
    showSnackbar() {
      this.$store.commit('showSnackbar', { active: true })
    },
    saveProduct(item, newItem, callback) {
      var that = this
      this.processing = true
      // var that = this

      // Save Item
      delete item.id

      this.$apollo
        .mutate({
          mutation: CREATE_PRODUCT,
          variables: {
            input: item
          }
        })
        .then(result => {
          if (newItem) {
            that.$store.commit('showSnackbar', {
              active: true,
              message: 'Product Added'
            })

            that.editedItem = that._.cloneDeep(that.defaultItem)
            that.processing = false
            that.errorMsg = ''
            that.editMode = false
            that.$apollo.queries.products.refetch()
            callback(true)
          } else {
            that.closeEditProductDialog(true)
            that.$router.push({
              name: 'ProductionProductDesignAssets',
              params: { productID: result.data.createProduct.id },
              query: { season: result.data.createProduct.seasons[0].id }
            })
          }
        })
        .catch(() => {
          that.errorMsg = 'Something went wrong.'
          that.processing = false
        })
    },

    closeEditProductDialog(success) {
      this.editDialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.editMode === true) {
          msg = 'Product Updated'
        } else {
          msg = 'Product Added'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    }
  }
}
</script>

<style scoped>
.v-data-footer {
  min-height: 59px;
}
/* div.v-data-footer__select {
  display: none !important;
} */
</style>
