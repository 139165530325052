<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >New Currency</v-card-title
        >
        <v-card-text>
          <v-alert type="info" dense outlined border="left">
            Add pricing for a new currency
          </v-alert>
          <v-row>
            <v-col v-if="currencies && currencies.length > 0">
              <v-select
                v-model="newItem"
                :items="unusedCurrencies"
                :disabled="!product || !product.prices"
                label="Currency"
                hide-details="auto"
                item-text="isoName"
                item-value="id"
                return-object
                outlined
                dense
              >
                <!-- <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <span v-text="item.name"></span>
                </template> -->
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeDialogs"
            >Cancel</v-btn
          >
          <v-btn
            v-if="
              product &&
                product.productState &&
                product.productState === 'OPEN' &&
                pricingAuthLevel &&
                pricingAuthLevel === 2
            "
            color="primary"
            rounded
            :disabled="!newItem"
            @click="addNewCurrency"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Pricing</v-card-title
        >
        <v-card-text>
          <p>
            This will delete the price and can't be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeDialogs"
            >Cancel</v-btn
          >
          <v-btn :loading="processing" color="error" text @click="removePrice"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="!$apollo.loading && product && currencyList">
      <v-row>
        <!-- manufaturer dropdown -->
        <v-col v-if="product" cols="12" class="pt-0">
          <v-card flat class="my-0" color="light-theme">
            <v-row>
              <v-col cols="12" md="4" lg="4" xl="3">
                <v-select
                  v-model="selectedManufacturer"
                  background-color="white"
                  autocomplete="off"
                  :items="productManufacturers"
                  label="Manufacturer"
                  hide-details="auto"
                  item-value="id"
                  color="info"
                  item-color="info"
                  class="hightlight-control info--text"
                  outlined
                  dense
                  return-object
                >
                  <template v-slot:selection="data">
                    <div class="text-truncate" style="width: 75%;">
                      <span>{{ data.item.manufacturer.name }}</span>
                      <span v-if="data.item.address" class="ml-2"
                        >(<span v-if="data.item.address.name">{{
                          data.item.address.name
                        }}</span>
                        -
                        <span v-if="data.item.address.country">{{
                          getCountryName(data.item.address.country)
                        }}</span
                        >)</span
                      >
                    </div>
                    <v-spacer></v-spacer>
                    <v-chip
                      v-if="data.item.defaultManufacturer"
                      color="info lighten-2 font-weight-bold"
                      text-color="info darken-2"
                      pill
                      x-small
                      >default</v-chip
                    >
                  </template>
                  <template v-slot:item="data">
                    <div class="text-truncate" style="width: 75%;">
                      <span>{{ data.item.manufacturer.name }}</span>
                      <span v-if="data.item.address" class="ml-2"
                        >(<span v-if="data.item.address.name">{{
                          data.item.address.name
                        }}</span>
                        -
                        <span v-if="data.item.address.country">{{
                          getCountryName(data.item.address.country)
                        }}</span
                        >)</span
                      >
                    </div>
                    <v-spacer></v-spacer>
                    <v-chip
                      v-if="data.item.defaultManufacturer"
                      color="info lighten-2 font-weight-bold"
                      text-color="info darken-2"
                      pill
                      x-small
                      >default</v-chip
                    >
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- Main Area -->
        <v-col cols="12" class="pt-0">
          <v-row v-if="editedItem || newItem" justify="center">
            <!-- currency column -->
            <v-col v-if="!addItem" cols="12" md="4" lg="3">
              <v-card flat>
                <v-card-title class="subtitle-1"
                  ><span>Currencies</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        pricingAuthLevel &&
                        pricingAuthLevel === 2 &&
                        !editMode
                    "
                    rounded
                    small
                    color="primary lighten-2"
                    :disabled="currencyList && currencyList.length === 0"
                    @click="dialog = true"
                    >Add</v-btn
                  >
                </v-card-title>
                <v-list dense>
                  <div
                    v-if="
                      product && product.prices && product.prices.length > 0
                    "
                  >
                    <v-list-item
                      v-for="(item, idx) in product.prices"
                      :key="item.id"
                      color="info"
                      :input-value="selectedIdx === idx"
                      @click="setSelectedPricing(idx)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters>
                            <v-col v-text="item.currency.isoName"> </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                  <div v-else-if="$apollo.loading">
                    <v-skeleton-loader
                      v-for="line in 3"
                      :key="line"
                      type="list-item"
                      class="mx-auto"
                    ></v-skeleton-loader>
                  </div>
                  <v-card-text v-else>
                    <div>
                      <message-box
                        height="150px"
                        icon="mdi-emoticon-neutral-outline"
                        title="No Prices"
                        caption="Add your first price to get started"
                      ></message-box>
                    </div>
                  </v-card-text>
                </v-list>
              </v-card>
            </v-col>

            <!-- pricing column -->
            <v-col cols="12" md="8" lg="9">
              <v-card v-if="editedItem && editedItem.currency" flat>
                <v-card-title class="mb-3">
                  <span v-if="!editedItem.id">New Price</span>
                  <span v-else-if="editMode && editedItem.id">Edit Price</span>
                  <span v-else>Details</span>
                  <v-spacer></v-spacer>
                  <!-- <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        pricingAuthLevel &&
                        pricingAuthLevel === 2 &&
                        editMode &&
                        ((editedItem &&
                          pricingAuthLevel &&
                          pricingAuthLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    color="info"
                    rounded
                    outlined
                    small
                    elevation="0"
                    class="mr-6"
                    :disabled="
                      processing ||
                        !editedItem ||
                        !wholesaleMarkup ||
                        !wholesaleMarkup.multiplier > 0 ||
                        !retailMarkup ||
                        !retailMarkup.multiplier > 0
                    "
                    @click="setPriceToSuggestedMarkups()"
                  >
                    apply suggested
                  </v-btn> -->
                  <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        pricingAuthLevel &&
                        pricingAuthLevel === 2 &&
                        editMode &&
                        ((editedItem &&
                          pricingAuthLevel &&
                          pricingAuthLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    small
                    text
                    :disabled="processing"
                    class="mr-2"
                    @click="cancelEdit(false)"
                  >
                    cancel
                  </v-btn>
                  <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        pricingAuthLevel &&
                        pricingAuthLevel === 2 &&
                        editMode &&
                        ((editedItem &&
                          pricingAuthLevel &&
                          pricingAuthLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    color="primary"
                    rounded
                    small
                    :loading="processing"
                    @click="savePricing"
                  >
                    save
                  </v-btn>
                  <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        pricingAuthLevel &&
                        pricingAuthLevel === 2 &&
                        !addItem &&
                        !editMode &&
                        editedItem &&
                        ((pricingAuthLevel && pricingAuthLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    text
                    color="grey lighten-1"
                    small
                    elevation="0"
                    aria-label="remove item"
                    class="ml-2"
                    @click.stop="openRemoveDialog(editedItem)"
                  >
                    remove
                  </v-btn>
                  <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        pricingAuthLevel &&
                        pricingAuthLevel === 2 &&
                        pricingAuthLevel &&
                        pricingAuthLevel === 2 &&
                        !editMode &&
                        ((editedItem &&
                          pricingAuthLevel &&
                          pricingAuthLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    color="primary lighten-2"
                    rounded
                    small
                    :disabled="processing"
                    @click="editPricing(editedItem)"
                  >
                    edit
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <div v-if="errorMsg !== ''" class="mb-3">
                    <v-alert type="error" class="mb-0">
                      {{ errorMsg }}
                    </v-alert>
                  </div>
                  <v-form
                    ref="pricingForm"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent
                  >
                    <v-row justify="center">
                      <!-- Wholesale Markup Controls -->
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="wholesaleMarkup"
                          :items="wholesaleMarkupList"
                          :rules="requiredSelectRule"
                          :disabled="!editMode"
                          background-color="white"
                          autocomplete="off"
                          label="Wholesale Markup"
                          hide-details="auto"
                          item-value="id"
                          outlined
                          dense
                          rounded
                          return-object
                        >
                          <template v-slot:selection="{ item }">
                            <div class="text-truncate" style="width: 75%;">
                              <span>{{ item.name }}</span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-chip
                              v-if="item.defaultMarkup"
                              color="info lighten-2 font-weight-bold"
                              text-color="info darken-2"
                              pill
                              x-small
                              >default</v-chip
                            >
                          </template>
                          <template v-slot:item="{ item }">
                            <div class="text-truncate" style="width: 75%;">
                              <span>{{ item.name }}</span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-chip
                              v-if="item.defaultMarkup"
                              color="info lighten-2 font-weight-bold"
                              text-color="info darken-2"
                              pill
                              x-small
                              >default</v-chip
                            >
                          </template>
                        </v-select>
                      </v-col>

                      <!-- Retail Markup -->
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="retailMarkup"
                          :items="retailMarkupList"
                          :rules="requiredSelectRule"
                          :disabled="!editMode"
                          background-color="white"
                          autocomplete="off"
                          label="RRP Markup"
                          hide-details="auto"
                          item-value="id"
                          outlined
                          dense
                          return-object
                        >
                          <template v-slot:selection="{ item }">
                            <div class="text-truncate" style="width: 75%;">
                              <span>{{ item.name }}</span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-chip
                              v-if="item.defaultMarkup"
                              color="info lighten-2 font-weight-bold"
                              text-color="info darken-2"
                              pill
                              x-small
                              >default</v-chip
                            >
                          </template>
                          <template v-slot:item="{ item }">
                            <div class="text-truncate" style="width: 75%;">
                              <span>{{ item.name }}</span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-chip
                              v-if="item.defaultMarkup"
                              color="info lighten-2 font-weight-bold"
                              text-color="info darken-2"
                              pill
                              x-small
                              >default</v-chip
                            >
                          </template>
                        </v-select>
                      </v-col>

                      <!-- Costing Fieldset -->
                      <v-col cols="12" md="12" lg="10" xl="10">
                        <v-row class="font-weight-bold">
                          <v-col class=" pb-0">
                            <span class="text-uppercase">
                              Currency
                            </span>
                          </v-col>
                          <v-col class="text-right pb-0">
                            <span>{{ editedItem.currency.isoName }}</span
                            ><span
                              v-if="editedItem.currency.isoName"
                              class="ml-1"
                            >
                              ({{ editedItem.currency.symbol }})</span
                            >
                          </v-col>
                        </v-row>
                        <!-- <v-row>
                          <v-col>
                            <span class="text-uppercase">
                              Exchange Rate
                            </span>
                          </v-col>
                          <v-col class="text-right">
                            <span>{{
                              editedItem.currency.exchangeRate.toFixed(4)
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider> -->
                        <v-row class="mt-3">
                          <v-col class="pb-0">
                            <span class="text-uppercase">
                              Total Cost
                            </span>
                          </v-col>
                          <v-col class="text-right pb-0">
                            <span>{{
                              (totalCost / 100)
                                | currency(editedItem.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</span>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <span class="text-uppercase">
                              Markup ({{
                                wholesaleMarkup.multiplier.toFixed(2)
                              }}x)
                            </span>
                          </v-col>
                          <v-col class="text-right">
                            <span>{{
                              (wholeSaleMarkup / 100)
                                | currency(editedItem.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mb-6">
                          <v-col class="pb-0">
                            <span class="text-uppercase">
                              Suggested Wholesale Price
                            </span>
                          </v-col>
                          <v-col class="text-right pb-0">
                            <span>{{
                              (suggestedWholesalePrice / 100)
                                | currency(editedItem.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="font-weight-bold ">
                          <v-col class="">
                            <span class="text-uppercase">
                              Wholesale Price
                            </span>
                          </v-col>
                          <v-col class="text-right py-0"
                            ><v-row no-gutters>
                              <v-col cols="12">
                                <v-row no-gutters justify="space-between">
                                  <v-col>
                                    <v-tooltip v-if="editMode" bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-if="
                                            product &&
                                              product.productState &&
                                              product.productState === 'OPEN' &&
                                              pricingAuthLevel &&
                                              pricingAuthLevel === 2
                                          "
                                          color="primary"
                                          icon
                                          class="mr-2"
                                          style="margin-top: 2px;"
                                          :disabled="
                                            !editMode ||
                                              !editedItem.retailPrice ||
                                              editedItem.retailPrice === 0 ||
                                              !retailMarkup.multiplier ||
                                              retailMarkup.multiplier === 0
                                          "
                                          v-on="on"
                                          @click="setWholesalePriceFromRRP"
                                        >
                                          <v-icon>mdi-calculator</v-icon></v-btn
                                        >
                                      </template>
                                      <span>Calculate Based On RRP</span>
                                    </v-tooltip>
                                  </v-col>

                                  <v-col>
                                    <v-currency-field
                                      v-if="editMode"
                                      v-model="editedItem.wholesalePrice"
                                      :prefix="editedItem.currency.symbol"
                                      :rules="requiredRule"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      class="right-text"
                                    >
                                    </v-currency-field>
                                    <span v-else>
                                      {{
                                        editedItem.wholesalePrice
                                          | currency(
                                            editedItem.currency.symbol,
                                            2
                                          )
                                      }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span class="text-uppercase">
                              Gross Profit (Margin)
                            </span>
                          </v-col>
                          <v-col
                            class="text-right font-weight-bold"
                            :class="[
                              { 'success--text': netProfit > 0 },
                              { 'error--text': netProfit < 0 }
                            ]"
                          >
                            <span>{{
                              (netProfit / 100)
                                | currency(editedItem.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</span
                            ><span> ({{ margin.toFixed(2) }}%)</span>
                            <v-tooltip
                              v-if="wholesalePriceBelowRecommended"
                              bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  color="warning"
                                  small
                                  class="ml-2"
                                  v-on="on"
                                  >mdi-alert-outline</v-icon
                                >
                              </template>
                              <span>Price is below suggested price</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col>
                            <span class="text-uppercase">
                              Suggested Retail Price (<span>{{
                                retailMarkup.multiplier.toFixed(2)
                              }}</span
                              ><span class="overline">x</span>)
                            </span>
                          </v-col>
                          <v-col class="text-right">
                            {{
                              (suggestedRetailPrice / 100)
                                | currency(editedItem.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}
                          </v-col>
                        </v-row>

                        <v-row class="font-weight-bold">
                          <v-col class="pb-0">
                            <span class="text-uppercase">
                              RRP
                            </span>
                          </v-col>
                          <v-col class="text-right py-0">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-row>
                                  <v-spacer></v-spacer>
                                  <v-col>
                                    <v-currency-field
                                      v-if="editMode"
                                      v-model="editedItem.retailPrice"
                                      :prefix="editedItem.currency.symbol"
                                      :rules="requiredRule"
                                      locale="en-gb"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      class="right-text"
                                    ></v-currency-field>
                                    <span v-else>
                                      {{
                                        editedItem.retailPrice
                                          | currency(
                                            editedItem.currency.symbol,
                                            2
                                          )
                                      }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <span class="text-uppercase">
                              Applied Multiplier
                            </span>
                          </v-col>
                          <v-col class="text-right font-weight-bold">
                            <span>{{ rrpMultiplier.toFixed(2) }}</span
                            ><span class="overline">x</span>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col>
                            <span class="text-uppercase">
                              Internal Gross Retail Profit (Margin)
                            </span>
                          </v-col>
                          <v-col class="text-right font-weight-bold">
                            {{
                              (internalProfit / 100)
                                | currency(editedItem.currency.symbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}
                            ({{ internalMargin.toFixed(2) }}%)
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
              <div v-else>
                <message-box
                  icon="mdi-emoticon-neutral-outline"
                  title="No Price Selected"
                  caption="Select a price to view the details"
                ></message-box>
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Results"
                caption="You have no prices setup yet"
              >
              </message-box>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <message-box
        class="mt-8"
        height="150px"
        icon="mdi-coffee-outline"
        title="Loading..."
        caption="Sit back while we load your data"
      >
      </message-box>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-skeleton-loader
            v-for="line in 3"
            :key="line"
            type="list-item-two-line"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import Dinero from 'dinero.js'
import MessageBox from '@/components/core/MessageBox.vue'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import PRICE_MARKUP_LIST from '@/graphql/PriceMarkups.gql'

import PRODUCT_MANUFACTURER_LIST from '@/graphql/ProductManufacturers.gql'
import PRODUCT_PRICE_CREATE from '@/graphql/ProductPriceCreate.gql'
import PRODUCT_PRICE_UPDATE from '@/graphql/ProductPriceUpdate.gql'
import PRODUCT_PRICE_REMOVE from '@/graphql/ProductPriceRemove.gql'
import SEASON_PRODUCT from '@/graphql/SeasonProduct.gql'
import { getName } from 'country-list'

export default {
  name: 'ProductionProductPricing',
  components: { MessageBox },
  props: {
    selectedSeason: { type: String, default: '' },
    // product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} }
  },
  data: () => ({
    dialog: false,
    removeDialog: false,
    removeableItem: false,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v.id || 'Field is required'],
    processing: false,
    editMode: false,
    selectedIdx: null,
    selectedManufacturer: null,
    productManufacturers: [],
    defaultItem: { wholesalePrice: 0, retailPrice: 0 },
    editedItem: { wholesalePrice: 0, retailPrice: 0 },
    masterItem: null,
    activeItem: null,
    newItem: null,
    addItem: false,
    errorMsg: '',
    wholesaleMarkup: { multiplier: 0, markupPercentage: 0, margin: 0 },
    retailMarkup: { multiplier: 0, markupPercentage: 0, margin: 0 }
    // totalCost: 0
  }),
  apollo: {
    seasonProduct: {
      query: SEASON_PRODUCT,
      variables() {
        return {
          id: this.$route.params.productID,
          seasonID: this.selectedSeason
        }
      },
      // fetchPolicy: 'no-cache',
      fetchPolicy: 'network-only',
      skip() {
        return !this.selectedSeason
      }
    },
    priceMarkups: PRICE_MARKUP_LIST,
    currencies: { query: CURRENCY_LIST, fetchPolicy: 'network-only' },
    productManufacturers: {
      query: PRODUCT_MANUFACTURER_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    }
  },
  computed: {
    pricingAuthLevel() {
      return this.$store.getters.authLevel('product_pricing')
    },
    product() {
      if (this.seasonProduct) {
        if (!this.seasonProduct.prices) {
          this.newItem = this._.find(this.currencies, e => e.isoName === 'EUR')
        }

        return this.seasonProduct
      }
    },
    currencyList() {
      return this.currencies
    },
    unusedCurrencies() {
      if (this.product?.prices?.length > 0) {
        var prices = this._.cloneDeep(this.currencies)
        this.product.prices.forEach(price => {
          this._.remove(prices, function(el) {
            return el.id === price.currency?.id
          })
        })
        return prices
      } else {
        return this.currencies
      }
    },
    manufacturerList() {
      this.setSelectedManufacturer()
      return this.productManufacturers
    },
    wholesaleMarkupList() {
      return this._.filter(this.priceMarkups, function(item) {
        return item.markupType === 'WHOLESALE'
      })
    },
    retailMarkupList() {
      return this._.filter(this.priceMarkups, function(item) {
        return item.markupType === 'RETAIL'
      })
    },
    selectedCosting() {
      var that = this
      let costing

      if (this.selectedManufacturer && this.selectedManufacturer.costs) {
        costing = this._.find(this.selectedManufacturer.costs, function(item) {
          return item.seasonID === that.selectedSeason
        })
      }
      return costing
    },
    wholeSaleMarkup() {
      if (this.selectedCosting) {
        return this.totalCost * this.wholesaleMarkup.multiplier - this.totalCost
      } else {
        return 0
      }
    },
    suggestedWholesalePrice() {
      return this.totalCost + this.wholeSaleMarkup
    },
    netProfit() {
      return this.editedItem.wholesalePrice * 100 - this.totalCost
    },
    margin() {
      let wPrice = this.editedItem.wholesalePrice * 100

      if (wPrice > 0 && this.totalCost > 0) {
        return (100 * (wPrice - this.totalCost)) / wPrice
      } else {
        return 0
      }
    },
    suggestedRetailPrice() {
      return this.editedItem.wholesalePrice * 100 * this.retailMarkup.multiplier
    },
    internalProfit() {
      return this.editedItem.retailPrice * 100 - this.totalCost
    },
    internalMargin() {
      let rrPrice = this.editedItem.retailPrice * 100

      if (rrPrice > 0 && this.totalCost > 0) {
        return (100 * (rrPrice - this.totalCost)) / rrPrice
      } else {
        return 0
      }
    },
    rrpMultiplier() {
      let rrPrice = this.editedItem.retailPrice * 100
      let wPrice = this.editedItem.wholesalePrice * 100

      if (rrPrice > 0 && wPrice > 0) {
        return rrPrice / wPrice
      } else {
        return 0
      }
    },
    wholesalePriceBelowRecommended() {
      if (this.editedItem && this.editedItem.wholesalePrice) {
        return (
          this.editedItem.wholesalePrice * 100 < this.suggestedWholesalePrice
        )
      } else {
        return false
      }
    },
    firstLoad() {
      this.$nextTick(() => {
        this.setSelectedManufacturer()
        this.setCostItem()
      })

      return this.product
    },
    totalCost() {
      let total = 0
      if (this.productManufacturers && this.productManufacturers.length > 0) {
        total = this.calcTotalCost()
      }

      return total
    }
  },
  watch: {
    addItem() {
      this.calcTotalCost()
    },
    selectedManufacturer() {
      if (this.editedItem.currency) {
        this.calcTotalCost()
      }
    },
    selectedIdx(idx) {
      // var that = this
      if (
        idx > -1 &&
        this.product &&
        this.product.prices &&
        this.product.prices.length > 0
      ) {
        this.editedItem = this._.cloneDeep(this.product.prices[idx])
        this.editedItem.wholesalePrice = this.editedItem.wholesalePrice / 100
        this.editedItem.retailPrice = this.editedItem.retailPrice / 100
        this.setMarkups()
      }
    },
    selectedSeason() {
      this.editedItem = this._.cloneDeep(this.defaultItem)
      if (this.editMode) this.cancelEdit()
      this.$apollo.queries.seasonProduct.refetch()

      // if (!this.product.prices || this.product.prices.length === 0) {
      //   this.editedItem = this._.cloneDeep(this.defaultItem)
      //   this.editMode = false
      // }
      // var productID = this.$route.params.productID
      // this.$apollo.queries.seasonProduct.refetch({
      //   id: productID,
      //   seasonID: id
      // })

      // this.setCostItem()
      // this.setSelectedManufacturer()
      // this.calcTotalCost()
      // this.setMarkups()
    },
    product(val) {
      if (val) {
        this.setCostItem()
        this.setSelectedManufacturer()
        this.calcTotalCost()
        this.setMarkups()
      }
    }
  },
  created() {
    // this.$apollo.queries.productManufacturers.refetch()
    if (this.product) {
      this.setCostItem()
      this.setSelectedManufacturer()
      this.calcTotalCost()
      this.setMarkups()
    }
  },
  mounted() {},
  methods: {
    setCostItem() {
      let val = this.product
      if (
        val &&
        val.prices &&
        val.prices.length > 0 &&
        !this.editMode &&
        !this.addItem &&
        !this.selectedIdx &&
        !this.editedItem.id
      ) {
        this.selectedIdx = 0
      }
    },
    setDefaultMarkups() {
      let wholesale = this._.find(this.wholesaleMarkupList, function(item) {
        return item.defaultMarkup
      })

      let retail = this._.find(this.retailMarkupList, function(item) {
        return item.defaultMarkup
      })

      if (wholesale) this.wholesaleMarkup = wholesale
      if (retail) this.retailMarkup = retail
    },
    setMarkups() {
      var that = this
      if (this.editedItem.currency) {
        this.wholesaleMarkup = this._.find(this.wholesaleMarkupList, function(
          item
        ) {
          return item.id === that.editedItem.wholesaleMarkupID
        })

        this.retailMarkup = this._.find(this.retailMarkupList, function(item) {
          return item.id === that.editedItem.retailMarkupID
        })
      }
    },
    defaultCurrency() {
      return this._.find(this.currencies, function(item) {
        return item.defaultCurrency === true
      })
    },
    calcTotalCost() {
      var vm = this
      let costing = 0
      var currencyID
      if (this.editedItem && this.editedItem.currency) {
        currencyID = this.editedItem.currency.id
      }

      var selectedCostingCurrency

      if (this.selectedCosting && this.selectedCosting.currency) {
        selectedCostingCurrency = this._.find(this.currencies, function(item) {
          return item.id === vm.selectedCosting.currency.id
        })
      }

      if (
        currencyID &&
        this.selectedCosting &&
        this.selectedCosting.currency &&
        this.selectedCosting.currency.id === currencyID
      ) {
        costing = this.selectedCosting.totalCost
      } else if (
        currencyID &&
        this.selectedCosting &&
        this.selectedCosting.currency &&
        this.selectedCosting.currency.id !== currencyID
      ) {
        var baseCurrencyCostingAmount = 0
        var reportingCurrencyCostingAmount = 0

        if (this.selectedCosting.currency.id === this.defaultCurrency.id) {
          baseCurrencyCostingAmount =
            this.selectedCosting.totalCost *
            selectedCostingCurrency.productionExchangeRate

          reportingCurrencyCostingAmount = baseCurrencyCostingAmount
        } else {
          baseCurrencyCostingAmount =
            this.selectedCosting.totalCost *
            selectedCostingCurrency.productionExchangeRate

          reportingCurrencyCostingAmount =
            baseCurrencyCostingAmount /
            this.editedItem.currency.productionExchangeRate
        }

        costing = Math.floor(reportingCurrencyCostingAmount)
      }

      return costing
    },
    setWholesalePriceFromRRP() {
      if (
        this.editedItem.retailPrice &&
        this.editedItem.retailPrice !== 0 &&
        this.retailMarkup &&
        this.retailMarkup.multiplier !== 0
      ) {
        this.editedItem.wholesalePrice =
          this.editedItem.retailPrice / this.retailMarkup.multiplier
      }
    },
    setPriceToSuggestedMarkups() {
      if (
        this.editedItem &&
        this.wholesaleMarkup &&
        this.wholesaleMarkup.multiplier > 0 &&
        this.retailMarkup &&
        this.retailMarkup.multiplier > 0
      ) {
        this.editedItem.wholesalePrice = (
          (this.totalCost * this.wholesaleMarkup.multiplier) /
          100
        ).toFixed(2)
        this.editedItem.retailPrice = (
          this.editedItem.wholesalePrice * this.retailMarkup.multiplier
        ).toFixed(2)
      }
    },
    setSelectedPricing(idx) {
      if (!idx || idx === -1) {
        this.selectedIdx = 0
      } else {
        this.selectedIdx = idx
      }
    },
    setSelectedManufacturer(item) {
      if (item) {
        this.selectedManufacturer = item
      } else if (
        this.productManufacturers &&
        this.productManufacturers.length > 0
      ) {
        this.selectedManufacturer = this.productManufacturers[0]
      } else {
        this.selectedManufacturer = null
      }
    },
    getCountryName(item) {
      return getName(item)
    },
    openRemoveDialog(item) {
      this.removeableItem = item
      this.removeDialog = true
    },
    closeDialogs() {
      this.newItem = false
      this.addItem = false
      this.dialog = false

      this.removeableItem = null
      this.removeDialog = false
    },
    clearEmptyEditItem() {
      this.addItem = false
      this.newItem = false
      // if (!this.editedItem.id) {
      //   this.editedItem = this._.cloneDeep(this.defaultItem)
      // }
      // if (this.masterItem) {
      //   this.editedItem = this._.cloneDeep(this.masterItem)
      //   this.masterItem = null
      // }
    },
    cancelEdit() {
      this.$refs.pricingForm.resetValidation()
      this.addItem = false
      this.newItem = false
      if (this.masterItem && this.masterItem.id) {
        this.editedItem = this._.cloneDeep(this.masterItem)
        this.masterItem = null
      } else if (
        this.product &&
        this.product.prices &&
        this.product.prices.length > 0 &&
        !this.selectedIdx
      ) {
        this.selectedIdx = 0
      } else {
        this.editedItem = this._.cloneDeep(this.defaultItem)
        this.selectedIdx = null
      }

      // this.clearEmptyEditItem()
      this.editMode = false
      this.errorMsg = ''
    },
    editPricing(item) {
      this.masterItem = this._.cloneDeep(item)
      this.editMode = true
    },
    addNewCurrency() {
      this.editedItem = {}
      this.editedItem.currency = this._.cloneDeep(this.newItem)
      this.editedItem.productID = this.$route.params.productID
      this.editedItem.seasonID = this.$route.query.season
      this.setDefaultMarkups()
      this.editMode = true
      this.addItem = true
      this.dialog = false
    },
    createBulkPricing(data) {
      var result = []
      result.push(data)

      if (!this.product.prices) {
        // Add other currencies
        var otherCurrencies = this._.filter(this.currencies, function(el) {
          return (
            el.isoName === 'USD' || el.isoName === 'GBP' || el.isoName === 'CAD'
          )
        })

        otherCurrencies.forEach(item => {
          let rrp = Math.ceil(Math.round(data.retailPrice / item.productionExchangeRate) / 500) * 500
          let rwp = Math.round(rrp / data.retailMarkup)

          result.push({
            productID: data.productID,
            seasonProductID: data.seasonProductID,
            seasonID: data.seasonID,
            currencyID: item.id,
            notes: data.notes,
            wholesaleMarkupID: data.wholesaleMarkupID,
            wholesaleMarkup: data.wholesaleMarkup,
            retailMarkupID: data.retailMarkupID,
            retailMarkup: data.retailMarkup,
            wholesalePrice: rwp,
            retailPrice: rrp
          })
        })
      }

      return result
    },
    savePricing() {
      // var that = this
      this.errorMsg = ''

      if (this.$refs.pricingForm.validate()) {
        var data = {
          id: this.editedItem.id,
          productID: this.editedItem.productID,
          seasonProductID: this.editedItem.seasonProductID
            ? this.editedItem.seasonProductID
            : null,
          seasonID: this.editedItem.seasonID,
          currencyID: this.editedItem.currency.id,
          notes: this.editedItem.notes,
          wholesaleMarkupID: this.wholesaleMarkup.id
            ? this.wholesaleMarkup.id
            : null,
          wholesaleMarkup: this.wholesaleMarkup.multiplier || 0,
          retailMarkupID: this.retailMarkup.id ? this.retailMarkup.id : null,
          retailMarkup: this.retailMarkup.multiplier || 0,
          wholesalePrice: parseInt(this.editedItem.wholesalePrice * 100),
          retailPrice: parseInt(this.editedItem.retailPrice * 100)
        }

        var bulk = this.createBulkPricing(data)

        this.processing = true
        if (data.id) {
          delete data.currencyID
          // Edit item
          this.$apollo
            .mutate({
              mutation: PRODUCT_PRICE_UPDATE,
              variables: {
                input: data
              }
            })
            .then(result => {
              // this.setEditedItem()
              // set the selectedIdx
              let idx = this._.findIndex(this.product.prices, function(item) {
                return item.id === result.data.updateProductPrice.id
              })

              this.selectedIdx = idx

              this.newItem = false
              this.addItem = false

              this.editMode = false
              this.errorMsg = ''

              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Price Updated'
              })
              this.processing = false
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        } else {
          var that = this
          that._.each(bulk, function(data) {
            // Save new item
            delete data.id
            delete data.seasonProductID

            that.$apollo
              .mutate({
                mutation: PRODUCT_PRICE_CREATE,
                variables: {
                  input: data
                },
                update: (store, { data: { createProductPrice } }) => {
                  // Read the data from our cache for this query.
                  const data = store.readQuery({
                    query: SEASON_PRODUCT,
                    variables: {
                      id: createProductPrice.productID,
                      seasonID: createProductPrice.seasonID
                    }
                  })

                  // Add our price from the mutation to the end
                  if (data.seasonProduct.prices) {
                    data.seasonProduct.prices.push(createProductPrice)
                  } else {
                    data.seasonProduct.prices = []
                    data.seasonProduct.prices.push(createProductPrice)
                  }

                  // Write our data back to the cache.
                  store.writeQuery({
                    query: SEASON_PRODUCT,
                    variables: {
                      id: createProductPrice.productID,
                      seasonID: createProductPrice.seasonID
                    },
                    data
                  })
                }
              })
              .then(result => {
                // this.$emit('change-state')
                // this.$apollo.queries.seasonProduct.refresh()
                // this.editedItem = result.data.createProductPrice

                // set the selectedIdx
                let idx = that._.findIndex(that.product.prices, function(item) {
                  return item.id === result.data.createProductPrice.id
                })

                that.selectedIdx = idx

                that.newItem = null
                that.addItem = false

                that.editMode = false
                that.errorMsg = ''
                that.$store.commit('showSnackbar', {
                  active: true,
                  message: 'Price Saved'
                })
                that.processing = false
                // this.$apollo.queries.productManufacturers.refetch()
                that.$emit('change-state')
              })
              .catch(() => {
                that.errorMsg = 'Something went wrong.'
                that.processing = false
              })
          })
        }
      }
    },

    removePrice() {
      this.processing = true

      if (this.removeableItem.id) {
        this.$apollo
          .mutate({
            mutation: PRODUCT_PRICE_REMOVE,
            variables: {
              id: this.removeableItem.id
            }
            // update: (store, { data: { removeProductPrice } }) => {
            //   // Read the data from our cache for this query.
            //   const data = store.readQuery({
            //     query: SEASON_PRODUCT,
            //     variables: {
            //       id: vm.$route.params.productID,
            //       seasonID: vm.$route.query.season
            //     }
            //   })

            //   // Add our requirement from the mutation to the end
            //   let idx = vm._.findIndex(data.seasonProduct.prices, function(e) {
            //     return e.id === removeProductPrice
            //   })

            //   data.seasonProduct.prices.splice(idx, 1)

            //   // Write our data back to the cache.
            //   store.writeQuery({
            //     query: SEASON_PRODUCT,
            //     variables: {
            //       id: vm.$route.params.productID,
            //       seasonID: vm.$route.query.season
            //     },
            //     data
            //   })
            // }
          })
          .then(() => {
            this.$emit('change-state')
            // this.$apollo.queries.seasonProduct.refetch()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Price Removed'
            })

            if (!this.product.prices || this.product.prices.length === 0) {
              this.editedItem = this._.cloneDeep(this.defaultItem)
            } else {
              this.selectedIdx = 0
            }

            this.removeDialog = false
            this.processing = false
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong'
            })
            this.removeableItem = null
            this.processing = false

            this.removeDialog = false
          })
      } else {
        this.removeableItem = null
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Something went wrong'
        })

        this.removeDialog = false
        this.processing = false
      }
    }
  }
}
</script>

<style lang="scss">
.hightlight-control {
  &.v-text-field--outlined.v-input--dense .v-label--active {
    color: var(--v-info-base) !important;
  }
  .v-select > .v-input__control > .v-input__slot {
    >>> .v-icon {
      color: var(--v-info-base) !important;
      caret-color: var(--v-info-base) !important;
    }
  }

  &.v-text-field--outlined fieldset {
    border: 2px solid var(--v-info-base) !important;
  }

  &.v-text-field--outlined {
    .v-select__slot {
      .v-input__append-inner {
        .v-input__icon.v-input__icon--append {
          .v-icon {
            color: var(--v-info-base) !important;
            caret-color: var(--v-info-base) !important;
          }
        }
      }
    }
  }

  // .theme--light.v-input--is-disabled {
  //   color: rgba(0, 0, 0, 0.87) !important;
  //   .v-text-field--outlined {
  //     transition: none;
  //   }
  //  }
}
</style>
