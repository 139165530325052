<template>
  <div class="page-header">
    <!-- Dialogs -->
    <v-dialog v-model="finalizeStyleDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Finalize Style</v-card-title
        >
        <v-card-text>
          <p class="font-weight-medium">
            This will finalize the style and lock it from further changes.
          </p>
          <p>
            Please make sure all design and production steps have been finalized
            before changing the status to final.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeDialogs"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="finalizeStyle"
            >Finalize</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendProductDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Send To Warehouse Providers</v-card-title
        >
        <v-card-text>
          <p class="font-weight-medium">
            This will force (re)send the product to the warehouse providers.
          </p>
          <p>
            Use this after you made some small changes to the Style or if the Style does not exist on the Warehouse provider system.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            rounded
            :disabled="processing"
            @click="closeDialogs"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="sendProduct"
            >Send</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelStyleDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Cancel Style</v-card-title
        >
        <v-card-text>
          <p class="font-weight-medium">
            This will cancel the style and lock it from further changes.
          </p>
          <p>
            This will also set the product to the canceled state and lock it
            from further changes.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            text
            small
            :disabled="processing"
            color="grey darken-1"
            @click="closeDialogs"
            >Do Not Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="error lighten-1"
            text
            small
            @click="cancelStyle"
            >Cancel Style</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="finalizeProductDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Finalize Product</v-card-title
        >
        <v-card-text>
          <p class="font-weight-medium">
            This will finalize the product and send if for review. The product
            will also be locked from further changes.
          </p>
          <p>
            Please make sure all cost and pricing steps have been finalized
            before submiting the product for review.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeDialogs"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="finalizeProduct"
            >Request Review</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelProductDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Cancel Product</v-card-title
        >
        <v-card-text>
          <p class="font-weight-medium">
            This will cancel the product and lock it from further changes.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            small
            :disabled="processing"
            @click="closeDialogs"
            >Do Not Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="error lighten-1"
            text
            small
            @click="cancelProduct"
            >Cancel Product</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="product && product.productState === 'REVIEW' && currentApprover"
      v-model="submitReviewDialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Product Review</v-card-title
        >
        <v-card-text>
          <v-form
            ref="reviewForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <p>Great, you're about to submit your review for this product.</p>

            <v-row class="mb-3">
              <v-col cols="12" class="pb-3">
                <label>Decission</label>
              </v-col>
              <v-col cols="12" class="py-0 pb-3">
                <v-select
                  v-model="reviewItem.status"
                  :items="approvalStates"
                  :rules="requiredRule"
                  outlined
                  autocomplete
                  item-value="id"
                  item-text="name"
                  hide-details="auto"
                  dense
                />
                <p
                  v-if="reviewItem && reviewItem.status === 'rejected'"
                  class="error--text text--darken-1 mb-0 pl-2 pt-2 pr-2"
                >
                  Beware any rejection is final. If you want to let the
                  requestor make changes please select
                  <strong>"Pending Changes"</strong>
                </p>
              </v-col>
            </v-row>
            <v-row class="mb-3">
              <v-col cols="12" class="pb-3">
                <label>Comments</label>
              </v-col>
              <v-col cols="12" class="py-0 pb-3">
                <vue-editor
                  v-if="product"
                  id="edit-review-comments"
                  v-model="reviewItem.comments"
                  :editor-options="editorConfig"
                  placeholder="Please specify the reasoning behind your decission."
                  class="pb-3"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn color="grey " small depressed text @click="closeDialogs"
            >Cancel</v-btn
          >
          <v-btn color="primary lighten-2" small rounded @click="submitReview"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unlockStyleDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Unlock Style</v-card-title
        >
        <v-card-text>
          <p class="font-weight-medium">
            This will unlock the style for editing so you can make further
            changes.
          </p>
          <p>Please make sure you change the state again when done.</p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeDialogs"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="unlockStyle"
            >Unlock</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unlockProductDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Unlock Product</v-card-title
        >
        <v-card-text>
          <p class="font-weight-medium">
            This will unlock the product for editing so you can make further
            changes.
          </p>
          <p>Please make sure you change the state again when done.</p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeDialogs"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="unlockProduct"
            >Unlock</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /Dialogs -->

    <!-- Toolbar -->
    <v-toolbar v-if="product" class="mb-0 b-radius" extended flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="pl-0">
            <v-row>
              <v-col cols="8" lg="9" xl="10">
                <h2 class="mb-0 text-truncate primary--text">
                  {{ product.name }}
                </h2>
                <div>
                  <v-tooltip bottom :open-delay="1000">
                    <template v-slot:activator="{ on }">
                      <v-chip outlined small color="grey" class="mr-2" v-on="on"
                        ><span class="pr-2">style:</span>
                        <span
                          v-if="
                            product &&
                            product.styleState &&
                            product.styleState === 'DEVELOPMENT'
                          "
                          class="info--text font-weight-bold"
                          >{{ product.styleState }}</span
                        >
                        <span
                          v-if="
                            product &&
                            product.styleState &&
                            product.styleState === 'REVIEW'
                          "
                          class="warning--text font-weight-bold"
                          >{{ product.styleState }}</span
                        >
                        <span
                          v-if="
                            product &&
                            product.styleState &&
                            product.styleState === 'FINAL'
                          "
                          class="success--text font-weight-bold"
                          >{{ product.styleState }}</span
                        >
                        <span
                          v-if="
                            product &&
                            product.styleState &&
                            product.styleState === 'CANCELED'
                          "
                          class="error--text text--lighten-2 font-weight-bold"
                          >{{ product.styleState }}</span
                        >
                      </v-chip>
                    </template>
                    <span>Style State</span>
                  </v-tooltip>

                  <v-tooltip bottom :open-delay="1000">
                    <template v-slot:activator="{ on }">
                      <v-chip outlined small color="grey" v-on="on"
                        ><span class="pr-2">product:</span>
                        <span
                          v-if="
                            product &&
                            product.productState &&
                            product.productState === 'OPEN'
                          "
                          class="info--text font-weight-bold"
                          >{{ product.productState }}</span
                        >
                        <span
                          v-if="
                            product &&
                            product.productState &&
                            product.productState === 'REVIEW'
                          "
                          class="warning--text font-weight-bold"
                          >{{ product.productState }}</span
                        >
                        <span
                          v-if="
                            product &&
                            product.productState &&
                            product.productState === 'FINAL'
                          "
                          class="success--text font-weight-bold"
                          >{{ product.productState }}</span
                        >
                        <span
                          v-if="
                            product &&
                            product.productState &&
                            product.productState === 'CANCELED'
                          "
                          class="error--text text--lighten-2 font-weight-bold"
                          >{{ product.productState }}</span
                        >
                      </v-chip>
                    </template>
                    <span>Product State</span>
                  </v-tooltip>
                  <!-- <v-chip label x-small color="info" class="mr-2"
                    >Development<v-avatar right> 50% </v-avatar></v-chip
                  >
                  <v-chip label x-small color="info" class="mr-2">
                    Costing <v-avatar right> 40% </v-avatar></v-chip
                  >
                  <v-chip label x-small color="info" class="mr-2"
                    >Pricing<v-avatar right> 0% </v-avatar></v-chip
                  > -->
                </div>
              </v-col>
              <v-spacer></v-spacer>

              <v-col cols="4" lg="3" xl="2">
                <v-select
                  :value="selectedSeason"
                  hide-details
                  outlined
                  flat
                  dense
                  label="season"
                  placeholder="Select Season"
                  item-value="id"
                  class="mt-3"
                  :items="product.availableSeasons"
                  :disabled="
                    !product.availableSeasons ||
                    product.availableSeasons.length === 0
                  "
                  @change="changeSeason"
                >
                  <template v-slot:item="{ item }">
                    {{ item.longName }} ({{ item.shortName }})
                  </template>
                  <template v-slot:selection="{ item }">
                    <div class="text-truncate">{{ item.shortName }}</div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="selectedSeason" v-slot:extension>
        <v-tabs
          style="width: 80%"
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            :to="{
              name: 'ProductionProductDetail',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            exact
            :disabled="!selectedSeason"
            ><v-badge
              color="pink lighten-2"
              dot
              :value="!product || !product.sizes || product.sizes.length === 0"
              >Details</v-badge
            ></v-tab
          >

          <v-tab
            v-if="techPackAuthLevel > 0"
            :to="{
              name: 'ProductionProductDesignAssets',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
          >
            <v-badge
              color="pink lighten-2"
              dot
              :value="!techPacks || techPacks.length === 0"
            >
              Tech Packs
            </v-badge>
          </v-tab>

          <v-tab
            v-if="styleSpecAuthLevel > 0"
            :to="{
              name: 'ProductionProductProductionAssets',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
          >
            <v-badge
              color="pink lighten-2"
              dot
              :value="!stylePacks || stylePacks.length === 0"
            >
              Style Specs
            </v-badge></v-tab
          >

          <v-tab
            v-if="manufacturerAuthLevel > 0"
            :to="{
              name: 'ProductionProductManufacturers',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge
              color="pink lighten-2"
              dot
              :value="
                !productManufacturers || productManufacturers.length === 0
              "
              >Manufacturers</v-badge
            ></v-tab
          >

          <v-tab
            v-if="materialAuthLevel > 0"
            :to="{
              name: 'ProductionProductMaterials',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge
              color="pink lighten-2"
              dot
              :value="!productMaterials || productMaterials.length === 0"
              >Materials</v-badge
            ></v-tab
          >

          <v-tab
            v-if="logisticsAuthLevel > 0"
            :to="{
              name: 'ProductionProductCustoms',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge color="pink lighten-2" dot :value="customCheck"
              >Logistics/Customs</v-badge
            ></v-tab
          >

          <v-tab
            v-if="costingAuthLevel > 0"
            :to="{
              name: 'ProductionProductCosts',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge color="info" dot :value="costCheck">Costs</v-badge></v-tab
          >

          <v-tab
            v-if="pricingAuthLevel > 0"
            :to="{
              name: 'ProductionProductPricing',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            ><v-badge color="info" dot :value="priceCheck"
              >Pricing</v-badge
            ></v-tab
          >

          <v-tab
            v-if="imageAuthLevel > 0"
            :to="{
              name: 'ProductionProductImages',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Images</v-tab
          >

          <v-tab
            v-if="inventoryAuthLevel > 0"
            :to="{
              name: 'ProductionProductInventory',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Inventory</v-tab
          >

          <v-tab
            v-if="seasonAuthLevel > 0"
            :to="{
              name: 'ProductionProductSeasons',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Seasons</v-tab
          >

          <v-tab
            v-if="authFlowAuthLevel > 0"
            :to="{
              name: 'ProductionProductAuditFlows',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Authorisation</v-tab
          >

          <v-tab
            :to="{
              name: 'ProductionProductChangeLog',
              params: { productID: $route.params.productID },
              query: { season: selectedSeason },
            }"
            class="text-capitalize"
            :disabled="!selectedSeason"
            >Change Log</v-tab
          >
        </v-tabs>

        <v-spacer v-if="product && authLevel && authLevel === 2"></v-spacer>

        <v-menu
          v-if="product && developmentAuthLevel && developmentAuthLevel === 2"
          close-on-content-click
        >
          <template v-slot:activator="{ on }">
            <v-btn color="primary lighten-2" small rounded v-on="on"
              >Actions</v-btn
            >
          </template>
          <v-card width="300">
            <v-row class="px-2">
              <!-- Style Actions -->
              <v-col cols="12">
                <div
                  class="px-4 body-2 font-weight-medium"
                  style="height: auto"
                >
                  Style
                </div>
                <div class="caption grey--text mt-1 mb-2 px-4">
                  Make changes to the style state
                </div>
                <v-alert
                  v-if="styleCheck"
                  type="info"
                  dense
                  outlined
                  class="caption"
                >
                  Correct the pink dots to finalize.
                </v-alert>
                <v-alert
                  v-if="customCheck && !styleCheck"
                  type="info"
                  dense
                  outlined
                  class="caption"
                >
                  Missing customs information.
                </v-alert>

                <v-btn
                  v-if="
                    product &&
                    product.styleState === 'DEVELOPMENT' &&
                    !customCheck
                  "
                  text
                  color="info darken-1"
                  @click="finalizeStyleDialog = true"
                >
                  Finalize Style
                </v-btn>
                <v-btn
                  v-if="
                    product &&
                    product.productState === 'FINAL' &&
                    product.styleState === 'FINAL'
                  "
                  text
                  color="info darken-1"
                  @click="sendProductDialog = true"
                >
                  Send To Warehouse Providers
                </v-btn>
                <v-btn
                  v-if="product && product.styleState === 'DEVELOPMENT'"
                  text
                  color="grey lighten-1"
                  @click="cancelStyleDialog = true"
                >
                  Cancel Style
                </v-btn>
                <v-btn
                  v-if="product && product.styleState !== 'DEVELOPMENT'"
                  text
                  color="info darken-1"
                  @click="unlockStyleDialog = true"
                >
                  Unlock Style
                </v-btn>
              </v-col>
              <v-col cols="12"
                ><v-row><v-divider></v-divider></v-row></v-col
            ></v-row>
            <v-row class="px-2">
              <!-- Product Actions -->
              <v-col cols="12">
                <div
                  class="px-4 body-2 font-weight-medium"
                  style="height: auto"
                >
                  Product
                </div>
                <div class="caption grey--text mt-1 mb-2 px-4">
                  Make changes to the product state
                </div>
                <v-alert
                  v-if="
                    product &&
                    product.productState === 'OPEN' &&
                    product.styleState === 'DEVELOPMENT' &&
                    (costCheck || priceCheck)
                  "
                  type="info"
                  dense
                  outlined
                  class="caption"
                >
                  Finalize Style and correct the blue dots to finalize.
                </v-alert>

                <v-btn
                  v-if="
                    product &&
                    product.productState === 'OPEN' &&
                    product.styleState === 'FINAL' &&
                    !costCheck &&
                    !priceCheck
                  "
                  text
                  color="info darken-1"
                  @click="finalizeProductDialog = true"
                >
                  Finalize Product
                </v-btn>
                <v-btn
                  v-if="product && product.productState !== 'OPEN'"
                  text
                  color="info darken-1"
                  @click="unlockProductDialog = true"
                >
                  Unlock Product
                </v-btn>
                <v-btn
                  v-if="product && product.productState !== 'CANCELED'"
                  text
                  color="grey"
                  @click="cancelProductDialog = true"
                >
                  Cancel Product
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-divider
              v-if="
                product &&
                  product.productState &&
                  product.productState === 'REVIEW' &&
                  currentApprover
              "
              class="mt-2"
            ></v-divider>
            <v-row
              v-if="
                product &&
                  product.productState &&
                  product.productState === 'REVIEW' &&
                  currentApprover
              "
              class="px-3"
            >
              <v-col>
                <v-btn
                  block
                  rounded
                  color="primary lighten-2"
                  @click="submitReviewDialog = true"
                  >Submit Review</v-btn
                >
              </v-col>
            </v-row> -->
          </v-card>
        </v-menu>

        <slot name="controls"> </slot>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
import STYLE_FINALIZE from '@/graphql/StyleFinalize.gql'
import STYLE_CANCEL from '@/graphql/StyleCancel.gql'
import STYLE_UNLOCK from '@/graphql/StyleUnlock.gql'
import PRODUCT_CANCEL from '@/graphql/ProductCancel.gql'
import PRODUCT_UNLOCK from '@/graphql/ProductUnlock.gql'
import PRODUCT_FINALIZE from '@/graphql/ProductFinalize.gql'
import PRODUCT_REVIEW from '@/graphql/ProductReview.gql'
import MANUAL_SEND_PRODUCT_TO_WAREHOUSE from '@/graphql/ManualSendProductToWarehouse.gql'

export default {
  name: 'ProductDetailHeader',
  props: {
    product: { type: Object, default: () => {} },
    productAssets: { type: Array, default: () => [] },
    productMaterials: { type: Array, default: () => [] },
    productManufacturers: { type: Array, default: () => [] },
    selectedSeason: { type: String, default: null },
    authLevel: { type: Number, default: () => 0 },
  },
  data() {
    return {
      valid: false,
      approvalStates: [
        { id: 'approved', name: 'Approved' },
        { id: 'pendingchanges', name: 'Pending Changes' },
        { id: 'rejected', name: 'Rejected' },
      ],
      reviewItem: {
        status: 'approved',
        comments: null,
      },
      editorConfig: {
        modules: {
          toolbar: false,
        },
      },
      requiredRule: [
        (v) => !!v || 'Field is required',
        (v) => !/^\s+$/.test(v) || 'Field is required',
      ],

      approvalDialog: false,
      processing: false,
      activeSeason: this.selectedSeason,
      finalizeStyleDialog: false,
      cancelStyleDialog: false,
      unlockStyleDialog: false,
      finalizeProductDialog: false,
      cancelProductDialog: false,
      unlockProductDialog: false,
      submitReviewDialog: false,
      sendProductDialog: false
    }
  },
  computed: {
    salesAuthLevel() {
      return this.$store.getters.authLevel('product_sales_details')
    },
    designAuthLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    developmentAuthLevel() {
      return this.$store.getters.authLevel('product_development_details')
    },
    logisticsAuthLevel() {
      return this.$store.getters.authLevel('product_logistics')
    },
    techPackAuthLevel() {
      return this.$store.getters.authLevel('product_tech_pack')
    },
    styleSpecAuthLevel() {
      return this.$store.getters.authLevel('product_style_spec')
    },
    manufacturerAuthLevel() {
      return this.$store.getters.authLevel('product_manufacturer')
    },
    materialAuthLevel() {
      return this.$store.getters.authLevel('product_material')
    },
    costingAuthLevel() {
      return this.$store.getters.authLevel('product_costing')
    },
    pricingAuthLevel() {
      return this.$store.getters.authLevel('product_pricing')
    },
    imageAuthLevel() {
      return this.$store.getters.authLevel('product_image')
    },
    inventoryAuthLevel() {
      return this.$store.getters.authLevel('product_inventory')
    },
    seasonAuthLevel() {
      return this.$store.getters.authLevel('product_season')
    },
    authFlowAuthLevel() {
      return this.$store.getters.authLevel('product_audit_flow')
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    currentApprover() {
      var that = this
      if (
        that.product &&
        (!that.product.auditFlows || that.product.auditFlows.length === 0)
      ) {
        return null
      } else {
        if (
          that.product &&
          that.product.productState === 'REVIEW' &&
          that.currentUser
        ) {
          var approver = null

          that._.find(that.product.auditFlows, function (item) {
            if (item.status === 'review') {
              approver = that._.find(item.auditors, function (auditor) {
                return auditor.status === 'review'
              })
            }
          })

          if (
            approver &&
            approver.userID === that.currentUser.id &&
            that.currentUser
          ) {
            return approver
          }
        }
        return null
      }
    },
    stylePacks() {
      return this._.find(this.productAssets, function (item) {
        return item.assetType === 'STYLEPACK'
      })
    },
    styleCheck() {
      return (
        !this.product ||
        !this.product.sizes ||
        this.product.sizes.length === 0 ||
        !this.techPacks ||
        this.techPacks.length === 0 ||
        !this.stylePacks ||
        this.stylePacks.length === 0 ||
        !this.productMaterials ||
        this.productMaterials.length === 0
      )
    },
    techPacks() {
      return this._.find(this.productAssets, function (item) {
        return item.assetType === 'TECHPACK'
      })
    },
    customCheck() {
      var check =
        this.product &&
        this.product.customType &&
        (this.product.htsCode || this.product.intrastatCode)
          ? false
          : true

      return check
    },
    costCheck() {
      var that = this

      var items = []

      this._.each(this.productManufacturers, function (item) {
        var cost = that._.find(item.costs, function (child) {
          return child.seasonID === that.selectedSeason
        })

        if (cost) items.push(cost)
      })

      return (
        items === 0 ||
        !that.productManufacturers ||
        that.productManufacturers.length === 0 ||
        items.length === 0
      )

      // return items
    },
    priceCheck() {
      var that = this

      var items = that._.filter(this.product.prices, function (item) {
        return item.seasonID === that.selectedSeason
      })

      return (
        items === 0 ||
        !that.product ||
        !that.product.prices ||
        that.product.prices.length === 0 ||
        items.length === 0 ||
        (items.length > 0 && items.length !== that.product.prices.length)
      )
    },
  },
  methods: {
    changeSeason(id) {
      this.$emit('set-season', id)
    },
    closeDialogs() {
      this.finalizeStyleDialog = false
      this.cancelStyleDialog = false
      this.unlockStyleDialog = false
      this.finalizeProductDialog = false
      this.unlockProductDialog = false
      this.cancelProductDialog = false
      this.submitReviewDialog = false
      this.approvalDialog = false
      this.processing = false
      this.reviewItem = {
        status: 'approved',
        comments: null,
      }
      this.sendProductDialog = false
    },
    sendProduct() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: MANUAL_SEND_PRODUCT_TO_WAREHOUSE,
          variables: {
            productID: this.product.id
          }
        })
        .then(() => {
          this.$emit('change-state')
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Style send to warehouse providers'
          })
        })
        .catch(() => {
          this.closeDialogs()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong'
          })
        })
    },
    finalizeStyle() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: STYLE_FINALIZE,
          variables: {
            productID: this.product.id,
          },
        })
        .then(() => {
          this.$emit('change-state')
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Style Finalized',
          })
        })
        .catch(() => {
          this.closeDialogs()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong',
          })
        })
    },
    cancelStyle() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: STYLE_CANCEL,
          variables: {
            productID: this.product.id,
          },
        })
        .then(() => {
          this.$emit('change-state')
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Style Canceled',
          })
        })
        .catch(() => {
          this.closeDialogs()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong',
          })
        })
    },
    unlockStyle() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: STYLE_UNLOCK,
          variables: {
            productID: this.product.id,
          },
        })
        .then(() => {
          this.$emit('change-state')
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Style Unlocked',
          })
        })
        .catch(() => {
          this.closeDialogs()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong',
          })
        })
    },
    finalizeProduct() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: PRODUCT_FINALIZE,
          variables: {
            seasonProductID: this.product.seasonProductID,
          },
        })
        .then(() => {
          this.$emit('change-state')
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Product Submitted',
          })
        })
        .catch(() => {
          this.closeDialogs()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong',
          })
        })
    },
    cancelProduct() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: PRODUCT_CANCEL,
          variables: {
            seasonProductID: this.product.seasonProductID,
          },
        })
        .then(() => {
          this.$emit('change-state')
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Product Canceled',
          })
        })
        .catch(() => {
          this.closeDialogs()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong',
          })
        })
    },
    submitReview() {
      if (this.$refs.reviewForm.validate()) {
        var data = {
          seasonProductID: this.product.seasonProductID,
          status: this.reviewItem.status,
          comments: this.reviewItem.comments,
        }

        this.$apollo
          .mutate({
            mutation: PRODUCT_REVIEW,
            variables: {
              input: data,
            },
          })
          .then(() => {
            this.$emit('change-state')
            this.closeDialogs()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Product Review Submitted',
            })
          })
          .catch((err) => {
            this.closeDialogs()
            if (
              err.graphQLErrors &&
              err.graphQLErrors[0].message === 'product already reviewed'
            ) {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Product already reviewed',
              })
            } else {
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Something went wrong. Please try again later',
              })
            }
          })
      }
    },
    unlockProduct() {
      this.processing = true
      this.$apollo
        .mutate({
          mutation: PRODUCT_UNLOCK,
          variables: {
            seasonProductID: this.product.seasonProductID,
          },
        })
        .then(() => {
          this.$emit('change-state')
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Product Unlocked',
          })
        })
        .catch(() => {
          this.closeDialogs()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong',
          })
        })
    },
  },
}
</script>

<style lang="scss">
#edit-review-comments {
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  // border: 1px solid var(--v-light-theme-darken3);
  border-width: 1.2px;
  border-radius: 4px;
  // height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
