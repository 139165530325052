<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="customerForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.name"
                          autocomplete="off"
                          label="Name"
                          hide-details="auto"
                          outlined
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.customID"
                          autocomplete="off"
                          label="Custom ID"
                          hint="ID used on another system"
                          persistent-hint
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Priority</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-select
                          v-model="item.priority"
                          :items="priorities"
                          autocomplete="off"
                          label="Priority"
                          hide-details="auto"
                          outlined
                          :rules="requiredSelectRule"
                          dense
                          autofocus
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="!editMode">
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Email</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.email"
                          autocomplete="off"
                          label="Email"
                          hide-details="auto"
                          outlined
                          dense
                          type="email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Phone</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.phone"
                          autocomplete="off"
                          label="Phone"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Website</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.vatRegistration"
                          autocomplete="off"
                          label="VAT ID"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Company Registration</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.companyRegistration"
                          autocomplete="off"
                          label="Company Registration"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                          <label>Payment Term</label>
                        </v-col> -->
                      <v-col cols="12">
                        <v-select
                          v-model="item.paymentTerm"
                          :items="paymentTerms"
                          outlined
                          autocomplete
                          item-value="id"
                          item-text="name"
                          hide-details="auto"
                          return-object
                          dense
                          label="Payment Term"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Currency</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.website"
                          autocomplete="off"
                          label="Website"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="!editMode">
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12">
                    <label>Address</label>
                  </v-col>
                </v-row>
                <v-row v-if="!editMode">
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.line1"
                          autocomplete="off"
                          label="Line 1"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.line2"
                          autocomplete="off"
                          label="Line 2"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="!editMode">
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.line3"
                          autocomplete="off"
                          label="Line 3"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.city"
                          autocomplete="off"
                          label="City"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="!editMode">
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.address.postCode"
                          autocomplete="off"
                          label="Postcode"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="item.address.country"
                          :items="countryList"
                          autocomplete="off"
                          label="Country"
                          hide-details="auto"
                          outlined
                          dense
                          item-text="name"
                          item-value="code"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey lighten-1" :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'CustomerEditDialog',
  props: {
    customer: {
      type: Object,
      required: true,
      default: () => ({})
    },
    paymentTerms: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    priorities: ['low', 'medium', 'high'],
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required']
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Customer' : 'New Customer'
    },
    item() {
      return this._.cloneDeep(this.customer)
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    }
  },
  watch: {},
  methods: {
    close() {
      this.$emit('close-customer-edit-dialog', false)

      // this.$nextTick(() => {
      //   this.$refs.customerForm.resetValidation()
      // })
    },
    save() {
      if (this.$refs.customerForm.validate()) {
        var data = {
          id: this.item.id,
          customID: this.item.customID,
          name: this.item.name,
          priority: this.item.priority,
          website: this.item.website,
          address: {
            id: this.item.address ? this.item.address.id : null,
            email: this.item.address ? this.item.address.email : null,
            phone: this.item.address ? this.item.address.phone : null,
            line1: this.item.address ? this.item.address.line1 : null,
            line2: this.item.address ? this.item.address.line2 : null,
            line3: this.item.address ? this.item.address.line3 : null,
            city: this.item.address ? this.item.address.city : null,
            postCode: this.item.address ? this.item.address.postCode : null,
            country: this.item.address ? this.item.address.country : null
          },
          companyRegistration: this.item.companyRegistration,
          vatRegistration: this.item.vatRegistration,
          paymentTermID: this.item.paymentTerm ? this.item.paymentTerm.id : null
        }

        if (this.editMode) {
          delete data.address
        }

        this.$emit('save-customer-edit-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.customerForm.resetValidation()
        // })
      }
    }
  }
}
</script>

<style></style>
