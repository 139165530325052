<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="stockItemForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <!-- <v-col cols="12">
                    <v-alert type="info">
                      <p>
                        This transfer will not transfer the items physically.
                        Unless this is a transfer within the same warehouse. Eg
                        Promese to Promese.
                      </p>
                      <p>
                        Please make sure the physical quantities are moved
                        between warehouses!
                      </p>
                    </v-alert>
                  </v-col> -->
                  <v-col cols="12" class="pt-0">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <div class="mb-2">
                          <v-label>From</v-label>
                        </div>

                        <span>{{ currentWarehouse }}</span>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <div class="mb-2">
                      <v-label>To</v-label>
                    </div>
                    <v-autocomplete
                      v-model="item.toWarehouseID"
                      :items="warehouseList"
                      placeholder="-- Destination Warehouse --"
                      autocomplete="off"
                      hide-details="auto"
                      outlined
                      persistent-hint
                      item-value="id"
                      item-text="name"
                      :rules="requiredSelectRule"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="item.quantity"
                      type="number"
                      label="Quantity"
                      placeholder="Quantity to transfer"
                      autocomplete="off"
                      hide-details="auto"
                      outlined
                      :hint="
                        'Available Quantity to transfer ' +
                          item.availableQuantity
                      "
                      persistent-hint
                      :rules="requiredQuantityRule"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey lighten-1" :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'StockTransferDialog',
  props: {
    stockItem: {
      type: Object,
      required: true,
      default: () => ({})
    },
    warehouses: {
      type: Object,
      required: true,
      default: () => ({ items: [] })
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      reasons: [
        { id: 'ADJUSTMENT', text: 'Adjustment' },
        { id: 'RETURN', text: 'Return' }
        // { id: 'RESERVATION', text: 'Reservation' }
      ],
      valid: true,
      requiredRule: [
        v => !!v || 'Field is required',
        v => !/^\s+$/.test(v) || 'Field is required'
      ],
      requiredQuantityRule: [
        v => !!v || 'Field is required',
        v =>
          !!(
            parseInt(v) > 0 &&
            parseInt(v) < parseInt(this.item.availableQuantity) + 1
          ) || 'Quantity must be between 1 and ' + this.item.availableQuantity,
        v => !/^\s+$/.test(v) || 'Field is required'
      ],
      requiredSelectRule: [v => !!v || 'Field is required'],
      item: {
        fromWarehouseID: null,
        toWarehouseID: null,
        barcode: null,
        quantity: null,
        notes: ''
      }
    }
  },
  computed: {
    title() {
      return 'Stock Transfer'
    },
    newStockQuantity() {
      return (
        parseInt(this.item.physicalQuantity) +
          parseInt(this.item.stockChange) || 0
      )
    },
    warehouseList() {
      var vm = this
      return this._.reject(
        vm.warehouses.items,
        item =>
          item.id === vm.item.fromWarehouseID ||
          item.provider.id !== vm.item.warehouseProviderID
      )
    },
    currentWarehouse() {
      var vm = this
      var from = this._.find(
        vm.warehouses.items,
        item => item.id === vm.item.fromWarehouseID
      )

      return from?.name || ''
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.stockItem)
      else this.$refs.stockItemForm.resetValidation()
    }
  },
  methods: {
    close() {
      this.$emit('close-stock-transfer-dialog', false)
      this.$refs.stockItemForm.resetValidation()
    },
    save() {
      if (this.$refs.stockItemForm.validate()) {
        var data = {
          fromWarehouseID: this.item.fromWarehouseID,
          toWarehouseID: this.item.toWarehouseID,
          barcode: this.item.barcode,
          quantity: this.item.quantity ? parseInt(this.item.quantity) : 0,
          notes: this.item.notes
        }

        this.$emit('save-stock-transfer-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.stockItemForm.resetValidation()
        // })
      }
    }
  }
}
</script>

<style lang="scss"></style>
