<template>
  <div class="page-wrap">
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-row v-if="product" justify="center">
          <v-col v-if="product.auditFlows" cols="12" md="10" lg="8" xl="6">
            <v-card
              v-for="flow in orderBy(product.auditFlows, 'updatedAt', -1)"
              :key="flow.id"
              flat
              class="mb-5"
            >
              <v-card-title class="py-0">
                <v-row>
                  <v-col cols="7">
                    <v-row no-gutters align="center" class="mt-2">
                      <v-col
                        cols="12"
                        class="title primary--text text-truncate "
                      >
                        Authorisation Request
                      </v-col>
                      <v-col cols="12">
                        <v-chip :color="stateColor(flow.status)" x-small>{{
                          flow.status
                        }}</v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-row justify="end">
                      <!-- <div class="title primary--text text-truncate ">
                      Authorisation Request
                    </div> -->
                      <div
                        class="grey--text text-caption font-weight-bold pa-3"
                      >
                        {{ flow.createdAt | moment('DD MMM YYYY') }}
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-list>
                  <template v-for="(item, index) in flow.auditors">
                    <v-divider :key="index - 1"></v-divider>
                    <v-list-item :key="item.id">
                      <v-list-item-content>
                        <v-row class="pt-2" no-gutters>
                          <v-col cols="8" class="pb-0">
                            <v-row no-gutters justify="start" align="center">
                              <avatar
                                :username="item.firstName + ' ' + item.lastName"
                                :size="25"
                                color="white"
                                style="user-select: none;"
                                class="mr-3"
                              ></avatar>
                              <div class="pt-1">
                                {{ item.firstName + ' ' + item.lastName }}
                              </div>
                            </v-row>
                          </v-col>
                          <v-col cols="4">
                            <v-row no-gutters justify="end" align="center">
                              <div>
                                <v-chip
                                  :color="stateColor(item.status)"
                                  x-small
                                  >{{ item.status }}</v-chip
                                >
                                <div
                                  v-if="item.approvalDate"
                                  class="grey--text font-weight-bold text-caption text-right mt-2"
                                >
                                  {{
                                    item.approvalDate | moment('DD MMM YYYY')
                                  }}
                                </div>
                              </div>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row v-if="item.comments" no-gutters>
                          <v-col cols="12">
                            <div class="pb-4">
                              <v-row>
                                <v-col cols="12" class="text--secondary pb-0">
                                  <div v-html="item.comments"></div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-else cols="12" md="10" lg="8" xl="6">
            <v-alert color="info" border="left" text class="mb-0"
              >Please come back once the product has been submitted for approval
              to track progress.</v-alert
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import Avatar from 'vue-avatar'

export default {
  name: 'ProductionProductAuditFlows',
  components: {
    Avatar
  },
  mixins: [Vue2Filters.mixin],
  props: {
    product: { type: Object, default: () => {} }
  },
  computed: {},
  apollo: {},
  methods: {
    stateColor: item => {
      if (item === 'pendingchanges') {
        return 'info lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'review') {
        return 'warning font-weight-bold upperCaseSpacing'
      } else if (item === 'rejected') {
        return 'error lighten-2 font-weight-bold upperCaseSpacing'
      } else if (item === 'approved') {
        return 'success lighten-1 font-weight-bold upperCaseSpacing'
      } else if (item === 'closed') {
        return 'primary lighten-2 font-weight-bold upperCaseSpacing'
      } else {
        return 'grey lighten-1 upperCaseSpacing'
      }
    }
  }
}
</script>

<style></style>
