<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet>
        <!-- <FullCalendar
          default-view="dayGridMonth"
          :plugins="calendarPlugins"
          :weekends="true"
          :events="[
            { title: 'event 1', date: '2020-03-01' },
            { title: 'event 2', date: '2019-04-02' }
          ]"
        /> -->
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
// import FullCalendar from '@fullcalendar/vue'
// import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  name: 'ProductionSeasonCalendar',
  components: {
    // FullCalendar
  },
  props: {
    season: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  data: () => ({
    // calendarPlugins: [dayGridPlugin]
  }),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
</style>
