<template>
  <div class="page-wrap">
    <stock-detail-toolbar
      :product="product"
      :warehouses="warehouses.items"
      :selected-warehouse="warehouseID"
      @select-warehouse="setSelectedWarehouse"
    ></stock-detail-toolbar>

    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <router-view :selected-warehouse="warehouseID" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import StockDetailToolbar from '@/modules/production/components/core/StockDetailToolbar.vue'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'StockDetailLayout',
  components: {
    StockDetailToolbar
  },
  data: () => ({
    warehouses: { items: [] },
    warehouseID: null
  }),
  apollo: {
    product: {
      query: gql`
        query Product($id: ID!) {
          product(id: $id) {
            id
            name
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.styleID
        }
      }
    },
    warehouses: WAREHOUSE_LIST
  },
  methods: {
    setSelectedWarehouse(id) {
      this.warehouseID = id
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 44px;
  /* --page-wrap-offset: -20px; */
}
</style>
