<template>
  <div class="page-wrap">
    <!-- <ProductionSettingsToolbar>
      <template v-slot:controls>
        <v-btn
          v-if="authLevel === 2"
          fab
          small
          color="primary"
          @click="newItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </ProductionSettingsToolbar> -->
    <div class="page-header">
      <v-toolbar class="mb-0 b-radius" flat>
        <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
        <v-row>
          <v-col cols="6" lg="2" xl="2">
            <v-select
              hide-details
              outlined
              flat
              dense
              clearable
              label="season"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              :disabled="!products || products.length === 0"
            >
            </v-select>
          </v-col>

          <v-col cols="6" lg="2" xl="2">
            <v-text-field
              v-model="search"
              hide-details
              outlined
              flat
              dense
              clearable
              background-color="#fff"
              placeholder="Search..."
              prepend-inner-icon="mdi-magnify"
              autocomplete="off"
              :disabled="!products || products.length === 0"
            ></v-text-field>
          </v-col>

          <v-col
            cols="3"
            lg="2"
            xl="2"
            class="d-none d-sm-none d-md-none d-lg-flex"
          >
            <v-select
              hide-details
              outlined
              flat
              dense
              clearable
              label="designer"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              :disabled="!products || products.length === 0"
            >
            </v-select>
          </v-col>
          <v-col
            cols="3"
            lg="2"
            xl="2"
            class="d-none d-sm-none d-md-none d-lg-flex"
          >
            <v-select
              hide-details
              outlined
              flat
              dense
              clearable
              label="producer"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              :disabled="!products || products.length === 0"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-btn
          icon
          class="d-sm-flex d-md-flex d-lg-none d-xl-none ml-2"
          @click="filters = !filters"
        >
          <v-icon :color="filters ? 'info' : ''">mdi-filter-variant</v-icon>
        </v-btn>

        <v-btn class="ml-2" color="primary" fab small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </div>
    <div class="main-page-column custom-scroll-wrap">
      <task-board
        :columns="workflow.steps"
        :items="products"
        :column-id-name="'id'"
        :list-column-id-name="'step.id'"
      ></task-board>
    </div>
  </div>
</template>

<script>
import TaskBoard from '@/modules/production/components/core/TaskBoard.vue'

export default {
  name: 'ProcessBoard',
  components: {
    TaskBoard
  },
  data: () => ({
    filters: false,
    search: null,
    workflow: {
      id: '1',
      name: 'Design',
      dependsOn: null,
      steps: [
        {
          id: '1',
          name: 'Inbox',
          acceptedSource: null
        },
        {
          id: '2',
          name: 'Sketch',
          acceptedSource: ['1']
        },
        {
          id: '3',
          name: 'Technical Drawing',
          acceptedSource: ['1']
        },
        {
          id: '4',
          name: 'Tech Pack',
          acceptedSource: ['1']
        },
        {
          id: '5',
          name: 'Prototype',
          acceptedSource: null
        },

        {
          id: '7',
          name: 'Done',
          acceptedSource: ['2']
        }
      ],
      updatedAt: new Date(),
      createdAt: new Date()
    },
    products: [
      {
        id: '1',
        name: 'Product 1',
        designer: {
          firstName: 'John',
          lastName: 'Doe',
          email: 'john.doe@email.com'
        },
        producer: {
          firstName: 'Pieter',
          lastName: 'Backker',
          email: 'pieter.bakker@email.com'
        },
        season: {
          id: '1',
          shortName: 'SS20',
          longName: 'Spring/Summer 2020'
        },
        step: {
          id: '1',
          name: 'Inbox'
        }
      },
      {
        id: '2',
        name: 'Product 2',
        designer: {
          firstName: 'Patrick',
          lastName: 'Doe',
          email: 'patrick.doe@email.com'
        },
        producer: {
          firstName: 'Pieter',
          lastName: 'Backker',
          email: 'pieter.bakker@email.com'
        },
        season: {
          id: '1',
          shortName: 'FW20',
          longName: 'Fall/Winter 2020'
        },
        step: {
          id: '1',
          name: 'Inbox'
        }
      },
      {
        id: '3',
        name: 'Product 3',
        designer: {
          firstName: 'John',
          lastName: 'Doe',
          email: 'john.doe@email.com'
        },
        producer: {
          firstName: 'Pieter',
          lastName: 'Backker',
          email: 'pieter.bakker@email.com'
        },
        season: {
          id: '1',
          shortName: 'SS20',
          longName: 'Spring/Summer 2020'
        },
        step: {
          id: '2',
          name: 'Technical Drawing'
        }
      },
      {
        id: '4',
        name: 'Product 4',
        designer: {
          firstName: 'Bill',
          lastName: 'Doe',
          email: 'Bill.doe@email.com'
        },
        producer: {
          firstName: 'Pieter',
          lastName: 'Backker',
          email: 'pieter.bakker@email.com'
        },
        season: {
          id: '1',
          shortName: 'SS20',
          longName: 'Spring/Summer 2020'
        },
        step: {
          id: '4',
          name: 'Done'
        }
      }
    ]
  })
}
</script>

<style>
/* .page-wrap {
  --page-header-height: 62px;
} */

.custom-scroll-wrap {
  height: calc(100vh - 150px);
  min-width: 100%;
  padding: 0 15px;
}
</style>
