<template>
  <div>
    <v-toolbar class="b-radius mb-3" flat>
      <v-toolbar-title>
        {{ selectedWorkflow.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn
        color="info"
        rounded
        small
        @click="$emit('editStep', selectedWorkflow)"
      >
        edit steps
      </v-btn> -->
    </v-toolbar>

    <workflow-step-list
      :selected-workflow="selectedWorkflow"
      @edit-steps="saveWorkflow"
      @reorderList="$emit('reorderList', $event)"
    />
  </div>
</template>

<script>
import WorkflowStepList from './WorkflowStepList'

export default {
  name: 'WorkflowBox',
  components: { WorkflowStepList },
  props: {
    selectedWorkflow: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    defaultItem: {},
    editedItem: {}
  }),
  methods: {
    editItem(item) {
      this.$emit('editStep', this.selectedWorkflow, item)
    },
    saveWorkFlowDialog(success) {
      this.$emit('closeStepEdit', success)
    },
    saveWorkflow(item) {
      this.$emit('edit-steps', item)
    }
  }
}
</script>

<style></style>
