<template>
  <v-autocomplete
    v-model="item"
    :items="customList"
    :dense="dense"
    autocomplete="off"
    :label="label"
    :hide-details="hideDetails"
    :outlined="outlined"
    :autofocus="autofocus"
    :readonly="readonly"
    :disabled="disabled"
    @change="$emit('input', item)"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'CustomTypeSelect',
  prop: ['value'],
  props: {
    label: {
      type: String,
      default: null
    },
    hideDetails: {
      type: String,
      default: 'auto'
    },
    dense: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customTypes: [
        'Accessories',
        'Childrens Footwear',
        'Childrenswear - Boy',
        'Childrenswear - Girl',
        'Childrenswear - Unisex',
        'Clocks And Watches',
        'Infant Clothing',
        'Infant Footwear',
        'Jewellery',
        'Mens Footwear',
        'Mens Fur',
        'Mens Knit',
        'Mens Leather',
        'Mens Woven',
        'Other Footwear',
        'Spectacles/Sunglasses',
        'Unisex Knit',
        'Unisex Woven',
        'Womens Footwear',
        'Womens Fur',
        'Womens Knit',
        'Womens Leather',
        'Womens Woven'
      ],
      item: this.$attrs.value
    }
  },
  computed: {
    customList() {
      return this._.orderBy(this.customTypes)
    }
  },
  watch: {
    $attrs(item) {
      this.item = item.value
    }
  }
}
</script>

<style></style>
