var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"page caption",attrs:{"elevation":"3","size":"A4","layout":_vm.layout}},[_c('div',{staticClass:"page-wrap d-flex flex-column flex-nowrap",staticStyle:{"padding":"50px 50px 0 50px","background-color":"white !important"}},[_c('v-row',{staticClass:"flex-grow-0",attrs:{"justify":"center"}},[_c('h2',[_vm._v("Manufacturing Order")])]),_c('v-row',{staticClass:"flex-grow-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"caption company"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Daily Paper Wholesale BV")]),_c('div',[_vm._v(" Danzigerbocht 45N ")]),_c('div',[_vm._v("Amsterdam")]),_c('div',[_vm._v("1013AM")]),_c('div',[_vm._v("Netherlands")]),_c('div',[_vm._v("T: +31 (0)20 3348970")]),_c('div',[_vm._v("https://www.dailypaperclothing.com")])])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('img',{staticClass:"logo",attrs:{"src":"/logo_alt.jpg"}})])],1)],1),_c('v-row',{staticClass:"flex-grow-0"},[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Docket")])]),_c('v-col',{staticClass:"pb-0"},[(
                _vm.manufacturingOrder.status === 'DRAFT' ||
                  _vm.manufacturingOrder.status === 'PENDINGCHANGES' ||
                  _vm.manufacturingOrder.status === 'REVIEW'
              )?_c('span',{staticClass:"font-weight-bold"},[_vm._v("DRAFT")]):_vm._e(),(
                _vm.manufacturingOrder.status === 'APPROVED' ||
                  _vm.manufacturingOrder.status === 'PROPOSALACCEPTED'
              )?_c('span',[_vm._v(_vm._s(_vm.manufacturingOrder.id))]):_vm._e()])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Docket Ref")])]),_c('v-col',{staticClass:"pb-0"},[_c('span',[_vm._v(_vm._s(_vm.manufacturingOrder.docketRef))])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Manufacturer")])]),_c('v-col',{staticClass:"pb-0 address caption"},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.manufacturingOrder.manufacturer.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.manufacturerAddress.line1)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.manufacturerAddress.line2)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.manufacturerAddress.line3)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.manufacturerAddress.city)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.manufacturerAddress.postCode)+" ")]),(
                _vm.manufacturingOrder.manufacturerAddress &&
                  _vm.manufacturingOrder.manufacturerAddress.country
              )?_c('div',[_vm._v(" "+_vm._s(_vm.getFullCountryName( _vm.manufacturingOrder.manufacturerAddress.country ))+" ")]):_vm._e()])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Payment Terms")])]),_c('v-col',{staticClass:"pb-0 caption"},_vm._l((_vm.manufacturingOrder.paymentTerms),function(term){return _c('div',{key:term.id},[_c('span',[_vm._v(" "+_vm._s(term.percentage + '% ' + term.days + ' days ' + term.direction + ' ' + term.eventType)+" ")])])}),0)],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Date")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.manufacturingOrder.orderDate,'DD MMM YYYY'))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Ship To")])]),_c('v-col',{staticClass:"pb-0 address caption"},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.manufacturingOrder.deliverTo.address.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.deliverTo.address.line1)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.deliverTo.address.line2)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.deliverTo.address.line3)+" ")]),_c('div',[_vm._v(_vm._s(_vm.manufacturingOrder.deliverTo.address.city))]),_c('div',[_vm._v(" "+_vm._s(_vm.manufacturingOrder.deliverTo.address.postCode)+" ")]),(
                _vm.manufacturingOrder.deliverTo.address &&
                  _vm.manufacturingOrder.deliverTo.address.country
              )?_c('div',[_vm._v(" "+_vm._s(_vm.getFullCountryName( _vm.manufacturingOrder.deliverTo.address.country ))+" ")]):_vm._e()])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Ex-Factory Date")])]),_c('v-col',{staticClass:"pb-0 caption"},[(_vm.manufacturingOrder && _vm.manufacturingOrder.exFactoryDate)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.manufacturingOrder.exFactoryDate,'D MMM YYYY')))]):_vm._e()])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Arrival Date")])]),_c('v-col',{staticClass:"pb-0 caption"},[(_vm.manufacturingOrder && _vm.manufacturingOrder.arrivalDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.manufacturingOrder.arrivalDate,'D MMM YYYY')))]):_vm._e()])],1)],1)],1),_c('v-row',{staticClass:"flex-grow-0"},[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Notes")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.manufacturingOrder.note)}})])],1)],1)],1),_c('v-row',{staticClass:"flex-grow-1"}),_c('v-row',{staticClass:"align-end flex-grow-0 pb-3"},[_c('v-col',[_c('span',{staticClass:"font-weight-medium pr-2"},[_vm._v("Company Reg.")]),_c('span',[_vm._v("68051875")]),_c('span',{staticClass:"px-3"},[_vm._v("|")]),_c('span',{staticClass:"font-weight-medium pr-2"},[_vm._v("VAT No.")]),_c('span',[_vm._v("NL857281483B01")])]),_c('v-col',{staticClass:"text-right"},[_vm._v(" Page "+_vm._s(_vm.page)+" of "+_vm._s(_vm.pageCount)+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }