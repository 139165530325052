<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="px-3">
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="articleGroupForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <!-- <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Code</label>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.articleCode"
                      autocomplete="off"
                      placeholder="Code"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Name</label>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      autocomplete="off"
                      placeholder="Name"
                      hide-details="auto"
                      outlined
                      :rules="requiredRule"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Description</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      v-model="item.description"
                      autocomplete="off"
                      placeholder="Description"
                      hide-details="auto"
                      outlined
                      dense
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn color="grey" text rounded @click="close(false)">Cancel</v-btn>
        <v-btn color="primary lighten-2" rounded @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ARTICLE_GROUPS from '@/graphql/ArticleGroups.gql'
import CREATE_ARTICLE_GROUP from '@/graphql/ArticleGroupCreate.gql'
import UPDATE_ARTICLE_GROUP from '@/graphql/ArticleGroupUpdate.gql'

export default {
  name: 'ArticleEditDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    from: false,
    till: false,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    dateRule: [v => !!v || 'Date is required']
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Article Group' : 'New Article Group'
    }
  },
  methods: {
    close(success) {
      this.$refs.articleGroupForm.resetValidation()
      this.$emit('close-edit-article-group-dialog', success)
    },
    save() {
      if (this.editMode) {
        // Update Item
        let data = {
          id: this.item.id,
          articleCode: this.item.articleCode,
          name: this.item.name,
          description: this.item.description
        }

        if (this.$refs.articleGroupForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_ARTICLE_GROUP,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // New Item
        let data = {
          articleCode: this.item.articleCode,
          name: this.item.name,
          description: this.item.description
        }

        if (this.$refs.articleGroupForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_ARTICLE_GROUP,
              variables: {
                input: data
              },
              update: (store, { data: { createArticleGroup } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: ARTICLE_GROUPS
                })
                // Add our requirement from the mutation to the end
                data.articleGroups.push(createArticleGroup)
                // Write our data back to the cache.
                store.writeQuery({ query: ARTICLE_GROUPS, data })
              }
            })
            .then(() => {
              this.close(true)
            })
            .catch(() => {
              this.$store.commit('showSnackbar', {
                active: true,
                message:
                  'A general server error occured. Please try again later'
              })

              return this.close(false)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-color-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
