<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="px-3">
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="workflowForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Name</label>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      autocomplete="off"
                      placeholder="Workflow Name"
                      outlined
                      :rules="requiredRule"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Description</label>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      autocomplete="off"
                      placeholder="Description"
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text :disabled="$apollo.loading" @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          workflow="blue darken-1"
          dark
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import WORKFLOWS from '@/graphql/Workflows.gql'
// import CREATE_WORKFLOW from '@/graphql/WorkflowCreate.gql'
// import UPDATE_WORKFLOW from '@/graphql/WorkflowUpdate.gql'

export default {
  name: 'WorkflowEditDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Workflow' : 'New Workflow'
    }
  },
  methods: {
    close(success) {
      this.$refs.workflowForm.resetValidation()
      this.$emit('close-edit-workflow-dialog', success)
    },
    save() {
      if (this.$refs.workflowForm.validate()) {
        this.$emit('save-workflow', this.item)
      }
      // if (this.editMode) {
      //   // Update Item
      //   let data = {
      //     id: this.item.id,
      //     workflowCode: this.item.workflowCode,
      //     name: this.item.name
      //   }
      //   if (this.$refs.workflowForm.validate()) {
      //     this.$apollo
      //       .mutate({
      //         mutation: UPDATE_WORKFLOW,
      //         variables: {
      //           input: data
      //         }
      //       })
      //       .then(() => {
      //         this.close(true)
      //       })
      //   }
      // } else {
      //   // New Item
      //   let data = {
      //     workflowCode: this.item.workflowCode,
      //     name: this.item.name
      //   }
      //   if (this.$refs.workflowForm.validate()) {
      //     this.$apollo
      //       .mutate({
      //         mutation: CREATE_WORKFLOW,
      //         variables: {
      //           input: data
      //         },
      //         update: (store, { data: { createWorkflow } }) => {
      //           // Read the data from our cache for this query.
      //           const data = store.readQuery({
      //             query: WORKFLOWS
      //           })
      //           // Add our requirement from the mutation to the end
      //           data.workflows.push(createWorkflow)
      //           // Write our data back to the cache.
      //           store.writeQuery({ query: WORKFLOWS, data })
      //         }
      //       })
      //       .then(() => {
      //         this.close(true)
      //       })
      //       .catch(() => {
      //         this.$store.commit('showSnackbar', {
      //           active: true,
      //           message:
      //             'A general server error occured. Please try again later'
      //         })
      //         return this.close(false)
      //       })
      //   }
      // }
    }
  }
}
</script>

<style lang="scss"></style>
