<template>
  <div class="page-header">
    <v-toolbar v-if="product" class="mb-0 b-radius" flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="pl-0">
            <v-row>
              <v-col cols="8" lg="9" xl="10">
                <h2 class="mb-0 text-truncate primary--text">
                  {{ product.name }}
                </h2>
                <div>
                  <v-chip
                    v-if="size"
                    pill
                    x-small
                    color="info lighten-2"
                    text-color="info darken-2"
                    >{{ size.name }}</v-chip
                  >
                </div>
              </v-col>
              <v-spacer></v-spacer>

              <v-col cols="4" lg="3" xl="2" class="pt-6">
                <v-autocomplete
                  v-model="warehouse"
                  :items="warehouses"
                  label="Warehouse"
                  placeholder="Global"
                  item-value="id"
                  item-text="name"
                  hide-details
                  flat
                  dense
                  clearable
                  outlined
                  background-color="#fff"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'StockDetailToolbar',
  props: {
    product: { type: Object, default: () => {} },
    size: { type: Object, default: () => {} },
    warehouses: { type: Array, default: () => [] },
    selectedWarehouse: { type: String, default: null }
  },
  data: () => ({
    warehouse: null
  }),
  watch: {
    warehouse(val) {
      this.setWarehouse(val)
    }
  },
  methods: {
    setWarehouse(id) {
      this.$emit('select-warehouse', id)
    }
  }
}
</script>

<style></style>
