<template>
  <div class="page-wrap">
    <div class="page-header">
      <v-toolbar class="mb-0 b-radius" color="transparent" flat>
        <manufacturer-edit-dialog
          :dialog="dialog"
          :manufacturer="editedItem"
          :payment-terms="paymentTerms"
          :currencies="currencies"
          :edit-mode="editMode"
          :processing="processing"
          :error-msg="errorMsg"
          @close-manufacturer-edit-dialog="close"
          @save-manufacturer-edit-dialog="save"
        ></manufacturer-edit-dialog>
        <v-row>
          <v-col cols="6" lg="2" xl="2" class="pl-0">
            <v-btn color="primary" rounded @click="addManufacturer">
              add manufacturer
            </v-btn>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-row justify="end">
          <span class="pt-3 text-truncate">{{ itemCount }} Manufacturers</span>

          <!-- <v-btn
            icon
            class="d-sm-flex d-md-flex d-lg-none d-xl-none ml-2"
            @click="filters = !filters"
          >
            <v-icon :color="filters ? 'info' : ''">mdi-filter-variant</v-icon>
          </v-btn> -->
        </v-row>

        <!-- <v-btn class="ml-2" color="primary" fab small>
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
      </v-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <!-- <v-card v-if="filters" class="mb-3">
          <div>
            <v-col cols="12">
              <v-row no-gutters>
                <span>Advanced Filters</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="filters = !filters"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-select
                outlined
                flat
                dense
                clearable
                label="producer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                :disabled="!manufacturers || manufacturers.items.length === 0"
              >
              </v-select>
            </v-col>
          </div>
        </v-card> -->

        <v-card flat>
          <v-card-title class="pt-0">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search..."
              single-line
              hide-details
              clearable
              autocomplete="off"
              :disabled="!manufacturers"
            ></v-text-field>
          </v-card-title>

          <v-data-table
            v-model="selected"
            :headers="headers"
            :loading="$apollo.loading"
            :items="manufacturers.items"
            :options.sync="options"
            :server-items-length="itemCount"
            :mobile-breakpoint="null"
            fixed-header
            :hide-default-footer="!manufacturers || itemCount < 16"
            class="plain-table"
            @click:row="navToDetail"
          >
            <template v-slot:item.updatedAt="{ item }">
              {{ item.updatedAt | moment('from', 'now') }}
            </template>
            <template v-slot:no-data>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Results"
                caption="Try adjusting your filters to get some results"
              ></message-box>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                v-for="item in 5"
                :key="item"
                type="list-item"
                class="mx-auto"
              ></v-skeleton-loader>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import MANUFACTURER_LIST from '@/graphql/Manufacturers.gql'
import CREATE_MANUFACTURER from '@/graphql/ManufacturerCreate.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import ManufacturerEditDialog from '@/modules/production/components/core/ManufacturerEditDialog.vue'

export default {
  name: 'ProductionManufacturerList',
  components: {
    MessageBox,
    ManufacturerEditDialog
  },
  data: () => ({
    filters: false,
    search: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    selected: [],
    manufacturers: { items: [] },
    defaultItem: {
      id: null,
      manufacturerCode: null,
      name: null,
      mid: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null
      },
      companyRegistration: null,
      vatRegistration: null,
      paymentTerm: null,
      currency: null
    },
    editedItem: {
      id: null,
      manufacturerCode: null,
      name: null,
      mid: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null,
        country: null
      },
      companyRegistration: null,
      vatRegistration: null,
      paymentTerm: null,
      currency: null
    }
  }),
  apollo: {
    manufacturers: {
      query: MANUFACTURER_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        this.page = result.data.manufacturers.page
        this.itemsPerPage = result.data.manufacturers.pageSize
        this.itemCount = result.data.manufacturers.total
      }
    },
    paymentTerms: PAYMENT_TERM_LIST,
    currencies: CURRENCY_LIST
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Website',
          value: 'website',
          sortable: false,
          filterable: true
        },
        {
          text: 'Address',
          align: 'left',
          value: 'address',
          sortable: false
          // sortable: true,
          // filter: this.stateFilterValue
        },
        {
          text: 'Last Update',
          align: 'left',
          value: 'updatedAt',
          sortable: false
        }
      ]
    }
  },
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'ManufacturerDetail',
        params: { manufacturerID: item.id }
      })
    },
    addManufacturer() {
      this.dialog = true
    },
    close(success) {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = 'Manufacturer Added'

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },
    save(item) {
      this.processing = true

      // Save Item
      delete item.id
      delete item.address.id

      this.$apollo
        .mutate({
          mutation: CREATE_MANUFACTURER,
          variables: {
            input: item
          }
          // update: (store, { data: { createManufacturer } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: MANUFACTURER_LIST
          //   })
          //   // Add our requirement from the mutation to the end
          //   data.manufacturers.push(createManufacturer)
          //   // Write our data back to the cache.
          //   store.writeQuery({
          //     query: MANUFACTURER_LIST,
          //     data
          //   })
          // }
        })
        .then(result => {
          this.close(true)
          this.$router.push({
            name: 'ManufacturerDetail',
            params: { manufacturerID: result.data.createManufacturer.id }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
