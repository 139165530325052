<template>
  <div class="page-wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ProductionSettingsLayout'
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 64px;
  /* --page-wrap-offset: -20px; */
}
</style>
