<template>
  <v-card elevation class="mb-2" style="cursor: grab;">
    <v-card-text class="pa-0">
      <v-row no-gutters>
        <v-col class="pl-2 pr-5 text-truncate">
          <v-row class="py-2">
            <v-col cols="auto" class="py-0 handle"
              ><v-icon>mdi-swap-vertical</v-icon></v-col
            >
            <v-col class="py-0">
              <input
                ref="editInput"
                v-model="item.title"
                style="width: 100%;padding: 1px 5px;"
                :disabled="!edit"
                required
                @keyup.enter="editItem"
              />
            </v-col>
          </v-row>

          <!-- <span v-text="item.title"></span> -->
        </v-col>

        <v-col cols="auto" class="pa-2">
          <v-row
            v-if="editIndex === null || editIndex === currentIndex"
            no-gutters
            justify="end"
          >
            <v-btn
              v-if="!edit"
              icon
              x-small
              aria-label="edit item"
              class="mr-3"
              @click.stop="editMode"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="!edit"
              color="error"
              icon
              x-small
              aria-label="delete item"
              @click.stop="deleteItem"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>

            <v-btn
              v-if="edit"
              color="success"
              icon
              x-small
              aria-label="save item"
              class="mr-3"
              @click="editItem"
            >
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="edit"
              icon
              x-small
              aria-label="save item"
              @click.stop="resetIndex"
            >
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'WorkflowStepListItem',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editIndex: {
      type: Number,
      default: null
    },
    currentIndex: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    edit: false,
    title: ''
  }),
  created() {
    this.title = this._.cloneDeep(this.item.title)
  },
  methods: {
    editMode() {
      if (!this.edit) {
        this.edit = true
        this.$nextTick(() => this.$refs.editInput.focus())
        this.$emit('set-edit-index')
      }
    },
    resetIndex() {
      this.edit = false
      this.$emit('reset-edit-index')
    },
    editItem(el) {
      el.preventDefault()
      if (this.item.title != '') {
        this.$emit('edit-step')
        this.edit = false
      } else {
        this.item.title = this._.cloneDeep(this.title)
      }
    },
    deleteItem() {
      this.$emit('delete-step')
    }
  }
}
</script>

<style></style>
