var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[(
      _vm.search ||
        (_vm.warehouses && _vm.warehouses.items && _vm.warehouses.items.length > 0)
    )?_c('div',{staticClass:"page-header"},[_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"flat":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","rounded":""},on:{"click":_vm.addWarehouse}},[_vm._v(" add warehouse ")])],1),_c('v-col',[_c('v-text-field',{attrs:{"placeholder":"search","autocomplete":"off","hide-details":"auto","outlined":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"main-page-column pt-0"},[_c('warehouse-edit-dialog',{attrs:{"dialog":_vm.dialog,"warehouse":_vm.editedItem,"warehouse-providers":_vm.warehouseProviders.items,"edit-mode":_vm.editMode,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"close-warehouse-edit-dialog":_vm.close,"save-warehouse-edit-dialog":_vm.save}}),_c('div',{staticClass:"scroll-container pt-0"},[(
          !_vm.$apollo.loading &&
            _vm.warehouses &&
            _vm.warehouses.items &&
            _vm.warehouses.items.length > 0
        )?_c('v-row',_vm._l((_vm.warehouses.items),function(warehouse){return _c('v-col',{key:warehouse.id,attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-card',{staticClass:"card-bordered",attrs:{"to":{
              name: 'WarehouseDetail',
              params: { warehouseID: warehouse.id }
            },"outlined":"","hover":""}},[_c('v-card-title',[_vm._v(_vm._s(warehouse.name))]),(warehouse.provider)?_c('v-card-subtitle',{staticClass:"pb-2"},[_vm._v(_vm._s(warehouse.provider.name))]):_vm._e(),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"grey--text caption pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-truncate"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("mdi-map-marker")]),(warehouse.address && warehouse.address.city)?_c('span',[_vm._v(_vm._s(warehouse.address.city))]):_vm._e(),(warehouse.address && warehouse.address.state)?_c('span',[_vm._v(" "+_vm._s(warehouse.address.state))]):_vm._e(),(
                        warehouse.address &&
                          warehouse.address.city &&
                          warehouse.address.country
                      )?_c('span',[_vm._v(", ")]):_vm._e(),(warehouse.address && warehouse.address.country)?_c('span',[_vm._v(_vm._s(_vm.getFullCountryName(warehouse.address.country)))]):_vm._e()],1)])],1)],1)],1)],1)}),1):_vm._e(),(
          !_vm.$apollo.loading &&
            _vm.warehouses &&
            _vm.warehouses.items &&
            _vm.warehouses.items.length === 0
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Warehouses","caption":_vm.search
                  ? 'Try adjusting your filters to get some results'
                  : 'Add your first warehouse to get started'}},[(!_vm.search)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary lighten-2","rounded":"","small":""},on:{"click":_vm.addWarehouse}},[_vm._v(" add warehouse ")]):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.$apollo.loading)?_c('v-row',_vm._l((5),function(item){return _c('v-col',{key:item},[_c('v-sheet',{staticClass:"px-3 pt-3 pb-3",attrs:{"color":"grey lighten-4"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}})],1)],1)}),1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }