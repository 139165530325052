<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="sizeForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="sizeGroupID"
                      :items="sizeGroups"
                      outlined
                      flat
                      placeholder="-- Select Sizes --"
                      hide-details="auto"
                      autocomplete="off"
                      item-value="id"
                      :rule="requiredRule"
                      :disabled="
                        !product || !sizeGroups || sizeGroups.length === 0
                      "
                    >
                      <template v-slot:selection="{ item }">
                        <span class="font-weight-bold pr-3"
                          >{{ item.name }} - {{ item.scale }} ({{
                            item.gender
                          }})</span
                        ><span class="pr-2">|</span>
                        <span
                          v-for="size in item.sizes"
                          :key="size.id"
                          class="size-delimiter"
                          >{{ size.name }} <span class="px-2">|</span>
                        </span></template
                      >
                      <template v-slot:item="{ item }">
                        <div>
                          <span class="font-weight-bold pr-3"
                            >{{ item.name }} - {{ item.scale }} ({{
                              item.gender
                            }})</span
                          ><span class="pr-2">|</span>
                          <span
                            v-for="size in item.sizes"
                            :key="size.id"
                            class="size-delimiter"
                            >{{ size.name }} <span> | </span>
                          </span>
                        </div>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn color="grey" text :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ProductSizeDialog',
  props: {
    sizeGroups: {
      type: Array,
      required: true,
      default: () => []
    },
    product: {
      type: Object,
      required: true,
      default: () => ({})
    },
    edit: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    sizeGroupID: null
  }),
  computed: {
    title() {
      return 'Add Sizes'
    },
    item() {
      return { productID: this.product.id, sizeGroupID: this.sizeGroupID }
    }
  },
  watch: {},
  methods: {
    close() {
      this.$emit('close-product-size-dialog', false)

      this.$nextTick(() => {
        this.$refs.sizeForm.reset()
      })
    },
    save() {
      var data = {
        productID: this.item.productID,
        sizeGroupID: this.item.sizeGroupID
      }

      var that = this

      if (this.$refs.sizeForm.validate()) {
        this.$emit('save-product-size-dialog', data)
        this.$nextTick(() => {
          if (that.errorMsg === '') {
            that.$refs.sizeForm.reset()
          } else {
            that.$refs.sizeForm.resetValidation()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.size-delimiter {
  &:last-child {
    span {
      visibility: hidden;
    }
  }
}
</style>
