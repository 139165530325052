var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[(_vm.product)?_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8","xl":"6"}},[_c('v-card',{staticClass:"mb-3",attrs:{"flat":""}},[_c('v-card-title',[_c('span',[_vm._v("Seasons")]),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(
                _vm.product &&
                  _vm.product.styleState &&
                  _vm.product.styleState === 'FINAL' &&
                  _vm.seasonAuthLevel &&
                  _vm.seasonAuthLevel === 2 &&
                  _vm.unselectedSeasons &&
                  _vm.seasons.length > 0 &&
                  _vm.selectedSeason &&
                  ((_vm.seasonAuthLevel && _vm.seasonAuthLevel === 2) ||
                    (_vm.currentUser &&
                      (_vm.currentUser.isSuperAdmin ||
                        _vm.currentUser.userRole === 'director' ||
                        _vm.currentUser.userRole === 'seniormanager' ||
                        _vm.currentUser.userRole === 'projectmanager')))
              )?_c('v-btn',_vm._g({attrs:{"color":"primary lighten-2","rounded":"","small":""}},on),[_vm._v(" add to season ")]):_vm._e()]}}],null,false,928175699),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline mb-3"},[_vm._v("Add Product To Season")]),_c('v-card-text',[_c('v-alert',{attrs:{"type":"info","outlined":"","dense":"","border":"left"}},[_vm._v(" This will add the product to the selected season. ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.unselectedSeasons,"outlined":"","dense":"","autocomplete":"","label":"Season","hint":"Season to add the product to","persistent-hint":"","color":"info","item-value":"id","item-text":"longName","background-color":"white","hide-details":"auto","rules":_vm.requiredSelectRule},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.longName)+" ("+_vm._s(item.shortName)+") ")]}},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.longName)+" ("+_vm._s(item.shortName)+") ")]}}],null,false,1998606940),model:{value:(_vm.editedItem.seasonID),callback:function ($$v) {_vm.$set(_vm.editedItem, "seasonID", $$v)},expression:"editedItem.seasonID"}})],1)],1),(false)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.usedSeasons,"outlined":"","dense":"","autocomplete":"","label":"Copy From Season","hint":"Season to copy settings from","persistent-hint":"","color":"info","item-value":"id","item-text":"longName","return-object":"","background-color":"white","hide-details":"auto"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.longName)+" ("+_vm._s(item.shortName)+") ")]}},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.longName)+" ("+_vm._s(item.shortName)+") ")]}}],null,false,1998606940)})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"grey darken-1","text":"","disabled":_vm.processing},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","rounded":"","small":"","loading":_vm.processing},on:{"click":_vm.saveToSeason}},[_vm._v("Add Product")])],1)],1)],1)],1),_c('v-card-subtitle',{staticClass:"mb-3"},[_vm._v("List of seasons the product is available in")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.$apollo.loading,"items":_vm.product.availableSeasons,"mobile-breakpoint":null,"hide-default-footer":""}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }