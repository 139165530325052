<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <stock-item-edit-dialog
        :dialog="dialog"
        :stock-item="editedItem"
        :edit-mode="editMode"
        :processing="processing"
        :error-msg="errorMsg"
        @close-stock-item-edit-dialog="close"
        @save-stock-item-edit-dialog="quickAdjust"
      ></stock-item-edit-dialog>

      <stock-transfer-dialog
        :dialog="stockTranferDialog"
        :stock-item="stockTransferItem"
        :warehouses="warehouses"
        :processing="processing"
        :error-msg="errorMsg"
        @close-stock-transfer-dialog="close"
        @save-stock-transfer-dialog="transferStock"
      ></stock-transfer-dialog>

      <v-card flat>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="8" md="4">
              <v-text-field
                v-model="search"
                hide-details
                outlined
                dense
                clearable
                background-color="#fff"
                placeholder="Search..."
                prepend-inner-icon="mdi-magnify"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-select
                v-model="searchProp"
                :background-color="'#fff'"
                :items="searchProps"
                prefix="by"
                hide-details
                outlined
                dense
                autocomplete="off"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          v-model="selected"
          item-key="barcode"
          :loading="$apollo.loading"
          :headers="computedHeaders"
          :items="stockList"
          :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
          :options.sync="options"
          :server-items-length="itemCount"
          :mobile-breakpoint="null"
          fixed-header
          :hide-default-header="
            !$apollo.loading &&
              !search &&
              (!stockItems ||
                !stockItems.items ||
                stockItems.items.length === 0)
          "
          :hide-default-footer="!stockItems || itemCount < 16"
        >
          <template v-slot:item.updatedAt="{ item }">
            <span v-if="item.updatedAt" class="text-no-wrap">{{
              item.updatedAt | moment('from', 'now')
            }}</span>
          </template>
          <template v-slot:item.style="{ item }">
            <span
              v-if="item.style"
              class="grey--text text--darken-3 font-weight-medium text-no-wrap"
              >{{ item.style.name }}</span
            >
          </template>
          <template v-slot:item.size="{ item }">
            <v-chip
              v-if="item.size"
              pill
              x-small
              color="secondary lighten-1 font-weight-bold"
              text-color="secondary darken-2"
              >{{ item.size.name }}</v-chip
            >
          </template>
          <template v-slot:item.warehouse="{ item }">
            <v-chip
              v-if="item.warehouse"
              pill
              x-small
              color="info lighten-3 font-weight-bold"
              text-color="info darken-1"
              light
              >{{ item.warehouse.name }}</v-chip
            >
            <v-chip
              v-else
              pill
              x-small
              color="info lighten-3 font-weight-bold"
              text-color="info darken-2"
              light
              >All</v-chip
            >
          </template>
          <template v-slot:item.availableQuantity="{ item }">
            <span class="font-weight-medium">{{ item.availableQuantity }}</span>
          </template>

          <template
            v-if="currentRoute.includes('logistics')"
            v-slot:item.controls="{ item }"
          >
            <div class="text-no-wrap">
              <v-btn
                v-if="stockAdjustAuthLevel === 2"
                color="info"
                class="ml-2"
                text
                rounded
                small
                @click="adjustStockItem(item)"
                >change</v-btn
              >
              <v-btn
                v-if="
                  stockAdjustAuthLevel === 2 &&
                    parseInt(item.availableQuantity) > 0
                "
                color="grey"
                class="ml-2"
                text
                rounded
                small
                @click="openStockTransferDialog(item)"
                >transfer</v-btn
              >
            </div>
          </template>
          <template v-slot:no-data>
            <message-box
              icon="mdi-emoticon-neutral-outline"
              title="No StockItems"
              :caption="
                search
                  ? 'Try adjusting your search to get some results'
                  : 'Create products to generate stock'
              "
            >
            </message-box>
          </template>
          <template v-slot:loading>
            <v-skeleton-loader
              v-for="item in 5"
              :key="item"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import StockItemEditDialog from '@/modules/logistics/components/core/StockItemEditDialog.vue'
import StockTransferDialog from '@/modules/logistics/components/core/StockTransferDialog.vue'
import STOCK_ITEM_LIST from '@/graphql/StockItems.gql'
import STOCK_ITEM_UPDATE from '@/graphql/StockItemUpdate.gql'
import STOCK_TRANSFER from '@/graphql/StockTransfer.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'WarehouseStockList',
  components: { MessageBox, StockItemEditDialog, StockTransferDialog },
  data: () => ({
    searchProp: 'Name',
    searchProps: ['Name', 'Barcode', 'Style Number'],
    search: null,
    nameSearch: null,
    styleSearch: null,
    barcodeSearch: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    selected: [],
    stockItems: { items: [] },
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    headers: [
      {
        text: 'Name',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'style'
      },
      {
        text: 'Color',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'style.seasonColor'
      },
      {
        text: 'Size',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'size'
      },
      {
        text: 'Production Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'productionQuantity'
      },
      {
        text: 'Picking Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'pickingQuantity'
      },
      // {
      //   text: 'Reserved Qty',
      //   align: 'right',
      //   sortable: false,
      //   filterable: true,
      //   class: ' font-weight-bold',
      //   value: 'reservedQuantity'
      // },
      {
        text: 'Available Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        class: ' font-weight-bold',
        value: 'availableQuantity'
      },
      {
        text: 'Physical Qty',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'physicalQuantity'
      },
      {
        text: '',
        value: 'controls',
        sortable: false,
        filterable: true
      }
    ],
    defaultItem: {
      productionQty: null,
      pickingQty: null,
      availableQty: null,
      physicalQty: null,
      notes: ''
    },
    editedItem: {
      productionQty: null,
      pickingQty: null,
      availableQty: null,
      physicalQty: null,
      notes: ''
    },

    stockTranferDialog: false,
    stockTransferItem: {
      fromWarehouseID: null,
      toWarehouseID: null,
      barcode: null,
      quantity: null,
      notes: ''
    }
  }),
  apollo: {
    stockItems: {
      query: STOCK_ITEM_LIST,
      variables() {
        return {
          filters: {
            nameSearch: this.nameSearch,
            styleID: this.styleSearch,
            barcode: this.barcodeSearch,
            warehouseID: this.warehouseID,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.stockItems.page
        this.itemsPerPage = result.data.stockItems.pageSize
        this.itemCount = result.data.stockItems.total
      }
    },
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 10000
          }
        }
      }
    }
  },
  computed: {
    warehouseID() {
      return this.$route.params.warehouseID
    },
    computedHeaders() {
      var items = this.headers
      if (!this.warehouseID)
        items = this.headers.filter(item => item.value !== 'updatedAt')
      return items
    },
    currentRoute() {
      return this.$route.path
    },
    currentWarehouse() {
      return this._.find(
        this.warehouses.items,
        item => item.id === this.$route.params.warehouseID
      )
    },
    stockAdjustAuthLevel() {
      return this.$store.getters.authLevel('stock_update')
    },
    stockList() {
      var that = this

      if(this.stockItems?.items?.length > 0) {
        var items = this._.filter(that.stockItems.items, (l) => l.size)
        that._.filter(items, (item) => that._.sortBy(item.size, (size) => parseInt(size.promeseCode)))
        return items
      } else {
        return []
      }
    }
  },
  watch: {
    search(val) {
      // Reset to first page on search
      this.options.page = 1
      this.nameSearch = null
      this.styleSearch = null
      this.barcodeSearch = null

      if (val) {
        switch (this.searchProp) {
          case 'Style Number':
            this.nameSearch = null
            this.styleSearch = val
            this.barcodeSearch = null
            break
          case 'Barcode':
            this.nameSearch = null
            this.styleSearch = null
            this.barcodeSearch = val
            break
          case 'Name':
            this.nameSearch = val
            this.styleSearch = null
            this.barcodeSearch = null
            break
          default:
            break
        }
      }
    },
    searchProp() {
      this.options.page = 1
      this.search = null
    }
  },
  methods: {
    adjustStockItem(item) {
      this.editedItem = this._.extend(this.defaultItem, item)
      this.editedItem.warehouseID = this.$route.params.warehouseID
      this.editedItem.productionQty = item.productionQuantity.toString()
      this.editedItem.pickingQty = item.pickingQuantity.toString()
      this.editedItem.availableQty = item.availableQuantity.toString()
      this.editedItem.physicalQty = item.physicalQuantity.toString()
      this.dialog = true
    },
    openStockTransferDialog(item) {
      this.stockTransferItem = {
        fromWarehouseID: this.$route.params.warehouseID,
        warehouseProviderID: this.currentWarehouse.provider.id,
        toWarehouseID: null,
        barcode: item.barcode,
        availableQuantity: item.availableQuantity,
        quantity: null,
        notes: ''
      }

      this.stockTranferDialog = true
    },
    close() {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false

      this.stockTranferDialog = false
      this.stockTransferItem = {
        fromWarehouseID: null,
        toWarehouseID: null,
        barcode: null,
        quantity: null,
        notes: ''
      }
    },
    quickAdjust(data) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: STOCK_ITEM_UPDATE,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.$apollo.queries.stockItems.refetch()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Stock Item Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    transferStock(data) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: STOCK_TRANSFER,
          variables: {
            input: data
          }
        })
        .then(() => {
          this.$apollo.queries.stockItems.refetch()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Stock Transfered'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
