<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="px-3">
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="barcodeRangeForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Code Type</label>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="item.barcodeType"
                      hide-details="auto"
                      :items="codeTypes"
                      placeholder="Color code"
                      outlined
                      :rules="requiredRule"
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>From Code</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="item.startCode"
                      hide-details="auto"
                      type="number"
                      autocomplete="off"
                      placeholder="Start Code"
                      :rules="numRule"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Till Code</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="item.endCode"
                      hide-details="auto"
                      type="number"
                      autocomplete="off"
                      placeholder="Last Code"
                      :rules="numRule"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Next Code</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="item.nextCode"
                      type="number"
                      autocomplete="off"
                      placeholder="Next Code"
                      hide-details="auto"
                      :rules="numRule"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-checkbox
                      v-model="item.active"
                      hide-details="auto"
                      label="Active"
                      dense
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BARCODE_RANGES from '@/graphql/BarcodeRanges.gql'
import CREATE_BARCODE_RANGE from '@/graphql/BarcodeRangeCreate.gql'
import UPDATE_BARCODE_RANGE from '@/graphql/BarcodeRangeUpdate.gql'

export default {
  name: 'BarcodeRangeEditDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    from: false,
    till: false,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    numRule: [v => !!v || 'Field is required'],
    codeTypes: [
      'EAN13',
      'EAN8',
      'EAN5',
      'EAN2',
      'UPCA',
      'CODE128',
      'CODE39',
      'ITF14',
      'MSI',
      'PHARMACODE',
      'CODABAR'
    ]
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Barcode Range' : 'New Barcode Range'
    }
  },
  methods: {
    close(success) {
      this.$refs.barcodeRangeForm.resetValidation()
      this.$emit('close-edit-barcode-range-dialog', success)
    },
    save() {
      if (this.editMode) {
        // Update Item
        if (this.$refs.barcodeRangeForm.validate()) {
          let data = {
            id: this.item.id,
            startCode: this.item.startCode,
            endCode: this.item.endCode,
            nextCode: this.item.nextCode,
            barcodeType: this.item.barcodeType,
            active: this.item.active
          }

          this.$apollo
            .mutate({
              mutation: UPDATE_BARCODE_RANGE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // New Item
        if (this.$refs.barcodeRangeForm.validate()) {
          let data = {
            startCode: this.item.startCode,
            endCode: this.item.endCode,
            nextCode: this.item.nextCode,
            barcodeType: this.item.barcodeType,
            active: this.item.active
          }

          this.$apollo
            .mutate({
              mutation: CREATE_BARCODE_RANGE,
              variables: {
                input: data
              },
              update: (store, { data: { createBarcodeRange } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: BARCODE_RANGES
                })
                // Add our requirement from the mutation to the end
                data.barcodeRanges.push(createBarcodeRange)
                // Write our data back to the cache.
                store.writeQuery({ query: BARCODE_RANGES, data })
              }
            })
            .then(() => {
              this.close(true)
            })
            .catch(() => {
              this.$store.commit('showSnackbar', {
                active: true,
                message:
                  'A general server error occured. Please try again later'
              })

              return this.close(false)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-color-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
