<template>
  <div class="app-layout">
    <price-markup-edit-dialog
      :dialog="dialog"
      :edited-item="editedItem"
      :edit-mode="editMode"
      :error-msg="errorMsg"
      @close-edit-price-markup-dialog="close"
      @save-price-markup="saveMarkup"
    ></price-markup-edit-dialog>
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Price Markup</v-card-title
        >
        <v-card-text>
          <p>
            This will delete this price markup. This can't be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="close"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="error"
            text
            @click.prevent="removePriceMarkup"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="page-wrap">
      <ProductionSettingsToolbar>
        <template v-slot:controls>
          <v-btn
            v-if="authLevel === 2"
            fab
            small
            color="primary"
            @click="newItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </ProductionSettingsToolbar>

      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col sm="12" md="10" lg="8" xl="8">
              <v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                    clearable
                    autocomplete="off"
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="markups"
                  :search="search"
                  :loading="$apollo.loading"
                  :mobile-breakpoint="null"
                  class="plain-table"
                  hide-default-footer
                >
                  <template v-slot:no-data>Nothing here yet...</template>
                  <template v-slot:item.markupPercentage="{ item }">
                    <span>{{ item.markupPercentage.toFixed(2) }}</span
                    >%
                  </template>
                  <template v-slot:item.multiplier="{ item }">
                    <span>{{ item.multiplier.toFixed(2) }}</span>
                  </template>
                  <template v-slot:item.margin="{ item }">
                    <span>{{ item.margin.toFixed(2) }}</span
                    >%
                  </template>
                  <template v-slot:item.defaultMarkup="{ item }">
                    <v-chip v-if="item.defaultMarkup" x-small color="secondary"
                      >default</v-chip
                    >
                  </template>
                  <template v-slot:item.controls="{ item }">
                    <v-btn fab elevation="0" x-small
                      ><v-icon small @click.stop="editItem(item)"
                        >mdi-pencil-outline</v-icon
                      ></v-btn
                    >
                    <v-btn class="ml-2" icon small
                      ><v-icon
                        color="error"
                        small
                        @click.stop="openRemoveEditDialog(item)"
                        >mdi-delete-outline</v-icon
                      ></v-btn
                    >
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductionSettingsToolbar from '@/modules/production/components/base/ProductionSettingsToolbar.vue'
import PriceMarkupEditDialog from '@/modules/production/components/core/PriceMarkupEditDialog.vue'
import PRICE_MARKUP_LIST from '@/graphql/PriceMarkups.gql'
import PRICE_MARKUP_CREATE from '@/graphql/PriceMarkupCreate.gql'
import PRICE_MARKUP_UPDATE from '@/graphql/PriceMarkupUpdate.gql'
import PRICE_MARKUP_REMOVE from '@/graphql/PriceMarkupRemove.gql'

export default {
  name: 'ProductionSettingsRetailPriceMarkup',
  components: {
    ProductionSettingsToolbar,
    PriceMarkupEditDialog
  },
  data: () => ({
    dialog: false,
    removeDialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    search: '',
    priceMarkups: [],
    removeablePriceMarkup: null,
    defaultItem: {
      id: '',
      markupType: 'RETAIL',
      name: '',
      description: '',
      markupPercentage: 0,
      multiplier: 0,
      margin: 0,
      defaultMarkup: false
    },
    editedItem: {
      id: '',
      markupType: 'RETAIL',
      name: '',
      description: '',
      markupPercentage: 0,
      multiplier: 0,
      margin: 0,
      defaultMarkup: false
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: true
        },
        {
          text: 'Markup',
          value: 'markupPercentage',
          align: 'left',
          sortable: true
        },
        {
          text: 'Multiplier',
          value: 'multiplier',
          align: 'left',
          sortable: true
        },
        {
          text: 'Margin',
          value: 'margin',
          align: 'left',
          sortable: true
        },
        {
          text: '',
          value: 'defaultMarkup',
          align: 'left',
          sortable: false
        },
        {
          text: '',
          value: 'controls',
          align: 'right',
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('production_settings')
    },
    markups() {
      return this._.filter(this.priceMarkups, function(item) {
        return item.markupType === 'RETAIL'
      })
    }
  },
  apollo: {
    priceMarkups: {
      query: PRICE_MARKUP_LIST
    }
  },
  methods: {
    close() {
      this.processing = false
      this.editMode = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.errorMsg = ''
      this.dialog = false
      this.removeablePriceMarkup = null
      this.removeDialog = false
    },
    newItem() {
      this.editMode = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.editMode = true
      this.editedItem = this._.cloneDeep(item)
      delete this.editedItem.__typename
      delete this.editedItem.updatedAt
      delete this.editedItem.createdAt
      this.dialog = true
    },

    saveMarkup(item) {
      this.processing = true

      if (this.editMode) {
        delete item.markupType

        // Update
        this.$apollo
          .mutate({
            mutation: PRICE_MARKUP_UPDATE,
            variables: {
              input: item
            }
          })
          .then(result => {
            if (result.data.updatePriceMarkup.defaultMarkup) {
              this.$apollo.queries.priceMarkups.refetch()
            }
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Markup Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        // Add new item
        delete item.id

        this.$apollo
          .mutate({
            mutation: PRICE_MARKUP_CREATE,
            variables: {
              input: item
            },
            update: (store, { data: { createPriceMarkup } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PRICE_MARKUP_LIST
              })
              // Add our requirement from the mutation to the end
              data.priceMarkups.push(createPriceMarkup)
              // Write our data back to the cache.
              store.writeQuery({
                query: PRICE_MARKUP_LIST,
                data
              })
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Markup Saved'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    openRemoveEditDialog(data) {
      this.removeablePriceMarkup = data
      this.removeDialog = true
    },
    removePriceMarkup() {
      var that = this

      if (this.removeablePriceMarkup) {
        this.processing = true

        this.$apollo
          .mutate({
            mutation: PRICE_MARKUP_REMOVE,
            variables: {
              id: this.removeablePriceMarkup.id
            },
            update: (store, { data: { removePriceMarkup } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: PRICE_MARKUP_LIST
              })

              let idx = that._.findIndex(data.priceMarkups, function(item) {
                return item.id === removePriceMarkup
              })

              // Add our requirement from the mutation to the end
              if (idx > -1) data.priceMarkups.splice(idx, 1)
              // Write our data back to the cache.
              store.writeQuery({
                query: PRICE_MARKUP_LIST,
                data
              })
            }
          })
          .then(() => {
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Markup Removed'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style></style>
