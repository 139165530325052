<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="shopifyForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredRule"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      label="Description"
                      outlined
                      dense
                      rows="3"
                      hide-details="auto"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.shopifyApiKey"
                      label="Shopify Api Key"
                      outlined
                      dense
                      :rules="requiredRule"
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.shopifyPassword"
                      label="Shopify Password"
                      outlined
                      dense
                      :rules="requiredRule"
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.webhookSecret"
                      label="Shopify Shared Secret"
                      outlined
                      dense
                      :rules="requiredRule"
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.locationID"
                      label="Shopify LocationID"
                      outlined
                      dense
                      hint="Find under settings on your shopify account. Under locations look for the location for this warehouse. Location ID is the last part of the url. This should be a number."
                      persistent-hint
                      :rules="requiredRule"
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.shopName"
                      label="Shop Name"
                      outlined
                      dense
                      hint="This is the part between https:// and .myshopify.com in the example url"
                      persistent-hint
                      :rules="requiredRule"
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="item.inboundOrders"
                      label="Full Service"
                      hint="If set to true this warehouse will be listening to paid/orders from Shopify. Make sure only one warehouse per Shopify Shop has this checked. For Seeding warehouses this should not be checked!"
                      persistent-hint
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="item.active"
                      label="Active"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WarehouseProviderEditDialog',
  props: {
    shopify: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    item: null
  }),
  computed: {
    title() {
      return this.editMode
        ? 'Edit Shopify Integration'
        : 'New Shopify Integration'
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.shopify)
    }
  },
  methods: {
    close() {
      // this.$refs.shopifyForm.reset()
      this.$emit('close-shopify-edit-dialog', false)

      this.$nextTick(() => {
        this.$refs.shopifyForm.resetValidation()
      })
    },
    save() {
      var that = this
      if (this.$refs.shopifyForm.validate()) {
        var data = {
          id: this.item.id,
          name: this.item.name,
          description: this.item.description,
          shopifyApiKey: this.item.shopifyApiKey,
          shopifyPassword: this.item.shopifyPassword,
          webhookSecret: this.item.webhookSecret,
          shopName: this.item.shopName,
          locationID: this.item.locationID,
          warehouseID: this.item.warehouseID,
          active: this.item.active,
          inboundOrders: this.item.inboundOrders
        }

        if (!this.editMode) {
          delete data.id
        } else {
          delete data.warehouseID
        }

        this.$emit('save-shopify', data)
        this.$nextTick(() => {
          that.$refs.shopifyForm.resetValidation()
        })
      }
    }
  }
}
</script>

<style></style>
