var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[(
      !_vm.$apollo.loading &&
        (!_vm.customers || !_vm.customers.items || _vm.customers.items.length > 0)
    )?_c('div',{staticClass:"page-header"},[_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"flat":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary lighten-2","rounded":"","small":""},on:{"click":_vm.addCustomer}},[_vm._v(" add customer ")])],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"main-page-column pt-0"},[_c('customer-edit-dialog',{attrs:{"dialog":_vm.dialog,"customer":_vm.editedItem,"payment-terms":_vm.paymentTerms,"currencies":_vm.currencies,"edit-mode":_vm.editMode,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"close-customer-edit-dialog":_vm.close,"save-customer-edit-dialog":_vm.save}}),_c('div',{staticClass:"scroll-container pt-0"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0"},[(
              _vm.search ||
                (_vm.customers && _vm.customers.items && _vm.customers.items.length > 0)
            )?_c('v-text-field',{attrs:{"hide-details":"","flat":"","clearable":"","background-color":"#fff","placeholder":"Search...","prepend-inner-icon":"mdi-magnify","autocomplete":"off"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-data-table',{staticClass:"plain-table",attrs:{"loading":_vm.$apollo.loading,"headers":_vm.headers,"items":_vm.customers.items,"footer-props":{'items-per-page-options':[15, 30, 50, 100]},"options":_vm.options,"server-items-length":_vm.itemCount,"mobile-breakpoint":null,"fixed-header":"","hide-default-header":!_vm.$apollo.loading &&
              !_vm.search &&
              (!_vm.customers || !_vm.customers.items || _vm.customers.items.length === 0)},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,'from', 'now'))+" ")]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Customers","caption":_vm.search
                  ? 'Try adjusting your filters to get some results'
                  : 'Add your first customer to get started'}},[(!_vm.search)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary lighten-2","rounded":"","small":""},on:{"click":_vm.addCustomer}},[_vm._v(" add customer ")]):_vm._e()],1)]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }