<template>
  <div class="page-wrap">
    <ProductionSettingsToolbar>
      <template v-slot:controls> </template>
    </ProductionSettingsToolbar>

    <div class="main-page-column pt-0">
      <size-group-edit-dialog
        :open="groupDialog"
        :edited-item="editedItem"
        :edit-mode="edit"
        @close-edit-group-dialog="closeGroupDialog"
      ></size-group-edit-dialog>
      <size-edit-dialog
        :open="dialog"
        :edited-item="editedItem"
        :edit-mode="edit"
        @close-edit-size-dialog="close"
      ></size-edit-dialog>

      <!-- Remove Size Group Dialog -->
      <v-dialog v-model="removeSizeGroupDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Size Group</v-card-title
          >
          <v-card-text>
            <p>
              This will delete this group. This can't be undone!
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="grey darken-1"
              text
              :disabled="processing"
              @click="removeSizeGroupDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :loading="processing"
              color="error"
              text
              @click.prevent="removeSizeGroup"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Remove Size Dialog -->
      <v-dialog v-model="removeSizeDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Size</v-card-title
          >
          <v-card-text>
            <p>
              This will delete this size. This can't be undone!
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="grey darken-1"
              text
              :disabled="processing"
              @click="removeSizeDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :loading="processing"
              color="error"
              text
              @click.prevent="removeSize"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="scroll-container pt-0">
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="3">
            <v-card class="mx-auto" flat>
              <v-card-title class="subtitle-1"
                ><span>Groups</span><v-spacer></v-spacer>
                <v-btn rounded small color="primary" @click="newGroup"
                  >Add Group</v-btn
                >
              </v-card-title>
              <v-list v-if="sizeGroups && sizeGroups.length > 0" dense>
                <template v-for="(list, key, idx) in orderedGroups">
                  <v-subheader :key="key + idx" class="px-4">{{
                    key
                  }}</v-subheader>

                  <template v-for="group in list">
                    <v-list-item
                      :key="group.id"
                      color="info"
                      :input-value="activeGroup && activeGroup === group.id"
                      @click="selectGroup(group)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters>
                            <span class="pt-1" v-text="group.name"></span>
                            <v-chip
                              v-if="group.promeseGroupName"
                              x-small
                              color="info"
                              class="ml-3 mt-1"
                              >{{
                                group.promeseGroupName.toUpperCase()
                              }}</v-chip
                            >
                            <v-chip
                              x-small
                              color="secondary"
                              class="ml-3 mt-1"
                              >{{ group.gender }}</v-chip
                            >
                            <v-spacer></v-spacer>

                            <v-btn
                              v-if="
                                selectedGroup && selectedGroup.id === group.id
                              "
                              icon
                              x-small
                              @click.stop="editGroup(group)"
                              ><v-icon>mdi-pencil-outline</v-icon></v-btn
                            >

                            <v-btn
                              v-if="
                                selectedGroup && selectedGroup.id === group.id
                              "
                              icon
                              x-small
                              class="ml-3"
                              @click.stop="openRemoveSizeGroupDialog(group.id)"
                              ><v-icon color="error"
                                >mdi-delete-outline</v-icon
                              ></v-btn
                            >
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </template>
              </v-list>
              <message-box
                v-else
                icon="mdi-emoticon-neutral-outline"
                title="Nothing here"
                caption="Create your first group"
              ></message-box>
            </v-card>
          </v-col>
          <v-col
            v-if="sizeGroups && selectedGroup"
            cols="12"
            sm="12"
            md="8"
            lg="8"
            xl="9"
          >
            <v-card flat>
              <v-card-title v-if="selectedGroup" class="subtitle-1"
                ><span>{{ selectedGroup.name }}</span>
                <v-chip x-small outlined color="info" class="ml-3">{{
                  selectedGroup.scale
                }}</v-chip>
                <v-chip x-small color="secondary" class="ml-3">{{
                  selectedGroup.gender
                }}</v-chip>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="selectedGroup.sizes && selectedGroup.sizes.length > 0"
                  rounded
                  small
                  color="info"
                  @click="newItem"
                >
                  add size
                </v-btn>
              </v-card-title>
              <v-data-table
                v-if="selectedGroup && selectedGroup.sizes.length > 0"
                :headers="headers"
                :items="selectedGroup.sizes"
                :mobile-breakpoint="null"
                :items-per-page="-1"
                fixed-header
                hide-default-footer
              >
                <template v-slot:item.updatedAt="{ item }"
                  ><span>{{
                    item.updatedAt | moment('DD/MM/YY')
                  }}</span></template
                >
                <template v-slot:item.controls="{ item }">
                  <v-row justify="end">
                    <v-btn
                      text
                      rounded
                      x-small
                      color="info"
                      @click.stop="editItem(item)"
                      >edit</v-btn
                    >
                    <v-btn
                      text
                      rounded
                      x-small
                      color="grey"
                      class="ml-3"
                      @click.stop="
                        openRemoveSizeDialog(selectedGroup.id, item.id)
                      "
                      >delete</v-btn
                    >
                  </v-row>
                </template>
              </v-data-table>
              <message-box
                v-else
                icon="mdi-emoticon-neutral-outline"
                title="Empty Size Group"
                caption="Add sizes to your group"
              >
                <v-btn rounded color="info" @click="newItem">
                  add size
                </v-btn>
              </message-box>
            </v-card>
          </v-col>
          <message-box
            v-else
            icon="mdi-cursor-default-click-outline"
            title="No Group Selected"
            caption="Select a group to display and edit sizes"
          >
          </message-box>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import SizeEditDialog from '@/modules/production/components/core/SizeEditDialog.vue'
import SizeGroupEditDialog from '@/modules/production/components/core/SizeGroupEditDialog.vue'
import ProductionSettingsToolbar from '@/modules/production/components/base/ProductionSettingsToolbar.vue'
import SIZE_GROUP_LIST from '@/graphql/SizeGroups.gql'
import SIZE_GROUP_REMOVE from '@/graphql/SizeGroupRemove.gql'
import SIZE_REMOVE from '@/graphql/SizeRemove.gql'

export default {
  name: 'ProductionSettingsSizes',
  components: {
    ProductionSettingsToolbar,
    SizeEditDialog,
    SizeGroupEditDialog,
    MessageBox
  },
  data: () => ({
    dialog: false,
    groupDialog: false,
    edit: false,
    activeGroup: null,
    defaultItem: {
      id: '',
      name: '',
      gender: 'UNISEX',
      description: '',
      scale: 'GLOBAL',
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      name: '',
      gender: 'UNISEX',
      description: '',
      scale: 'GLOBAL',
      updatedAt: new Date(),
      createdAt: new Date()
    },
    removeSizeGroupDialog: false,
    removeSizeGroupID: null,
    removeSizeDialog: false,
    removeSizeID: null,
    processing: false
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Size',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Scale',
          value: 'scale',
          sortable: true,
          filterable: true
        },
        {
          text: 'Code',
          value: 'promeseCode',
          sortable: true,
          filterable: true
        },
        {
          text: 'Last Update',
          value: 'updatedAt',
          sortable: true,
          filterable: true
        },
        {
          text: '',
          value: 'controls',
          sortable: false,
          filterable: false
        }
      ]
    },
    selectedGroup() {
      if (this.activeGroup) {
        return this._.find(this.sizeGroups, { id: this.activeGroup })
      } else {
        return false
      }
    },
    orderedGroups() {
      return this._.groupBy(this.sizeGroups, 'scale')
    }
  },
  apollo: {
    sizeGroups: SIZE_GROUP_LIST
  },
  methods: {
    selectGroup(item) {
      this.activeGroup = item.id
    },
    closeGroupDialog(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.groupDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Size Group Saved'
        })
      }
    },

    newGroup() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.groupDialog = true
    },

    editGroup(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.groupDialog = true
    },
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      this.groupDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Size Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editedItem.sizeGroupID = this.selectedGroup.id
      this.editedItem.gender = this.selectedGroup.gender
      this.editedItem.scale = this.selectedGroup.scale
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },

    openRemoveSizeGroupDialog(id) {
      this.removeSizeGroupID = id
      this.removeSizeGroupDialog = true
    },
    removeSizeGroup() {
      var that = this
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SIZE_GROUP_REMOVE,
          variables: {
            id: this.removeSizeGroupID
          },
          update: (store, { data: { removeSizeGroup } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: SIZE_GROUP_LIST
            })

            let idx = that._.findIndex(data.sizeGroups, function(item) {
              return item.id === removeSizeGroup
            })

            // Remove our requirement from the mutation list
            if (idx > -1) data.sizeGroups.splice(idx, 1)
            // Write our data back to the cache.
            store.writeQuery({
              query: SIZE_GROUP_LIST,
              data
            })
          }
        })
        .then(() => {
          this.processing = false
          this.removeSizeGroupDialog = false
          this.removeSizeGroupID = null
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Size Group Removed'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Somthing went wrong. Please try again later.'
          })
          this.processing = false
        })
    },

    openRemoveSizeDialog(groupID, sizeID) {
      this.removeSizeGroupID = groupID
      this.removeSizeID = sizeID
      this.removeSizeDialog = true
    },
    removeSize() {
      var that = this
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SIZE_REMOVE,
          variables: {
            id: this.removeSizeID
          },
          update: (store, { data: { removeSize } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: SIZE_GROUP_LIST
            })

            let sizeGroupIdx = that._.findIndex(data.sizeGroups, function(
              item
            ) {
              return item.id === that.removeSizeGroupID
            })

            if (sizeGroupIdx > -1) {
              let sizeIdx = that._.findIndex(
                data.sizeGroups[sizeGroupIdx].sizes,
                function(item) {
                  return item.id === removeSize
                }
              )

              // Remove our item from the mutation list
              if (sizeIdx > -1) {
                data.sizeGroups[sizeGroupIdx].sizes.splice(sizeIdx, 1)
                // Write our data back to the cache.
              }
            }

            store.writeQuery({
              query: SIZE_GROUP_LIST,
              data
            })
          }
        })
        .then(() => {
          this.processing = false
          this.removeSizeDialog = false
          this.removeSizeGroupID = null
          this.removeSizeID = null
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Size Removed'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Somthing went wrong. Please try again later.'
          })
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
