<template>
  <v-row justify="center">
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Manufacturer</v-card-title
        >
        <v-card-text>
          <p>
            This will delete this manufacturer. This will also remove all orders
            and products associated with this manufacturer.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closeRemoveDialog(false)"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="openSecondRemoveDialog"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="secondRemoveDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Are you really sure?</v-card-title
        >
        <v-card-text>
          <p>
            This operation can't be undone!
          </p>

          <v-checkbox
            v-model="removalConfirmation"
            color="error"
            label="I understand the concequences!"
          >
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closeRemoveDialog(false)"
            >Cancel</v-btn
          >
          <v-btn
            color="error"
            text
            :disabled="!removalConfirmation"
            @click="removeManufacturer"
            >Remove Manufacturer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col v-if="manufacturer" cols="12" md="10" lg="8" xl="6">
      <v-card flat class="mx-auto">
        <v-card-title class="mb-3">Delete Manufacturer</v-card-title>
        <v-card-subtitle
          >Once you delete this manufacturer, you will lose all data associated
          with it.</v-card-subtitle
        >

        <v-card-text>
          <v-btn
            v-if="
              manufacturer &&
                authLevel &&
                authLevel === 2 &&
                currentUser &&
                (currentUser.isSuperAdmin ||
                  currentUser.userRole === 'director' ||
                  currentUser.userRole === 'seniormanager' ||
                  currentUser.userRole === 'projectmanager')
            "
            color="error lighten-2"
            depressed
            rounded
            small
            class="mt-3 upperCaseSpacing"
            @click="openSecondRemoveDialog"
          >
            delete manufacturer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MANUFACTURER_LIST from '@/graphql/Manufacturers.gql'
import REMOVE_MANUFACTURER from '@/graphql/ManufacturerRemove.gql'

export default {
  name: 'ManufacturerSettings',
  components: {},
  props: {
    manufacturer: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  data: () => ({
    removeDialog: false,
    secondRemoveDialog: false,
    removalConfirmation: false
  }),
  computed: {},
  methods: {
    closeRemoveDialog(success) {
      this.removeDialog = false
      this.secondRemoveDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Manufacturer Removed'
        })
      }
    },

    openSecondRemoveDialog() {
      this.removalConfirmation = false
      this.removeDialog = false
      this.secondRemoveDialog = true
    },

    removeManufacturer() {
      var that = this
      if (this.removalConfirmation) {
        this.$apollo
          .mutate({
            mutation: REMOVE_MANUFACTURER,
            variables: {
              id: that.manufacturer.id
            },
            update: (store, { data: { removeManufacturer } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: MANUFACTURER_LIST
              })

              var idx = that._.findIndex(data.manufacturers, function(item) {
                return item.id === removeManufacturer
              })

              data.manufacturers.splice(idx, 1)

              // Add our requirement from the mutation to the end
              // data.projectCategory.lineItems.push(createProjectLine)
              // Write our data back to the cache.
              store.writeQuery({
                query: MANUFACTURER_LIST,
                data
              })
            }
          })
          .then(() => {
            this.closeRemoveDialog(true)

            this.$router.replace({ name: 'ProductionManufacturerList' })
          })
      }
    }
  }
}
</script>

<style></style>
