<template>
  <div style="display: inline-block" class="b-radius">
    <div class="tboard-column-wrap">
      <h4
        :class="[col.inbox ? '' : 'tboard-column-handle']"
        class="tboard-column-header primary--text text-truncate"
      >
        {{ col.name }}
      </h4>
      <div class="tboard-scroll-column">
        <draggable v-model="list" v-bind="dragOptions">
          <transition-group
            class="item-placeholder"
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <div
              v-for="item in list"
              :key="item.id"
              class="tboard-item pa-3 mb-2 elevation-2"
            >
              <div class="tboard-item-header text-truncate">
                {{ item.name }}
              </div>
              <div class="tboard-item-body">
                Description
              </div>
              <v-row no-gutters class="tboard-item-footer">
                <v-col class="pa-0">
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <avatar
                          :username="
                            item.designer.firstName +
                              ' ' +
                              item.designer.lastName
                          "
                          :size="20"
                          color="white"
                          class="mr-1"
                          style="user-select: none;"
                        ></avatar>
                      </span>
                    </template>
                    <span v-if="item.designer"
                      >{{
                        item.designer.firstName + ' ' + item.designer.lastName
                      }}
                      - Designer</span
                    >
                  </v-tooltip>

                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <avatar
                          :username="
                            item.producer.firstName +
                              ' ' +
                              item.producer.lastName
                          "
                          :size="20"
                          color="white"
                          class="mr-1"
                          style="user-select: none;"
                        ></avatar>
                      </span>
                    </template>
                    <span v-if="item.producer"
                      >{{
                        item.producer.firstName + ' ' + item.producer.lastName
                      }}
                      - Producer</span
                    >
                  </v-tooltip>
                </v-col>

                <v-spacer></v-spacer>
                <v-chip label outlined color="info" x-small>{{
                  item.season.shortName
                }}</v-chip>
              </v-row>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Avatar from 'vue-avatar'

export default {
  name: 'TaskBoardColumn',
  components: {
    draggable,
    Avatar
  },
  props: {
    column: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    orderBy: { type: String, default: 'updatedAt' }
  },
  data: () => ({
    drag: false
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'title',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    col() {
      return this.column
    },
    list: {
      get() {
        return this._.orderBy(this.items, this.orderBy, 'desc')
      },
      set() {}
    }
  }
}
</script>

<style scoped>
.vue-avatar--wrapper {
  float: left;
}

.tboard-column-wrap {
  /* border: 2px solid red; */
  position: relative;
  width: 280px;
  height: calc(100vh - 150px);
  overflow: hidden;
  padding: 15px 10px var(--main-area-offset-y) 10px;

  /* padding-bottom: var(--main-area-offset-y); */
}

.tboard-scroll-column {
  border: 1px solid var(--v-info-lighten2);
  margin-bottom: 30px;
  height: calc(100% - 30px);
  overflow-y: auto;
  border-radius: 5px;
  /* background: #fff; */
  background: linear-gradient(#fff 10%, #f6f9fc 60%);
}

.tboard-column-header {
  border-radius: 4px;
  /* background-color: white; */
  margin-bottom: 15px;
  padding-left: 5px;
}

.tboard-item,
.item-placeholder {
  border-radius: 4px;
  background-color: white;
  /* background-color: var(--v-info-lighten5); */
  border: 2px solid transparent;
  min-height: 100px;
  max-height: 100px;
  display: block;
}

.tboard-item {
  border: 2px solid var(--v-light-theme-base);
}

.tboard-item:hover {
  cursor: pointer;
}

.tboard-item-header {
  /* line-height: 20px; */
  max-height: 40px;
  font-size: 13px;
  color: var(--v-info-darken3);
  /* color: #151b26; */
  position: relative;
  overflow: hidden;
  white-space: normal;
  /* text-overflow: ellipsis; */
  padding-right: 1rem; /* space for ellipsis */
}

/* .tboard-item-header::before {
  content: '...';
  position: absolute;
  bottom: 0;
  right: 0;
} */

/* .tboard-item-header::after {
  content: '';
  position: absolute;
  right: 0; note: not using bottom
  width: 1rem;
  height: 1rem;
  background: white;
} */

.tboard-item-body {
  height: 40px;
  font-size: 13px;
}

.tboard-column-handle {
  cursor: pointer;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
