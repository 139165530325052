<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent scrollable :fullscreen="$vuetify.breakpoint.smAndDown" max-width="auto">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          ><div class="flex-grow-1">Changes</div>
          <div class="flex-grow-1"></div>
          <v-btn
            color="info"
            rounded
            @click="closeDialog()"
            >Close</v-btn
          >
          </v-card-title
        >
        <v-card-text>
          <v-alert dense text type="info" outlined border="left">
            <p>This screen show the changes made between the previous version and the changes made.</p>
            <p>On the left side is the old data and on the right the new data.</p>
            <p>All money is in cents and dates are in UTC time.</p>
          </v-alert>

          <div class="py-5" v-if="oldData || newData">
            <code-diff output-format="side-by-side" :old-string="oldData" :new-string="newData" :context="1000"></code-diff>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-col v-if="product" cols="12" class="pt-0">
      <v-row justify="center">
        <v-col cols="12" md="10" xl="6">
          <v-card flat class="mb-3 px-3">
            <v-card-title>Change Log</v-card-title>
          </v-card>
          <v-card flat>
            <v-card-title class="pt-0">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
                clearable
                autocomplete="off"
                :disabled="changeLog && changeLog.length === 0"
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :search="search"
              :headers="headers"
              :loading="$apollo.loading"
              :items="changeLog"
              :mobile-breakpoint="null"
              :items-per-page="-1"
              fixed-header
              hide-default-footer
            >
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt | moment('LLL') }}
              </template>
              <template v-slot:item.user="{ item }">
                {{ item.user.firstName + " " + item.user.lastName }}
              </template>
              <template v-slot:item.operationType="{ item }">
                <v-chip x-small pill color="success lighten-3 font-weight-bold upperCaseSpacing" text-color="success" v-if="item.operationType === 'CREATE'">create</v-chip>
                <v-chip x-small pill color="warning lighten-1 font-weight-bold upperCaseSpacing" text-color="warning darken-3" v-else-if="item.operationType === 'UPDATE'">update</v-chip>
                <v-chip x-small pill color="error lighten-3 font-weight-bold upperCaseSpacing" text-color="error darken-1" v-if="item.operationType === 'DELETE'">delete</v-chip>
              </template>
              <template v-slot:item.controls="{ item }">
                <v-btn
                  text
                  small
                  rounded
                  color="info"
                  elevation="0"
                  class="mr-2"
                  @click="viewChanges(item)"
                  >view changes</v-btn
                >

              </template>
              <template v-slot:no-data>
                <message-box
                  title="No Results"
                ></message-box>
              </template>
              <template v-slot:loading>
                <v-skeleton-loader
                  v-for="item in 5"
                  :key="item"
                  type="list-item"
                  class="mx-auto"
                ></v-skeleton-loader>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import CHANGE_LOG_BY_STYLE from '@/graphql/ChangeLogByStyle.gql'

export default {
  name: 'ProductionProductChangeLog',
  components: { MessageBox },
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    colors: { type: Array, default: () => [] },
    categories: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    sizeGroups: { type: Array, default: () => [] }
  },
  apollo: {
    changeLogByStyle: {
      query: CHANGE_LOG_BY_STYLE,
      variables() {
        return {
          id: this.$route.params.productID
        }
      },
      fetchPolicy: 'network-only',
      result(result) {
        this.changeLog = result.data.changeLogByStyle
      }
    },
  },
  data: () => ({
    search: null,
    searchMaterial: null,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    changeLog: [],
    dialog: false,
    errorMsg: '',
    oldData: null,
    newData: null
  }),
  computed: {
    materialAuthLevel() {
      return this.$store.getters.authLevel('product_material')
    },
    headers() {
      return [
        {
          text: 'Date',
          align: 'left',
          value: 'createdAt'
        },
        {
          text: '',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'operationType'
        },
        {
          text: 'User',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'user'
        },
        {
          text: 'Description',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'description'
        },
        {
          text: '',
          align: 'right',
          value: 'controls'
        }
      ]
    }
  },
  methods: {
    closeDialog() {
      this.oldData = null
      this.newData = null
      this.dialog = false
    },
    viewChanges(item) {
      this.oldData = JSON.stringify(JSON.parse(item.oldPayload),null,'\t')
      this.newData = JSON.stringify(JSON.parse(item.newPayload),null,'\t')
      this.dialog = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
