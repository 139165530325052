<template>
  <v-row justify="center">
    <v-dialog v-model="collectionDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline primary--text mb-3">{{
          collectionDialogTitle
        }}</v-card-title>
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <p>
            Create a collection to organise your images
          </p>
          <v-form
            ref="collectionForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="collectionName"
                  :rules="requiredRule"
                  hide-details="auto"
                  outlined
                  flat
                  dense
                  clearable
                  background-color="#fff"
                  label="Name"
                  placeholder="Name The Image Collection"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <div class="font-weight-medium">Naming convention</div>
                <div class="caption">
                  Select a naming convention for the image
                </div>
                <v-divider class="mt-2 mb-0"></v-divider>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-radio-group v-model="namingConvention">
                  <v-radio
                    label="Use The Product Name"
                    value="PRODUCTNAME"
                  ></v-radio>
                  <v-radio
                    label="Preserve File Name"
                    value="ORIGINAL"
                  ></v-radio>
                  <v-radio label="Use A Custom Name" value="CUSTOM"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeCollectionDialog"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="createCollection"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageDialog" persistent max-width="550">
      <v-card>
        <v-card-title class="headline primary--text mb-3">{{
          imageDialogTitle
        }}</v-card-title>
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3 pb-0">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="imageForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <!-- <v-row>
              <v-col cols="12" class="pb-0">
                <div class="font-weight-medium">Naming convention</div>
                <div class="caption">
                  Select a naming convention for the image
                </div>
                <v-divider class="mt-2 mb-0"></v-divider>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-radio-group v-model="namingConvention">
                  <v-radio
                    label="Use The Product Name"
                    value="PRODUCTNAME"
                  ></v-radio>
                  <v-radio
                    label="Preserve File Name"
                    value="ORIGINAL"
                  ></v-radio>
                  <v-radio label="Use A Custom Name" value="CUSTOM"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row> -->
            <v-row v-if="advancedImageName">
              <v-col cols="12" class="pb-0">
                <div class="font-weight-medium">Name</div>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-text-field
                  v-model="imageName"
                  :rules="requiredRule"
                  hide-details="auto"
                  outlined
                  flat
                  dense
                  clearable
                  background-color="#fff"
                  placeholder="Image Name"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12" class="pb-0">
                <div class="font-weight-medium">Images</div>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  v-model="imageFiles"
                  :rules="requiredRule"
                  counter
                  multiple
                  placeholder="Select your images"
                  prepend-inner-icon="mdi-image-outline"
                  :prepend-icon="null"
                  outlined
                  dense
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="info accent-4" dark small>
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                    >
                      +{{ imageFiles.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeImageDialog"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="uploadImages"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeImageDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Image</v-card-title
        >
        <v-card-text>
          <p>
            This will delete the product image and can't be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeRemoveImageDialog"
            >Cancel</v-btn
          >
          <v-btn :loading="processing" color="error" text @click="removeFile"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeCollectionDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Collection</v-card-title
        >
        <v-card-text>
          <p>
            This will delete the collection and all product images. This can't
            be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeRemoveCollectionDialog"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="error"
            text
            @click="removeCollection"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col v-if="$apollo.loading" cols="12">
      <v-row>
        <v-col v-for="i in 5" :key="i">
          <v-sheet class="px-3 pt-3 pb-3">
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="!$apollo.loading" cols="12">
      <v-card
        v-if="productImageCollections && productImageCollections.length > 0"
        flat
        class="mb-3"
      >
        <v-card-title class="mb-3"
          ><div>
            <div>Product Images</div>
            <div class="body-2 grey--text">
              Order your product image into logical collections
            </div>
          </div>
          <v-spacer></v-spacer>
          <!-- <asset-upload-button
            v-if="
              !editMode &&
                ((product && imageAuthLevel && imageAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            rounded
            color="primary lighten-3"
            small
            :loading="processing"
            :disabled="processing"
            @cancel="loading = false"
            @selected-files="uploadFile"
            >add collection</asset-upload-button
          > -->
          <v-btn
            v-if="
              !editMode &&
                ((product && imageAuthLevel && imageAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            rounded
            color="primary lighten-3"
            small
            @click="openCollectionDialog(false)"
            >add collection</v-btn
          >
        </v-card-title>
      </v-card>

      <div
        v-if="imageCollections && imageCollections.length > 0"
        flat
        class="pa-3 mt-6"
      >
        <div v-for="collection in imageCollections" :key="collection.id">
          <v-row>
            <v-toolbar flat color="transparent" class="px-0">
              <v-toolbar-title>
                <div class="primary--text text--lighten-1">
                  <span>{{ collection.name }}</span>
                  <v-btn
                    v-if="imageAuthLevel && imageAuthLevel === 2"
                    elevation="0"
                    x-small
                    fab
                    class="ml-3"
                    @click="openCollectionDialog(true, collection)"
                    ><v-icon small color="info"
                      >mdi-pencil-outline</v-icon
                    ></v-btn
                  >
                  <v-btn
                    v-if="imageAuthLevel && imageAuthLevel === 2"
                    elevation="0"
                    x-small
                    fab
                    class="ml-3"
                    @click="openRemoveCollectionDialog(collection)"
                    ><v-icon small color="grey"
                      >mdi-delete-outline</v-icon
                    ></v-btn
                  >
                </div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <asset-upload-button
              v-if="
                !editMode &&
                  ((product && imageAuthLevel && imageAuthLevel === 2) ||
                    (currentUser &&
                      (currentUser.isSuperAdmin ||
                        currentUser.userRole === 'director' ||
                        currentUser.userRole === 'seniormanager' ||
                        currentUser.userRole === 'projectmanager')))
              "
              rounded
              color="secondary"
              small
              :loading="processing"
              :disabled="processing"
              @cancel="loading = false"
              @selected-files="uploadFile"
              >add image</asset-upload-button
            > -->
              <v-btn
                v-if="
                  imageAuthLevel &&
                    imageAuthLevel === 2 &&
                    !editMode &&
                    collection &&
                    collection.namingConvention &&
                    collection.namingConvention === 'CUSTOM' &&
                    ((product && imageAuthLevel && imageAuthLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                outlined
                rounded
                color="secondary"
                small
                @click="openImageDialog(true, collection)"
                >add image</v-btn
              >
              <v-btn
                v-if="
                  imageAuthLevel &&
                    imageAuthLevel === 2 &&
                    !editMode &&
                    collection &&
                    collection.namingConvention &&
                    collection.namingConvention !== 'CUSTOM' &&
                    ((product && imageAuthLevel && imageAuthLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                outlined
                rounded
                color="secondary"
                small
                @click="openImageDialog(false, collection)"
                >add image</v-btn
              >
            </v-toolbar>
          </v-row>

          <v-divider></v-divider>
          <v-row
            v-if="collection.images && collection.images.length > 0"
            class="mt-5"
          >
            <v-card
              v-for="image in orderBy(collection.images, 'createdAt')"
              :key="image.id"
              flat
              outlined
              class="ml-3 mb-3"
            >
              <v-img
                :src="image.files[0].url"
                :lazy-src="image.files[0].url"
                cover
                height="350px"
                max-width="200px"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-actions>
                <v-row justify="center">
                  <v-btn
                    :href="image.files[0].url"
                    fab
                    x-small
                    outlined
                    elevation="0"
                    color="primary lighten-2"
                    class="text-lowercase"
                    target="_blank"
                    ><v-icon>mdi-magnify</v-icon></v-btn
                  >
                  <!-- <v-btn
                    fab
                    x-small
                    outlined
                    elevation="0"
                    color="grey lighten-1"
                    class="ml-2 text-lowercase"
                    @click.prevent="downLoadFile(image)"
                    ><v-icon>mdi-download-outline</v-icon></v-btn
                  > -->
                  <v-btn
                    fab
                    x-small
                    outlined
                    elevation="0"
                    color="grey lighten-1"
                    class="ml-2 text-lowercase"
                    @click.prevent="openRemoveImageDialog(image, collection)"
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                </v-row>
              </v-card-actions>
            </v-card>
          </v-row>
        </div>
      </div>

      <message-box
        v-else
        icon="mdi-brush"
        title="Product Images"
        caption="You have not added any product images to this product yet"
      >
        <v-btn
          v-if="
            !editMode &&
              ((imageAuthLevel && imageAuthLevel === 2) ||
                (currentUser &&
                  (currentUser.isSuperAdmin ||
                    currentUser.userRole === 'director' ||
                    currentUser.userRole === 'seniormanager' ||
                    currentUser.userRole === 'projectmanager')))
          "
          rounded
          color="primary
          "
          small
          @click="openCollectionDialog(false)"
          >add collection</v-btn
        >
      </message-box>
    </v-col>
  </v-row>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import MessageBox from '@/components/core/MessageBox'
// import AssetUploadButton from '@/components/core/AssetUploadButton'
import PRODUCT_IMAGE_COLLECTIONS from '@/graphql/ProductImageCollections.gql'
import PRODUCT_IMAGE_COLLECTION_CREATE from '@/graphql/ProductImageCollectionCreate.gql'
import PRODUCT_IMAGE_COLLECTION_UPDATE from '@/graphql/ProductImageCollectionUpdate.gql'
import PRODUCT_IMAGE_COLLECTION_REMOVE from '@/graphql/ProductImageCollectionRemove.gql'
import PRODUCT_ASSET_CREATE from '@/graphql/ProductAssetCreate.gql'
import PRODUCT_IMAGE_REMOVE from '@/graphql/ProductImageRemove.gql'

export default {
  name: 'ProductionProductImages',
  components: { MessageBox },
  mixins: [Vue2Filters.mixin],
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} }
  },
  data: () => ({
    processing: false,
    editMode: false,
    removeImageDialog: false,
    removeCollectionDialog: false,
    file: null,
    removeableItem: null,
    collectionDialog: false,
    imageDialog: false,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    editedCollection: null,
    collectionName: '',
    advancedImageName: false,
    imageName: '',
    namingConvention: 'PRODUCTNAME',
    imageFiles: [],
    errorMsg: ''
  }),
  computed: {
    imageAuthLevel() {
      return this.$store.getters.authLevel('product_image')
    },
    headers() {
      return [
        {
          text: 'Version',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'version'
        },
        {
          text: 'Uploaded On',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'createdAt'
        },
        {
          text: 'Created By',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'createdBy'
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          filterable: true,
          value: 'controls'
        }
      ]
    },
    imageCollections() {
      return this.productImageCollections
    },
    collectionDialogTitle() {
      return this.editMode ? 'Update Collection' : 'Add Collection'
    },
    imageDialogTitle() {
      return 'Add Image'
    }
  },
  apollo: {
    productImageCollections: {
      query: PRODUCT_IMAGE_COLLECTIONS,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    }
  },
  methods: {
    openCollectionDialog(editMode, collection) {
      this.editMode = editMode

      if (editMode) {
        this.editedCollection = this._.cloneDeep(collection)
        this.collectionName = this.editedCollection.name
        this.namingConvention = this.editedCollection.namingConvention
      }

      this.collectionDialog = true
    },
    closeCollectionDialog() {
      this.collectionDialog = false

      this.processing = false
      this.editMode = false
      this.collectionName = ''
      this.errorMsg = ''
      this.namingConvention = 'PRODUCTNAME'
      this.$refs.collectionForm.resetValidation()
    },
    createCollection() {
      // Create file object
      var that = this

      if (this.$refs.collectionForm.validate()) {
        this.errorMsg = ''
        this.processing = true

        var data = {}

        if (!this.editMode) {
          data = {
            productID: that.$route.params.productID,
            name: that.collectionName,
            namingConvention: that.namingConvention
          }

          // Create a new one
          that.$apollo
            .mutate({
              mutation: PRODUCT_IMAGE_COLLECTION_CREATE,
              variables: {
                input: data
              },
              update: (store, { data: { createProductImageCollection } }) => {
                const data = store.readQuery({
                  query: PRODUCT_IMAGE_COLLECTIONS,
                  variables: {
                    productID: createProductImageCollection.productID
                  }
                })

                data.productImageCollections.push(createProductImageCollection)

                store.writeQuery({
                  query: PRODUCT_IMAGE_COLLECTIONS,
                  variables: {
                    productID: createProductImageCollection.productID
                  },
                  data
                })
              }
            })
            .then(() => {
              that.closeCollectionDialog()

              that.$store.commit('showSnackbar', {
                active: true,
                message: 'Collection Added'
              })
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong'

              this.processing = false
            })
        } else {
          data = {
            id: that.editedCollection.id,
            name: that.collectionName,
            namingConvention: that.namingConvention
          }
          // Update the collection
          that.$apollo
            .mutate({
              mutation: PRODUCT_IMAGE_COLLECTION_UPDATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              that.closeCollectionDialog()

              that.$store.commit('showSnackbar', {
                active: true,
                message: 'Collection Updated'
              })
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong'

              this.processing = false
            })
        }
      }
    },
    openImageDialog(advanced, collection) {
      if (advanced) {
        this.advancedImageName = true
      }

      this.editedCollection = this._.cloneDeep(collection)

      this.imageDialog = true
    },
    closeImageDialog() {
      this.imageDialog = false

      this.processing = false
      this.editMode = false
      this.errorMsg = ''
      this.advancedImageName = false
      this.editedCollection = false

      this.$refs.imageForm.reset()
    },
    uploadImages() {
      // Create file object
      var that = this
      this.processing = true

      if (this.$refs.imageForm.validate()) {
        this._.forEach(this.imageFiles, function(file, index) {
          let data = {
            name: that.imageName,
            collectionID: that.editedCollection.id,
            productID: that.$route.params.productID,
            assetType: 'PRODUCTIMAGE',
            versioned: false,
            file: file
          }

          // Create a new one
          that.$apollo
            .mutate({
              mutation: PRODUCT_ASSET_CREATE,
              variables: {
                input: data
              },
              update: (store, { data: { createProductAsset } }) => {
                const data = store.readQuery({
                  query: PRODUCT_IMAGE_COLLECTIONS,
                  variables: {
                    productID: createProductAsset.productID
                  }
                })

                let collectionIdx = that._.findIndex(
                  data.productImageCollections,
                  item => item.id === that.editedCollection.id
                )

                if (!data.productImageCollections[collectionIdx].images) {
                  data.productImageCollections[collectionIdx].images = []
                }

                data.productImageCollections[collectionIdx].images.push(
                  createProductAsset
                )

                store.writeQuery({
                  query: PRODUCT_IMAGE_COLLECTIONS,
                  variables: { productID: createProductAsset.productID },
                  data
                })
              }
            })
            .then(() => {
              if (that.imageFiles.length - 1 === index) {
                // All successful
                that.closeImageDialog()
                that.$store.commit('showSnackbar', {
                  active: true,
                  message: 'Product Image Added'
                })
              }
            })
            .catch(() => {
              that.closeImageDialog()

              that.$store.commit('showSnackbar', {
                active: true,
                message: 'Something went wrong. Please try again later.'
              })

              // Break from loop
              return false
            })
        })
      }
    },
    downLoadFile(file) {
      console.log(file)
    },
    openRemoveCollectionDialog(collection) {
      this.removeableItem = this._.cloneDeep(collection)
      this.removeCollectionDialog = true
    },
    closeRemoveCollectionDialog() {
      this.removeCollectionDialog = false
      this.removeableItem = null
      this.processing = false
    },
    removeCollection() {
      var vm = this
      this.processing = true
      if (this.removeableItem && this.removeableItem.id) {
        this.$apollo
          .mutate({
            mutation: PRODUCT_IMAGE_COLLECTION_REMOVE,
            variables: {
              id: vm.removeableItem.id
            },
            update: (store, { data: { removeProductImageCollection } }) => {
              const data = store.readQuery({
                query: PRODUCT_IMAGE_COLLECTIONS,
                variables: {
                  productID: vm.removeableItem.productID
                }
              })

              let collectionIdx = vm._.findIndex(
                data.productImageCollections,
                item => item.id === removeProductImageCollection
              )

              // Remove the item
              data.productImageCollections.splice(collectionIdx, 1)

              store.writeQuery({
                query: PRODUCT_IMAGE_COLLECTIONS,
                variables: {
                  productID: vm.removeableItem.productID
                },
                data
              })
            }
          })
          .then(() => {
            this.closeRemoveCollectionDialog()

            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Collection Removed'
            })
          })
          .catch(() => {
            this.closeRemoveCollectionDialog()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong'
            })
          })
      } else {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Nothing to remove'
        })
        this.processing = false
        this.removeCollectionDialog = false
      }
    },
    openRemoveImageDialog(item, collection) {
      this.removeableItem = item
      this.editedCollection = this._.cloneDeep(collection)
      this.removeImageDialog = true
    },
    closeRemoveImageDialog() {
      this.removeImageDialog = false
      this.removeableItem = null
      this.editedCollection = null
      this.processing = false
    },
    removeFile() {
      var vm = this
      this.processing = true
      if (this.removeableItem && this.removeableItem.id) {
        this.$apollo
          .mutate({
            mutation: PRODUCT_IMAGE_REMOVE,
            variables: {
              id: this.removeableItem.id
            },
            update: (store, { data: { removeProductImage } }) => {
              const data = store.readQuery({
                query: PRODUCT_IMAGE_COLLECTIONS,
                variables: {
                  productID: vm.editedCollection.productID
                }
              })

              let collectionIdx = vm._.findIndex(
                data.productImageCollections,
                item => item.id === vm.editedCollection.id
              )

              let paIdx = vm._.findIndex(
                data.productImageCollections[collectionIdx].images,
                function(item) {
                  return item.id === removeProductImage
                }
              )

              data.productImageCollections[collectionIdx].images.splice(
                paIdx,
                1
              )

              // Remove the item

              store.writeQuery({
                query: PRODUCT_IMAGE_COLLECTIONS,
                variables: {
                  productID: vm.editedCollection.productID
                },
                data
              })
            }
          })
          .then(() => {
            this.closeRemoveImageDialog()

            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Image Removed'
            })
          })
          .catch(() => {
            this.closeRemoveImageDialog()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong'
            })
          })
      } else {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Nothing to remove'
        })
        this.processing = false
        this.removeImageDialog = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.file-card {
  width: 200px;
  font-size: 0.8em;
}
</style>
