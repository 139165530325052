<template>
  <v-row justify="center">
    <v-col v-if="product" cols="12" md="10" lg="8" xl="6">
      <v-card flat class="mb-3">
        <v-card-title
          ><span>Seasons</span><v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="500">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="
                  product &&
                    product.styleState &&
                    product.styleState === 'FINAL' &&
                    seasonAuthLevel &&
                    seasonAuthLevel === 2 &&
                    unselectedSeasons &&
                    seasons.length > 0 &&
                    selectedSeason &&
                    ((seasonAuthLevel && seasonAuthLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                color="primary lighten-2"
                rounded
                small
                v-on="on"
              >
                add to season
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline mb-3"
                >Add Product To Season</v-card-title
              >
              <v-card-text>
                <v-alert type="info" outlined dense border="left">
                  This will add the product to the selected season.
                </v-alert>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent
                >
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.seasonID"
                        :items="unselectedSeasons"
                        outlined
                        dense
                        autocomplete
                        label="Season"
                        hint="Season to add the product to"
                        persistent-hint
                        color="info"
                        item-value="id"
                        item-text="longName"
                        background-color="white"
                        hide-details="auto"
                        :rules="requiredSelectRule"
                      >
                        <template v-slot:item="{ item }">
                          {{ item.longName }} ({{ item.shortName }})
                        </template>
                        <template v-slot:selection="{ item }">
                          {{ item.longName }} ({{ item.shortName }})
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col cols="12">
                      <v-select
                        :items="usedSeasons"
                        outlined
                        dense
                        autocomplete
                        label="Copy From Season"
                        hint="Season to copy settings from"
                        persistent-hint
                        color="info"
                        item-value="id"
                        item-text="longName"
                        return-object
                        background-color="white"
                        hide-details="auto"
                      >
                        <template v-slot:item="{ item }">
                          {{ item.longName }} ({{ item.shortName }})
                        </template>
                        <template v-slot:selection="{ item }">
                          {{ item.longName }} ({{ item.shortName }})
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn
                  color="grey darken-1"
                  text
                  :disabled="processing"
                  @click="cancel"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  rounded
                  small
                  :loading="processing"
                  @click="saveToSeason"
                  >Add Product</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-subtitle class="mb-3"
          >List of seasons the product is available in</v-card-subtitle
        >

        <v-data-table
          :headers="headers"
          :loading="$apollo.loading"
          :items="product.availableSeasons"
          :mobile-breakpoint="null"
          hide-default-footer
        >
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SEASON_PRODUCT_CREATE from '@/graphql/SeasonProductCreate.gql'

export default {
  name: 'ProductionProductSeasons',
  components: {},
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    seasons: { type: Array, default: () => [] },
    selectedSeason: { type: String, default: '' }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      processing: false,
      requiredSelectRule: [v => !!v || 'Field is required'],
      editedItem: {
        productID: null,
        seasonID: null
      }
    }
  },
  computed: {
    seasonAuthLevel() {
      return this.$store.getters.authLevel('product_season')
    },
    headers() {
      return [
        {
          text: 'Code',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'shortName'
        },
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'longName'
        }
      ]
    },
    unselectedSeasons() {
      return this._.differenceBy(this.seasons, this.usedSeasons, 'id')
    },
    usedSeasons() {
      return this.product.availableSeasons
    }
  },
  created() {},
  methods: {
    cancel() {
      this.dialog = false
      this.$refs.form.reset()
    },
    saveToSeason() {
      // var that = this
      this.errorMsg = ''

      if (this.$refs.form.validate()) {
        var data = {
          productID: this.$route.params.productID,
          seasonID: this.editedItem.seasonID
        }

        this.processing = true

        this.$apollo
          .mutate({
            mutation: SEASON_PRODUCT_CREATE,
            variables: {
              input: data
            }
          })
          .then(() => {
            // let path = this.$route.path
            this.processing = false
            this.dialog = false
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Product Added to season'
            })
            // this.$route.push({
            //   path: path,
            //   query: {
            //     season: result.data.createSeasonProduct.seasonID
            //   }
            // })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style lang="scss"></style>
