<template>
  <v-row>
    <product-edit-dialog
      v-if="product"
      :dialog="editDialog"
      :edit="editMode"
      :seasons="product.seasons"
      :product="editedItem"
      :colors="colors"
      :categories="categories"
      :article-groups="articleGroups"
      :sub-article-groups="subArticleGroups"
      :style-types="styleTypes"
      :processing="processing"
      :error-msg="errorMsg"
      @save-product-edit-dialog="saveProduct"
      @close-product-edit-dialog="closeEditProductDialog"
    >
    </product-edit-dialog>
    <product-size-dialog
      v-if="product"
      :dialog="editSizeDialog"
      :edit="editMode"
      :size-groups="sizeGroups"
      :product="editedItem"
      :processing="processing"
      :error-msg="errorMsg"
      @save-product-size-dialog="saveSizes"
      @close-product-size-dialog="closeEditSizeDialog"
    >
    </product-size-dialog>
    <product-size-barcode-edit-dialog
      :dialog="editSizeBarcodeDialog"
      :edit="editMode"
      :product-size="editProductSize"
      :processing="processing"
      :error-msg="errorMsg"
      @save-product-size-barcode-edit-dialog="updateProductSizeBarcode"
      @close-product-size-barcode-edit-dialog="closeEditSizeBarcodeDialog"
    >
    </product-size-barcode-edit-dialog>

    <v-dialog v-model="packShotDialog" persistent max-width="550">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Upload {{ packShotTitle }} Packshot Image</v-card-title
        >
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3 pb-0">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="packShotForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row class="mt-2">
              <v-col cols="12">
                <v-file-input
                  v-model="packShotFile"
                  :rules="requiredRule"
                  placeholder="Select your image"
                  prepend-inner-icon="mdi-image-outline"
                  :prepend-icon="null"
                  outlined
                  dense
                  :show-size="1000"
                  hide-details="auto"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip color="info accent-4" dark small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closePackshotDialog"
            >Cancel</v-btn
          >
          <v-btn
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="uploadPackshot"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col v-if="product" cols="12" md="6" lg="6" xl="6">
      <v-row>
        <v-col cols="12" class="pt-0">
          <!-- Pack Shots -->
          <v-card flat class="mb-3">
            <v-card-title>Pack Shots</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12" md="6">
                      <v-row justify="space-around">
                        <v-img
                          max-width="200"
                          style="border-radius: 4px"
                          :src="
                            product &&
                            product.frontImage &&
                            product.frontImage !== ''
                              ? product.frontImage
                              : '/DAILY_PAPER_LOGO.png'
                          "
                        ></v-img>
                      </v-row>
                      <v-row justify="center" class="pt-6 pb-0">
                        <v-btn
                          text
                          small
                          color="info"
                          @click="openPackshotDialog('FRONT')"
                          >Change Front</v-btn
                        >
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6" class="justify-center">
                      <v-row justify="space-around">
                        <v-img
                          max-width="200"
                          style="border-radius: 4px"
                          center
                          :src="
                            product &&
                            product.rearImage &&
                            product.rearImage !== ''
                              ? product.rearImage
                              : '/DAILY_PAPER_LOGO.png'
                          "
                        ></v-img>
                      </v-row>
                      <v-row justify="center" class="pt-6 pb-0">
                        <v-btn
                          text
                          small
                          color="info"
                          @click="openPackshotDialog('REAR')"
                          >Change Rear</v-btn
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Details -->
          <v-card flat class="mb-3">
            <v-card-title
              ><span>Details</span><v-spacer></v-spacer
              ><v-btn
                v-if="
                  product &&
                    product.styleState &&
                    product.styleState === 'DEVELOPMENT' &&
                    designAuthLevel &&
                    designAuthLevel === 2
                "
                outlined
                rounded
                color="grey"
                small
                @click="editProduct"
              >
                edit details
              </v-btn></v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <div class="mb-2">Name</div>
                      <div class="font-weight-bold text-truncate">
                        {{ product.name }}
                      </div>
                      <div class="font-weight-bold mt-3">
                        <v-chip
                          v-if="product.gender"
                          small
                          color="info lighten-1 font-weight-bold"
                          >{{ product.gender.toLowerCase() }}</v-chip
                        >
                        <v-chip
                          small
                          color="warning font-weight-bold"
                          class="ml-3"
                          >{{ product.category.name }}</v-chip
                        >
                        <v-chip
                          v-if="product.articleGroup && product.articleGroup.name"
                          small
                          color="purple lighten-2 font-weight-bold"
                          text-color="white"
                          class="ml-3"
                          >{{ product.articleGroup.name }}</v-chip
                        >
                        <v-chip
                        v-if="product.subArticleGroup && product.subArticleGroup.name"
                          small
                          color="error lighten-2 font-weight-bold"
                          class="ml-3"
                          >{{ product.subArticleGroup.name }}</v-chip
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <div class="mb-2">Style Number</div>
                      <div class="font-weight-bold">
                        {{ product.styleNumber }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <div class="mb-2">Warehouse Color</div>
                      <div
                        v-if="product.color && product.color.id"
                        class="font-weight-bold"
                      >
                        {{ product.color.name }} ({{ product.color.colorCode }})
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <div class="mb-2">Season Color</div>
                      <div
                        v-if="product.color && product.color.id"
                        class="font-weight-bold"
                      >
                        {{ product.seasonColor }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <div class="mb-2">Category</div>
                      <div
                        v-if="product.category && product.category.id"
                        class="font-weight-bold"
                      >
                        {{ product.category.name }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <div class="mb-2">Style Type</div>
                      <div
                        v-if="product.styleType && product.styleType.id"
                        class="font-weight-bold"
                      >
                        {{ product.styleType.name }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <div class="mb-2">Article Group</div>
                      <div
                        v-if="product.articleGroup && product.articleGroup.id"
                        class="font-weight-bold"
                      >
                        {{ product.articleGroup.name }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row>
                    <v-col>
                      <div class="mb-2">Sub Article Group</div>
                      <div
                        v-if="product.subArticleGroup && product.subArticleGroup.id"
                        class="font-weight-bold"
                      >
                        {{ product.subArticleGroup.name }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <div class="mb-2">Description</div>
                  <v-row no-gutters justify="start">
                    <v-col class="font-weight-bold">{{
                      product.description
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Team -->
          <v-card v-if="teamAuthLevel && teamAuthLevel > 0" flat class="mb-3">
            <v-card-title class="mb-3"
              ><span>Team</span><v-spacer></v-spacer
            ></v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <div class="mb-2">Designers</div>
                  <v-row no-gutters justify="start">
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="product.designers"
                        :items="designers"
                        :search-input.sync="searchDesigner"
                        item-text="firstName"
                        solo
                        flat
                        hide-details
                        hide-selected
                        hide-no-data
                        attach
                        chips
                        background-color="#fff"
                        placeholder="Add Designers"
                        return-object
                        multiple
                        class="team-selection"
                        :disabled="
                          (product &&
                            product.styleState &&
                            product.styleState !== 'DEVELOPMENT') ||
                            !teamAuthLevel ||
                            teamAuthLevel !== 2
                        "
                        @change="addDesigner"
                      >
                        <template v-slot:selection="subData"
                          ><v-chip
                            pill
                            small
                            :close="
                              product &&
                                product.styleState &&
                                product.styleState === 'DEVELOPMENT'
                            "
                            color="primary lighten-4"
                            :class="
                              product &&
                              product.styleState &&
                              product.styleState !== 'DEVELOPMENT'
                                ? ''
                                : 'pr-1'
                            "
                            @click:close="removeDesigner(subData.item)"
                          >
                            {{
                              subData.item.firstName +
                                ' ' +
                                subData.item.lastName
                            }}</v-chip
                          ></template
                        >
                        <template v-slot:item="{ item }">
                          <span>{{
                            item.firstName + ' ' + item.lastName
                          }}</span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-4"></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <div class="mb-2">Product Developers</div>
                  <v-row no-gutters justify="start">
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="product.producers"
                        :items="productDevelopers"
                        :search-input.sync="searchProducer"
                        item-text="firstName"
                        hide-details
                        hide-selected
                        hide-no-data
                        solo
                        flat
                        attach
                        chips
                        background-color="#fff"
                        placeholder="Add Producers"
                        autocomplete="off"
                        return-object
                        multiple
                        class="team-selection"
                        :disabled="
                          (product &&
                            product.styleState &&
                            product.styleState !== 'DEVELOPMENT') ||
                            !teamAuthLevel ||
                            teamAuthLevel !== 2
                        "
                        @change="addProducer"
                      >
                        <template v-slot:selection="subData"
                          ><v-chip
                            pill
                            small
                            :close="
                              product &&
                                product.styleState &&
                                product.styleState === 'DEVELOPMENT'
                            "
                            color="primary lighten-4"
                            :class="
                              product &&
                              product.styleState &&
                              product.styleState !== 'DEVELOPMENT'
                                ? ''
                                : 'pr-1'
                            "
                            @click="subData.select"
                            @click:close="removeProducer(subData.item)"
                            >{{
                              subData.item.firstName +
                                ' ' +
                                subData.item.lastName
                            }}</v-chip
                          ></template
                        >
                        <template v-slot:item="{ item }">
                          <span>{{
                            item.firstName + ' ' + item.lastName
                          }}</span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- Sizes -->
    <v-col v-if="product" cols="12" md="6" lg="6" xl="6">
      <v-card flat class="mb-3">
        <v-card-title class="mb-3"
          ><span>Sizes</span><v-spacer></v-spacer
          ><v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                developmentAuthLevel &&
                developmentAuthLevel === 2
            "
            outlined
            rounded
            color="grey"
            small
            @click="editSizes"
          >
            <v-icon>mdi-plus</v-icon> add sizes
          </v-btn></v-card-title
        >
        <v-card-text>
          <v-row>
            <v-col v-if="product.sizes" cols="12" class="pt-0">
              <v-data-table
                :headers="headers"
                :loading="$apollo.loading"
                :items="product.sizes"
                :mobile-breakpoint="null"
                :items-per-page="-1"
                fixed-header
                hide-default-footer
              >
                <template v-slot:item.createdAt="{ item }">
                  {{ item.createdAt | moment('from', 'now') }}
                </template>
                <template v-slot:item.scale="{ item }">
                  {{ item.gender }}
                  <span v-if="item.gender && item.scale"> - </span>
                  {{ item.scale }}
                </template>
                <template v-slot:item.controls="{ item }">
                  <!-- <v-btn
                  v-if="
                    product &&
                      product.styleState &&
                      product.styleState === 'DEVELOPMENT'
                  "
                  fab
                  x-small
                  elevation="0"
                  class="mr-2"
                  @click="editMaterial(item)"
                  ><v-icon>mdi-pencil-outline</v-icon></v-btn
                > -->

                  <v-btn
                    v-if="!item.barcode"
                    text
                    x-small
                    color="error lighten-2"
                    elevation="0"
                    @click="removeSize(item.id)"
                    >remove</v-btn
                  >
                  <v-btn
                    v-if="product && product.styleState === 'DEVELOPMENT'"
                    text
                    x-small
                    color="info lighten-2"
                    elevation="0"
                    @click="editBarcode(item)"
                    >edit barcode</v-btn
                  >
                </template>
                <template v-slot:loading>
                  <v-skeleton-loader
                    v-for="item in 5"
                    :key="item"
                    type="list-item"
                    class="mx-auto"
                  ></v-skeleton-loader>
                </template>
              </v-data-table>
            </v-col>

            <v-col v-else cols="12">
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Sizes"
                caption="Add some sizes to get started"
              ></message-box>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import MessageBox from '@/components/core/MessageBox.vue'
import ProductEditDialog from '@/modules/production/components/core/ProductEditDialog.vue'
import ProductSizeDialog from '@/modules/production/components/core/ProductSizeDialog.vue'
import ProductSizeBarcodeEditDialog from '@/modules/production/components/core/ProductSizeBarcodeEditDialog.vue'
import UPDATE_PRODUCT from '@/graphql/ProductUpdate.gql'
import UPLOAD_PRODUCT_PACK_SHOT from '@/graphql/ProductPackshotUpload.gql'
// import PRODUCT from '@/graphql/Product.gql'
import ADD_TEAM_MEMBER from '@/graphql/ProductTeamMemberAdd.gql'
import REMOVE_TEAM_MEMBER from '@/graphql/ProductTeamMemberRemove.gql'
import CREATE_PRODUCT_SIZES from '@/graphql/ProductSizesCreate.gql'
import UPDATE_PRODUCT_SIZE_BARCODE from '@/graphql/ProductSizeBarcodeUpdate.gql'
import REMOVE_PRODUCT_SIZE from '@/graphql/ProductSizeRemove.gql'

export default {
  name: 'ProductionProductDetail',
  components: {
    MessageBox,
    ProductEditDialog,
    ProductSizeDialog,
    ProductSizeBarcodeEditDialog
  },
  mixins: [Vue2Filters.mixin],
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    colors: { type: Array, default: () => [] },
    categories: { type: Array, default: () => [] },
    articleGroups: { type: Array, default: () => [] },
    subArticleGroups: { type: Array, default: () => [] },
    styleTypes: { type: Array, default: () => [] },
    departments: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    sizeGroups: { type: Array, default: () => [] }
  },
  data: () => ({
    packShotDialog: false,
    packShotFile: null,
    packShotType: null,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],

    editDialog: false,
    editSizeDialog: false,
    editSizeBarcodeDialog: false,
    processing: false,
    editMode: false,
    errorMsg: '',
    defaultItem: {
      id: '',
      name: '',
      styleNumber: '',
      description: '',
      designer: {}
    },
    editedItem: {
      id: '',
      name: '',
      styleNumber: '',
      description: '',
      designer: {}
    },
    editProductSize: {},
    searchDesigner: null,
    searchProducer: null
  }),
  computed: {
    packShotTitle() {
      return this.packShotType === 'FRONT' ? 'Front' : 'Rear'
    },
    salesAuthLevel() {
      return this.$store.getters.authLevel('product_sales_details')
    },
    designAuthLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    developmentAuthLevel() {
      return this.$store.getters.authLevel('product_development_details')
    },
    teamAuthLevel() {
      return this.$store.getters.authLevel('product_team')
    },
    headers() {
      return [
        {
          text: 'Size',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'name'
        },
        {
          text: '',
          value: 'scale',
          sortable: true,
          filterable: true
        },
        {
          text: 'Barcode',
          align: 'left',
          value: 'barcode'
          // sortable: true,
          // filter: this.stateFilterValue
        },
        {
          text: 'Created On',
          align: 'left',
          value: 'createdAt'
          // sortable: true,
          // filter: this.stateFilterValue
        },
        {
          text: '',
          align: 'right',
          value: 'controls',
          sortable: false,
          filterable: false
        }
      ]
    },
    orderedSizeGroups() {
      return this._.chain(this.product.sizes)
        .groupBy('scale')
        .map((value, key) => ({ name: key, items: value }))
        .value()
      // return this._.groupBy()
    },
    designDepartment() {
      return this._.find(this.departments, item => item.name === 'Design')
    },
    productionDepartment() {
      return this._.find(this.departments, item => item.name === 'Production')
    },
    designers() {
      var vm = this

      if (this.designDepartment) {
        return this._.filter(
          this.users,
          item => item.department.id === vm.designDepartment.id
        )
      } else {
        return []
      }
    },
    productDevelopers() {
      var vm = this
      if (this.productionDepartment) {
        return this._.filter(
          this.users,
          item => item.department.id === vm.productionDepartment.id
        )
      } else {
        return []
      }
    }
    // orderedGroups() {
    //   return this._.groupBy(this.sizeGroups, 'name')
    // }
  },

  methods: {
    closePackshotDialog() {
      this.packShotFile = null
      this.packShotFile = null
      this.packShotDialog = false
    },
    openPackshotDialog(packType) {
      this.packShotType = packType
      this.packShotDialog = true
    },

    uploadPackshot() {
      this.errorMsg = ''
      this.processing = true
      if (this.$refs.packShotForm.validate()) {
        var data = {
          productID: this.$route.params.productID,
          file: this.packShotFile,
          type: this.packShotType
        }

        this.$apollo
          .mutate({
            mutation: UPLOAD_PRODUCT_PACK_SHOT,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.closeEditProductDialog(true)
            this.$emit('change-state')
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },

    // Product methods
    closeEditProductDialog(success) {
      this.editDialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.editMode === true) {
          msg = 'Product Updated'
        } else {
          msg = 'Product Added'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },

    editProduct() {
      this.editMode = true
      let item = this._.cloneDeep(this.product)
      this.editedItem = this._.cloneDeep(item)
      this.editDialog = true
    },

    saveProduct(item) {
      this.processing = true

      // Save Item
      this.$apollo
        .mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.closeEditProductDialog(true)
          this.$emit('change-state')
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    // Sizes methods
    editSizes() {
      this.editMode = true
      let item = this._.cloneDeep(this.product)
      this.editedItem = this._.cloneDeep(item)
      this.editSizeDialog = true
    },

    closeEditSizeDialog(success) {
      this.editSizeDialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.editMode === true) {
          msg = 'Size Updated'
        } else {
          msg = 'Size Added'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },

    editBarcode(data) {
      this.editProductSize = this._.cloneDeep(data)
      this.editSizeBarcodeDialog = true
    },

    closeEditSizeBarcodeDialog(success) {
      this.editSizeBarcodeDialog = false
      this.processing = false
      this.errorMsg = ''
      this.editProductSize = {}

      if (success) {
        let msg = ''

        msg = 'Barcode Updated'

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },

    saveSizes(item) {
      this.processing = true
      // var that = this

      // Save Item
      this.$apollo
        .mutate({
          mutation: CREATE_PRODUCT_SIZES,
          variables: {
            input: item
          }
          // update: (store, { data: { createProductSizes } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: PRODUCT,
          //     variables: {
          //       id: that.$route.params.productID,
          //       seasonID: that.product.season.id
          //     }
          //   })
          //   if (!data.product.sizes) {
          //     data.product.sizes = []
          //   }
          //   // Add our requirement from the mutation to the end
          //   data.product.sizes.push(...createProductSizes)

          //   // Write our data back to the cache.
          //   store.writeQuery({
          //     query: PRODUCT,
          //     variables: {
          //       id: that.$route.params.productID,
          //       seasonID: that.product.season.id
          //     },
          //     data
          //   })
          // }
        })
        .then(() => {
          this.$emit('change-state')
          this.closeEditSizeDialog(true)
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    updateProductSizeBarcode(item) {
      this.processing = true
      // var that = this

      // Save Item
      this.$apollo
        .mutate({
          mutation: UPDATE_PRODUCT_SIZE_BARCODE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('change-state')
          this.closeEditSizeBarcodeDialog(true)
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    removeSize(id) {
      this.$apollo
        .mutate({
          mutation: REMOVE_PRODUCT_SIZE,
          variables: { id: id }
          // update: (store, { data: { removeProductSize } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: PRODUCT,
          //     variables: {
          //       id: that.product.id,
          //       seasonID: that.product.season.id
          //     }
          //   })

          //   // remove the item
          //   // let idx = that._.indexOf(data.product.sizes, function(size) {
          //   //   return size.id === removeProductSize
          //   // })

          //   var idx = that._.findIndex(
          //     data.product.sizes,
          //     item => item.id === removeProductSize
          //   )

          //   data.product.sizes.splice(idx, 1)

          //   // Write our data back to the cache.
          //   store.writeQuery({
          //     query: PRODUCT,
          //     variables: {
          //       id: that.product.id,
          //       seasonID: that.product.season.id
          //     },
          //     data
          //   })
          // }
        })
        .then(() => {
          this.$emit('change-state')
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Size Removed'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again'
          })
        })
    },

    // Team methods
    addDesigner(items) {
      var newItem = this._.last(items)
      this.searchDesigner = null

      let item = {
        userID: newItem.id,
        productID: this.product.id,
        productRole: 'DESIGNER',
        seasonID: this.product.season.id
      }

      this.addTeamMember(item)
    },
    removeDesigner(item) {
      let data = {
        userID: item.id,
        productID: this.product.id,
        productRole: 'DESIGNER',
        seasonID: this.product.season.id
      }

      this.searchDesigner = null

      this.removeTeamMember(data)
    },

    addProducer(items) {
      var newItem = this._.last(items)
      this.searchProducer = null

      let item = {
        userID: newItem.id,
        productID: this.product.id,
        productRole: 'PRODUCER',
        seasonID: this.product.season.id
      }

      this.addTeamMember(item)
    },

    removeProducer(item) {
      let data = {
        userID: item.id,
        productID: this.product.id,
        productRole: 'PRODUCER',
        seasonID: this.product.season.id
      }

      this.searchProducer = null

      this.removeTeamMember(data)
    },

    addTeamMember(item) {
      // var that = this
      this.$apollo
        .mutate({
          mutation: ADD_TEAM_MEMBER,
          variables: { input: item }
          // update: (store, { data: { createProductTeamMember } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: PRODUCT,
          //     variables: {
          //       id: that.product.id,
          //       seasonID: that.product.season.id
          //     }
          //   })
          //   // Add our requirement from the mutation to the end
          //   if (item.productRole === 'DESIGNER') {
          //     data.product.designers.push(createProductTeamMember)
          //   } else if (item.productRole === 'PRODUCER') {
          //     data.product.producers.push(createProductTeamMember)
          //   }

          //   // Write our data back to the cache.
          //   store.writeQuery({
          //     query: PRODUCT,
          //     variables: {
          //       id: that.product.id,
          //       seasonID: that.product.season.id
          //     },
          //     data
          //   })
          // }
        })
        .then(() => {
          this.$emit('change-state')
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Team Member Added'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again'
          })
        })
    },

    removeTeamMember(item) {
      this.$apollo
        .mutate({
          mutation: REMOVE_TEAM_MEMBER,
          variables: { input: item }
          // update: store => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: PRODUCT,
          //     variables: {
          //       id: that.product.id,
          //       seasonID: that.product.season.id
          //     }
          //   })
          //   // remove the item
          //   var idx = -1

          //   if (item.productRole === 'DESIGNER') {
          //     idx = that._.findIndex(data.product.designers, {
          //       id: item.userID
          //     })

          //     data.product.designers.splice(idx, 1)
          //   } else if (item.productRole === 'PRODUCER') {
          //     idx = that._.remove(data.product.producers, { id: item.userID })

          //     data.product.producers.splice(idx, 1)
          //   }

          //   // Write our data back to the cache.
          //   store.writeQuery({
          //     query: PRODUCT,
          //     variables: {
          //       id: that.product.id,
          //       seasonID: that.product.season.id
          //     },
          //     data
          //   })
          // }
        })
        .then(() => {
          this.$emit('change-state')
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Team Member Removed'
          })
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again'
          })
        })
    }
  }
}
</script>

<style scoped lang="scss">
.team-selection >>> .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.size-delimiter {
  &:last-child {
    span {
      visibility: hidden;
    }
  }
}
</style>
