<template>
  <div class="page-wrap">
    <season-detail-header :season="season"></season-detail-header>

    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <router-view
          :season="season"
          :current-user="currentUser"
          :auth-level="authLevel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SeasonDetailHeader from '@/modules/production/components/core/SeasonDetailHeader.vue'
import SEASON from '@/graphql/Season.gql'

export default {
  name: 'ProductionSeasonDetailLayout',
  components: {
    SeasonDetailHeader
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('seasons')
    }
  },
  apollo: {
    season: {
      query: SEASON,
      variables() {
        return {
          id: this.$route.params.seasonID
        }
      }
      // update (data) {
      //   return data.season
      // }
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 112px;
  /* --page-wrap-offset: -20px; */
}
</style>
