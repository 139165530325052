<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="stockItemForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-row>
                      <v-col v-if="item && item.style" cols="12" class="py-0">
                        <span>{{ item.style.name }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-row>
                      <v-col v-if="item && item.size" cols="12" class="py-0">
                        <v-chip
                          small
                          color="secondary lighten-1 font-weight-bold"
                          text-color="secondary darken-2"
                          >{{ item.size.name }} - {{ item.size.gender }} -
                          {{ item.size.scale }}</v-chip
                        >
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.productionQty"
                          type="number"
                          label="Production Quantity"
                          placeholder="New Quantity"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          hint="Quantity after change"
                          persistent-hint
                          class="stockchange"
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.pickingQty"
                          type="number"
                          label="Picking Quantity"
                          placeholder="New Quantity"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          hint="Quantity after change"
                          persistent-hint
                          class="stockchange"
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.availableQty"
                          type="number"
                          label="Available Quantity"
                          placeholder="New Quantity"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          hint="Quantity after change"
                          persistent-hint
                          class="stockchange"
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.physicalQty"
                          type="number"
                          label="Phisical Quantity"
                          placeholder="New Quantity"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          hint="Quantity after change"
                          persistent-hint
                          class="stockchange"
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="pt-2">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-textarea
                          v-model="item.notes"
                          rows="3"
                          label="Notes"
                          placeholder="Reasoning behind the change"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          persistent-hint
                          dense
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey lighten-1" :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'StockItemEditDialog',
  props: {
    stockItem: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    reasons: [
      { id: 'ADJUSTMENT', text: 'Adjustment' },
      { id: 'RETURN', text: 'Return' }
      // { id: 'RESERVATION', text: 'Reservation' }
    ],
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    item: {
      productionQty: null,
      pickingQty: null,
      availableQty: null,
      physicalQty: null,
      notes: ''
    }
  }),
  computed: {
    title() {
      return 'Stock Adjustment'
    },
    // item() {
    //   return this._.cloneDeep(this.stockItem)
    // },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    },
    newStockQuantity() {
      return (
        parseInt(this.item.physicalQuantity) +
          parseInt(this.item.stockChange) || 0
      )
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.stockItem)
      else this.$refs.stockItemForm.resetValidation()
    }
  },
  methods: {
    close() {
      this.$emit('close-stock-item-edit-dialog', false)
    },
    save() {
      if (this.$refs.stockItemForm.validate()) {
        var data = {
          warehouseID: this.item.warehouseID,
          barcode: this.item.barcode,
          productionQty: this.item.productionQty
            ? parseInt(this.item.productionQty)
            : 0,
          pickingQty: this.item.pickingQty ? parseInt(this.item.pickingQty) : 0,
          availableQty: this.item.availableQty
            ? parseInt(this.item.availableQty)
            : 0,
          physicalQty: this.item.physicalQty
            ? parseInt(this.item.physicalQty)
            : 0,
          notes: this.item.notes
        }

        this.$emit('save-stock-item-edit-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.stockItemForm.resetValidation()
        // })
      }
    }
  }
}
</script>

<style lang="scss">
// .stockchange {
//   input {
//     text-align: right;
//   }
// }
</style>
