<template>
  <v-sheet elevation="3" class="page caption" size="A4" :layout="layout">
    <div
      class="page-wrap d-flex flex-column flex-nowrap"
      style="padding: 50px 50px 0 50px;background-color: white !important;"
    >
      <!-- Header -->
      <v-row justify="center" class="flex-grow-0">
        <h2>Manufacturing Order</h2>
      </v-row>

      <v-row class="flex-grow-0">
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <span class="font-weight-medium">Docket</span>
            </v-col>
            <v-col class="pb-0">
              <span
                v-if="manufacturingOrder.status === 'DRAFT'"
                class="font-weight-bold"
                >DRAFT</span
              >
              <span v-else>{{ manufacturingOrder.id }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col class="pb-0">
              <span class="font-weight-medium">Docket Ref</span>
            </v-col>
            <v-col class="pb-0">
              <span>{{ manufacturingOrder.docketRef }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="flex-grow-1" align="start" style="font-size: 0.8em;">
        <v-col cols="12" class="font-weight-bold">
          <!-- Header -->
          <v-card outlined tile class="table-border">
            <v-row no-gutters>
              <v-col cols="3" class="right-border pa-2">
                Product
              </v-col>
              <v-col cols="5" class="right-border py-2 pl-2">
                Sizes
              </v-col>
              <v-col cols="4">
                <v-row no-gutters>
                  <v-col cols="4" outlined class="text-center right-border py-2"
                    >Qty</v-col
                  >
                  <v-col cols="4" outlined class="text-center right-border py-2"
                    >Unit Cost</v-col
                  >
                  <v-col cols="4" outlined class="text-center pa-2"
                    >Total Cost</v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <!-- Order Lines -->
          <v-card
            v-for="line in limitBy(
              manufacturingOrder.orderLines,
              itemsPerPage,
              skip
            )"
            :key="line.id"
            class="font-weight-regular table-border row-border"
            style="border-top: 0;"
            outlined
            tile
          >
            <v-row no-gutters>
              <v-col cols="3" outlined class="right-border pa-2">
                <div
                  class="text-truncate font-weight-medium"
                  style="font-size: 1em;"
                >
                  {{ line.product.styleNumber }} {{ line.product.name }}
                </div>
                <div
                  class="text-truncate font-weight-medium grey--text text--darken-2"
                  style="font-size: 1em;"
                >
                  {{ line.product.seasonColor }}
                </div>
                <!-- <div style="max-height: 38px;overflow: hidden;">
                  {{ line.product.description }}
                </div> -->
                <div class="text-truncate" style="font-size: 1em;">
                  <span class="grey--text mr-2">Ex-factory:</span>
                  <span>{{ line.exFactoryDate | moment('DD MMM YYYY') }}</span>
                </div>
                <div class="text-truncate" style="font-size: 1em;">
                  <span class="grey--text mr-2">Arrival:</span>
                  <span>{{ line.arrivalDate | moment('DD MMM YYYY') }}</span>
                </div>
                <div></div>
              </v-col>
              <v-col cols="5" outlined class="right-border pa-0">
                <v-row
                  no-gutters
                  class="d-flex flex-row flex-nowrap"
                  style="height: 100%;"
                >
                  <div
                    v-for="item in line.items"
                    :key="item.id"
                    class="flex-grow-1 text-center right-border"
                  >
                    <div
                      class="font-weight-medium bottom-border py-3 px-1"
                      style="height: 50%;"
                    >
                      {{ item.productSize.name }}
                    </div>
                    <div class="py-3 px-1">{{ item.quantity }}</div>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row
                  no-gutters
                  class="d-flex flex-row flex-nowrap"
                  style="height: 100%;"
                >
                  <v-col cols="4" class="align-self-stretch right-border">
                    <v-container fill-height fluid>
                      <v-row justify="center" align="center">
                        <div>{{ line.quantity }}</div>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="4" class="align-self-stretch right-border">
                    <v-container fill-height fluid>
                      <v-row justify="center" align="center">
                        <div>
                          {{
                            (line.unitCost / 100)
                              | currency(
                                manufacturingOrder.currency.symbol,
                                2,
                                {
                                  spaceBetweenAmountAndSymbol: true
                                }
                              )
                          }}
                        </div>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="4" class="align-self-stretch">
                    <v-container fill-height fluid>
                      <v-row justify="center" align="center">
                        <div>
                          {{
                            (line.amount / 100)
                              | currency(
                                manufacturingOrder.currency.symbol,
                                2,
                                {
                                  spaceBetweenAmountAndSymbol: true
                                }
                              )
                          }}
                        </div>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <!-- Total Row -->
          <v-card
            v-if="page === pageCount"
            class="font-weight-bold table-border"
            style="border-top: 0;"
            outlined
            tile
          >
            <v-row cols="8" no-gutters>
              <v-col class="right-border pa-2">
                <div class="text-truncate">Grand Total</div>
              </v-col>

              <v-col cols="4">
                <v-row
                  no-gutters
                  class="d-flex flex-row flex-nowrap"
                  style="height: 100%;"
                >
                  <v-col
                    cols="4"
                    class="align-self-stretch right-border caption"
                  >
                    <v-container fill-height fluid>
                      <v-row justify="center" align="center">
                        <div>{{ manufacturingOrder.total }}</div>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="4" class="align-self-stretch right-border">
                    &nbsp;
                  </v-col>
                  <v-col cols="4" class="align-self-stretch caption">
                    <v-container fill-height fluid>
                      <v-row justify="center" align="center">
                        <div>
                          {{
                            (manufacturingOrder.net / 100)
                              | currency(
                                manufacturingOrder.currency.symbol,
                                2,
                                {
                                  spaceBetweenAmountAndSymbol: true
                                }
                              )
                          }}
                        </div>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="flex-grow-1"></v-row>
      <v-row class="align-end flex-grow-0 pb-3">
        <v-col
          ><span class="font-weight-medium pr-2">Company Reg.</span
          ><span>68051875</span>
          <span class="px-3">|</span>
          <span class="font-weight-medium pr-2">VAT No.</span
          ><span>NL857281483B01</span></v-col
        >
        <v-col class="text-right"> Page {{ page }} of {{ pageCount }} </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import { getName } from 'country-list'

export default {
  name: 'ManufacturingOrderPdf',
  mixins: [Vue2Filters.mixin],
  props: {
    manufacturingOrder: {
      type: Object,
      default: () => ({}),
      required: true
    },
    page: {
      type: Number,
      default: 1
    },
    pageCount: {
      type: Number,
      default: 1
    },
    layout: {
      type: String,
      default: 'portrait'
    }
  },
  computed: {
    itemsPerPage() {
      return this.layout === 'portrait' ? 8 : 5
    },
    skip() {
      return (this.page - 2) * this.itemsPerPage
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    unitCost(qty, cost) {
      return cost / qty / 100
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  background: white;
  display: block;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.page[size='A4'][layout='portrait'] {
  width: 21cm;
  height: 29.7cm;
}
.page[size='A4'][layout='landscape'] {
  width: 29.7cm;
  height: 21cm;
}
.page[size='A3'] {
  width: 29.7cm;
  height: 42cm;
}
.page[size='A3'][layout='landscape'] {
  width: 42cm;
  height: 29.7cm;
}
.page[size='A5'] {
  width: 14.8cm;
  height: 21cm;
}
.page[size='A5'][layout='landscape'] {
  width: 21cm;
  height: 14.8cm;
}
@media print {
  .page {
    margin: 0;
    box-shadow: 0;
  }
}

.company,
.address {
  div {
    line-height: 1.1em;
  }
}

.logo {
  height: 100px;
}

.right-border {
  border-right: thin solid rgba(0, 0, 0, 0.12);
  &:last-of-type {
    border-right: 0;
  }
}

.bottom-border {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.row-border {
  border-bottom: 0px solid transparant !important;
}

.table-border {
  border: thin solid rgba(0, 0, 0, 0.45);
}
</style>
