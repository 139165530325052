<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="bleckmannForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredRule"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      label="Description"
                      outlined
                      dense
                      rows="3"
                      hide-details="auto"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.profile"
                      label="Profile"
                      outlined
                      dense
                      hint="This should be supplied by Bleckmann"
                      :rules="requiredRule"
                      hide-details="auto"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="item.type"
                      :items="['ECOMMERCE', 'WHOLESALE']"
                      label="Warehouse Type"
                      outlined
                      dense
                      :rules="requiredSelectRule"
                      hide-details="auto"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.prefix"
                      label="Prefix (eg DPEU)"
                      persistent-hint
                      hint="Should be a unique value"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredRule"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="item.active"
                      label="Active"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WarehouseProviderEditDialog',
  props: {
    bleckmann: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    item: null
  }),
  computed: {
    title() {
      return this.editMode
        ? 'Edit Bleckmann Integration'
        : 'New Bleckmann Integration'
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.bleckmann)
    }
  },
  methods: {
    close() {
      this.$emit('close-bleckmann-edit-dialog', false)

      this.$nextTick(() => {
        this.$refs.bleckmannForm.resetValidation()
      })
    },
    save() {
      var that = this
      if (this.$refs.bleckmannForm.validate()) {
        var data = {
          id: this.item.id,
          name: this.item.name,
          description: this.item.description,
          profile: this.item.profile,
          warehouseID: this.item.warehouseID,
          type: this.item.type,
          active: this.item.active,
          prefix: this.item.prefix
        }

        if (!this.editMode) {
          delete data.id
        } else {
          delete data.warehouseID
        }

        this.$emit('save-bleckmann', data)
        this.$nextTick(() => {
          that.$refs.bleckmannForm.resetValidation()
        })
      }
    }
  }
}
</script>

<style></style>
