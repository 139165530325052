<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="px-3">
        <div v-if="item" class="scroll-container pt-0">
          <v-alert v-if="error" type="error">
            {{ errorMsg }}
          </v-alert>
          <v-form
            ref="sizeForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Name</label>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      autocomplete="off"
                      placeholder="Size Name"
                      hide-details="auto"
                      outlined
                      :rules="requiredRule"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Description</label>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      autocomplete="off"
                      placeholder="Description"
                      hide-details="auto"
                      rows="3"
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Promese Group Name</label>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.promeseGroupName"
                      autocomplete="off"
                      placeholder="Promese Group Name"
                      hide-details="auto"
                      outlined
                      dense
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Gender</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-select
                      v-model="item.gender"
                      :items="genders"
                      autocomplete="off"
                      placeholder="Gender"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <label>Scale</label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-select
                      v-model="item.scale"
                      :items="scales"
                      autocomplete="off"
                      placeholder="Scale"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SIZE_LIST from '@/graphql/SizeGroups.gql'
import CREATE_SIZE from '@/graphql/SizeGroupCreate.gql'
import UPDATE_SIZE from '@/graphql/SizeGroupUpdate.gql'

export default {
  name: 'SizeGroupEditDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    error: false,
    errorMsg: 'A general server error occured. Please try again later',
    valid: true,
    processing: false,
    disabled: false,
    genders: ['UNISEX', 'MALE', 'FEMALE', 'BOY', 'GIRL', 'CHILD', 'BABY'],
    scales: ['GLOBAL', 'EU', 'UK', 'US', 'JP'],
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    formTitle() {
      return this.editMode ? 'Edit Size Group' : 'New Size Group'
    }
  },
  watch: {
    formattedStartDate(date) {
      let dateObj = this.$moment(date, 'LL')
      if (dateObj.isAfter(this.$moment(this.formattedEndDate, 'LL'))) {
        // this.formattedEndDate = date
        this.item.till = this.$moment(date).format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    close(success, id) {
      this.$refs.sizeForm.resetValidation()
      this.$emit('close-edit-group-dialog', success, id)
    },
    save() {
      this.error = false

      if (this.editMode) {
        // Update Item
        let data = {
          id: this.item.id,
          name: this.item.name,
          description: this.item.description,
          gender: this.item.gender,
          scale: this.item.scale,
          promeseGroupName: this.item.promeseGroupName
        }

        if (this.$refs.sizeForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_SIZE,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // New Item
        let data = {
          name: this.item.name,
          description: this.item.description,
          gender: this.item.gender,
          scale: this.item.scale,
          promeseGroupName: this.item.promeseGroupName
        }

        if (this.$refs.sizeForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_SIZE,
              variables: {
                input: data
              },
              update: (store, { data: { createSizeGroup } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: SIZE_LIST
                })
                // Add our requirement from the mutation to the end
                data.sizeGroups.push(createSizeGroup)
                // Write our data back to the cache.
                store.writeQuery({ query: SIZE_LIST, data })
              }
            })
            .then(() => {
              this.close(true)
            })
            .catch(() => {
              this.error = true
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-season-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
