<template>
  <div class="app-layout">
    <barcode-range-edit-dialog
      :open="dialog"
      :edited-item="editedItem"
      :edit-mode="edit"
      @close-edit-barcode-range-dialog="close"
    ></barcode-range-edit-dialog>

    <div class="page-wrap">
      <ProductionSettingsToolbar>
        <template v-slot:controls>
          <v-btn
            v-if="authLevel === 2"
            fab
            small
            color="primary"
            @click="newItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </ProductionSettingsToolbar>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col xs="12" sm="12" md="10" lg="10" xl="8">
              <v-data-table
                :headers="headers"
                :items="barcodeRanges"
                :search="search"
                :loading="$apollo.loading"
                :mobile-breakpoint="null"
                class="plain-table"
                hide-default-footer
              >
                <template v-slot:item.numLeft="{ item }">
                  <span>{{ item.endCode - item.nextCode + 1 }}</span>
                </template>
                <template v-slot:item.active="{ item }">
                  <v-icon v-if="item.active">mdi-check</v-icon>
                </template>
                <template v-slot:no-data>Nothing here yet...</template>
                <template v-slot:item.controls="{ item }">
                  <v-btn icon small
                    ><v-icon small @click.stop="editItem(item)"
                      >mdi-pencil-outline</v-icon
                    ></v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductionSettingsToolbar from '@/modules/production/components/base/ProductionSettingsToolbar.vue'
import BarcodeRangeEditDialog from '@/modules/production/components/core/BarcodeRangeEditDialog.vue'
import BARCODE_RANGES from '@/graphql/BarcodeRanges.gql'

export default {
  name: 'ProductionSettingsBarcodes',
  components: {
    ProductionSettingsToolbar,
    BarcodeRangeEditDialog
  },
  data: () => ({
    dialog: false,
    edit: false,
    search: '',
    barcodeRanges: [],
    defaultItem: {
      id: '',
      startCode: 0,
      endCode: 0,
      nextCode: 0,
      barcodeType: 'EAN13',
      active: true,
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      startCode: 0,
      endCode: 0,
      nextCode: 0,
      barcodeType: 'EAN13',
      active: true,
      updatedAt: new Date(),
      createdAt: new Date()
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Type',
          align: 'left',
          sortable: false,
          filterable: false,
          value: 'barcodeType'
        },
        {
          text: 'From',
          value: 'startCode',
          sortable: false
        },
        {
          text: 'Till',
          align: 'left',
          value: 'endCode',
          sortable: false
        },
        {
          text: 'Next',
          align: 'left',
          value: 'nextCode',
          sortable: false
        },
        {
          text: '# of Codes Left',
          align: 'left',
          value: 'numLeft',
          sortable: false
        },
        {
          text: 'Active',
          align: 'left',
          value: 'active',
          filterable: false
        },
        {
          text: '',
          value: 'controls',
          align: 'right',
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('production_settings')
    }
  },
  apollo: {
    barcodeRanges: BARCODE_RANGES
  },
  methods: {
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Barcode Range Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    }
  }
}
</script>

<style></style>
