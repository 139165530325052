<template>
  <v-sheet elevation="3" class="page caption" size="A4" :layout="layout">
    <div
      class="page-wrap d-flex flex-column flex-nowrap"
      style="padding: 50px 50px 0 50px;background-color: white !important;"
    >
      <!-- Header -->
      <v-row justify="center" class="flex-grow-0">
        <h2>Manufacturing Order</h2>
      </v-row>
      <v-row class="flex-grow-0">
        <!-- Address -->
        <v-col cols="6">
          <div class="caption company">
            <h3 class="mb-2">Daily Paper Wholesale BV</h3>
            <div>
              Danzigerbocht 45N
            </div>
            <div>Amsterdam</div>
            <div>1013AM</div>
            <div>Netherlands</div>
            <div>T: +31 (0)20 3348970</div>
            <div>https://www.dailypaperclothing.com</div>
          </div>
        </v-col>
        <v-col cols="6">
          <v-row justify="end">
            <img src="/logo_alt.jpg" class="logo" />
          </v-row>
        </v-col>
      </v-row>
      <v-row class="flex-grow-0">
        <v-col>
          <v-row>
            <v-col cols="4" class="pb-0">
              <span class="font-weight-medium">Docket</span>
            </v-col>
            <v-col class="pb-0">
              <span
                v-if="
                  manufacturingOrder.status === 'DRAFT' ||
                    manufacturingOrder.status === 'PENDINGCHANGES' ||
                    manufacturingOrder.status === 'REVIEW'
                "
                class="font-weight-bold"
                >DRAFT</span
              >
              <span
                v-if="
                  manufacturingOrder.status === 'APPROVED' ||
                    manufacturingOrder.status === 'PROPOSALACCEPTED'
                "
                >{{ manufacturingOrder.id }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pb-0">
              <span class="font-weight-medium">Docket Ref</span>
            </v-col>
            <v-col class="pb-0">
              <span>{{ manufacturingOrder.docketRef }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pb-0">
              <span class="font-weight-medium">Manufacturer</span>
            </v-col>
            <v-col class="pb-0 address caption">
              <h3 class="mb-2">
                {{ manufacturingOrder.manufacturer.name }}
              </h3>
              <div>
                {{ manufacturingOrder.manufacturerAddress.line1 }}
              </div>
              <div>
                {{ manufacturingOrder.manufacturerAddress.line2 }}
              </div>
              <div>
                {{ manufacturingOrder.manufacturerAddress.line3 }}
              </div>
              <div>
                {{ manufacturingOrder.manufacturerAddress.city }}
              </div>
              <div>
                {{ manufacturingOrder.manufacturerAddress.postCode }}
              </div>
              <div
                v-if="
                  manufacturingOrder.manufacturerAddress &&
                    manufacturingOrder.manufacturerAddress.country
                "
              >
                {{
                  getFullCountryName(
                    manufacturingOrder.manufacturerAddress.country
                  )
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pb-0">
              <span class="font-weight-medium">Payment Terms</span>
            </v-col>
            <v-col class="pb-0 caption">
              <div
                v-for="term in manufacturingOrder.paymentTerms"
                :key="term.id"
              >
                <span>
                  {{
                    term.percentage +
                      '% ' +
                      term.days +
                      ' days ' +
                      term.direction +
                      ' ' +
                      term.eventType
                  }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="4" class="pb-0">
              <span class="font-weight-medium">Date</span>
            </v-col>
            <v-col cols="4" class="pb-0">
              {{ manufacturingOrder.orderDate | moment('DD MMM YYYY') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pb-0">
              <span class="font-weight-medium">Ship To</span>
            </v-col>
            <v-col class="pb-0 address caption">
              <h3 class="mb-2">
                {{ manufacturingOrder.deliverTo.address.name }}
              </h3>
              <div>
                {{ manufacturingOrder.deliverTo.address.line1 }}
              </div>
              <div>
                {{ manufacturingOrder.deliverTo.address.line2 }}
              </div>
              <div>
                {{ manufacturingOrder.deliverTo.address.line3 }}
              </div>
              <div>{{ manufacturingOrder.deliverTo.address.city }}</div>
              <div>
                {{ manufacturingOrder.deliverTo.address.postCode }}
              </div>
              <div
                v-if="
                  manufacturingOrder.deliverTo.address &&
                    manufacturingOrder.deliverTo.address.country
                "
              >
                {{
                  getFullCountryName(
                    manufacturingOrder.deliverTo.address.country
                  )
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pb-0">
              <span class="font-weight-medium">Ex-Factory Date</span>
            </v-col>
            <v-col class="pb-0 caption">
              <span
                v-if="manufacturingOrder && manufacturingOrder.exFactoryDate"
                >{{
                  manufacturingOrder.exFactoryDate | moment('D MMM YYYY')
                }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pb-0">
              <span class="font-weight-medium">Arrival Date</span>
            </v-col>
            <v-col class="pb-0 caption">
              <span v-if="manufacturingOrder && manufacturingOrder.arrivalDate">
                {{
                  manufacturingOrder.arrivalDate | moment('D MMM YYYY')
                }}</span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="flex-grow-0">
        <v-col>
          <v-row>
            <v-col cols="12" class="pb-0">
              <span class="font-weight-medium">Notes</span>
            </v-col>
            <v-col cols="12" class="py-0">
              <span v-text="manufacturingOrder.note"></span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="flex-grow-1"></v-row>
      <v-row class="align-end flex-grow-0 pb-3">
        <v-col
          ><span class="font-weight-medium pr-2">Company Reg.</span
          ><span>68051875</span>
          <span class="px-3">|</span>
          <span class="font-weight-medium pr-2">VAT No.</span
          ><span>NL857281483B01</span></v-col
        >
        <v-col class="text-right"> Page {{ page }} of {{ pageCount }} </v-col>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import { getName } from 'country-list'

export default {
  name: 'ManufacturingOrderCoverPagePdf',
  mixins: [Vue2Filters.mixin],
  props: {
    manufacturingOrder: {
      type: Object,
      default: () => ({}),
      required: true
    },
    page: {
      type: Number,
      default: 1
    },
    pageCount: {
      type: Number,
      default: 1
    },
    layout: {
      type: String,
      default: 'portrait'
    }
  },
  computed: {
    itemsPerPage() {
      return this.layout === 'portrait' ? 8 : 5
    },
    skip() {
      return (this.page - 2) * this.itemsPerPage
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    unitCost(qty, cost) {
      return cost / qty / 100
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  background: white;
  display: block;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.page[size='A4'][layout='portrait'] {
  width: 21cm;
  height: 29.7cm;
}
.page[size='A4'][layout='landscape'] {
  width: 29.7cm;
  height: 21cm;
}
.page[size='A3'] {
  width: 29.7cm;
  height: 42cm;
}
.page[size='A3'][layout='landscape'] {
  width: 42cm;
  height: 29.7cm;
}
.page[size='A5'] {
  width: 14.8cm;
  height: 21cm;
}
.page[size='A5'][layout='landscape'] {
  width: 21cm;
  height: 14.8cm;
}
@media print {
  .page {
    margin: 0;
    box-shadow: 0;
  }
}

.company,
.address {
  div {
    line-height: 1.1em;
  }
}

.logo {
  height: 100px;
}

.right-border {
  border-right: thin solid rgba(0, 0, 0, 0.12);
  &:last-of-type {
    border-right: 0;
  }
}

.bottom-border {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.row-border {
  border-bottom: 0px solid transparant !important;
}

.table-border {
  border: thin solid rgba(0, 0, 0, 0.45);
}
</style>
