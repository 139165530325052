<template>
  <div class="page-header">
    <!-- Toolbar -->
    <v-toolbar v-if="warehouse" class="mb-0 b-radius" extended flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="pl-0">
            <v-row>
              <v-col cols="8" lg="9" xl="10">
                <h2 class="mb-0 text-truncate primary--text">
                  {{ warehouse.name }}
                </h2>
                <div>
                  <v-chip
                    v-if="warehouse && warehouse.provider"
                    pill
                    x-small
                    color="info lighten-2"
                    text-color="info darken-2"
                    >{{ warehouse.provider.name }}</v-chip
                  >
                </div>
              </v-col>
              <v-spacer></v-spacer>

              <v-col cols="4" lg="3" xl="2"> </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:extension>
        <v-tabs
          style="width: 80%;"
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            :to="{
              name: 'WarehouseDetail',
              params: { warehouseID: $route.params.warehouseID }
            }"
            class="text-capitalize"
            exact
            >Details</v-tab
          >

          <v-tab
            :to="{
              name: 'WarehouseStockList',
              params: { warehouseID: $route.params.warehouseID }
            }"
            class="text-capitalize"
            >Stock</v-tab
          >

          <v-tab
            :to="{
              name: 'ProductionWarehouseSettings',
              params: { warehouseID: $route.params.warehouseID }
            }"
            class="text-capitalize"
            >Settings</v-tab
          >
        </v-tabs>

        <v-spacer v-if="warehouse && authLevel && authLevel === 2"></v-spacer>

        <!-- <v-menu
          v-if="warehouse && authLevel && authLevel === 2"
          close-on-content-click
        >
          <template v-slot:activator="{ on }">
            <v-btn color="primary lighten-2" small rounded v-on="on"
              >Actions</v-btn
            >
          </template>
        </v-menu> -->

        <slot name="controls"> </slot>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'WarehouseDetailHeader',
  props: {
    warehouse: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  data() {
    return {}
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  methods: {}
}
</script>

<style lang="scss"></style>
