<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="warehouseForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.name"
                      autocomplete="off"
                      label="Name"
                      hide-details="auto"
                      outlined
                      :rules="requiredRule"
                      autofocus
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                      v-model="item.provider"
                      :items="warehouseProviders"
                      autocomplete="off"
                      label="Warehouse Provider"
                      hide-details="auto"
                      item-text="name"
                      outlined
                      return-object
                      :rules="requiredSelectRule"
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="item.type"
                      :items="warehouseTypes"
                      autocomplete="off"
                      label="Warehouse Type"
                      hide-details="auto"
                      outlined
                      :rules="requiredSelectRule"
                      autofocus
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- <v-row
                  v-if="
                    currentUser &&
                      (currentUser.isSuperAdmin || currentUser.isAdmin)
                  "
                >
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.providerKey"
                      autocomplete="off"
                      label="Provider Key"
                      hint="Key received from provider"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="item.shopifyKey"
                      autocomplete="off"
                      label="Shopify Key"
                      hint="Shoppify API Key"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row> -->

                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.notes"
                      autocomplete="off"
                      label="Notes"
                      hide-details="auto"
                      outlined
                      dense
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12">
                    <label>Address</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.address.email"
                      autocomplete="off"
                      label="Email"
                      hide-details="auto"
                      outlined
                      dense
                      type="email"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.address.phone"
                      autocomplete="off"
                      label="Phone"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="item.address.line1"
                          autocomplete="off"
                          label="Line 1"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="item.address.line2"
                          autocomplete="off"
                          label="Line 2"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="item.address.line3"
                          autocomplete="off"
                          label="Line 3"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="item.address.city"
                          autocomplete="off"
                          label="City"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="item.address.postCode"
                          autocomplete="off"
                          label="Postcode"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          v-model="item.address.country"
                          :items="countryList"
                          autocomplete="off"
                          label="Country"
                          hide-details="auto"
                          outlined
                          dense
                          item-text="name"
                          item-value="alpha2"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey lighten-1" :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { countries } from 'country-data-list'

export default {
  name: 'WarehouseEditDialog',
  props: {
    warehouse: {
      type: Object,
      required: true,
      default: () => ({})
    },
    warehouseProviders: {
      type: Array,
      required: true,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    warehouseTypes: ['WHOLESALE', 'ECOMMERCE', 'SHOP'],
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required']
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Warehouse' : 'New Warehouse'
    },
    item() {
      return this._.cloneDeep(this.warehouse)
    },
    countryList() {
      return this._.sortBy(countries.all, 'name')
    },
    currentUser() {
      return this.$store.state.currentUser
    }
  },
  watch: {},
  methods: {
    close() {
      this.$emit('close-warehouse-edit-dialog', false)
      this.$refs.warehouseForm.resetValidation()
    },
    save() {
      if (this.$refs.warehouseForm.validate()) {
        var data = {
          id: this.item.id,
          name: this.item.name,
          notes: this.item.notes,
          type: this.item.type,
          providerID: this.item.provider?.id,
          address: {
            id: this.item.address?.id,
            email: this.item.address?.email,
            phone: this.item.address?.phone,
            line1: this.item.address?.line1,
            line2: this.item.address?.line2,
            line3: this.item.address?.line3,
            city: this.item.address?.city,
            postCode: this.item.address?.postCode,
            country: this.item.address?.country,
            addressType: this.item?.address?.addressType || 'VISITING'
          }
        }

        if (!this.editMode) {
          delete data.id
          delete data.address.id
        }

        this.$emit('save-warehouse-edit-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.warehouseForm.resetValidation()
        // })
      }
    }
  }
}
</script>

<style></style>
