<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="px-3">
        <div v-if="item" class="scroll-container pt-0">
          <v-form
            ref="seasonForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.shortName"
                      autocomplete="off"
                      label="Season short code"
                      hide-details="auto"
                      outlined
                      :rules="requiredRule"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.longName"
                      autocomplete="off"
                      label="Season long name"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.styleNumberPrefix"
                      autocomplete="off"
                      label="Style Number Prefix"
                      hide-details="auto"
                      outlined
                      :rules="requiredRule"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" lg="6" class="pt-0">
                    <v-row>
                      <v-col cols="12">
                        <v-menu
                          ref="menu1"
                          v-model="from"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formattedStartDate"
                              outlined
                              label="From"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              hide-details="auto"
                              dense
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.from"
                            no-title
                            @input="from = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" class="pt-0">
                    <v-row>
                      <v-col cols="12">
                        <v-menu
                          ref="menu2"
                          v-model="till"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formattedEndDate"
                              outlined
                              label="Till"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              hide-details="auto"
                              dense
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.till"
                            :min="item.from"
                            no-title
                            @input="till = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" lg="6" class="pt-0">
                    <v-row>
                      <v-col cols="12">
                        <v-menu
                          ref="menu3"
                          v-model="prebookStartDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="item.prebookStartDate | moment('LL')"
                              outlined
                              label="Prebook Start"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              hide-details="auto"
                              dense
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.prebookStartDate"
                            no-title
                            @input="prebookStartDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" class="pt-0">
                    <v-row>
                      <v-col cols="12">
                        <v-menu
                          ref="menu4"
                          v-model="prebookEndDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="item.prebookEndDate | moment('LL')"
                              outlined
                              label="Prebook End"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              hide-details="auto"
                              dense
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.prebookEndDate"
                            :min="item.prebookStartDate"
                            no-title
                            @input="prebookEndDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" lg="6" class="pt-0">
                    <v-row>
                      <v-col cols="12">
                        <v-menu
                          ref="menu5"
                          v-model="firstDropStartDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="item.firstDropStartDate | moment('LL')"
                              outlined
                              label="Drop Start"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              hide-details="auto"
                              dense
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.firstDropStartDate"
                            no-title
                            @input="firstDropStartDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" class="pt-0">
                    <v-row>
                      <v-col cols="12">
                        <v-menu
                          ref="menu6"
                          v-model="firstDropEndDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="item.firstDropEndDate | moment('LL')"
                              outlined
                              label="Drop End"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              hide-details="auto"
                              dense
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.firstDropEndDate"
                            :min="item.firstDropStartDate"
                            no-title
                            @input="firstDropEndDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          rounded
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          small
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SEASONS from '@/graphql/Seasons.gql'
import CREATE_SEASON from '@/graphql/SeasonCreate.gql'
import UPDATE_SEASON from '@/graphql/SeasonUpdate.gql'

export default {
  name: 'SeasonEditDialog',
  components: {},
  props: {
    editedItem: { type: Object, default: () => {} },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    processing: false,
    disabled: false,
    from: false,
    till: false,
    prebookStartDate: false,
    prebookEndDate: false,
    firstDropStartDate: false,
    firstDropEndDate: false,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    dateRule: [v => !!v || 'Date is required']
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    },
    item: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    },
    unFormattedStartDate() {
      return this.item.from
    },
    formTitle() {
      return this.editMode ? 'Edit Season' : 'New Season'
    },
    formattedStartDate() {
      return this.item.from ? this.$moment(this.item.from).format('LL') : ''
    },
    formattedEndDate() {
      // this.$moment().locale('nl')
      // if (this.item.till) {
      return this.item.till ? this.$moment(this.item.till).format('LL') : ''
      // } else if (this.item.till < this.item.from) {
      //   return this.$moment(this.item.from).format('LL')
      // } else {
      //   return this.item.till
      //     ? this.$moment(this.item.till).format('LL')
      //     : this.$moment(this.item.from).format('LL')
      // }
    }
  },
  watch: {
    formattedStartDate(date) {
      let dateObj = this.$moment(date, 'LL')
      if (dateObj.isAfter(this.$moment(this.formattedEndDate, 'LL'))) {
        // this.formattedEndDate = date
        this.item.till = this.$moment(date).format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    close(success, id) {
      this.$refs.seasonForm.resetValidation()
      this.$emit('close-edit-season-dialog', success, id)
    },
    save() {
      if (this.editMode) {
        // Update Item
        let data = {
          id: this.item.id,
          shortName: this.item.shortName,
          longName: this.item.longName,
          styleNumberPrefix: this.item.styleNumberPrefix,
          status: this.item.status,
          from: this.$moment(this.item.from).utc(),
          till: this.$moment(this.item.till).utc(),
          prebookStartDate: this.$moment(this.item.prebookStartDate).utc(),
          prebookEndDate: this.$moment(this.item.prebookEndDate).utc(),
          firstDropStartDate: this.$moment(this.item.firstDropStartDate).utc(),
          firstDropEndDate: this.$moment(this.item.firstDropEndDate).utc()
        }

        if (this.$refs.seasonForm.validate()) {
          this.$apollo
            .mutate({
              mutation: UPDATE_SEASON,
              variables: {
                input: data
              }
            })
            .then(() => {
              this.close(true)
            })
        }
      } else {
        // New Item
        let data = {
          shortName: this.item.shortName,
          longName: this.item.longName,
          styleNumberPrefix: this.item.styleNumberPrefix,
          from: this.$moment(this.item.from).utc(),
          till: this.$moment(this.item.till).utc(),
          prebookStartDate: this.$moment(this.item.prebookStartDate).utc(),
          prebookEndDate: this.$moment(this.item.prebookEndDate).utc(),
          firstDropStartDate: this.$moment(this.item.firstDropStartDate).utc(),
          firstDropEndDate: this.$moment(this.item.firstDropEndDate).utc()
        }

        if (this.$refs.seasonForm.validate()) {
          this.$apollo
            .mutate({
              mutation: CREATE_SEASON,
              variables: {
                input: data
              },
              update: (store, { data: { createSeason } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: SEASONS
                })
                // Add our requirement from the mutation to the end
                data.seasons.seasons.unshift(createSeason)
                // Write our data back to the cache.
                store.writeQuery({ query: SEASONS, data })
              }
            })
            .then(response => {
              this.close(true, response.data.createSeason.id)
            })
            .catch(() => {
              this.$store.commit('showSnackbar', {
                active: true,
                message:
                  'A general server error occured. Please try again later'
              })

              return this.close(false)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">
#edit-season-description {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
}
</style>
