<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="shipperForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Email</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.email"
                          autocomplete="off"
                          label="Email"
                          hide-details="auto"
                          outlined
                          dense
                          type="email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Phone</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.phone"
                          autocomplete="off"
                          label="Phone"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12">
                    <label>Address</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.line1"
                          autocomplete="off"
                          label="Line 1"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.line2"
                          autocomplete="off"
                          label="Line 2"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.line3"
                          autocomplete="off"
                          label="Line 3"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.city"
                          autocomplete="off"
                          label="City"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="address.postCode"
                          autocomplete="off"
                          label="Postcode"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Mid</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="address.country"
                          :items="countryList"
                          autocomplete="off"
                          label="Country"
                          hide-details="auto"
                          outlined
                          dense
                          item-text="name"
                          item-value="code"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey lighten-1" :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'ShipperAddressEditDialog',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    paymentTerms: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    priorities: ['low', 'medium', 'high'],
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required']
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Contact Details' : 'New Contact Details'
    },
    address() {
      return this._.cloneDeep(this.data)
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    }
  },
  watch: {},
  methods: {
    close() {
      this.$emit('close-shipper-address-edit-dialog', false)

      // this.$nextTick(() => {
      //   this.$refs.shipperForm.resetValidation()
      // })
    },
    save() {
      if (this.$refs.shipperForm.validate()) {
        var data = {
          id: this.address ? this.address.id : null,
          email: this.address ? this.address.email : null,
          phone: this.address ? this.address.phone : null,
          line1: this.address ? this.address.line1 : null,
          line2: this.address ? this.address.line2 : null,
          line3: this.address ? this.address.line3 : null,
          city: this.address ? this.address.city : null,
          postCode: this.address ? this.address.postCode : null,
          country: this.address ? this.address.country : null
        }

        this.$emit('save-shipper-address-edit-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.shipperForm.resetValidation()
        // })
      }
    }
  }
}
</script>

<style></style>
