<template>
  <v-row justify="center">
    <v-col v-if="product" cols="12" md="6" lg="6" xl="6">
      <v-card flat class="mb-3">
        <v-card-title class="mb-3"
          ><span>Logistics / Customs</span><v-spacer></v-spacer
          ><v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                editMode &&
                ((product && logisticsAuthLevel && logisticsAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            text
            small
            rounded
            :disabled="processing"
            class="mr-2"
            @click="cancelEdit(false)"
          >
            cancel
          </v-btn>
          <v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                editMode &&
                ((product && logisticsAuthLevel && logisticsAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="primary"
            rounded
            small
            :loading="processing"
            @click="saveProduct"
          >
            save
          </v-btn>
          <v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                !editMode &&
                ((product && logisticsAuthLevel && logisticsAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            color="grey"
            rounded
            outlined
            small
            depressed
            :disabled="processing"
            @click="editProduct"
          >
            edit
          </v-btn></v-card-title
        >
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="productForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row>
              <v-col cols="12">
                <custom-type-select
                  v-model="editedItem.customType"
                  autocomplete="off"
                  label="Custom Type"
                  hide-details="auto"
                  outlined
                  dense
                  :disabled="!editMode"
                ></custom-type-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.htsCode"
                  v-mask="'#### ## ## ##'"
                  autocomplete="off"
                  label="HS Code"
                  hide-details="auto"
                  outlined
                  dense
                  :rules="htsCodeRule"
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.intrastatCode"
                  autocomplete="off"
                  label="HS Code - USA"
                  hide-details="auto"
                  outlined
                  dense
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.weight"
                  autocomplete="off"
                  label="Weight"
                  hide-details="auto"
                  outlined
                  dense
                  append-icon="mdi-weight-kilogram"
                  type="number"
                  :readonly="!editMode"
                  :disabled="!editMode && !item.weight"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CustomTypeSelect from '@/components/core/CustomTypeSelect.vue'
import UPDATE_PRODUCT_CUSTOMS from '@/graphql/ProductCustomsUpdate.gql'

export default {
  name: 'ProductionProductCustoms',
  components: {
    CustomTypeSelect
  },
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    colors: { type: Array, default: () => [] },
    categories: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    sizeGroups: { type: Array, default: () => [] }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    htsCodeRule: [
      v => v == '' || (v && v.length == 13) || 'HTS codes should be 10 digits'
    ],
    editDialog: false,
    processing: false,
    editMode: false,
    errorMsg: '',
    defaultItem: {
      productID: null,
      seasonID: null,
      customType: null,
      textileContent: null,
      htsCode: null,
      intrastatCode: null,
      weight: null
    },
    editedItem: {
      productID: null,
      seasonID: null,
      customType: null,
      textileContent: null,
      htsCode: null,
      intrastatCode: null,
      weight: null
    }
  }),
  computed: {
    logisticsAuthLevel() {
      return this.$store.getters.authLevel('product_logistics')
    },
    item() {
      this.setEditedItem()
      return this._.cloneDeep(this.product)
    }
  },
  methods: {
    setEditedItem() {
      this.editedItem = this._.cloneDeep(this.product)
    },
    // Product methods
    cancelEdit(success) {
      if (!success) {
        this.$refs.productForm.resetValidation()
        this.setEditedItem()
      }

      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.editMode === true) {
          msg = 'Customs Data Updated'
        } else {
          msg = 'Customs Data Added'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    },

    editProduct() {
      this.editMode = true
    },

    saveProduct() {
      this.errorMsg = ''

      var data = {
        productID: this.product.id,
        seasonID: this.product.season.id,
        customType: this.editedItem.customType,
        textileContent: this.editedItem.textileContent,
        htsCode: this.editedItem.htsCode,
        intrastatCode: this.editedItem.intrastatCode,
        weight: this.editedItem.weight
      }

      if (this.$refs.productForm.validate()) {
        this.processing = true

        // Save Item
        this.$apollo
          .mutate({
            mutation: UPDATE_PRODUCT_CUSTOMS,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.$emit('change-state')
            this.cancelEdit(true)
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
