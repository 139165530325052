<template>
  <v-row justify="center">
    <warehouse-edit-dialog
      :dialog="dialog"
      :warehouse="editedItem"
      :warehouse-providers="warehouseProviders.items"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-warehouse-edit-dialog="close"
      @save-warehouse-edit-dialog="save"
    ></warehouse-edit-dialog>
    <!-- <warehouse-address-edit-dialog
      :dialog="addressDialog"
      :data="editedItem.address"
      :payment-terms="paymentTerms"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-warehouse-address-edit-dialog="close"
      @save-warehouse-address-edit-dialog="saveAddress"
    ></warehouse-address-edit-dialog> -->
    <v-col v-if="warehouse" cols="12" class="py-0">
      <v-row>
        <v-col cols="12" lg="6">
          <v-card flat class="mb-3">
            <v-card-title class="mb-3"
              ><span>Details</span><v-spacer></v-spacer>
              <v-btn
                v-if="
                  !editMode &&
                    ((warehouse && authLevel && authLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                rounded
                outlined
                small
                color="grey"
                :disabled="processing"
                @click="editWarehouse"
              >
                edit
              </v-btn></v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Name</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.name }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Type</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.type }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Notes</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.notes }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Provider Key</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.providerKey }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row> -->

              <!-- <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Shopify Key</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.shopifyKey }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row> -->

              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Last Update</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.updatedAt | moment('from', 'now') }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6">
          <v-card flat class="mb-3">
            <v-card-title class="mb-3"
              ><span>Contact Details</span><v-spacer></v-spacer>
              <v-btn
                v-if="
                  !editMode &&
                    ((warehouse && authLevel && authLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                rounded
                outlined
                small
                color="grey"
                :disabled="processing"
                @click="editWarehouse"
              >
                edit
              </v-btn></v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Name</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.provider.name }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Email</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.address.email }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Phone</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.address.phone }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Line 1</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.address.line1 }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Line 2</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.address.line2 }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Line 3</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.address.line3 }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Postcode</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.address.postCode }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">City</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.address.city }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >State/Province</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ warehouse.address.state }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Country</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        v-if="warehouse.address && warehouse.address.country"
                        class="font-weight-medium grey--text text--darken-2"
                        >{{
                          getFullCountryName(warehouse.address.country)
                        }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { lookup } from 'country-data-list'
import WarehouseEditDialog from '@/modules/logistics/components/core/WarehouseEditDialog.vue'
// import WarehouseAddressEditDialog from '@/modules/production/components/core/WarehouseAddressEditDialog.vue'
import WAREHOUSE_UPDATE from '@/graphql/WarehouseUpdate.gql'
import WAREHOUSE_ADDRESS_UPDATE from '@/graphql/WarehouseAddressUpdate.gql'

export default {
  name: 'WarehouseDetail',
  components: { WarehouseEditDialog },
  props: {
    warehouseProviders: {
      type: Object,
      default: () => {
        return { items: [] }
      }
    },
    warehouse: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    paymentTerms: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    dialog: false,
    addressDialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    defaultItem: {
      id: null,
      name: null,
      providerKey: null,
      shopifyKey: null,
      note: null,
      address: {
        id: null,
        name: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        state: null,
        city: null,
        postCode: null,
        country: null
      }
    },
    editedItem: {
      id: null,
      name: null,
      providerKey: null,
      shopifyKey: null,
      note: null,
      address: {
        id: null,
        name: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        state: null,
        city: null,
        postCode: null,
        country: null
      }
    }
  }),
  methods: {
    getFullCountryName(code) {
      return lookup.countries({ alpha2: code })[0]?.name
    },
    editAddress() {
      this.editedItem = this._.cloneDeep(this.warehouse)
      this.editMode = true
      this.addressDialog = true
    },
    editWarehouse() {
      this.editedItem = this._.cloneDeep(this.warehouse)
      this.editMode = true
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.addressDialog = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      // delete item.address
      // delete item.email
      // delete item.phone

      this.$apollo
        .mutate({
          mutation: WAREHOUSE_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.close()
          this.$emit('refresh-warehouse')
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Warehouse Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    saveAddress(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: WAREHOUSE_ADDRESS_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Warehouse Address Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
