<template>
  <v-row justify="center">
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Tech Pack</v-card-title
        >
        <v-card-text>
          <p>
            This will delete tech pack version
            <strong v-if="removeableItem && removeableItem.version">{{
              removeableItem.version
            }}</strong>
            and can't be undone!
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeRemoveDialog"
            >Cancel</v-btn
          >
          <v-btn :loading="processing" color="error" text @click="removeFile"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="imageDialog" persistent max-width="550">
      <v-card>
        <v-card-title class="headline primary--text mb-3">{{
          imageDialogTitle
        }}</v-card-title>
        <v-card-text>
          <div v-if="errorMsg !== ''" class="mb-3 pb-0">
            <v-alert type="error" class="mb-0">
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-form
            ref="imageForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="editMode">
              <v-col cols="12" class="pb-0">
                <h3 class="mb-3">
                  Version <strong>{{ file.version }}</strong>
                </h3>
              </v-col>
            </v-row>

            <v-row class="mb-2">
              <v-col cols="12" class="pb-0">
                <div class="font-weight-medium">Title</div>
              </v-col>
              <v-col cols="12" class="mb-2">
                <v-text-field
                  v-model="versionTitle"
                  hide-details="auto"
                  outlined
                  flat
                  dense
                  background-color="#fff"
                  placeholder="Title"
                  autocomplete="off"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="!editMode" class="mt-2">
              <v-col cols="12" class="pb-0">
                <div class="font-weight-medium">Tech Pack File</div>
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-file-input
                  v-model="imageFile"
                  :rules="requiredRule"
                  counter
                  placeholder="Select your file"
                  prepend-inner-icon="mdi-image-outline"
                  :prepend-icon="null"
                  outlined
                  dense
                  :show-size="1000"
                  hide-details="auto"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip color="info accent-4" dark small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row v-if="!editMode" class="mt-2">
              <v-col cols="12" class="pb-0">
                <div class="font-weight-medium">Artwork File</div>
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-file-input
                  v-model="artworkFile"
                  counter
                  placeholder="Select your file"
                  prepend-inner-icon="mdi-image-outline"
                  :prepend-icon="null"
                  outlined
                  dense
                  :show-size="1000"
                  hide-details="auto"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip color="info accent-4" dark small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pb-0">
                <div class="font-weight-medium">Notes</div>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="imageNotes"
                  hide-details="auto"
                  outlined
                  flat
                  dense
                  rows="3"
                  background-color="#fff"
                  placeholder="Notes"
                  autocomplete="off"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            :disabled="processing"
            @click="closeImageDialog"
            >Cancel</v-btn
          >
          <v-btn
            v-if="editMode"
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="updateNotes"
            >Save</v-btn
          >
          <v-btn
            v-else
            :loading="processing"
            color="primary lighten-2"
            rounded
            small
            @click="uploadFile"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col v-if="$apollo.loading" cols="12">
      <v-skeleton-loader
        v-for="i in 5"
        :key="i"
        type="list-item"
        class="mx-auto"
      ></v-skeleton-loader>
    </v-col>

    <v-col v-if="!$apollo.loading" cols="12">
      <v-card
        v-if="techPack && techPack.files && techPack.files.length > 0"
        flat
        class="mb-3"
      >
        <v-card-title class="mb-3"
          ><div>
            <div>Tech Packs</div>
            <div class="caption grey--text">
              All tech pack files are assigned a version number
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              product &&
                product.styleState &&
                product.styleState === 'DEVELOPMENT' &&
                !editMode &&
                ((product && techPackAuthLevel && techPackAuthLevel === 2) ||
                  (currentUser &&
                    (currentUser.isSuperAdmin ||
                      currentUser.userRole === 'director' ||
                      currentUser.userRole === 'seniormanager' ||
                      currentUser.userRole === 'projectmanager')))
            "
            rounded
            color="primary lighten-3"
            small
            @click="openImageDialog"
            >add version</v-btn
          >
        </v-card-title>
        <v-data-table
          :headers="headers"
          :loading="$apollo.loading"
          :items="techPack.files"
          :mobile-breakpoint="null"
          hide-default-footer
        >
          <template v-slot:item.version="{ item }">
            {{ item.version }}
          </template>
          <template v-slot:item.note="{ item }">
            <div class="d-inline-block text-truncate" style="max-width: 200px;">
              {{ item.note }}
            </div>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span>{{ item.createdAt | moment('DD MMM, YYYY') }}</span>
          </template>
          <template v-slot:item.createdBy="{ item }">
            <span
              >{{ item.createdBy.firstName }}
              {{ item.createdBy.lastName }}</span
            >
          </template>
          <template v-slot:item.controls="{ item }">
            <v-btn
              :href="item.url"
              text
              rounded
              color="info"
              class="text-lowercase"
              target="_blank"
              >tech pack</v-btn
            >
            <v-btn
              :href="item.artworkUrl"
              text
              rounded
              color="info"
              class="text-lowercase"
              target="_blank"
              :disabled="!item.artworkUrl"
              >artwork</v-btn
            >
            <v-btn
              text
              rounded
              color="info"
              class="text-lowercase"
              @click="updateAsset(item)"
              >view/edit</v-btn
            >
            <v-btn
              v-if="
                product &&
                  product.styleState &&
                  product.styleState === 'DEVELOPMENT' &&
                  techPackAuthLevel &&
                  techPackAuthLevel === 2
              "
              text
              rounded
              color="grey lighten-1"
              class="ml-2 text-lowercase"
              @click.stop="openRemoveDialog(item)"
              >delete</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
      <message-box
        v-else
        icon="mdi-brush"
        title="Tech Packs"
        caption="You have not added any tech packs to this product yet"
      >
        <v-btn
          v-if="
            product &&
              product.styleState &&
              product.styleState === 'DEVELOPMENT' &&
              !editMode &&
              ((product && techPackAuthLevel && techPackAuthLevel === 2) ||
                (currentUser &&
                  (currentUser.isSuperAdmin ||
                    currentUser.userRole === 'director' ||
                    currentUser.userRole === 'seniormanager' ||
                    currentUser.userRole === 'projectmanager')))
          "
          rounded
          color="primary lighten-3"
          small
          @click="openImageDialog"
          >add tech pack</v-btn
        >
      </message-box>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox'
// import AssetUploadButton from '@/components/core/AssetUploadButton'
import PRODUCT_ASSETS from '@/graphql/ProductAssets.gql'
import PRODUCT_ASSET_CREATE from '@/graphql/ProductAssetCreate.gql'
import PRODUCT_ASSET_VERSION_CREATE from '@/graphql/ProductAssetVersionCreate.gql'
import PRODUCT_ASSET_VERSION_UPDATE from '@/graphql/ProductAssetVersionUpdate.gql'
import PRODUCT_ASSET_FILE_VERSION_REMOVE from '@/graphql/ProductAssetFileVersionRemove.gql'

export default {
  name: 'ProductionProductDesignAssets',
  components: { MessageBox },
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} }
  },
  data: () => ({
    processing: false,
    editMode: false,
    removeDialog: false,
    file: {
      name: null,
      version: null
    },
    imageDialog: false,
    imageNotes: null,
    versionTitle: null,
    imageFile: null,
    artworkFile: null,
    errorMsg: '',
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    removeableItem: null
  }),
  computed: {
    techPackAuthLevel() {
      return this.$store.getters.authLevel('product_tech_pack')
    },
    headers() {
      return [
        {
          text: 'Version',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'version'
        },
        {
          text: 'Title',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'title'
        },
        {
          text: 'Notes',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'note'
        },
        {
          text: 'Uploaded On',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'createdAt'
        },
        {
          text: 'Created By',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'createdBy'
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          filterable: true,
          value: 'controls'
        }
      ]
    },
    techPack() {
      return this._.find(this.productAssets, function(item) {
        return item.assetType === 'TECHPACK'
      })
    },
    imageDialogTitle() {
      return this.editMode ? 'Tech Pack Details' : 'Add New Tech Pack'
    }
  },
  apollo: {
    productAssets: {
      query: PRODUCT_ASSETS,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      }
    }
  },
  methods: {
    openImageDialog() {
      this.imageDialog = true
    },
    updateAsset(item) {
      this.file = this._.cloneDeep(item)
      this.editMode = true
      this.imageNotes = item.note
      this.versionTitle = item.title
      this.imageDialog = true
    },
    updateNotes() {
      var that = this
      this.processing = true

      let data = {
        id: that.file.id,
        note: that.imageNotes,
        title: that.versionTitle
      }

      that.$apollo
        .mutate({
          mutation: PRODUCT_ASSET_VERSION_UPDATE,
          variables: {
            input: data
          }
        })
        .then(() => {
          that.$store.commit('showSnackbar', {
            active: true,
            message: 'Tech Pack Version Updated'
          })
          that.closeImageDialog()
        })
    },
    uploadFile() {
      // Create file object
      var that = this

      if (this.$refs.imageForm.validate()) {
        this.processing = true

        if (
          !that.techPack ||
          !that.techPack.files ||
          that.techPack.files.length === 0
        ) {
          let data = {
            productID: that.$route.params.productID,
            assetType: 'techpack',
            versioned: true,
            note: that.imageNotes,
            title: that.versionTitle,
            file: that.imageFile,
            artworkFile: that.artworkFile
          }

          // Create a new one
          that.$apollo
            .mutate({
              mutation: PRODUCT_ASSET_CREATE,
              variables: {
                input: data
              },
              update: (store, { data: { createProductAsset } }) => {
                const data = store.readQuery({
                  query: PRODUCT_ASSETS,
                  variables: {
                    productID: createProductAsset.productID
                  }
                })

                data.productAssets.push(createProductAsset)

                store.writeQuery({
                  query: PRODUCT_ASSETS,
                  variables: { productID: createProductAsset.productID },
                  data
                })
              }
            })
            .then(() => {
              that.$store.commit('showSnackbar', {
                active: true,
                message: 'Tech Pack Added'
              })
              that.closeImageDialog()
            })
            .catch(err => console.log(err))
        } else {
          let data = {
            id: that.techPack.id,
            note: that.imageNotes,
            title: that.versionTitle,
            file: that.imageFile,
            artworkFile: that.artworkFile
          }
          // Add new version
          that.$apollo
            .mutate({
              mutation: PRODUCT_ASSET_VERSION_CREATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              that.$store.commit('showSnackbar', {
                active: true,
                message: 'Tech Pack Version Added'
              })

              that.closeImageDialog()
            })
            .catch(err => console.log(err))
        }
      }
    },
    closeImageDialog() {
      this.imageDialog = false

      this.processing = false
      this.editMode = false
      this.errorMsg = ''
      this.imageNotes = null
      this.versionTitle = null
      this.imageFile = null
      this.artworkFile = null

      this.$refs.imageForm.reset()
    },
    openRemoveDialog(item) {
      this.removeableItem = item
      this.removeDialog = true
    },
    closeRemoveDialog() {
      this.removeableItem = null
      this.removeDialog = false
    },
    removeFile() {
      var vm = this
      this.processing = true
      if (this.removeableItem && this.removeableItem.id) {
        this.$apollo
          .mutate({
            mutation: PRODUCT_ASSET_FILE_VERSION_REMOVE,
            variables: {
              id: this.removeableItem.id
            },
            update: (store, { data: { removeProductAssetFileVersion } }) => {
              const data = store.readQuery({
                query: PRODUCT_ASSETS,
                variables: {
                  productID: vm.product.id
                }
              })

              let paIdx = vm._.findIndex(data.productAssets, function(item) {
                return item.id === vm.techPack.id
              })

              if (
                paIdx > -1 &&
                data.productAssets[paIdx].files &&
                data.productAssets[paIdx].files.length > 1
              ) {
                var idx = vm._.findIndex(
                  data.productAssets[paIdx].files,
                  item => item.id === removeProductAssetFileVersion
                )

                data.productAssets[paIdx].files.splice(idx, 1)
              } else {
                data.productAssets.splice(paIdx, 1)
              }

              // Remove the item

              store.writeQuery({
                query: PRODUCT_ASSETS,
                variables: { productID: vm.product.id },
                data
              })
            }
          })
          .then(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Tech Pack Version Removed'
            })

            this.removeableItem = null
            this.removeDialog = false
            this.processing = false
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Something went wrong'
            })

            this.removeableItem = null
            this.removeDialog = false
            this.processing = false
          })
      } else {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Nothing to remove'
        })
        this.processing = false
        this.removeDialog = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
