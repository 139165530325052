<template>
  <div class="page-header">
    <v-toolbar class="mb-0 b-radius" flat>
      <v-tabs
        next-icon="mdi-chevron-right"
        prev-icon="mdi-chevron-left"
        background-color="transparent"
        align-with-title
        center-active
        show-arrows
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab
          :to="{ name: 'ProductionSettingsSizes' }"
          class="text-capitalize"
          exact
          >Sizes</v-tab
        >

        <v-tab
          :to="{ name: 'ProductionSettingsCategories' }"
          class="text-capitalize"
          exact
          >Categories</v-tab
        >

        <v-tab
          :to="{ name: 'ProductionSettingsArticleGroups' }"
          class="text-capitalize"
          exact
          >Article Groups</v-tab
        >

        <v-tab
          :to="{ name: 'ProductionSettingsSubArticleGroups' }"
          class="text-capitalize"
          exact
          >Sub Article Groups</v-tab
        >

        <v-tab
          :to="{ name: 'ProductionSettingsStyleTypes' }"
          class="text-capitalize"
          exact
          >Style Types</v-tab
        >

        <v-tab
          :to="{ name: 'ProductionSettingsColors' }"
          class="text-capitalize"
          exact
          >Colors</v-tab
        >

        <v-tab
          :to="{ name: 'ProductionSettingsBarcodes' }"
          class="text-capitalize"
          exact
          >Barcode Ranges</v-tab
        >

        <v-tab
          :to="{ name: 'ProductionSettingsWholesalePriceMarkup' }"
          class="text-capitalize"
          exact
          >Wholesale Markups</v-tab
        >

        <v-tab
          :to="{ name: 'ProductionSettingsRetailPriceMarkup' }"
          class="text-capitalize"
          exact
          >Retail Markups</v-tab
        >

        <!-- <v-tab
          :to="{ name: 'ProductionSettingsWorkflow' }"
          class="text-capitalize"
          exact
          >Workflow</v-tab
        > -->
      </v-tabs>

      <slot name="controls"></slot>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'ProductionSettingsToolbar'
}
</script>

<style></style>
