<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div v-if="item">
        <div class="scroll-container pt-0">
          <v-form
            ref="manufacturingOrderForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col>
                <v-row>
                  <v-col
                    v-if="!editMode || (editDocketRef && editMode)"
                    cols="12"
                  >
                    <v-text-field
                      v-model="item.docketRef"
                      autocomplete="off"
                      outlined
                      label="Docket Ref"
                      placeholder="Docket Ref"
                      dense
                      :rules="requiredRule"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    v-if="!editMode || (editOrderDate && editMode)"
                    cols="12"
                  >
                    <v-menu
                      ref="menu2"
                      v-model="orderDate"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedOrderDate"
                          autocomplete="off"
                          outlined
                          label="Order Date"
                          placeholder="Order Date"
                          prepend-inner-icon="mdi-calendar-month-outline"
                          dense
                          hide-details="auto"
                          readonly
                          :rules="requiredRule"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.orderDate"
                        no-title
                        hide-details
                        first-day-of-week="1"
                        @input="endDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    v-if="!editMode || (editManufacturerAddress && editMode)"
                    cols="12"
                    class="pt-0"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="item.manufacturer"
                          :items="manufacturers"
                          :search-input.sync="searchManufacturer"
                          item-text="name"
                          item-value="id"
                          return-object
                          label="Manufacturer"
                          placeholder="Select a Manufacturer"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          :rules="requiredSelectRule"
                          :disabled="editMode || manufacturerId !== null"
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    v-if="!editMode || (editManufacturerAddress && editMode)"
                    cols="12"
                    class="pt-0"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="item.manufacturerAddressID"
                          :items="
                            item.manufacturer && item.manufacturer.addresses
                              ? item.manufacturer.addresses
                              : []
                          "
                          item-text="name"
                          item-value="id"
                          label="Manufacturer Location"
                          placeholder="Select Manufacturing Address"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          :rules="requiredSelectRule"
                          :disabled="!item.manufacturer"
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Delivery Address -->
                  <v-col
                    v-if="!editMode || (editDeliveryAddress && editMode)"
                    cols="12"
                    class="pt-0"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="item.deliverToID"
                          :items="warehouses.items"
                          item-text="name"
                          item-value="id"
                          label="Deliver To"
                          placeholder="Select Delivery Address"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          :rules="requiredSelectRule"
                          :disabled="
                            !item.manufacturer || !item.manufacturerAddressID
                          "
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col v-if="!editMode" cols="12" class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="item.seasonID"
                          :items="seasons"
                          item-text="longName"
                          item-value="id"
                          label="Season"
                          placeholder="Select a Season"
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          :rules="requiredSelectRule"
                          :disabled="
                            !item.manufacturer || !item.manufacturerAddressID
                          "
                          dense
                        >
                          <template v-slot:item="{ item }">
                            {{ item.longName }} ({{ item.shortName }})
                          </template>
                          <template v-slot:selection="{ item }">
                            {{ item.longName }} ({{ item.shortName }})
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Deliver Dates -->
                  <v-col
                    v-if="editDeliveryDate && editMode"
                    cols="12"
                    class="py-0"
                  >
                    <v-row>
                      <v-col cols="12" md="6" lg="6">
                        <v-row>
                          <v-col cols="12">
                            <v-menu
                              ref="menu1"
                              v-model="startDate"
                              autocomplete="off"
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="formattedStartDate"
                                  outlined
                                  prepend-inner-icon="mdi-calendar-month-outline"
                                  dense
                                  label="ex-factory"
                                  placeholder="Ex-factory Date"
                                  hide-details="auto"
                                  readonly
                                  clearable
                                  v-bind="attrs"
                                  v-on="on"
                                  @click:clear="item.exFactoryDate = null"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="item.exFactoryDate"
                                no-title
                                hide-details
                                first-day-of-week="1"
                                @input="startDate = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">
                        <v-row>
                          <v-col cols="12">
                            <v-menu
                              ref="menu2"
                              v-model="endDate"
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="formattedEndDate"
                                  autocomplete="off"
                                  outlined
                                  label="arrival"
                                  placeholder="Arrival Date"
                                  prepend-inner-icon="mdi-calendar-month-outline"
                                  dense
                                  hide-details="auto"
                                  readonly
                                  clearable
                                  :disabled="
                                    !item.exFactoryDate && !item.arrivalDate
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  @click:clear="item.arrivalDate = null"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="item.arrivalDate"
                                :min="
                                  $moment(item.exFactoryDate)
                                    .add(1, 'days')
                                    .format('YYYY-MM-DD')
                                "
                                no-title
                                hide-details
                                first-day-of-week="1"
                                @input="endDate = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Notes -->
                  <v-col v-if="editNote && editMode" cols="12" class="pt-2">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-textarea
                          v-model="item.note"
                          placeholder="Notes you want to add to the order."
                          autocomplete="off"
                          hide-details="auto"
                          outlined
                          persistent-hint
                          dense
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey lighten-1" :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'ManufacturingOrderEditDialog',
  props: {
    manufacturingOrder: {
      type: Object,
      required: true,
      default: () => {}
    },
    manufacturers: {
      type: Array,
      required: true,
      default: () => []
    },
    manufacturerId: {
      type: String,
      default: null
    },
    warehouses: {
      type: Object,
      required: true,
      default: () => ({ items: [] })
    },
    seasons: {
      type: Array,
      required: true,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    editDocketRef: {
      type: Boolean,
      default: false
    },
    editNote: {
      type: Boolean,
      default: false
    },
    editOrderDate: {
      type: Boolean,
      default: false
    },
    editDeliveryDate: {
      type: Boolean,
      default: false
    },
    editDeliveryAddress: {
      type: Boolean,
      default: false
    },
    editManufacturerAddress: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchManufacturer: null,
      valid: true,
      requiredRule: [
        v => !!v || 'Field is required',
        v => !/^\s+$/.test(v) || 'Field is required'
      ],
      requiredSelectRule: [v => !!v || 'Field is required'],
      requiredDateRule: [v => !!v || 'Date is required'],
      item: {
        id: null,
        manufacturerID: null,
        paymentTermID: null,
        exchangeRate: null,
        currencyID: null,
        seasonID: null,
        shipperID: null,
        sampleOrder: false,
        net: 0,
        taxPercentage: 0,
        tax: 0,
        discountPercentage: 0,
        discount: 0,
        note: null,
        orderDate: this.$moment().format('YYYY-MM-DD'),
        expectedDeliveryStartDate: this.$moment().format('YYYY-MM-DD'),
        expectedDeliveryEndDate: this.$moment()
          .add(10, 'days')
          .format('YYYY-MM-DD')
      },
      startDate: false,
      endDate: false,
      orderDate: false
    }
  },
  computed: {
    title() {
      if (this.editManufacturerAddress && this.editMode)
        return 'Manufacturer Address'
      if (this.editDeliveryAddress && this.editMode) return 'Delivery Address'
      if (this.editDeliveryDate && this.editMode) return 'Delivery Dates'
      if (this.editNote && this.editMode) return 'Notes'
      if (this.editMode) return 'Edit Manufacturing Order'
      else return 'New Manufacturing Order'
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    },
    newStockQuantity() {
      return (
        parseInt(this.item.physicalQuantity) +
          parseInt(this.item.stockChange) || 0
      )
    },
    unFormattedStartDate() {
      return this.item.exFactoryDate
    },
    formattedStartDate() {
      return this.item.exFactoryDate
        ? this.$moment(this.item.exFactoryDate).format('LL')
        : ''
    },
    formattedEndDate() {
      return this.item.arrivalDate
        ? this.$moment(this.item.arrivalDate).format('LL')
        : ''
    },
    formattedOrderDate() {
      return this.item.orderDate
        ? this.$moment(this.item.orderDate).format('LL')
        : ''
    }
  },
  watch: {
    dialog(val) {
      var vm = this
      if (val) {
        vm.item = vm._.cloneDeep(vm.manufacturingOrder)

        if (vm.manufacturers && vm.manufacturers.length > 0) {
          vm.item.manufacturer = vm._.find(vm.manufacturers, function(
            manufacturer
          ) {
            return manufacturer.id === vm.manufacturerId || vm.item.id
          })
        }
      } else {
        vm.$refs.manufacturingOrderForm.resetValidation()
      }
    },
    formattedStartDate(date) {
      let dateObj = this.$moment(date, 'LL')
      if (dateObj.isAfter(this.$moment(this.formattedEndDate, 'LL'))) {
        // this.formattedEndDate = date
        this.item.arrivalDate = this.$moment(date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
    },
    searchManufacturer(input) {
      var vm = this
      // vm._.debounce(() => {
      vm.$emit('search-manufacturer', input)
      // }, 200)
    }
  },
  methods: {
    // formatDate (date) {
    //     if (!date) return null

    //     const [year, month, day] = date.split('-')
    //     return `${month}/${day}/${year}`
    //   },
    //   parseDate (date) {
    //     if (!date) return null

    //     const [month, day, year] = date.split('/')
    //     return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    //   }
    close() {
      this.$emit('close-manufacturing-order-edit-dialog', false)
    },
    save() {
      if (this.$refs.manufacturingOrderForm.validate()) {
        var data = {
          id: this.item.id,
          docketRef: this.item.docketRef,
          manufacturerID: this.item.manufacturer
            ? this.item.manufacturer.id
            : null,
          currencyID:
            this.item.manufacturer && this.item.manufacturer.currency
              ? this.item.manufacturer.currency.id
              : null,
          // paymentTermID:
          //   this.item.manufacturer && this.item.manufacturer.paymentTerm
          //     ? this.item.manufacturer.paymentTerm.id
          //     : null,
          exchangeRate:
            this.item.manufacturer && this.item.manufacturer.currency
              ? this.item.manufacturer.currency.exchangeRate
              : null,
          manufacturerAddressID: this.item.manufacturerAddressID,
          deliverToID: this.item.deliverToID,
          seasonID: this.item.seasonID,
          note: this.item.note,
          exFactoryDate:
            this.editMode && this.item.exFactoryDate
              ? this.$moment(this.item.exFactoryDate).utc()
              : null,
          arrivalDate:
            this.editMode && this.item.arrivalDate
              ? this.$moment(this.item.arrivalDate).utc()
              : null,
          orderDate: this.item.orderDate
            ? this.$moment(this.item.orderDate).utc()
            : null
        }

        this.$emit('save-manufacturing-order-edit-dialog', data)
      }
    }
  }
}
</script>

<style lang="scss">
.stockchange {
  input {
    text-align: right;
  }
}
</style>
