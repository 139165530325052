<template>
  <v-row justify="center">
    <product-edit-dialog
      :dialog="editDialog"
      :edit="editMode"
      :seasons="seasons.seasons"
      :selected-season="selectedSeason"
      :product="editedItem"
      :colors="colors"
      :categories="productCategories"
      :article-groups="articleGroups"
      :sub-article-groups="subArticleGroups"
      :style-types="styleTypes"
      :processing="processing"
      :error-msg="errorMsg"
      :season-add="true"
      @save-product-edit-dialog="saveProduct"
      @close-product-edit-dialog="closeEditProductDialog"
    >
    </product-edit-dialog>
    <v-col cols="12" class="py-0 mb-0">
      <v-sheet>
        <v-toolbar class="mb-0 b-radius" color="white" flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col>
              <v-btn
                v-if="productDesignAuthLevel && productDesignAuthLevel === 2"
                color="primary"
                class="mr-2"
                rounded
                @click="editDialog = true"
              >
                add product
              </v-btn>
            </v-col>

            <v-col
              cols="3"
              lg="3"
              xl="3"
              class="d-none d-sm-none d-md-none d-lg-flex"
            >
              <v-select
                v-model="selectedDesigner"
                :items="users"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                label="designer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                item-value="id"
                :disabled="
                  !products ||
                    (products.items.length === 0 && !users) ||
                    users.length === 0
                "
              >
                <template v-slot:selection="subData"
                  ><div class="text-truncate" style="width: 80%">
                    {{ subData.item.firstName + ' ' + subData.item.lastName }}
                  </div></template
                >
                <template v-slot:item="{ item }">
                  <span>{{ item.firstName + ' ' + item.lastName }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="3"
              lg="3"
              xl="3"
              class="d-none d-sm-none d-md-none d-lg-flex"
            >
              <v-select
                v-model="selectedProducer"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
                label="producer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                item-value="id"
                :items="users"
                :disabled="
                  !products ||
                    (products.items.length === 0 && !users) ||
                    users.length === 0
                "
              >
                <template v-slot:selection="subData"
                  ><div class="text-truncate" style="width: 80%">
                    {{ subData.item.firstName + ' ' + subData.item.lastName }}
                  </div></template
                >
                <template v-slot:item="{ item }">
                  <span>{{ item.firstName + ' ' + item.lastName }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <span class="pt-3 text-truncate">{{ itemCount }} Products</span>

          <v-btn
            icon
            class="d-sm-flex d-md-flex d-lg-none d-xl-none ml-2"
            @click="filters = !filters"
          >
            <v-icon :color="filters ? 'info' : ''">mdi-filter-variant</v-icon>
          </v-btn>
        </v-toolbar>
      </v-sheet>
    </v-col>

    <v-col cols="12" class="pt-0">
      <v-card v-if="filters" class="mb-3">
        <div>
          <v-col cols="12">
            <v-row no-gutters>
              <span>Advanced Filters</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="filters = !filters"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="selectedDesigner"
              :items="users"
              hide-details
              outlined
              flat
              dense
              clearable
              background-color="#fff"
              label="designer"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              item-value="id"
              :disabled="
                !products ||
                  (products.items.length === 0 && !users) ||
                  users.length === 0
              "
            >
              <template v-slot:selection="subData"
                ><div class="text-truncate" style="width: 80%">
                  {{ subData.item.firstName + ' ' + subData.item.lastName }}
                </div></template
              >
              <template v-slot:item="{ item }">
                <span>{{ item.firstName + ' ' + item.lastName }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="selectedProducer"
              hide-details
              outlined
              flat
              dense
              clearable
              background-color="#fff"
              label="producer"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              item-value="id"
              :items="users"
              :disabled="
                !products ||
                  (products.items.length === 0 && !users) ||
                  users.length === 0
              "
            >
              <template v-slot:selection="subData"
                ><div class="text-truncate" style="width: 80%">
                  {{ subData.item.firstName + ' ' + subData.item.lastName }}
                </div></template
              >
              <template v-slot:item="{ item }">
                <span>{{ item.firstName + ' ' + item.lastName }}</span>
              </template>
            </v-select>
          </v-col>
        </div>
      </v-card>

      <v-card flat>
        <v-card-title class="pt-0">
          <v-text-field
            v-model="search"
            hide-details
            flat
            clearable
            background-color="#fff"
            placeholder="Search..."
            prepend-inner-icon="mdi-magnify"
            autocomplete="off"
            :disabled="!products || products.length === 0"
          ></v-text-field>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :loading="$apollo.loading"
          :items="products.items"
          :options.sync="options"
          :server-items-length="itemCount"
          :mobile-breakpoint="null"
          :hide-default-footer="
            !products ||
              products.items.length === 0 ||
              products.items.length < 15
          "
          show-select
          class="plain-table"
          @page-count="pageCount = $event"
          @click:row="navToDetail"
        >
          <template v-slot:item.name="{ item }">
            <div
              class="text-truncate primary--text text--darken-2 font-weight-medium"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.styleState="{ item }">
            <div class="text-truncate primary--text">
              <v-chip
                pill
                x-small
                :color="styleState(item.styleState) + ' upperCaseSpacing'"
                :text-color="styleText(item.styleState)"
              >
                {{ item.styleState }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.category="{ item }">
            <div class="text-truncate primary--text">
              <v-chip
                v-if="item.category && item.category.id"
                color="info"
                x-small
                pill
                outlined
                >{{ item.category.name }}</v-chip
              >
            </div>
          </template>
          <!-- <template v-slot:item.color="{ item }">
            <div class="text-truncate primary--text">
              <span v-if="item.color.id"
                >{{ item.color.name }} ({{ item.color.colorCode }})</span
              >
            </div>
          </template> -->
          <template v-slot:item.designers="{ item }">
            <div
              v-if="item.designers && item.designers.length > 0"
              class="text-truncate"
            >
              <template v-for="(designer, index) in item.designers">
                <v-chip
                  v-if="index < 1"
                  :key="designer.id"
                  pill
                  x-small
                  outlined
                  color="secondary"
                  class="mr-2"
                  ><v-avatar x-small left style="margin-left: -19px">
                    <v-icon>mdi-account-circle</v-icon>
                  </v-avatar>
                  {{ designer.firstName + ' ' + designer.lastName }}</v-chip
                >
                <span
                  v-if="index > 0"
                  :key="designer.id"
                  class="grey--text caption"
                  >(+{{ item.designers.length - 1 }} others)</span
                >
              </template>
            </div>
          </template>
          <template v-slot:no-data>
            <message-box
              icon="mdi-emoticon-neutral-outline"
              title="No Results"
              caption="Try adjusting your filters to get some results"
            ></message-box>
          </template>
          <template v-slot:loading>
            <v-skeleton-loader
              v-for="item in 5"
              :key="item"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import ProductEditDialog from '@/modules/production/components/core/ProductEditDialog.vue'
import SEASONS from '@/graphql/Seasons.gql'
import PRODUCTS from '@/graphql/Products.gql'
import CREATE_PRODUCT from '@/graphql/ProductCreate.gql'
import USERS from '@/graphql/Users.gql'
import COLORS from '@/graphql/Colors.gql'
import CATEGORIES from '@/graphql/ProductCategories.gql'
import ARTICLE_GROUPS from '@/graphql/ArticleGroups.gql'
import SUB_ARTICLE_GROUPS from '@/graphql/SubArticleGroups.gql'
import STYLE_TYPES from '@/graphql/StyleTypes.gql'

export default {
  name: 'ProductionSeasonProducts',
  components: { MessageBox, ProductEditDialog },
  props: {
    season: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  data: () => ({
    filters: false,
    selectedDesigner: null,
    selectedProducer: null,
    search: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    selected: [],
    products: { items: [] },
    users: [],
    seasons: [],
    processing: false,
    editMode: false,
    editDialog: false,
    errorMsg: '',
    defaultItem: {
      name: '',
      description: '',
      designer: {}
    },
    editedItem: {
      name: '',
      description: '',
      designer: {}
    }
  }),
  apollo: {
    users: USERS,
    seasons: SEASONS,
    colors: COLORS,
    productCategories: CATEGORIES,
    articleGroups: ARTICLE_GROUPS,
    subArticleGroups: SUB_ARTICLE_GROUPS,
    styleTypes: STYLE_TYPES,
    products: {
      query: PRODUCTS,
      variables() {
        return {
          filters: {
            nameSearch: this.search,
            season: this.$route.params.seasonID,
            designer: this.selectedDesigner,
            producer: this.selectedProducer,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        this.page = result.data.products.page
        this.itemsPerPage = result.data.products.pageSize
        this.itemCount = result.data.products.total
      }
    }
  },
  computed: {
    productDesignAuthLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    headers() {
      return [
        {
          text: '',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'images.thumbnail.url'
        },
        {
          text: 'Style Status',
          align: 'left',
          value: 'styleState',
          sortable: false,
          filter: this.stateFilterValue
        },
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Style Number',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'styleNumber'
        },
        {
          text: 'Color',
          value: 'seasonColor',
          sortable: false,
          filterable: true
        },
        {
          text: 'Composite',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'textileContent'
        },
        {
          text: 'Gender',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
          filterable: true
        }
      ]
    },
    selectedSeason() {
      return this._.find(
        this.seasons.seasons,
        item => item.id === this.$route.params.seasonID
      )
    }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    styleState(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'grey lighten-2 font-weight-bold'
          break
        case 'REVIEW':
          color = 'warning lighten-1 font-weight-bold'
          break
        case 'FINAL':
          color = 'info lighten-2 font-weight-bold'
          break
        default:
          color = 'success lighten-3 font-weight-bold'
          break
      }
      return color
    },
    styleText(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'grey darken-1'
          break
        case 'REVIEW':
          color = 'warning darken-3'
          break
        case 'FINAL':
          color = 'info darken-1'
          break
        default:
          color = 'success'
          break
      }
      return color
    },
    navToDetail(item) {
      var seasonID = this.selectedSeason.id || item.seasons[0].id

      this.$router.push({
        name: 'ProductionProductDetail',
        params: { productID: item.id },
        query: { season: seasonID }
      })
    },
    showSnackbar() {
      this.$store.commit('showSnackbar', { active: true })
    },
    saveProduct(item, newItem, callback) {
      this.processing = true
      var that = this

      // Save Item
      delete item.id

      this.$apollo
        .mutate({
          mutation: CREATE_PRODUCT,
          variables: {
            input: item
          },
          update: (store, { data: { createProduct } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: PRODUCTS,
              variables: {
                filters: {
                  nameSearch: that.search,
                  season: that.selectedSeason,
                  designer: that.selectedDesigner,
                  page: that.options.page,
                  pageSize: that.options.itemsPerPage
                }
              }
            })
            // Add our requirement from the mutation to the end
            data.products.items.unshift(createProduct)
            // Write our data back to the cache.
            store.writeQuery({
              query: PRODUCTS,
              variables: {
                filters: {
                  nameSearch: that.search,
                  season: that.selectedSeason,
                  designer: that.selectedDesigner,
                  page: that.options.page,
                  pageSize: that.options.itemsPerPage
                }
              },
              data
            })
          }
        })
        .then(() => {
          if (newItem) {
            that.$store.commit('showSnackbar', {
              active: true,
              message: 'Product Added'
            })

            that.editedItem = that._.cloneDeep(that.defaultItem)
            that.processing = false
            that.errorMsg = ''
            that.editMode = false
            that.$apollo.queries.products.refetch()
            callback(true)
          } else {
            that.closeEditProductDialog(true)
            that.$apollo.queries.products.refetch()
          }
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    closeEditProductDialog(success) {
      this.editDialog = false
      this.processing = false
      this.errorMsg = ''

      if (success) {
        let msg = ''
        if (this.editMode === true) {
          msg = 'Product Updated'
        } else {
          msg = 'Product Added'
        }

        this.$store.commit('showSnackbar', {
          active: true,
          message: msg
        })
      }

      this.editMode = false
    }
  }
}
</script>

<style></style>
