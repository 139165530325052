<template>
  <div class="page-header">
    <v-toolbar v-if="season" class="mb-0 b-radius" extended flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-row justify="start" align="start">
              <div sm6>
                <h2 class="mb-0 primary--text">
                  {{ season.longName }} ({{ season.shortName }})
                </h2>
                <div>
                  <v-chip
                    v-if="
                      season.from && season.till
                        ? isCurrentSeason(season.from, season.till)
                        : false
                    "
                    x-small
                    color="success lighten-3 font-weight-medium upperCaseSpacing"
                    text-color="success"
                    >active</v-chip
                  >
                </div>
              </div>
              <v-spacer></v-spacer>
              <div sm6></div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:extension>
        <v-tabs
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            :to="{
              name: 'ProductionSeasonDetail',
              params: { seasonID: $route.params.seasonID }
            }"
            class="text-capitalize"
            exact
            >Details</v-tab
          >
          <v-tab
            :to="{
              name: 'ProductionSeasonAssets',
              params: { seasonID: $route.params.seasonID }
            }"
            class="text-capitalize"
          >
              Collection Overview
          </v-tab>

          <v-tab
            :to="{
              name: 'ProductionSeasonProducts',
              params: { seasonID: $route.params.seasonID }
            }"
            class="text-capitalize"
            exact
            >Products</v-tab
          >

          <!-- <v-tab
            :to="{
              name: 'ProductionSeasonPlanning',
              params: { seasonID: $route.params.seasonID }
            }"
            class="text-capitalize"
            exact
            >Planning</v-tab
          > -->


          <v-tab
            :to="{
              name: 'ProductionSeasonSettings',
              params: { seasonID: $route.params.seasonID }
            }"
            class="text-capitalize"
            exact
            >Settings</v-tab
          >
        </v-tabs>

        <slot name="controls"></slot>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'SeasonDetailHeader',
  props: { season: { type: Object, default: () => {} } },
  data: () => ({}),
  computed: {},
  methods: {
    isCurrentSeason(from, till) {
      var today = this.$moment()
      var start = this.$moment(from)
      var end = this.$moment(till)

      return this.$moment(today).isBetween(start, end)
    }
  }
}
</script>

<style></style>
