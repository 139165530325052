<template>
  <v-expansion-panel v-if="editedItem" class="mb-1">
    <v-expansion-panel-header>
      <template v-slot:default="{}">
        <v-row no-gutters>
          <v-col cols="6">
            <div class="text-truncate body-2">
              <v-badge
                v-if="orderLine.quantity === 0"
                color="pink lighten-2"
                dot
                inline
                ><span
                  ><span>{{ editedItem.product.name }}</span>
                  <span
                    v-if="editedItem.product.seasonColor"
                    class="text-lowercase grey--text darken-1"
                  >
                    - {{ editedItem.product.seasonColor }}</span
                  ></span
                ></v-badge
              >
              <span v-else
                ><span>{{ editedItem.product.name }}</span>
                <span
                  v-if="editedItem.product.seasonColor"
                  class="text-lowercase grey--text darken-1"
                >
                  - {{ editedItem.product.seasonColor }}</span
                ></span
              >

              <span class="ml-2">
                <v-chip
                  v-if="
                    orderLine &&
                      orderLine.seasonProduct &&
                      orderLine.seasonProduct.productState === 'OPEN'
                  "
                  pill
                  x-small
                  color="grey lighten-2 font-weight-medium"
                  text-color="grey darken-1"
                  class="upperCaseSpacing"
                >
                  Draft
                </v-chip>
              </span>
            </div>
            <!-- <div v-if="orderLine.quantity === 0">
              <v-badge color="pink lighten-2" dot></v-badge>
            </div> -->
          </v-col>
          <v-col>
            <span class="grey--text body-2"
              ><span class="hidden-md-and-up">Min:</span
              ><span class="hidden-sm-and-down">Minimum Qty:</span></span
            >
            <span class="pl-5"
              ><span>{{ minQty }}</span></span
            >
          </v-col>
          <v-col>
            <span class="grey--text body-2"
              ><span class="hidden-md-and-up">Qty:</span
              ><span class="hidden-sm-and-down">Production Qty:</span></span
            >

            <span
              class="pl-5"
              :class="
                itemCount < minQty && itemCount > 0
                  ? 'error--text text--lighten-2'
                  : ''
              "
              >{{ itemCount }}</span
            >
          </v-col>
          <v-col>
            <!-- <span class="grey--text body-2">Cost:</span> -->

            <div class="text-right pr-3 body-2">
              {{
                (orderLine.amount / 100)
                  | currency(manufacturingOrder.currency.symbol, 2, {
                    spaceBetweenAmountAndSymbol: true
                  })
              }}
            </div>
          </v-col>

          <!-- <v-col class="hidden-xs-only">
            <v-row justify="end">
              <v-chip
                v-if="!open && editedItem.done"
                x-small
                color="info lighten-2"
                text-color="info darken-2"
                class="mr-5"
                >done</v-chip
              >
            </v-row>
          </v-col> -->
        </v-row>
      </template>
      <template v-slot:actions>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <!-- <v-col v-if="edit && editedItem.done" cols="12" class="pb-0">
          <v-alert type="info" outlined color="info lighten-2">
            This line is marked as done to prevent further changes.
          </v-alert>
        </v-col> -->
        <v-col v-if="errorMsg" cols="12" class="pb-0">
          <v-alert type="error" color="error lighten-1" class="mb-0">
            {{ errorMsg }}
          </v-alert>
        </v-col>
        <!-- <v-col cols="12" class="pt-0 pb-6">
          <v-row no-gutters>
            <v-checkbox
              v-model="editedItem.done"
              color="info"
              label="Done"
              hint="Mark this line as final to prevent further changes"
              persistent-hint
              :disabled="!edit"
            ></v-checkbox>
          </v-row>
        </v-col> -->
        <v-col
          v-for="item in sizeFilter(editedItem.items)"
          :key="item.id"
          cols="12"
          sm="3"
          md="2"
          lg="2"
          xl="2"
          xs="12"
        >
          <v-text-field
            v-model="item.quantity"
            dense
            placeholder="Qty"
            type="number"
            min="0"
            outlined
            hide-details
            :disabled="
              !edit ||
                (manufacturingOrder.status !== 'DRAFT' &&
                  manufacturingOrder.status !== 'PENDINGCHANGES')
            "
          >
            <template v-slot:label>
              <span class="font-weight-bold">{{ item.productSize.name }}</span>
            </template>
          </v-text-field>
          <v-row v-if="false" no-gutters>
            <v-col
              cols="12"
              class="pa-2 text-truncate"
              style="
                border: 1px solid var(--v-light-theme-darken1);
                border-radius: 4px;
              "
            >
              <span>{{ item.productSize.name }} |</span>
              <strong> {{ item.quantity }}</strong>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-currency-field
                v-model="unitCost"
                :prefix="manufacturingOrder.currency.symbol"
                :decimal-length="2"
                :default-value="0"
                autocomplete="off"
                label="Unit Cost"
                hide-details="auto"
                outlined
                dense
                :hint="currentUnitCost"
                persistent-hint
                :disabled="
                  (manufacturingOrder.status !== 'DRAFT' &&
                    manufacturingOrder.status !== 'PENDINGCHANGES') ||
                    !edit
                "
              ></v-currency-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col cols="12" class="pb-0">
          <span class="grey--text caption">Delivery Dates</span>
        </v-col>
        <v-col cols="12" class="pt-1">
          <!-- <span v-if="orderLine.exFactoryDate"
            ><small class="grey--text">Earliest:</small>
            {{
              orderLine.exFactoryDate | moment('DD MMM YYYY')
            }}</span
          >
          <span v-if="orderLine.arrivalDate" class="ml-5">
            <small class="grey--text">Latest:</small>
            {{
              orderLine.arrivalDate | moment('DD MMM YYYY')
            }}</span
          > -->
          <v-row>
            <v-col cols="12" sm="auto" class="pt-0">
              <v-row>
                <v-col cols="12">
                  <v-menu
                    ref="menu1"
                    v-model="startDate"
                    autocomplete="off"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedStartDate"
                        outlined
                        prepend-inner-icon="mdi-calendar-month-outline"
                        dense
                        clearable
                        label="ex-factory"
                        placeholder="Ex-Factory Date"
                        hide-details="auto"
                        readonly
                        :disabled="!edit"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="editedItem.exFactoryDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.exFactoryDate"
                      no-title
                      hide-details
                      first-day-of-week="1"
                      @input="startDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="auto" class="pt-0">
              <v-row>
                <v-col cols="12">
                  <v-menu
                    ref="menu2"
                    v-model="endDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedEndDate"
                        autocomplete="off"
                        outlined
                        label="arrival"
                        placeholder="Arrival Date"
                        prepend-inner-icon="mdi-calendar-month-outline"
                        dense
                        clearable
                        hide-details="auto"
                        readonly
                        :disabled="!edit"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="editedItem.arrivalDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.arrivalDate"
                      :min="
                        $moment(editedItem.exFactoryDate)
                          .add(1, 'days')
                          .format('YYYY-MM-DD')
                      "
                      no-title
                      hide-details
                      first-day-of-week="1"
                      @input="endDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="manufacturingOrder && authLevel && authLevel === 2"
          cols="12"
        >
          <v-row justify="end" class="pl-3">
            <v-btn
              v-if="!edit"
              small
              outlined
              rounded
              color="info"
              class="mr-2"
              @click.stop="edit = true"
              ><v-icon small left>mdi-pencil-outline</v-icon>edit</v-btn
            >
            <v-btn
              v-if="edit"
              small
              rounded
              color="primary lighten-2"
              :loading="processing"
              @click="save"
              >save</v-btn
            >
            <v-btn v-if="edit" small text color="grey" @click="cancel"
              >cancel</v-btn
            >
            <v-btn
              v-if="
                !edit &&
                  (manufacturingOrder.status === 'DRAFT' ||
                    manufacturingOrder.status === 'PENDINGCHANGES')
              "
              small
              text
              color="grey"
              @click="remove(editedItem.id)"
              >delete</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue2Filters from 'vue2-filters'

export default {
  name: 'ManufacturingOrderLine',
  mixins: [Vue2Filters.mixin],
  props: {
    manufacturingOrder: { type: Object, default: () => {}, required: true },
    orderLine: { type: Object, default: () => {}, required: true },
    processing: { type: Boolean, default: false },
    authLevel: { type: Number, default: 0 },
    errorMsg: { type: String, default: '' },
    activeItem: { type: String, default: null }
  },
  data() {
    return {
      togglePanel: false,
      edit: false,
      valid: true,
      requiredRule: [
        v => !!v || 'Field is required',
        v => !/^\s+$/.test(v) || 'Field is required'
      ],
      requiredSelectRule: [v => !!v || 'Field is required'],
      requiredDateRule: [v => !!v || 'Date is required'],
      startDate: false,
      endDate: false,
      editedItem: null,
      masterItem: null,
      unitCost: 0
    }
  },
  computed: {
    currentUnitCost() {
      var cost =
        this.orderLine.manufacturer &&
        this.orderLine.manufacturer.costs &&
        this._.isArray(this.orderLine.manufacturer.costs) &&
        this.orderLine.manufacturer.costs.length > 0 &&
        this.orderLine.manufacturer.costs[0].fobCost
          ? (this.orderLine.manufacturer.costs[0].fobCost / 100).toFixed(2)
          : '0.00'
      var formatted = `Current: ${this.manufacturingOrder.currency.symbol}
        ${cost}`

      return formatted
    },
    unFormattedStartDate() {
      return this.editedItem.exFactoryDate
    },
    formattedStartDate() {
      return this.editedItem && this.editedItem.exFactoryDate
        ? this.$moment(this.editedItem.exFactoryDate).format('LL')
        : ''
    },
    formattedEndDate() {
      return this.editedItem && this.editedItem.arrivalDate
        ? this.$moment(this.editedItem.arrivalDate).format('LL')
        : ''
    },
    itemCount() {
      return this._.sumBy(
        this.editedItem.items,
        item => parseInt(item.quantity) || 0
      )
    },
    minQty() {
      return this.editedItem.manufacturer &&
        this.editedItem.manufacturer.minimumQuantity
        ? this.editedItem.manufacturer.minimumQuantity
        : 0
    }
  },
  watch: {
    formattedStartDate(date) {
      let dateObj = this.$moment(date, 'LL')
      if (dateObj.isAfter(this.$moment(this.formattedEndDate, 'LL'))) {
        // this.formattedEndDate = date
        this.editedItem.arrivalDate = this.$moment(date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
    },
    orderLine() {
      this.editedItem = this._.cloneDeep(this.orderLine)
    },
    processing(val) {
      if (!val && !this.errorMsg) this.edit = false
    },
    activeItem(val) {
      if (val !== this.orderLine.id) this.cancel()
    }
  },
  created() {
    this.editedItem = this._.cloneDeep(this.orderLine)
    this.editedItem.unitCost
      ? (this.unitCost = this.editedItem.unitCost / 100)
      : (this.unitCost = 0)
  },
  methods: {
    sizeFilter(items) {
      return this._.sortBy(items, function (obj) {
        return parseInt(obj?.productSize?.promeseCode);
      })
    },
    cancel() {
      this.editedItem = this._.cloneDeep(this.orderLine)
      this.edit = false
    },
    updateUnitCost() {
      this.$emit('update-unit-cost', this.editedItem.id)
    },
    save() {
      var lineData = []

      // Save all sizes
      this.editedItem.items.forEach(element => {
        lineData.push({
          id: element.id,
          quantity: element.quantity,
          reservation: element.reservation
        })
      })

      // Save order line
      var orderLineData = {
        id: this.editedItem.id,
        unitCost: this.unitCost ? Math.round(this.unitCost * 100) : 0,
        exFactoryDate: this.$moment(this.editedItem.exFactoryDate).utc(),
        arrivalDate: this.$moment(this.editedItem.arrivalDate).utc(),
        items: lineData,
        done: this.editedItem.done
      }

      // Call the update method
      this.$emit('save-order-line', orderLineData)
    },
    remove(id) {
      // Call the remove method
      if (id) this.$emit('remove-order-line', id)
    }
  }
}
</script>

<style></style>
