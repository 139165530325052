<template>
  <v-row justify="center">
    <shipper-edit-dialog
      :dialog="dialog"
      :shipper="editedItem"
      :payment-terms="paymentTerms"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-shipper-edit-dialog="close"
      @save-shipper-edit-dialog="save"
    ></shipper-edit-dialog>
    <shipper-address-edit-dialog
      :dialog="addressDialog"
      :data="editedItem.addresses[0]"
      :payment-terms="paymentTerms"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-shipper-address-edit-dialog="close"
      @save-shipper-address-edit-dialog="saveAddress"
    ></shipper-address-edit-dialog>
    <v-col v-if="shipper" cols="12" class="py-0">
      <v-row>
        <v-col cols="12" lg="6">
          <v-card flat class="mb-3">
            <v-card-title class="mb-3"
              ><span>Details</span><v-spacer></v-spacer>
              <v-btn
                v-if="
                  !editMode &&
                    ((shipper && authLevel && authLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                rounded
                outlined
                small
                color="grey"
                :disabled="processing"
                @click="editShipper"
              >
                edit
              </v-btn></v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Name</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.name }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Website</label>
                    </v-col>
                    <v-col cols="12">
                      <a
                        v-if="shipper.website"
                        :href="shipper.website"
                        target="_blank"
                        >{{ shipper.website }}</a
                      >
                      <span
                        v-else
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.website }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Company Registration</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.companyRegistration }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >VAT Registration</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.vatRegistration }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Payment Terms</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        v-if="shipper.paymentTerm"
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.paymentTerm.name }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Shipping Terms</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.shippingTerm }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Last Update</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.updatedAt | moment('from', 'now') }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6">
          <v-card flat class="mb-3">
            <v-card-title class="mb-3"
              ><span>Contact Details</span><v-spacer></v-spacer>
              <v-btn
                v-if="
                  !editMode &&
                    ((shipper && authLevel && authLevel === 2) ||
                      (currentUser &&
                        (currentUser.isSuperAdmin ||
                          currentUser.userRole === 'director' ||
                          currentUser.userRole === 'seniormanager' ||
                          currentUser.userRole === 'projectmanager')))
                "
                rounded
                outlined
                small
                color="grey"
                :disabled="processing"
                @click="editAddress"
              >
                edit
              </v-btn></v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Email</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.addresses[0].email }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Phone</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.addresses[0].phone }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Line 1</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.addresses[0].line1 }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Line 2</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.addresses[0].line2 }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Line 3</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.addresses[0].line3 }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Postcode</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.addresses[0].postCode }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">City</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ shipper.addresses[0].city }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Country</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        v-if="
                          shipper.addresses[0] && shipper.addresses[0].country
                        "
                        class="font-weight-medium grey--text text--darken-2"
                        >{{
                          getFullCountryName(shipper.addresses[0].country)
                        }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getName } from 'country-list'
import ShipperEditDialog from '@/modules/production/components/core/ShipperEditDialog.vue'
import ShipperAddressEditDialog from '@/modules/production/components/core/ShipperAddressEditDialog.vue'
import SHIPPER_UPDATE from '@/graphql/ShipperUpdate.gql'
import SHIPPER_ADDRESS_UPDATE from '@/graphql/ShipperAddressUpdate.gql'

export default {
  name: 'ShipperDetail',
  components: { ShipperEditDialog, ShipperAddressEditDialog },
  props: {
    shipper: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    paymentTerms: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    dialog: false,
    addressDialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    defaultItem: {
      id: null,
      name: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null,
        country: null
      },
      addresses: [],
      companyRegistration: null,
      vatRegistration: null,
      paymentTerm: null,
      shippingTerm: null,
      currency: null
    },
    editedItem: {
      id: null,
      name: null,
      website: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null,
        country: null
      },
      addresses: [],
      companyRegistration: null,
      vatRegistration: null,
      paymentTerm: null,
      shippingTerm: null,
      currency: null
    }
  }),
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    editAddress() {
      this.editedItem = this._.cloneDeep(this.shipper)
      this.editMode = true
      this.addressDialog = true
    },
    editShipper() {
      this.editedItem = this._.cloneDeep(this.shipper)
      this.editMode = true
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.addressDialog = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      delete item.address
      delete item.email
      delete item.phone

      this.$apollo
        .mutate({
          mutation: SHIPPER_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Shipper Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    saveAddress(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SHIPPER_ADDRESS_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Shipper Address Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
