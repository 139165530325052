<template>
  <v-dialog
    v-model="dialog"
    width="350"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="materialForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="item.material"
                      :items="materialList"
                      :search-input.sync="search"
                      autocomplete="off"
                      label="Material"
                      outlined
                      clearable
                      :rules="requiredSelectRule"
                      hide-details="auto"
                      return-object
                      item-value="id"
                      item-text="name"
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.compound"
                      autocomplete="off"
                      label="Compound"
                      hint="Amount in percentage. Eg 100%"
                      persistent-hint
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.quantity"
                      autocomplete="off"
                      label="Quantity"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                      v-model="item.material.unit"
                      autocomplete="off"
                      label="Unit"
                      outlined
                      hide-details="auto"
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text rounded :disabled="processing" @click="close">Cancel</v-btn>
        <v-btn
          color="primary lighten-2"
          rounded
          small
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ProductMaterialEditDialog',
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({})
    },
    material: {
      type: Object,
      required: true,
      default: () => ({})
    },
    materialList: {
      type: Array,
      required: true,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valid: true,
      requiredRule: [
        v => !!v || 'Field is required',
        v => !/^\s+$/.test(v) || 'Field is required'
      ],
      requiredSelectRule: [v => (v ? !!v.id : !!v) || 'Field is required'],
      units: ['Item', 'Meter', 'Feet', 'Kilograms', 'Pounds'],
      search: null
    }
  },
  computed: {
    title() {
      return this.edit ? 'Edit Material' : 'New Material'
    },
    item() {
      // this.search = this.material.material.name
      if (this.$refs.materialForm) this.$refs.materialForm.resetValidation()
      this.setInitalSearch(this.material.material.name)
      return this._.cloneDeep(this.material)
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.$emit('search-materials', val)
      }
    }
  },
  methods: {
    setInitalSearch(val) {
      this.search = val
    },
    close() {
      this.$emit('close-product-material-edit-dialog', false)

      // this.$nextTick(() => {
      // this.$refs.materialForm.reset()
      // })
    },
    save() {
      var data = {
        id: this.item.id,
        materialID: this.item.material.id,
        productID: this.item.productID,
        quantity: this.item.quantity ? this.item.quantity : 0,
        compound: this.item.compound
      }

      var that = this

      if (this.$refs.materialForm.validate()) {
        this.$emit('save-product-material-edit-dialog', data)
        this.$nextTick(() => {
          if (that.errorMsg === '') {
            // that.$refs.materialForm.reset()
          } else {
            that.$refs.materialForm.resetValidation()
          }
        })
      }
    }
  }
}
</script>

<style></style>
