var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('stock-item-edit-dialog',{attrs:{"dialog":_vm.dialog,"stock-item":_vm.editedItem,"edit-mode":_vm.editMode,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"close-stock-item-edit-dialog":_vm.close,"save-stock-item-edit-dialog":_vm.quickAdjust}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.warehouses.items,"label":"Warehouse","placeholder":"Global","item-value":"id","item-text":"name","hide-details":"","flat":"","outlined":"","dense":"","clearable":"","background-color":"#fff"},model:{value:(_vm.selectedWarehouse),callback:function ($$v) {_vm.selectedWarehouse=$$v},expression:"selectedWarehouse"}})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.$apollo.loading,"headers":_vm.computedHeaders,"items":_vm.stockList,"options":_vm.options,"server-items-length":_vm.itemCount,"mobile-breakpoint":null,"fixed-header":"","hide-default-header":!_vm.$apollo.loading &&
            !_vm.search &&
            (!_vm.stockItems ||
              !_vm.stockItems.items ||
              _vm.stockItems.items.length === 0),"hide-default-footer":!_vm.stockItems || _vm.itemCount < 16},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
              var item = ref.item;
return [(item.updatedAt)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("moment")(item.updatedAt,'from', 'now')))]):_vm._e()]}},{key:"item.style",fn:function(ref){
              var item = ref.item;
return [(item.style)?_c('span',{staticClass:"grey--text text--darken-3 font-weight-medium text-no-wrap"},[_vm._v(_vm._s(item.style.name))]):_vm._e()]}},{key:"item.size",fn:function(ref){
              var item = ref.item;
return [(item.size)?_c('v-chip',{attrs:{"pill":"","x-small":"","color":"secondary lighten-1 font-weight-bold","text-color":"secondary darken-2"}},[_vm._v(_vm._s(item.size.name))]):_vm._e()]}},{key:"item.warehouse",fn:function(ref){
              var item = ref.item;
return [(item.warehouse)?_c('v-chip',{attrs:{"pill":"","x-small":"","color":"info lighten-3 font-weight-bold","text-color":"info darken-1","light":""}},[_vm._v(_vm._s(item.warehouse.name))]):_c('v-chip',{attrs:{"pill":"","x-small":"","color":"info lighten-3 font-weight-bold","text-color":"info darken-2","light":""}},[_vm._v("All")])]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No StockItems","caption":_vm.search
                ? 'Try adjusting your search to get some results'
                : 'Create products to generate stock'}})]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }