<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="productForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      autocomplete="off"
                      outlined
                      hide-details="auto"
                      :rules="requiredRule"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="item.styleNumber"
                      label="Style Number"
                      autocomplete="off"
                      outlined
                      hide-details="auto"
                      hint="Leave empty to auto generate number"
                      persistent-hint
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="item.seasonColor"
                      label="Season Color"
                      autocomplete="off"
                      outlined
                      hide-details="auto"
                      hint="This is the name for current season color"
                      persistent-hint
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!edit">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="season"
                      :items="seasons"
                      outlined
                      dense
                      autocomplete
                      label="Season"
                      color="info"
                      item-value="id"
                      item-text="longName"
                      return-object
                      background-color="white"
                      hide-details="auto"
                      :rules="requiredSelectRule"
                      :disabled="seasonAdd"
                    >
                      <template v-slot:item="{ item }">
                        {{ item.longName }} ({{ item.shortName }})
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.longName }} ({{ item.shortName }})
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.color"
                      :items="colors"
                      :rules="requiredSelectRule"
                      outlined
                      flat
                      dense
                      clearable
                      item-value="id"
                      item-text="name"
                      label="Warehouse Color"
                      hint="For warehouse purposes only"
                      persistent-hint
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      :disabled="!product || !colors || colors.length === 0"
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }} ({{ item.colorCode }})
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }} ({{ item.colorCode }})
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="item.gender"
                      :items="genders"
                      outlined
                      dense
                      autocomplete
                      label="Gender"
                      hide-details="auto"
                      :rules="requiredSelectRule"
                    >
                      <template v-slot:item="{ item }">
                        {{ item.toLowerCase() }}
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.toLowerCase() }}
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.category"
                      :items="categories"
                      outlined
                      flat
                      dense
                      clearable
                      label="Category"
                      item-value="id"
                      item-text="name"
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      :rules="requiredSelectRule"
                      :disabled="
                        !product || !categories || categories.length === 0
                      "
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }}
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col v-if="!edit" cols="12" md="6">
                    <v-autocomplete
                      v-model="item.articleGroup"
                      :items="articleGroups"
                      outlined
                      flat
                      dense
                      clearable
                      label="Article Group"
                      item-value="id"
                      item-text="name"
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      single-line
                      :disabled="
                        !product || !articleGroups || articleGroups.length === 0
                      "
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }}
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col v-if="!edit" cols="12" md="6">
                    <v-autocomplete
                      v-model="item.subArticleGroup"
                      :items="subArticleGroups"
                      outlined
                      flat
                      dense
                      clearable
                      label="Sub Article Group"
                      item-value="id"
                      item-text="name"
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      :disabled="
                        !product || !subArticleGroups || subArticleGroups.length === 0
                      "
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }}
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col v-if="!edit" cols="12" md="6">
                    <v-autocomplete
                      v-model="item.styleType"
                      :items="styleTypes"
                      outlined
                      flat
                      dense
                      clearable
                      label="Style Type"
                      item-value="id"
                      item-text="name"
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      :rules="requiredSelectRule"
                      single-line
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }}
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row v-if="edit">
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.color"
                      :items="colors"
                      outlined
                      flat
                      dense
                      clearable
                      label="Warehouse Color"
                      hint="For warehouse purposes only"
                      persistent-hint
                      item-value="id"
                      item-text="name"
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      :disabled="!product || !colors || colors.length === 0"
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }} ({{ item.colorCode }})
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }} ({{ item.colorCode }})
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.styleType"
                      :items="styleTypes"
                      outlined
                      flat
                      dense
                      clearable
                      label="Style Type"
                      item-value="id"
                      item-text="name"
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      single-line
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }}
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.articleGroup"
                      :items="articleGroups"
                      outlined
                      flat
                      dense
                      clearable
                      label="Article Group"
                      item-value="id"
                      item-text="name"
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      :disabled="
                        !product || !articleGroups || articleGroups.length === 0
                      "
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }}
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.subArticleGroup"
                      :items="subArticleGroups"
                      outlined
                      flat
                      dense
                      clearable
                      label="Sub Article Group"
                      item-value="id"
                      item-text="name"
                      autocomplete="off"
                      return-object
                      hide-details="auto"
                      :disabled="
                        !product || !subArticleGroups || subArticleGroups.length === 0
                      "
                    >
                      <template v-slot:selection="{ item }"
                        ><div class="text-truncate">
                          {{ item.name }}
                        </div></template
                      >
                      <template v-slot:item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      label="Description"
                      outlined
                      dense
                      hide-details="auto"
                      rows="3"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn color="grey" text :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          dark
          :loading="processing"
          @click="save(false)"
          >Save</v-btn
        >
        <v-btn
          v-if="!edit"
          color="grey"
          rounded
          outlined
          dark
          :loading="processing"
          @click="save(true)"
          >Save &amp; New</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ProductEditDialog',
  props: {
    seasons: {
      type: Array,
      required: true,
      default: () => [],
    },
    seasonAdd: {
      type: Boolean,
      default: false,
    },
    selectedSeason: {
      type: Object,
      default: () => ({}),
    },
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
    articleGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    subArticleGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    styleTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    product: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    colors: {
      type: Array,
      required: true,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    valid: true,
    requiredRule: [
      (v) => !!v || 'Field is required',
      (v) => !/^\s+$/.test(v) || 'Field is required',
    ],
    requiredSelectRule: [(v) => !!v || 'Field is required'],
    season: null,
    genders: ['MALE', 'FEMALE', 'UNISEX', 'KIDS', 'BABY'],
  }),
  computed: {
    title() {
      return this.edit ? 'Edit Product' : 'New Product'
    },
    item() {
      return this._.cloneDeep(this.product)
    },
  },
  watch: {
    dialog() {
      this.setSeason()
    },
  },
  methods: {
    setSeason() {
      // if (this.seasonAdd) {
      this.season = this.selectedSeason
      // }
    },
    close() {
      this.$emit('close-product-edit-dialog', false)

      this.$nextTick(() => {
        this.$refs.productForm.reset()
      })
    },
    save(newItem) {
      if (this.$refs.productForm.validate()) {
        var data = {
          id: this.item.id,
          styleNumber: this.item.styleNumber,
          seasonColor: this.item.seasonColor,
          name: this.item.name,
          description: this.item.description,
          gender: this.item.gender,
          colorID: this.item.color ? this.item.color.id : null,
          categoryID: this.item.category ? this.item.category.id : null,
          articleGroupID: this.item.articleGroup
            ? this.item.articleGroup.id
            : null,
          subArticleGroupID: this.item.subArticleGroup
            ? this.item.subArticleGroup.id
            : null,
          styleTypeID: this.item.styleType ? this.item.styleType.id : null,
        }

        if (this.edit) {
          data.seasonID = this.item.season.id
        } else {
          data.seasonID = this.season.id
        }

        var that = this

        this.$emit(
          'save-product-edit-dialog',
          data,
          newItem,
          function (callback) {
            if (callback) {
              if (that.errorMsg === '') {
                that.setSeason()
                that.$refs.productForm.resetValidation()
              } else {
                that.$refs.productForm.resetValidation()
              }
            } else {
              that.$refs.productForm.reset()
            }
          }
        )
      }
    },
  },
}
</script>

<style></style>
