<template>
  <div class="page-wrap">
    <manufacturer-detail-header
      :manufacturer="manufacturer"
    ></manufacturer-detail-header>

    <div class="main-page-column py-0">
      <div class="scroll-container pt-0">
        <router-view
          :manufacturer="manufacturer"
          :current-user="currentUser"
          :payment-terms="paymentTerms"
          :currencies="currencies"
          :auth-level="authLevel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ManufacturerDetailHeader from '@/modules/production/components/core/ManufacturerDetailHeader.vue'
import MANUFACTURER from '@/graphql/Manufacturer.gql'
import PAYMENT_TERM_LIST from '@/graphql/PaymentTerms.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'ManufacturerDetailLayout',
  components: {
    ManufacturerDetailHeader
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('manufacturers')
    },
    currentRoute() {
      return this.$route
    }
  },
  apollo: {
    manufacturer: {
      query: MANUFACTURER,
      variables() {
        return {
          id: this.$route.params.manufacturerID
        }
      }
    },
    paymentTerms: PAYMENT_TERM_LIST,
    currencies: CURRENCY_LIST
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 112px;
}
</style>
