<template>
  <div>
    <div v-if="!$apollo.loading">
      <v-row v-if="productManufacturers && productManufacturers.length > 0">
        <v-col v-if="product" cols="12" class="pt-0 pb-0 mb-0">
          <v-card flat class="mb-3 pb-3 mt-0" color="light-theme">
            <v-row>
              <v-col cols="12" md="4" lg="4" xl="3" class="mb-0 pb-0">
                <v-select
                  v-model="selectedManufacturer"
                  background-color="white"
                  autocomplete="off"
                  :items="productManufacturers"
                  label="Manufacturer"
                  hide-details="auto"
                  item-value="id"
                  return-object
                  color="info"
                  item-color="info"
                  class="highlight-control info--text"
                  outlined
                  dense
                >
                  <template v-slot:selection="data">
                    <div class="text-truncate" style="width: 75%;">
                      <span>{{ data.item.manufacturer.name }}</span>
                      <span v-if="data.item.address" class="ml-2"
                        >(<span v-if="data.item.address.name">{{
                          data.item.address.name
                        }}</span>
                        -
                        <span v-if="data.item.address.country">{{
                          getCountryName(data.item.address.country)
                        }}</span
                        >)</span
                      >
                    </div>
                    <v-spacer></v-spacer>
                    <v-chip
                      v-if="data.item.defaultManufacturer"
                      color="info lighten-2 font-weight-bold"
                      text-color="info darken-2"
                      pill
                      x-small
                      >default</v-chip
                    >
                  </template>
                  <template v-slot:item="data">
                    <div class="text-truncate" style="width: 75%;">
                      <span>{{ data.item.manufacturer.name }}</span>
                      <span v-if="data.item.address" class="ml-2"
                        >(<span v-if="data.item.address.name">{{
                          data.item.address.name
                        }}</span>
                        -
                        <span v-if="data.item.address.country">{{
                          getCountryName(data.item.address.country)
                        }}</span
                        >)</span
                      >
                    </div>
                    <v-spacer></v-spacer>
                    <v-chip
                      v-if="data.item.defaultManufacturer"
                      color="info lighten-2 font-weight-bold"
                      text-color="info darken-2"
                      pill
                      x-small
                      >default</v-chip
                    >
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-if="
          !$apollo.loading &&
            productManufacturers &&
            productManufacturers.length > 0
        "
      >
        <v-col
          v-if="
            (selectedManufacturer &&
              selectedManufacturer.costs &&
              selectedManufacturer.costs.length > 0 &&
              editedItem &&
              editedItem.id) ||
              (editedItem && !editedItem.id && editMode)
          "
          cols="12"
          md="8"
          lg="7"
          xl="8"
          class="pt-0"
        >
          <v-row>
            <v-col>
              <v-card
                v-if="
                  selectedManufacturer &&
                    selectedManufacturer.manufacturer &&
                    editedItem &&
                    editedItem.currency &&
                    editedItem.currency.id
                "
                flat
              >
                <v-card-title>
                  <span>{{ selectedManufacturer.manufacturer.name }}</span>
                  <span
                    v-if="selectedManufacturer.address"
                    class="ml-2 subtitle-1 text--secondary"
                    >(<span v-if="selectedManufacturer.address.name">{{
                      selectedManufacturer.address.name
                    }}</span>
                    -
                    <span v-if="selectedManufacturer.address.country">{{
                      getCountryName(selectedManufacturer.address.country)
                    }}</span
                    >)</span
                  >
                  <v-chip
                    v-if="selectedManufacturer.defaultManufacturer"
                    color="info lighten-2 font-weight-bold"
                    text-color="info darken-2"
                    pill
                    x-small
                    class="ml-3"
                    >default</v-chip
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        costingAuthLevel &&
                        costingAuthLevel === 2 &&
                        editMode &&
                        ((editedItem &&
                          costingAuthLevel &&
                          costingAuthLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    small
                    text
                    :disabled="processing"
                    class="mr-2"
                    @click="cancelEdit(false)"
                  >
                    cancel
                  </v-btn>
                  <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        costingAuthLevel &&
                        costingAuthLevel === 2 &&
                        editMode &&
                        ((editedItem &&
                          costingAuthLevel &&
                          costingAuthLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    color="primary"
                    rounded
                    small
                    :loading="processing"
                    @click="saveProductCost"
                  >
                    save
                  </v-btn>
                  <v-btn
                    v-if="
                      product &&
                        product.productState &&
                        product.productState === 'OPEN' &&
                        costingAuthLevel &&
                        costingAuthLevel === 2 &&
                        !editMode &&
                        ((editedItem &&
                          costingAuthLevel &&
                          costingAuthLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    color="grey"
                    outlined
                    rounded
                    small
                    :disabled="processing"
                    @click="editMode = true"
                  >
                    edit
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <div v-if="errorMsg !== ''" class="mb-3">
                    <v-alert type="error" class="mb-0">
                      {{ errorMsg }}
                    </v-alert>
                  </div>
                  <v-form
                    ref="costForm"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent
                  >
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-currency-field
                          v-model="editedItem.makeCost"
                          :prefix="editedItem.currency.symbol"
                          :decimal-length="2"
                          :default-value="0"
                          autocomplete="off"
                          label="Make Cost"
                          hide-details="auto"
                          outlined
                          dense
                          :rules="requiredRule"
                          :readonly="!editMode"
                        ></v-currency-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-currency-field
                          v-model="editedItem.smsCost"
                          :prefix="editedItem.currency.symbol"
                          :decimal-length="2"
                          :default-value="0"
                          autocomplete="off"
                          label="SMS Cost"
                          hide-details="auto"
                          outlined
                          dense
                          :readonly="!editMode"
                        ></v-currency-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="selectedManufacturer.minimumQuantity"
                          type="number"
                          autocomplete="off"
                          label="Minimum Quantity"
                          hide-details="auto"
                          outlined
                          dense
                          readonly
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="selectedManufacturer.leadTime"
                          autocomplete="off"
                          label="Lead Time"
                          suffix="days"
                          hide-details="auto"
                          outlined
                          dense
                          readonly
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.costNotes"
                          autocomplete="off"
                          label="Production Notes"
                          hide-details="auto"
                          rows="3"
                          outlined
                          dense
                          :readonly="!editMode"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <div
                      class="mt-6 mb-2 caption text--secondary text-uppercase"
                    >
                      Freight Costs
                    </div>
                    <v-divider class="mb-2"></v-divider>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="editedItem.freightMethod"
                          :items="freightMethods"
                          autocomplete="off"
                          label="Freight Method"
                          hide-details="auto"
                          rows="3"
                          outlined
                          dense
                          :readonly="!editMode"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-currency-field
                          v-model="editedItem.freightMethodValue"
                          :decimal-length="2"
                          :default-value="0"
                          :max="
                            editedItem.freightMethod &&
                            editedItem.freightMethod === 'PERCENTAGE'
                              ? 100
                              : null
                          "
                          autocomplete="off"
                          label="Freight Value"
                          hide-details="auto"
                          outlined
                          dense
                          :readonly="!editMode"
                        ></v-currency-field>
                      </v-col>
                    </v-row>
                    <div
                      class="mt-6 mb-2 caption text--secondary text-uppercase"
                    >
                      Import Costs
                    </div>
                    <v-divider class="mb-2"></v-divider>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-currency-field
                          v-model="editedItem.importDutyValue"
                          suffix="%"
                          :decimal-length="2"
                          :default-value="0"
                          :max="100"
                          autocomplete="off"
                          label="Import Duty"
                          hide-details="auto"
                          outlined
                          dense
                          :readonly="!editMode"
                        ></v-currency-field>
                      </v-col>
                    </v-row>
                    <div
                      class="mt-6 mb-2 caption text--secondary text-uppercase"
                    >
                      Miscellaneous Costs
                    </div>
                    <v-divider class="mb-2"></v-divider>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="editedItem.miscCostMethod"
                          :items="costMethods"
                          autocomplete="off"
                          label="Misc Method"
                          hide-details="auto"
                          outlined
                          dense
                          :readonly="!editMode"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-currency-field
                          v-model="editedItem.miscCostMethodValue"
                          locale="us-EN"
                          :decimal-length="2"
                          :default-value="0"
                          :max="
                            editedItem.freightMethod === 'PERCENTAGE'
                              ? 100
                              : null
                          "
                          autocomplete="off"
                          label="Misc Value"
                          hide-details="auto"
                          outlined
                          dense
                          :readonly="!editMode"
                        ></v-currency-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="
            (selectedManufacturer &&
              selectedManufacturer.costs &&
              selectedManufacturer.costs.length > 0 &&
              editedItem &&
              editedItem.id) ||
              (editedItem && !editedItem.id && editMode)
          "
          cols="12"
          md="4"
          lg="5"
          xl="4"
        >
          <v-card flat>
            <v-card-title class="body-2 text-uppercase grey--text">
              Cost Calculation
            </v-card-title>
            <v-card-text
              v-if="editedItem && editedItem.currency"
              class="body-2"
            >
              <v-row justify="center">
                <v-col cols="12" md="12" lg="10" xl="10">
                  <v-row>
                    <v-col class="pb-0">
                      <span class="text-uppercase">
                        Make Cost
                      </span>
                    </v-col>
                    <v-col class="text-right pb-0">
                      {{
                        editedItem.makeCost
                          | currency(editedItem.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </v-col>
                  </v-row>

                  <!-- <v-row>
                    <v-col>
                      <span class="text-uppercase">
                        Material Cost
                      </span>
                    </v-col>
                    <v-col class="text-right">
                      {{
                        editedItem.materialCost
                          | currency(editedItem.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </v-col>
                  </v-row> -->
                  <v-divider></v-divider>
                  <v-row class="font-weight-bold mb-6">
                    <v-col class="pb-0">
                      <span class="text-uppercase">
                        FOB Cost
                      </span>
                    </v-col>
                    <v-col class="text-right pb-0">
                      {{
                        fobCost
                          | currency(editedItem.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <span class="text-uppercase">
                        Freight Cost
                      </span>
                    </v-col>
                    <v-col class="text-right pb-0">
                      {{
                        freightCost
                          | currency(editedItem.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <span class="text-uppercase">
                        Import Duty
                      </span>
                    </v-col>
                    <v-col class="text-right pb-0">
                      {{
                        importDutyCost
                          | currency(editedItem.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="text-uppercase">
                        Miscellaneous Cost
                      </span>
                    </v-col>
                    <v-col class="text-right">
                      {{
                        miscCost
                          | currency(editedItem.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="font-weight-bold">
                    <v-col class="pb-0">
                      <span class="text-uppercase">
                        Total Unit Cost
                      </span>
                    </v-col>
                    <v-col class="text-right pb-0">
                      {{
                        totalCost
                          | currency(editedItem.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-else cols="12">
          <message-box
            icon="mdi-emoticon-neutral-outline"
            title="No Results"
            caption="You have no cost setup for this manufacuturer yet"
          >
            <v-btn
              v-if="
                product &&
                  product.productState &&
                  product.productState === 'OPEN' &&
                  costingAuthLevel &&
                  costingAuthLevel === 2
              "
              rounded
              color="primary"
              @click="addCostItem"
              >Add Costs</v-btn
            >
          </message-box>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col cols="12" md="8">
          <message-box
            icon="mdi-emoticon-neutral-outline"
            title="No Results"
            caption="You will first need to add manufacturers to the product to set cost"
          ></message-box>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <message-box
        height="150px"
        icon="mdi-coffee-outline"
        title="Loading..."
        caption="Sit back while we load your data"
      >
      </message-box>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-skeleton-loader
            v-for="line in 3"
            :key="line"
            type="list-item-two-line"
            class="mx-auto"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PRODUCT_MANUFACTURER_LIST from '@/graphql/ProductManufacturers.gql'
import PRODUCT_COST_CREATE from '@/graphql/ProductCostCreate.gql'
import PRODUCT_COST_UPDATE from '@/graphql/ProductCostUpdate.gql'
import { getName } from 'country-list'
import MessageBox from '@/components/core/MessageBox.vue'

export default {
  name: 'ProductionProductCosts',
  components: { MessageBox },
  props: {
    product: { type: Object, default: () => {} },
    selectedSeason: { type: String, default: '' },
    currentUser: { type: Object, default: () => {} },
    colors: { type: Array, default: () => [] },
    categories: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    sizeGroups: { type: Array, default: () => [] },
    currencyList: { type: Array, default: () => [] }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    processing: false,
    errorMsg: '',
    editMode: false,
    selectedManufacturer: null,
    productManufacturers: [],
    firstCostItem: false,
    freightMethods: ['PERCENTAGE', 'VALUE', 'KG'],
    costMethods: ['PERCENTAGE', 'VALUE'],
    defaultItem: {
      id: '',
      makeCost: 0,
      smsCost: 0,
      materialCost: 0,
      fobCost: 0,
      freightCost: 0,
      importDutyCost: 0,
      miscCost: 0,
      totalCost: 0,
      minimumQuantity: 1,
      costNotes: '',
      freightMethod: 'PERCENTAGE',
      freightMethodValue: 0,
      importDutyValue: 0,
      miscCostMethod: 'PERCENTAGE',
      miscCostMethodValue: 0
    },
    editedItem: {
      id: '',
      makeCost: 0,
      smsCost: 0,
      materialCost: 0,
      fobCost: 0,
      freightCost: 0,
      importDutyCost: 0,
      miscCost: 0,
      totalCost: 0,
      minimumQuantity: 1,
      costNotes: '',
      freightMethod: 'PERCENTAGE',
      freightMethodValue: 0,
      importDutyValue: 0,
      miscCostMethod: 'PERCENTAGE',
      miscCostMethodValue: 0
    }
  }),
  apollo: {
    productManufacturers: {
      query: PRODUCT_MANUFACTURER_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      },
      result({ data }) {
        // set default manufacturer as selected manufacturer
        if (
          data &&
          data.productManufacturers &&
          data.productManufacturers.length > 0 &&
          !this.selectedManufacturer
        ) {
          // Set the default manufacturer as the selected manufacturer
          let defaultManufacturer = this._.find(
            data.productManufacturers,
            function(item) {
              return item.defaultManufacturer
            }
          )

          this.selectedManufacturer = this._.cloneDeep(defaultManufacturer)

          // return this.setSelectedManufacturer(defaultManufacturer)
        }
      }
    }
  },
  computed: {
    costingAuthLevel() {
      return this.$store.getters.authLevel('product_costing')
    },
    fobCost() {
      return this.editedItem.makeCost + this.editedItem.materialCost
    },
    freightCost() {
      let freightCost = 0

      switch (this.editedItem.freightMethod) {
        case 'PERCENTAGE':
          freightCost =
            (this.editedItem.freightMethodValue / 100) * this.fobCost || 0
          break
        case 'VALUE':
          freightCost = this.editedItem.freightMethodValue || 0
          break
        case 'KG':
          freightCost =
            this.editedItem.freightMethodValue * this.product.weight || 0
          break
        default:
          break
      }

      return freightCost
    },
    importDutyCost() {
      return (this.editedItem.importDutyValue / 100) * this.fobCost || 0
    },
    miscCost() {
      let miscCost = 0

      switch (this.editedItem.miscCostMethod) {
        case 'PERCENTAGE':
          miscCost =
            (this.editedItem.miscCostMethodValue / 100) * this.fobCost || 0
          break
        case 'VALUE':
          miscCost = this.editedItem.miscCostMethodValue || 0
          break
        default:
          break
      }

      return miscCost
    },
    totalCost() {
      return (
        this.fobCost + this.freightCost + this.importDutyCost + this.miscCost
      )
    }
  },
  watch: {
    selectedManufacturer() {
      this.removeAllEmptyCosts()
      if (!this.editMode) {
        this.setEditedItem()
      }

      this.editMode = false
    },
    selectedSeason() {
      if (
        this.selectedManufacturer &&
        (this.selectedManufacturer.id || this.selectedManufacturer.id !== '')
      ) {
        this.removeAllEmptyCosts()
        if (!this.editMode) {
          this.setEditedItem()
        }
        this.editMode = false
      }
    }
  },
  created() {
    this.$apollo.queries.productManufacturers.refetch()
  },
  methods: {
    defaultCurrency() {
      let currency
      if (this.currencyList) {
        currency = this._.find(this.currencyList, function(item) {
          return item.defaultCurrency === true
        })
      }
      return this._.cloneDeep(currency)
    },
    manufacturerCurrency(currencyID) {
      let currency
      if (this.currencyList) {
        currency = this._.find(this.currencyList, function(item) {
          return item.id === currencyID
        })
      }
      return this._.cloneDeep(currency)
    },
    setEditedItem() {
      let seasonID = this.selectedSeason

      var currentProductCost

      var defaultCurrency = this.defaultCurrency()

      if (
        this.selectedManufacturer &&
        this.selectedManufacturer.costs &&
        this.selectedManufacturer.costs.length > 0
      ) {
        // If existing item
        currentProductCost = this._.find(
          this.selectedManufacturer.costs,
          function(item) {
            return item.seasonID === seasonID
          }
        )
      }
      if (currentProductCost) {
        this.editedItem = this._.cloneDeep(currentProductCost)
        this.editedItem.currency = this.selectedManufacturer.manufacturer.currency
        this.editedItem.reportingCurrency = defaultCurrency
        this.editedItem.makeCost = this.editedItem.makeCost / 100
        this.editedItem.smsCost = this.editedItem.smsCost / 100
        this.editedItem.materialCost = this.editedItem.materialCost / 100
      } else {
        this.editedItem = this._.cloneDeep(this.defaultItem)
      }

      if (!this.editedItem.currency)
        this.editedItem.currency = this.selectedManufacturer.manufacturer.currency
      if (!this.editedItem.reportingCurrency)
        this.editedItem.reportingCurrency = defaultCurrency

      if(this.editedItem.currency.productionExchangeRate) {
        this.editedItem.exchangeRate = this.editedItem.currency.productionExchangeRate
      } else {
        this.editedItem.exchangeRate = this.manufacturerCurrency(this.editedItem.currency.id).productionExchangeRate
      }

      this.editedItem.productWeight = this.product.weight
      this.editedItem.seasonID = seasonID
    },
    addCostItem() {
      this.firstCostItem = true
      if (!this.selectedManufacturer.costs) {
        this.selectedManufacturer.costs = []
      }

      this.setEditedItem()
      this.selectedManufacturer.costs.push(this.editedItem)
      this.editMode = true
    },
    getCountryName(item) {
      return getName(item)
    },
    removeAllEmptyCosts() {
      var that = this
      if (
        this.selectedManufacturer &&
        this.selectedManufacturer.costs &&
        this.selectedManufacturer.costs.length > 0
      ) {
        let idx = that._.findIndex(this.selectedManufacturer.costs, function(
          item
        ) {
          return !item.id
        })
        if (idx > -1) {
          this.selectedManufacturer.costs.splice(idx, 1)
        }
      }
    },
    cancelEdit() {
      this.processing = false
      this.firstCostItem = false
      this.editMode = false
      this.errorMsg = ''

      if (!this.selectedManufacturer.costs[0].id) {
        this.selectedManufacturer.costs.pop()
      }
    },
    saveProductCost() {
      var that = this
      this.errorMsg = ''

      if (this.$refs.costForm.validate()) {
        var data = {
          id: this.editedItem.id,
          productID: this.$route.params.productID,
          seasonID: this.editedItem.seasonID,
          productManufacturerID: this.selectedManufacturer.id,
          makeCost: Math.round(this.editedItem.makeCost * 100),
          smsCost: Math.round(this.editedItem.smsCost * 100),
          costNotes: this.editedItem.costNotes,
          currencyID: this.editedItem.currency
            ? this.editedItem.currency.id
            : null,
          reportingCurrencyID: this.editedItem.reportingCurrency
            ? this.editedItem.reportingCurrency.id
            : null,
          exchangeRate: this.editedItem.exchangeRate,
          freightMethod: this.editedItem.freightMethod,
          freightMethodValue: this.editedItem.freightMethodValue,
          importDutyValue: this.editedItem.importDutyValue,
          miscCostMethod: this.editedItem.miscCostMethod,
          miscCostMethodValue: this.editedItem.miscCostMethodValue,
          materialCost: Math.round(this.editedItem.materialCost * 100)
        }

        this.processing = true

        if (data.id) {
          // Edit item
          this.$apollo
            .mutate({
              mutation: PRODUCT_COST_UPDATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              // this.setEditedItem()
              this.cancelEdit()

              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Cost Item Updated'
              })
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        } else {
          // Save new item
          delete data.id

          this.$apollo
            .mutate({
              mutation: PRODUCT_COST_CREATE,
              variables: {
                input: data
              },
              update: (store, { data: { createProductCost } }) => {
                // Read the data from our cache for this query.
                const data = store.readQuery({
                  query: PRODUCT_MANUFACTURER_LIST,
                  variables: {
                    productID: createProductCost.productID
                  }
                })

                // Get the index of the selected manufacturer
                let idx = that._.findIndex(data.productManufacturers, function(
                  item
                ) {
                  return item.id == that.selectedManufacturer.id
                })

                if (!data.productManufacturers[idx].costs) {
                  data.productManufacturers[idx].costs = []
                }

                // Add our requirement from the mutation to the end
                data.productManufacturers[idx].costs.push(createProductCost)
                // Write our data back to the cache.
                store.writeQuery({
                  query: PRODUCT_MANUFACTURER_LIST,
                  variables: {
                    productID: createProductCost.productID
                  },
                  data
                })
              }
            })
            .then(result => {
              this.selectedManufacturer.costs.push(
                result.data.createProductCost
              )
              this.removeAllEmptyCosts()
              this.setEditedItem()

              this.cancelEdit()

              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Cost Item Saved'
              })
              this.$emit('change-state')
              // this.$apollo.queries.productManufacturers.refetch()
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
