<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Edit Barcode</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-3">
          <v-form
            ref="sizeForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.barcode"
                      outlined
                      flat
                      dense
                      label="Barcode"
                      hide-details="auto"
                      autocomplete="off"
                      clearable
                      :rules="barcodeRule"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn color="grey" text rounded :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ProductSizeBarcodeEditDialog',
  props: {
    productSize: {
      type: Object,
      required: true,
      default: () => ({})
    },
    edit: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valid: true,
      barcodeRule: [
        v => !!/^null$/.test(v) || !!/^\d+$/.test(v) || 'Barcode can only contain numbers or clear using the X'
      ],
      sizeGroupID: null
    }
  },
  computed: {
    item() {
      return this._.cloneDeep(this.productSize)
    }
  },
  watch: {},
  methods: {
    close() {
      this.$emit('close-product-size-barcode-edit-dialog', false)

      this.$nextTick(() => {
        this.$refs.sizeForm.reset()
      })
    },
    save() {
      var data = {
        id: this.item.id,
        barcode: this.item.barcode
      }

      var that = this

      if (this.$refs.sizeForm.validate()) {
        this.$emit('save-product-size-barcode-edit-dialog', data)
        this.$nextTick(() => {
          if (that.errorMsg === '') {
            that.$refs.sizeForm.reset()
          } else {
            that.$refs.sizeForm.resetValidation()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.size-delimiter {
  &:last-child {
    span {
      visibility: hidden;
    }
  }
}
</style>
