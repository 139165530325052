<template>
  <div class="page-wrap">
    <warehouse-detail-header
      :warehouse="warehouse"
      :auth-level="authLevel"
    ></warehouse-detail-header>

    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <router-view
          :warehouse-providers="warehouseProviders"
          :warehouse="warehouse"
          :current-user="currentUser"
          :auth-level="authLevel"
          :loading="$apollo.loading"
          @refresh-warehouse="refreshWarehouse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WarehouseDetailHeader from '@/modules/production/components/core/WarehouseDetailHeader.vue'
import WAREHOUSE from '@/graphql/Warehouse.gql'
import WAREHOUSE_PROVIDER_LIST from '@/graphql/WarehouseProviders.gql'

export default {
  name: 'WarehouseDetailLayout',
  components: {
    WarehouseDetailHeader
  },
  data: () => ({}),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('warehouses')
    }
  },
  apollo: {
    warehouse: {
      query: WAREHOUSE,
      variables() {
        return {
          id: this.$route.params.warehouseID
        }
      }
    },
    warehouseProviders: {
      query: WAREHOUSE_PROVIDER_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  watch: {},
  created() {},
  methods: {
    refreshWarehouse() {
      this.$apollo.queries.warehouse.refetch()
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 92px;
  /* --page-wrap-offset: -20px; */
}
</style>
