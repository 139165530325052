var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),(_vm.errorMsg !== '')?_c('div',{staticClass:"pa-3 pb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])],1):_vm._e(),(_vm.item)?_c('div',[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-form',{ref:"manufacturingOrderForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"id":"scroll-target","justify":"center"}},[_c('v-col',[_c('v-row',[(!_vm.editMode || (_vm.editDocketRef && _vm.editMode))?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"off","outlined":"","label":"Docket Ref","placeholder":"Docket Ref","dense":"","rules":_vm.requiredRule,"hide-details":"auto"},model:{value:(_vm.item.docketRef),callback:function ($$v) {_vm.$set(_vm.item, "docketRef", $$v)},expression:"item.docketRef"}})],1):_vm._e(),(!_vm.editMode || (_vm.editOrderDate && _vm.editMode))?_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedOrderDate,"autocomplete":"off","outlined":"","label":"Order Date","placeholder":"Order Date","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","hide-details":"auto","readonly":"","rules":_vm.requiredRule}},'v-text-field',attrs,false),on))]}}],null,false,6530885),model:{value:(_vm.orderDate),callback:function ($$v) {_vm.orderDate=$$v},expression:"orderDate"}},[_c('v-date-picker',{attrs:{"no-title":"","hide-details":"","first-day-of-week":"1"},on:{"input":function($event){_vm.endDate = false}},model:{value:(_vm.item.orderDate),callback:function ($$v) {_vm.$set(_vm.item, "orderDate", $$v)},expression:"item.orderDate"}})],1)],1):_vm._e(),(!_vm.editMode || (_vm.editManufacturerAddress && _vm.editMode))?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.manufacturers,"search-input":_vm.searchManufacturer,"item-text":"name","item-value":"id","return-object":"","label":"Manufacturer","placeholder":"Select a Manufacturer","autocomplete":"off","hide-details":"auto","outlined":"","rules":_vm.requiredSelectRule,"disabled":_vm.editMode || _vm.manufacturerId !== null,"dense":""},on:{"update:searchInput":function($event){_vm.searchManufacturer=$event},"update:search-input":function($event){_vm.searchManufacturer=$event}},model:{value:(_vm.item.manufacturer),callback:function ($$v) {_vm.$set(_vm.item, "manufacturer", $$v)},expression:"item.manufacturer"}})],1)],1)],1):_vm._e(),(!_vm.editMode || (_vm.editManufacturerAddress && _vm.editMode))?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.item.manufacturer && _vm.item.manufacturer.addresses
                            ? _vm.item.manufacturer.addresses
                            : [],"item-text":"name","item-value":"id","label":"Manufacturer Location","placeholder":"Select Manufacturing Address","autocomplete":"off","hide-details":"auto","outlined":"","rules":_vm.requiredSelectRule,"disabled":!_vm.item.manufacturer,"dense":""},model:{value:(_vm.item.manufacturerAddressID),callback:function ($$v) {_vm.$set(_vm.item, "manufacturerAddressID", $$v)},expression:"item.manufacturerAddressID"}})],1)],1)],1):_vm._e(),(!_vm.editMode || (_vm.editDeliveryAddress && _vm.editMode))?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.warehouses.items,"item-text":"name","item-value":"id","label":"Deliver To","placeholder":"Select Delivery Address","autocomplete":"off","hide-details":"auto","outlined":"","rules":_vm.requiredSelectRule,"disabled":!_vm.item.manufacturer || !_vm.item.manufacturerAddressID,"dense":""},model:{value:(_vm.item.deliverToID),callback:function ($$v) {_vm.$set(_vm.item, "deliverToID", $$v)},expression:"item.deliverToID"}})],1)],1)],1):_vm._e(),(!_vm.editMode)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.seasons,"item-text":"longName","item-value":"id","label":"Season","placeholder":"Select a Season","autocomplete":"off","hide-details":"auto","outlined":"","rules":_vm.requiredSelectRule,"disabled":!_vm.item.manufacturer || !_vm.item.manufacturerAddressID,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.longName)+" ("+_vm._s(item.shortName)+") ")]}},{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.longName)+" ("+_vm._s(item.shortName)+") ")]}}],null,false,1998606940),model:{value:(_vm.item.seasonID),callback:function ($$v) {_vm.$set(_vm.item, "seasonID", $$v)},expression:"item.seasonID"}})],1)],1)],1):_vm._e(),(_vm.editDeliveryDate && _vm.editMode)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"autocomplete":"off","close-on-content-click":true,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedStartDate,"outlined":"","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","label":"ex-factory","placeholder":"Ex-factory Date","hide-details":"auto","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.item.exFactoryDate = null}}},'v-text-field',attrs,false),on))]}}],null,false,406187074),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-date-picker',{attrs:{"no-title":"","hide-details":"","first-day-of-week":"1"},on:{"input":function($event){_vm.startDate = false}},model:{value:(_vm.item.exFactoryDate),callback:function ($$v) {_vm.$set(_vm.item, "exFactoryDate", $$v)},expression:"item.exFactoryDate"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedEndDate,"autocomplete":"off","outlined":"","label":"arrival","placeholder":"Arrival Date","prepend-inner-icon":"mdi-calendar-month-outline","dense":"","hide-details":"auto","readonly":"","clearable":"","disabled":!_vm.item.exFactoryDate && !_vm.item.arrivalDate},on:{"click:clear":function($event){_vm.item.arrivalDate = null}}},'v-text-field',attrs,false),on))]}}],null,false,3295352404),model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-date-picker',{attrs:{"min":_vm.$moment(_vm.item.exFactoryDate)
                                  .add(1, 'days')
                                  .format('YYYY-MM-DD'),"no-title":"","hide-details":"","first-day-of-week":"1"},on:{"input":function($event){_vm.endDate = false}},model:{value:(_vm.item.arrivalDate),callback:function ($$v) {_vm.$set(_vm.item, "arrivalDate", $$v)},expression:"item.arrivalDate"}})],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.editNote && _vm.editMode)?_c('v-col',{staticClass:"pt-2",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"placeholder":"Notes you want to add to the order.","autocomplete":"off","hide-details":"auto","outlined":"","persistent-hint":"","dense":""},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)]):_vm._e(),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey lighten-1","disabled":_vm.processing},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary lighten-2","rounded":"","loading":_vm.processing},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }