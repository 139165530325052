<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col cols="12">
              <v-alert text color="info" border="left">
                Select any availble reports below. Please contact your
                administrator if you feel you are missing a report.
              </v-alert>
            </v-col>
            <v-col cols="12" sm="10" xl="8">
              <v-expansion-panels v-model="panel" flat multiple>
                <v-expansion-panel
                  v-if="productReportAuthLevel > 0"
                  class="mb-1"
                >
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Product Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none"
                          :to="{ name: 'ProductionReportProductList' }"
                          >Product List</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="10"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of products by season and size. Includes costs and
                        prices.
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="styleReportAuthLevel > 0">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Style Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none text"
                          :to="{ name: 'ProductionReportStyleList' }"
                          >Style List</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="10"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of styles by season with costs and prices.
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductionReportList',
  components: {},
  data: () => ({ panel: [0, 1] }),
  computed: {
    productReportAuthLevel() {
      return this.$store.getters.authLevel('product_reports')
    },
    styleReportAuthLevel() {
      return this.$store.getters.authLevel('style_reports')
    }
  },
  apollo: {},
  methods: {}
}
</script>

<style></style>
