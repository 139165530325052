<template>
  <div>
    <div class="pt-0 pb-1">
      <v-dialog v-model="deleteDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Step</v-card-title
          >
          <v-card-text>
            <p>
              This will delete this step. Existing workflows in use will not be
              effected by this change.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey darken-1" text @click="deleteDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="error" text @click="deleteStep">Remove</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-form ref="newItemForm" v-model="valid" lazy-validation @submit.prevent>
        <v-text-field
          v-model="newItem"
          dense
          outlined
          background-color="white"
          color="info"
          placeholder="New Step"
          hide-details
          autocomplete="off"
          clearable
          :rules="requiredRule"
          :disabled="editIndex !== null"
          @keyup.enter="addNewItem"
        >
          <template v-slot:append-outer>
            <v-btn
              color="info"
              style="top: -6px"
              :disabled="editIndex !== null"
              @click="addNewItem"
              >add</v-btn
            >
          </template>
        </v-text-field>
      </v-form>
    </div>
    <div v-if="workflow.steps && workflow.steps.length > 0" :key="workflow.id">
      <draggable
        v-model="workflow.steps"
        class="list-group"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        @change="moveStep"
      >
        <transition-group class="item-placeholder">
          <div v-for="(item, idx) in workflow.steps" :key="item.order">
            <workflow-step-list-item
              :item="item"
              :current-index="idx"
              :edit-index="editIndex"
              @edit-step="editSteps"
              @delete-step="openDeleteStepDialog(idx)"
              @set-edit-index="editIndex = idx"
              @reset-edit-index="editIndex = null"
            />
          </div>
        </transition-group>
      </draggable>
    </div>

    <div v-else>
      <message-box
        icon="mdi-emoticon-neutral-outline"
        title="No Steps"
        caption="Add steps to your workflow in sequence"
      ></message-box>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import WorkflowStepListItem from './WorkflowStepListItem'
import MessageBox from './MessageBox'

export default {
  name: 'WorkflowStepList',
  components: { WorkflowStepListItem, draggable, MessageBox },
  props: {
    selectedWorkflow: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    drag: false,
    newItem: null,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    deleteDialog: false,
    editIndex: null,
    currentIndex: null
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'name',
        disabled: this.disableDrag,
        ghostClass: 'ghost',
        handle: '.handle'
      }
    },
    disableDrag() {
      return this.editIndex === null ? false : true
    },
    workflow() {
      return this.selectedWorkflow
    }
  },
  watch: {
    selectedWorkflow() {
      this.editIndex = null
      this.currentIndex = null
    }
  },
  methods: {
    moveStep() {
      this.editSteps()
    },
    editSteps() {
      let workflow = this._.cloneDeep(this.workflow)

      workflow.steps.forEach((element, idx) => {
        element.order = idx + 1
      })

      this.$emit('edit-steps', workflow)
    },
    openDeleteStepDialog(idx) {
      this.currentIndex = idx
      this.deleteDialog = true
    },
    deleteStep() {
      let workflow = this._.cloneDeep(this.workflow)

      workflow.steps.splice(this.currentIndex, 1)

      workflow.steps.forEach((element, idx) => {
        element.order = idx + 1
      })

      this.$emit('edit-steps', workflow)

      this.currentIndex = null
      this.deleteDialog = false
    },
    addNewItem() {
      if (this.$refs.newItemForm.validate()) {
        let item = {
          title: this.newItem
        }

        let workflow = this._.cloneDeep(this.workflow)

        workflow.steps.push(item)
        workflow.steps.forEach((element, idx) => {
          element.order = idx + 1
        })

        this.$emit('edit-steps', workflow)

        this.$refs.newItemForm.reset()
      }
    }
  }
}
</script>

<style></style>
