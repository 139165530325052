var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":"","clearable":"","autocomplete":"off","disabled":!_vm.productsByManufacturer},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('season-switcher',{attrs:{"background-color":'#fff',"items":_vm.seasons.seasons,"outlined":false,"dense":false,"disabled":_vm.$apollo.queries.seasons.loading ||
                  !_vm.seasons ||
                  _vm.seasons.seasons.length === 0}})],1)],1)],1),_c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"loading":_vm.$apollo.loading,"options":_vm.options,"server-items-length":_vm.itemCount,"items":_vm.productsByManufacturer.items,"mobile-breakpoint":null,"hide-default-footer":!_vm.productsByManufacturer || _vm.itemCount < 16},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.styleState",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[_c('v-chip',{attrs:{"pill":"","x-small":"","color":_vm.styleState(item.styleState),"text-color":_vm.styleText(item.styleState)}},[_vm._v(" "+_vm._s(item.styleState)+" ")])],1)]}},{key:"item.category",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[(item.category)?_c('span',[_vm._v(_vm._s(item.category.name))]):_vm._e()])]}},{key:"item.color",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-truncate primary--text"},[(item.color.id)?_c('span',[_vm._v(_vm._s(item.color.name)+" ("+_vm._s(item.color.colorCode)+")")]):_vm._e()])]}},{key:"item.designers",fn:function(ref){
                  var item = ref.item;
return [(item.designers && item.designers.length > 0)?_c('div',{staticClass:"text-truncate"},[_vm._l((item.designers),function(designer,index){return [(index < 1)?_c('span',{key:designer.id,staticClass:"mr-2"},[_vm._v(_vm._s(designer.firstName + ' ' + designer.lastName))]):_vm._e(),(index > 0)?_c('span',{key:designer.id,staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(item.designers.length - 1)+" others)")]):_vm._e()]})],2):_vm._e()]}},{key:"no-data",fn:function(){return [(!_vm.$apollo.loading)?_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Results","caption":"Try adjusting your filters to get some results or add the manufacturer to a product"}}):_vm._e()]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }