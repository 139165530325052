<template>
  <div class="page-wrap">
    <div class="page-header">
      <v-toolbar class="mb-0 b-radius" flat>
        <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
        <v-row>
          <v-col cols="6" lg="3" xl="3">
            <v-select
              hide-details
              outlined
              flat
              dense
              clearable
              label="season"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              :disabled="!sampleOrders || sampleOrders.length === 0"
            >
            </v-select>
          </v-col>

          <v-col cols="4" lg="3" xl="3">
            <v-text-field
              v-model="search"
              hide-details
              outlined
              flat
              dense
              clearable
              background-color="#fff"
              placeholder="Search..."
              prepend-inner-icon="mdi-magnify"
              autocomplete="off"
              :disabled="!sampleOrders || sampleOrders.length === 0"
            ></v-text-field>
          </v-col>

          <v-col
            cols="4"
            lg="3"
            xl="3"
            class="d-none d-sm-none d-md-none d-lg-flex"
          >
            <v-select
              hide-details
              outlined
              flat
              dense
              clearable
              label="producer"
              placeholder="all"
              prepend-inner-icon="mdi-filter-variant"
              autocomplete="off"
              :disabled="!sampleOrders || sampleOrders.length === 0"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-btn
          icon
          class="d-sm-flex d-md-flex d-lg-none d-xl-none ml-2"
          @click="filters = !filters"
        >
          <v-icon :color="filters ? 'info' : ''">mdi-filter-variant</v-icon>
        </v-btn>

        <v-btn class="ml-2" color="primary" fab small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <v-card v-if="filters" class="mb-3">
          <div>
            <v-col cols="12">
              <v-row no-gutters>
                <span>Advanced Filters</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="filters = !filters"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-select
                outlined
                flat
                dense
                clearable
                label="producer"
                placeholder="all"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
                :disabled="!sampleOrders || sampleOrders.length === 0"
              >
              </v-select>
            </v-col>
          </div>
        </v-card>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="sampleOrders"
          fixed-header
          show-select
          hide-default-footer
        >
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductionSampleOrderList',
  data: () => ({
    filters: false,
    search: null,
    selected: [],
    sampleOrders: []
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Order No.',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'id'
        },
        {
          text: 'Season',
          value: 'season',
          sortable: true,
          filterable: true
        },
        {
          text: 'Ordered By',
          value: 'orderedBy.firstName',
          sortable: true,
          filterable: true
        },
        {
          text: 'Delivery Date',
          value: 'deliveryDate',
          sortable: true,
          filterable: true
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
          // sortable: true,
          filter: this.stateFilterValue
        }
      ]
    }
  },
  methods: {
    showSnackbar() {
      this.$store.commit('showSnackbar', { active: true })
    }
  }
}
</script>

<style></style>
