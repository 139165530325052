<template>
  <div style="overflow-x: auto">
    <div class="px-0" style="white-space: nowrap;">
      <!-- <draggable
        v-model="orderedColumns"
        v-bind="dragOptions"
        handle=".tboard-column-handle"
      > -->
      <task-board-column
        v-for="col in orderedColumns"
        :key="col.id"
        :column="col"
        :items="filteredItems(col)"
      ></task-board-column>
      <!-- </draggable> -->
    </div>
  </div>
</template>

<script>
import TaskBoardColumn from '@/modules/production/components/core/TaskBoardColumn.vue'

export default {
  name: 'TaskBoard',
  components: {
    TaskBoardColumn
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    columnIdName: {
      type: String,
      default: 'id'
    },
    listColumnIdName: {
      type: String,
      required: true,
      default: null
    },
    updateColumnOrder: {
      type: Function,
      default: () => ({})
    },
    orderItemsBy: {
      type: String,
      default: null
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'rank',
        disabled: false,
        ghostClass: 'ghost',
        dragoverBubble: true
      }
    },
    orderedColumns: {
      get() {
        // return this.columns
        return this._.orderBy(this.columns, 'rank', 'asc')
      },
      set(data) {
        if (data.length > 0) {
          if (data[0].rank === 0 && data[0].inbox) {
            this.$emit('update-task-board-column-order', data)
          }
        }
      }
    },
    taskItems() {
      return this.items
    }
  },
  methods: {
    filteredItems(col) {
      var vm = this
      if (this.taskItems && this.taskItems.length > 0) {
        // var colID = col[this.columnIdName]
        // var result = this._.filter(this.taskItems, {
        //   [this.listColumnIdName]: colID
        // })
        // return result
        var list = vm._.filter(vm.taskItems, function(item) {
          var itemScopeID = vm._.get(item, vm.listColumnIdName)
          var colID = vm._.get(col, vm.columnIdName)
          return itemScopeID === colID
        })

        return list
      }
      return []
    }
  }
}
</script>

<style></style>
