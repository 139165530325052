<template>
  <div class="app-layout">
    <category-edit-dialog
      :open="dialog"
      :edited-item="editedItem"
      :edit-mode="edit"
      @close-edit-category-dialog="close"
    ></category-edit-dialog>
    <div class="page-wrap">
      <ProductionSettingsToolbar>
        <template v-slot:controls>
          <v-btn
            v-if="authLevel === 2"
            fab
            small
            color="primary"
            @click="newItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </ProductionSettingsToolbar>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col sm="12" md="10" lg="8" xl="8">
              <v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                    clearable
                    autocomplete="off"
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="productCategories"
                  :search="search"
                  :loading="$apollo.loading"
                  :mobile-breakpoint="null"
                  class="plain-table"
                  hide-default-footer
                >
                  <template v-slot:no-data>Nothing here yet...</template>
                  <template v-slot:item.controls="{ item }">
                    <v-btn icon small
                      ><v-icon small @click.stop="editItem(item)"
                        >mdi-pencil-outline</v-icon
                      ></v-btn
                    >
                  </template>
                  <template v-slot:item.updatedAt="{ item }">
                    {{ item.updatedAt | moment('DD MMM YYYY') }}
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductionSettingsToolbar from '@/modules/production/components/base/ProductionSettingsToolbar.vue'
import CategoryEditDialog from '@/modules/production/components/core/CategoryEditDialog.vue'
import CATEGORIES from '@/graphql/ProductCategories.gql'

export default {
  name: 'ProductionSettingsCategories',
  components: {
    ProductionSettingsToolbar,
    CategoryEditDialog
  },
  data: () => ({
    dialog: false,
    edit: false,
    search: '',
    colors: [],
    defaultItem: {
      id: '',
      name: '',
      description: '',
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      name: '',
      description: '',
      updatedAt: new Date(),
      createdAt: new Date()
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Code',
          value: 'categoryCode',
          align: 'left',
          sortable: true
        },
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: true
        },
        {
          text: 'Description',
          value: 'description',
          align: 'left',
          sortable: true
        },
        {
          text: 'Last Update',
          value: 'updatedAt',
          align: 'left',
          sortable: true
        },
        {
          text: '',
          value: 'controls',
          align: 'right',
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('production_settings')
    }
  },
  apollo: {
    productCategories: CATEGORIES
  },
  methods: {
    close(success) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Category Saved'
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },

    editItem(item) {
      this.edit = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    }
  }
}
</script>

<style></style>
