var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('season-edit-dialog',{attrs:{"open":_vm.dialog,"edited-item":_vm.editedItem,"edit-mode":_vm.edit},on:{"close-edit-season-dialog":_vm.close}}),(_vm.season)?_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8","xl":"6"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-3"},[_c('span',[_vm._v("Details")]),_c('v-spacer'),(
            _vm.season &&
              _vm.authLevel &&
              _vm.authLevel === 2 &&
              _vm.currentUser &&
              (_vm.currentUser.isSuperAdmin ||
                _vm.currentUser.userRole === 'director' ||
                _vm.currentUser.userRole === 'seniormanager' ||
                _vm.currentUser.userRole === 'projectmanager')
          )?_c('v-btn',{attrs:{"color":"primary","rounded":"","small":""},on:{"click":_vm.editSeason}},[_vm._v("edit ")]):_vm._e()],1),_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_vm._v("Name")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.season.longName)+" ("+_vm._s(_vm.season.shortName)+") ")]),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"mb-2"},[_vm._v("Style Number Prefix")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.season.styleNumberPrefix))]),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"mb-2"},[_vm._v("Season Period")]),_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('v-col',[_vm._v(" From: "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.season.from,'DD MMMM YYYY')))])]),_c('v-col',[_vm._v(" Until: "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.season.till,'DD MMMM YYYY')))])])],1),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"mb-2"},[_vm._v("Prebook Period")]),_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('v-col',[_vm._v(" From: "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.season.prebookStartDate,'DD MMMM YYYY')))])]),_c('v-col',[_vm._v(" Until: "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.season.prebookEndDate,'DD MMMM YYYY')))])])],1),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"mb-2"},[_vm._v("First Drop Period")]),_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('v-col',[_vm._v(" From: "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.season.firstDropStartDate,'DD MMMM YYYY')))])]),_c('v-col',[_vm._v(" Until: "),_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.season.firstDropEndDate,'DD MMMM YYYY')))])])],1)],1),_c('v-card-actions')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }